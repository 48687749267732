<div class="container">
    <div class="enrlmnt-demographic">
        <div class="stepper">
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
        </div>
    </div>

    <!-- <div class="mb-3">
        <app-reusable-applicationform (applicantdata)="handleApplicantData($event)"></app-reusable-applicationform>
    </div> -->



    <div class="row" *ngIf="!isSubmitted">
        <div class="col-md-8">
            <p-accordion [activeIndex]="active_index">
                <p-accordionTab header="Member Information" (click)="enrollmentForm('member')">
                    <form [formGroup]="enrollment_form">
                    
                        <div class="row mt-4">
                            <div class="col-md-6 form-group">
                                <label class="required">First Name </label>
                                <input maxlength="25" type="text" autocomplete="off" formControlName="firstname"
                                    class="form-control" placeholder="First Name">
                                <small class="errormsg"
                                    *ngIf="!enrollment_form.controls.firstname.valid && (enrollment_form.controls.firstname.touched || submitAttempt)">
                                    First Name is required
                                </small>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="required">Last Name </label>
                                <input maxlength="25" type="text" autocomplete="off" formControlName="lastname"
                                    class="form-control" placeholder="Last Name">
                                <small class="errormsg"
                                    *ngIf="!enrollment_form.controls.lastname.valid && (enrollment_form.controls.lastname.touched || submitAttempt)">
                                    Last Name is required
                                </small>
                            </div>
                            <div class="col-md-6 form-group ">
                                <label class="required">Email</label>
                                <input type="text" placeholder="Email" class="form-control marginTop"
                                    formControlName="email">
                                <small class="errormsg" *ngIf="errorMsgShow">
                                    {{emailError}}
                                </small>
                                <small class="errormsg"
                                    *ngIf="!enrollment_form.controls['email'].valid && (enrollment_form.controls['email'].touched || submitAttempt)">
                                    Email is required.
                                </small>
                                <small class="errormsg"
                                    *ngIf="enrollment_form.controls['email'].hasError('pattern') && (enrollment_form.controls['email'].dirty || enrollment_form.controls['email'].touched)">Please
                                    enter valid email address</small>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="required">Phone </label>
                                <input type="text" maxlength="12" placeholder="Phone" autocomplete="off"
                                    (keypress)="emailValidation($event.target.value);numberOnly($event)"
                                    id="phone-number" (focusout)="onleavePhonenumber(enrollment_form.value)" appPhonenumberValidation
                                    class="form-control marginTop" formControlName="phone">
                                <small class="errormsg" *ngIf="errorMsgShow"> {{phoneerrmsg}}</small>
                                <small class="errormsg"
                                    *ngIf="!enrollment_form.controls['phone'].valid && (enrollment_form.controls['phone'].touched || submitAttempt)">
                                    Phone is required.
                                </small>
                                <small class="errormsg">
                                    {{phnumbererrmsg}}
                                </small>
                            </div>
                            <div class="col-md-4  form-group ">
                                <label class="required"> Birth Date (mm/dd/yyyy) </label>
                                <p-calendar placeholder="mm/dd/yyyy" (onInput)="ageCalculate(enrollment_form.value)"
                                    [style]="{'width': '100%','display': 'inline-flex','height':'50px','background': '#ffa500' }"
                                    formControlName="dob" [maxDate]="minimumDate" [disabledDates]="invalidDates1"
                                    [monthNavigator]="true" [yearNavigator]="true"
                                    [yearRange]="'1904:' + maxDate.getFullYear()" inputId="navigators" [showIcon]="true"
                                    inputId="icon" (onSelect)="onSearchChange($event)">
                                </p-calendar>
                                <div class="errormsg mt-2"
                                    *ngIf="!enrollment_form.controls['dob'].valid && (enrollment_form.controls['dob'].touched || submitAttempt)">
                                    DOB is required
                                </div>
                                <!-- <small class="errormsg mt-2">{{parentAge}}</small>
                            <small class="errormsg">{{parentAge1}}</small> -->
                                <div class="text_info">
                                    <i data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Age is automatically calculated from the DOB."
                                        class="fa fa-question-circle-o"></i> Age is automatically calculated
                                    from the DOB.
                                </div>
                            </div>
                            <div class="col-md-2 form-group">
                                <label class=" alignCenter">Age</label>
                                <input class="form-control" placeholder="0" [readonly]="true" formControlName="age">
                            </div>
                            <!-- <div class="col-md-6 form-group ">
                                <label class="required">Gender</label>
                                <div class="form-check">
                                    <div class="row mt-4">
                                        <div class="col-3">
                                            <input class="form-check-input" type="radio" value="Male"
                                                formControlName="gender">
                                            <span class="form-check-label">
                                                Male
                                            </span>
                                        </div>
                                        <div class="col-3">
                                            <input class="form-check-input" type="radio" value="Female"
                                                formControlName="gender">
                                            <span class="form-check-label">
                                                Female
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <small class="errormsg"
                                    *ngIf="!enrollment_form.controls.gender.valid && (enrollment_form.controls.gender.dirty || submitAttempt)">
                                    Gender is required
                                </small>
                            </div> -->
                            <div class="col-md-6 form-group ">
                                <label class="form-label"> Dependents </label>
                                <select formControlName="member_count" class="form-select"
                                    aria-label="Default select example" #mySelect
                                    (change)="onOptionsSelected(mySelect.value)">
                                    <option disabled selected Value="">Select Your Dependents
                                    </option>
                                    <option [value]=item.value *ngFor="let item of members">
                                        {{item.value}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-12 form-group text-center">
                                <button type="button" class="btn btn-primary"
                                    (click)="memberEnrollment(enrollment_form.value)">Next</button>
                            </div>
                        </div>
                    </form>
                </p-accordionTab>
                <p-accordionTab header="Dependents" [class.disablePointerEvents]="display_dependents_section"
                    (click)="enrollmentForm('dependent')" [disabled]="display_dependents_section"
                    *ngIf="display_dependents">
                    <form [formGroup]="enrollment_form">
                        <div formArrayName="child_Array">
                            <div *ngFor="let childForm of child_Array().controls; let empIndex=index">
                                <div class="form-card-header  py-1 mb-1">
                                    <h6 class="title">Dependent - {{empIndex +1}}</h6>
                                    <!-- <button type="button" class="btn  btn-outline-danger btn-sm"
                                        (click)="removeEmployee(empIndex,childForm.value)"><i
                                            class="ri-user-unfollow-line"></i>
                                        Remove</button> -->
                                </div>
                                <div class="" [formGroupName]="empIndex">
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <label class="required">First Name </label>
                                            <input class="form-control" maxlength="25" type="text"
                                                placeholder="First Name" autocomplete="off"
                                                formControlName="childFirstname">
                                            <small class="errormsg"
                                                *ngIf="!childForm.controls.childFirstname.valid && (childForm.controls.childFirstname.touched || submitAttempt)">
                                                First Name is required
                                            </small>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="required">Last Name</label>
                                            <input type="text" placeholder="Last Name" maxlength="25"
                                                class="form-control marginTop" formControlName="childLastname">
                                            <small class="errormsg"
                                                *ngIf="!childForm.controls.childLastname.valid && (childForm.controls.childLastname.touched || submitAttempt)">
                                                Last Name is required.
                                            </small>
                                        </div>


                                        <div class="col-md-6  form-group ">
                                            <label class="required"> Birth Date (mm/dd/yyyy) </label>

                                            <p-calendar   (onSelect)="
                                            childAgeCal($event, empIndex, 'dob' + empIndex,childForm)
                                          " [disabledDates]="invalidDates1" [maxDate]="minimumDate"
                                                [monthNavigator]="true" [showIcon]="true" [style]="{
                                            width: '100%',
                                            display: 'inline-flex',
                                            height: '48px'
                                          }" [yearNavigator]="true"
                                          (onInput)="childAgeCalculate(empIndex,childForm)" [yearRange]="'1994:' + maxDate.getFullYear()"
                                                formControlName="child_dob" inputId="navigators"
                                                inputId="{{ 'dob' + empIndex }}" placeholder="mm/dd/yyyy">
                                            </p-calendar>

                                            <div class="text_info">
                                                <i data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Age is automatically calculated from the DOB."
                                                    class="fa fa-question-circle-o"></i> Age is automatically calculated
                                                from the DOB.
                                            </div>

                                            <div class="errormsg mt-2"
                                            *ngIf="!childForm.controls?.child_dob?.valid && (childForm.controls?.child_dob?.touched || submitAttempt)">
                                            DOB is required
                                        </div>

                                            <!-- <div class="errormsg mt-2"
                                    *ngIf="!childForm.controls?.child_check?.valid && (childForm.controls?.child_check?.touched || submitAttempt)">
                                    Child(ren) over the age 26 need to enroll in their own Program as the Primary Member
                                </div> -->
                                        </div>


                                        <div class="col-md-2 form-group">
                                            <label class=" alignCenter">Age</label>
                                            <input class="form-control" placeholder="0" [readonly]="true" formControlName="child_age">
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 form-group text-center">
                                <button type="button" class="btn btn-primary"
                                    (click)="memberEnrollment(enrollment_form.value)">Next</button>
                            </div>
                        </div>
                    </form>
                </p-accordionTab>
                <!-- [disabled]="display_payment_section" -->
                <p-accordionTab header="Payment Details" [class.disablePointerEvents]="display_payment_section"
                    (click)="enrollmentForm('payment')" [disabled]="display_payment_section">
                    <h6 class="mb-3">Select the type of payment mode</h6>
                    <div class="paymentType">
                        <form [formGroup]="paymentType" class="d-flex ">
                            <ng-container *ngFor="let type of paymentTypes">
                                <label class="d-flex gap-3">
                                    <input type="radio" value="{{type.value}}" id="{{'payment'+type.value}}"
                                        formControlName="type" (click)="onPaymentTypeChange(type.value)" />
                                    {{ type.name }}
                                </label>
                            </ng-container>
                        </form>
                    </div>
                    <div class="paymentinfo mt-4">
                        <form [formGroup]="Credit_Form" class="payment-form"
                            *ngIf=" choosenPayemntType == 'credit' || choosenPayemntType == 'debit'">
                            <div class="row">
                                <div class="col-md-8 form-group">
                                    <input type="text" class="form-control" formControlName="name"
                                        placeholder="Name on Card">
                                    <div class="errormsg"
                                        *ngIf="!Credit_Form.controls.name.valid && (Credit_Form.controls.name.touched || submitCardAttempt)">
                                        Name on card is required
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8 form-group">
                                    <input type="text" class="form-control" formControlName="cardNumber"
                                        placeholder="Card Number" #SelectList (keyup)="onKeyup(SelectList.value)"
                                        (keypress)="numberOnly($event)" (paste)="removeSpaces($event)" maxlength="16">
                                    <div class="errormsg"
                                        *ngIf="!Credit_Form.controls.cardNumber.valid && (Credit_Form.controls.cardNumber.touched || submitCardAttempt)">
                                        Card Number is required
                                    </div>
                                    <div class="errormsg"
                                        *ngIf="(Credit_Form.controls.cardNumber.dirty && Credit_Form.controls.cardNumber.touched && notValidCardNumber) || (Credit_Form.controls.cardNumber.dirty && Credit_Form.controls.cardNumber.touched && !checkCardValid)">
                                        Please enter a Valid Card Number
                                    </div>
                                    <div class="errormsg" *ngIf="!!card_number_Err">{{card_number_Err}}</div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-4 col-5">
                                                <input type="text" name="month" #selectListExpiryMonth
                                                    formControlName="expiryMonth" numbersOnly placeholder="MM"
                                                    maxlength="2" size="2" class="form-control" />
                                            </div>
                                            <div class="col-md-2 col-2 slash">
                                                <span class="slash_symbol">/</span>
                                            </div>
                                            <div class="col-md-4 col-5">
                                                <input type="text" name="year" #selectListExpiryYear
                                                    formControlName="expiryYear" numbersOnly placeholder="YY"
                                                    maxlength="2" size="2" class="form-control" />
                                            </div>
                                        </div>
                                        <!-- Error for when any field is untouched but an attempt to submit has been made -->
                                        <span class="form-validate-error errormsg"
                                            *ngIf="(Credit_Form.controls.expiryMonth.errors?.required && (Credit_Form.controls.expiryMonth.touched || submitCardAttempt) ||
                              (Credit_Form.controls.expiryYear.errors?.required && (Credit_Form.controls.expiryYear.touched || submitCardAttempt)))">
                                            Expiration Date is required
                                        </span>

                                        <!-- Error for incorrect format after field has been touched or an attempt to submit has been made -->
                                        <span class="form-validate-error errormsg" *ngIf="(((Credit_Form.controls.expiryMonth.touched || submitCardAttempt)) &&
                              (Credit_Form.get('expiryMonth').errors?.pattern ))">
                                            Please use 'MM/YY' format
                                        </span>
                                        <span class="form-validate-error errormsg" *ngIf="((Credit_Form.controls.expiryMonth.dirty && Credit_Form.controls.expiryMonth.touched || submitCardAttempt) ||
                              (Credit_Form.controls.expiryYear.dirty && Credit_Form.controls.expiryYear.touched || submitCardAttempt)) &&
                              Credit_Form.errors?.expiryInvalid && !Credit_Form.get('expiryMonth').errors?.pattern">
                                            Please enter a valid expiration date
                                        </span>
                                    </div>


                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="cvv" minlength="3"
                                            (focusout)="oncvvfocusout()" id="cvvvalue" maxlength="4"
                                            (keypress)="numberOnly($event)" placeholder="CVV">
                                        <div class="errormsg"
                                            *ngIf="!Credit_Form.controls.cvv.valid && (Credit_Form.controls.cvv.touched || submitCardAttempt)">
                                            CVV is required
                                        </div>
                                        <div class="errormsg" *ngIf="!!cvvErr">{{cvvErr}}</div>

                                        <div class="errormsg" id="cvverror">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form [formGroup]="Banking_Form" class="payment-form" *ngIf="choosenPayemntType == 'ach'">
                            <div class="row">
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="name"
                                        placeholder="Banking Name">
                                    <div class="errormsg"
                                        *ngIf="!Banking_Form.controls.name.valid && (Banking_Form.controls.name.touched || submitCardAttempt)">
                                        Banking Name is required
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="routingNumber"
                                            placeholder="Routing Number" #routingNumber
                                            (keyup)=onKeyUpEnterRoutingNumber(routingNumber.value)
                                            (keypress)="numberOnly($event)">
                                        <div class="errormsg"
                                            *ngIf="!Banking_Form.controls.routingNumber.valid && (Banking_Form.controls.routingNumber.touched || submitBankingAttempt)">
                                            Routing Number is required
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="reenterRouting"
                                            #selectListConfirmRoutingNumber (keypress)="numberOnly($event)"
                                            (keyup)="onKeyUpRoutingNumberMismatch(selectListConfirmRoutingNumber.value)"
                                            placeholder="Re-enter Routing Number">
                                        <div class="errormsg"
                                            *ngIf="(!Banking_Form.controls.reenterRouting.dirty && !Banking_Form.controls.reenterRouting.valid) && (Banking_Form.controls.reenterRouting.touched || submitBankingAttempt)">
                                            Re-enter Routing Number is required
                                        </div>
                                        <div class="errormsg"
                                            *ngIf="Banking_Form.controls.reenterRouting.dirty && routingNumberMismatch">
                                            Routing Number and Re-enter Routing Number do not match
                                        </div>
                                    </div>
                                </div> -->
                                <!-- </div>
                            <div class="row"> -->
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="accountNumber"
                                            placeholder="Account Number" minlength="4" #accountNumber
                                            (keyup)=onKeyUpEnterAccountNumber(accountNumber.value)
                                            (keypress)="numberOnly($event)">
                                        <div class="errormsg"
                                            *ngIf="!Banking_Form.controls.accountNumber.valid && (Banking_Form.controls.accountNumber.touched || submitBankingAttempt)">
                                            Account Number is required minimum length 4 digits
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="reenterAccount"
                                            #selectListConfirmAccountNumber (keypress)="numberOnly($event)"
                                            minlength="4"
                                            (keyup)="onKeyUpAccountNumberMismatch(selectListConfirmAccountNumber.value)"
                                            placeholder="Re-enter Account Number">
                                        <div class="errormsg"
                                            *ngIf="(!Banking_Form.controls.reenterAccount.dirty && !Banking_Form.controls.reenterAccount.valid) && (Banking_Form.controls.reenterAccount.touched || submitBankingAttempt)">
                                            Re enter Account Number is required
                                        </div>
                                        <div class="errormsg"
                                            *ngIf="Banking_Form.controls.reenterAccount.dirty && accountNumberMismatch">
                                            Account Number and Re-enter Account Number do not match
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <!-- <div class="d-flex">
                                <label class="gap-3">
                                    <input type="radio" value="C" formControlName="type" />
                                    Checking
                                </label>
                                <label class="gap-3">
                                    <input type="radio" value="S" formControlName="type" />
                                    Saving
                                </label>
                            </div> -->
                            <div class="d-flex custom-radio-group mt-3">
                                <label class="custom-radio-label">
                                    <input type="radio" value="C" formControlName="type" />
                                    Checking
                                </label>
                                <label class="custom-radio-label">
                                    <input type="radio" value="S" formControlName="type" />
                                    Saving
                                </label>
                            </div>
                            <div class="errormsg"
                                *ngIf="!Banking_Form.controls.type.valid && (Banking_Form.controls.type.touched || submitBankingAttempt)">
                                Type is required.
                            </div>

                        </form>
                        <div class="row">

                            <div class="col-md-12 form-group text-center">
                                <button type="button" class="btn btn-primary"
                                    (click)="nextBillingAddress()">Next</button>
                            </div>
                        </div>
                    </div>

                </p-accordionTab>
                <p-accordionTab header="Billing Address" [class.disablePointerEvents]="display_address_section"
                    (click)="enrollmentForm('billing')" [disabled]="display_address_section">
                    <form [formGroup]="shipping_address_form">
                        <div class="row ">
                            <small>Transactions are secure and encrypted.</small>
                        </div>

                        <div class="row mt-3">
                            <h5>Billing address</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <!--<div class="shipping">
                            <input type="checkbox"   [formControl]="shippingAddressControl" [(ngModel)]="isShippingChecked" (change)="onCheckboxChange()">
                            <label for="">Use Shipping address</label>
                          </div>-->
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <form action="">
                                                <div class="form-group">
                                                    <label class="">United States</label>
                                                    <select class="form-control" style="margin-bottom: 15px;">
                                                        <option *ngFor="let item of country_arr">{{item}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="required">Zip Code</label>
                                                <input type="text" class="form-control" formControlName="zip"
                                                    placeholder="ZIP Code" maxlength="5" numbersOnly>
                                                <div class="errormsg"
                                                    *ngIf="!shipping_address_form.controls.zip.valid && (shipping_address_form.controls.zip.touched || submitCardAttempt)">
                                                    Zip Code is required
                                                </div>
                                                <span class="errormsg"
                                                    *ngIf="shipping_address_form.controls['zip'].hasError('pattern') && (shipping_address_form.controls['zip'].dirty )">Please
                                                    enter valid 5 digit zip code</span>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="required">Address 1 </label>
                                                <input type="text" class="form-control" formControlName="address1"
                                                    placeholder="Address 1">
                                                <div class="errormsg"
                                                    *ngIf="!shipping_address_form.controls.address1.valid && (shipping_address_form.controls.address1.touched || submitCardAttempt)">
                                                    Address is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="">Address 2 </label>
                                                <input type="text" class="form-control" placeholder="Address 2"
                                                    formControlName="address2">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="required">City</label>
                                                        <input type="text" class="form-control" formControlName="city"
                                                            placeholder="City">
                                                        <div class="errormsg"
                                                            *ngIf="!shipping_address_form.controls.city.valid && (shipping_address_form.controls.city.touched || submitCardAttempt)">
                                                            City is required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="required">State</label>
                                                        <select class="form-control form-select" formControlName="state"
                                                            aria-label="Default select example" id="state">
                                                            <option disabled value="">Select Your State</option>
                                                            <option [value]=item.name *ngFor="let item of usaStates">
                                                                {{item.name}}
                                                            </option>
                                                        </select>
                                                        <div class="errormsg"
                                                            *ngIf="!shipping_address_form.controls.state.valid && (shipping_address_form.controls.state.touched || submitCardAttempt)">
                                                            State is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 form-group text-center">
                                <button type="button" class="btn btn-primary" (click)="Next()">Next</button>
                            </div>
                        </div>
                    </form>
                </p-accordionTab>
                <!-- [disabled]="display_summary_section" *ngIf="showDiscountSection"- Change Effective Date---->
                <p-accordionTab header="Summary" [class.disablePointerEvents]="display_summary_section"
                    (click)="enrollmentForm('summary')" [disabled]="display_summary_section">
                    <div class="row mt-3" *ngIf="enrollment_type == 'ACC' ||  !!agent_Id">
                        <div class="col-lg-12">
                            <span class=" terms" (click)="ChangeEffectiveDate()">Change Effective Date</span>
                            <h5></h5>
                        </div>
                        <div class="col-md-4" *ngIf="display_effective_date">
                            <div class="form-group">
                                <p-calendar placeholder="mm/dd/yyyy"
                                    [style]="{'width': '100%','display': 'inline-flex','height':'40px'}"
                                    [monthNavigator]="true" inputId="navigators" [showIcon]="true" inputId="icon"
                                    [maxDate]="effectiveMaxDate" [minDate]="effectiveMinDate"
                                    [(ngModel)]="change_effective_date" [ngModelOptions]="{standalone: true}">
                                </p-calendar>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngFor="let item of cart_products">
                        <div class="col-md-8">
                            <h6>{{item.family_type_name}}</h6>
                        </div>
                        <div class="col-md-4 text-end">
                            <h6>{{item.total_amount | currency:'USD'}}</h6>
                        </div>
                    </div>
                    <div class="row" *ngIf="cart_fee">
                        <div class="col-md-8">
                            <h6>{{cart_fee[0]?.name}}</h6>
                        </div>
                        <div class="col-md-4 text-end">
                            <h6>{{cart_fee[0]?.amount | currency:USD}}</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <hr>
                        </div>
                        <div class="col-md-8">
                            <h6><b>Monthly Recurring</b></h6>
                        </div>
                        <div class="col-md-4 text-end">
                            <h6><b>{{monthly_contribution | currency:'USD'}} </b></h6>
                        </div>
                    </div>
                    <div class="row" *ngIf="cart_fee">
                        <div class="col-md-8">
                            <h6>{{cart_fee[1]?.name}} (One Time)</h6>
                        </div>
                        <div class="col-md-4 text-end">
                            <h6>{{cart_fee[1]?.amount | currency:USD}}</h6>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-lg-12 mb-2">
                            <span class=" terms" (click)="DisplayDiscount()">Discount Code</span>
                        </div>
                        <div class="col-lg-7 mb-2" *ngIf="display_discount">
                            <div class="input-group">
                                <div class="input-group-prepend" *ngIf="discountType == 'amount'">
                                    <span class="input-group-text">$</span>
                                </div>
                                <input type="text" class="form-control discount-field" [(ngModel)]="discountValue">
                                <button class="input-group-text btn btn-primary apply-btn"
                                    *ngIf="!display_discount_button" (click)="applyDiscount()">Apply</button>
                                <button class="input-group-text btn btn-primary apply-btn"
                                    *ngIf="display_discount_button" (click)="removeDiscount()">Remove</button>
                            </div>
                            <p class="discount-error text-danger m-0" *ngIf="discountError">{{ discountError }}</p>
                        </div>
                    </div>
                    <hr>
                    <div class="row" *ngIf="cart_data && cart_data[0]?.discount > 0">
                        <div class="col-md-8">
                            <h6><b>Discount</b></h6>
                        </div>
                        <div class="col-md-4 text-end">
                            <h6><b>-{{ cart_data[0]?.discount | currency: "USD" }}</b></h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <h6><b>Today's Total</b></h6>
                            <div>(including Enrollment Administration)</div>
                        </div>
                        <div class="col-md-4 text-end">
                            <h6><b>{{total_payment_today | currency:'USD'}} </b></h6>
                        </div>
                    </div>
                    <div class="col-md-12 form-group mb-2 mt-2">
                        <label class="hover">
                            <input type="checkbox" (change)="changeStatus($event)" class="me-1"> I agree to the
                            <span (click)="$event.stopPropagation()">
                                <a class="terms" (click)="getUserInfo()" data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop">Terms & Conditions</a>
                            </span>
                        </label>
                    </div>


                    <!-- Modal -->
                    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
                        tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-xl">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="staticBackdropLabel">Terms &
                                        Conditions</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div class="modal-body model_height">
                                    <div class="pdf-container">
                                        <ngx-extended-pdf-viewer [src]="pdfSrc" [showSidebarButton]="false" [showFindButton]="false" [showPagingButtons]="true"
                                        [showZoomButtons]="true" [showPresentationModeButton]="false" [showOpenFileButton]="false"
                                        [showPrintButton]="false" [showDownloadButton]="false" [showBookmarkButton]="false"
                                        [showSecondaryToolbarButton]="false" [showHandToolButton]="false" [showScrollingButton]="false"
                                        [showRotateButton]="false" [showSelectToolButton]="false" [showTextLayer]="false" [showTextEditor]="false"
                                        [showStampEditor]="false" [showDrawEditor]="false" [showAnnotationTools]="false"
                                        [showSpreadButton]="false" [showPageBorders]="true" [textLayer]="false" [enablePrint]="false"
                                        [enableDownload]="false" useBrowserLocale="true"></ngx-extended-pdf-viewer>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary"
                                        data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 form-group text-center mt-4">
                        <button type="button" class="btn btn-primary" (click)="finalSubmit()"
                            [disabled]="btn_disabled">Complete Purchase</button>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-12">
                    <h5><b>Summary</b></h5>
                </div>
                <div class="row" *ngFor="let item of data">
                    <div class="col-md-8">
                        <h6>{{item.family_type_name}}</h6>
                    </div>
                    <div class="col-md-4 text-end">
                        <h6>{{item.total_price | currency:'USD'}}</h6>
                    </div>
                </div>
                <div class="row" *ngIf="cart_fee?.length>0">
                    <div class="col-md-8">
                        <h6>{{cart_fee[0]?.name}}</h6>
                    </div>
                    <div class="col-md-4 text-end">
                        <h6>{{cart_fee[0]?.amount | currency:USD}}</h6>
                    </div>
                </div>
                <!-- <div class="row" *ngIf="cart_data && cart_data[0]?.discount > 0"> //Future Use
                    <div class="col-md-8">
                        <h6>Discount</h6>
                    </div>
                    <div class="col-md-4 text-end">
                        <h6>-{{ cart_data[0]?.discount | currency: "USD" }}</h6>
                    </div>
                </div> -->
                <div class="row mb-3" *ngIf="monthly_contribution">
                    <div class="col-md-12">
                        <hr>
                    </div>
                    <div class="col-md-8">
                        <h6><b>Monthly Membership Fee</b></h6>
                    </div>
                    <div class="col-md-4 text-end">
                        <h6><b>{{monthly_contribution | currency:'USD'}} </b></h6>
                    </div>
                </div>
            </div>


            <p-accordion [activeIndex]="affiliate_active_index">
                <p-accordionTab [header]="'Affiliate Info'">
                    <form [formGroup]="enrollment_form">
                        <div class="row">
                            <div class="col-md-12 form-group">
                                <label class=""><span>{{agent_text}} Code </span><span
                                        class="optional">(Optional)</span></label>
                                <div class="input-group">
                                    <input type="text" formControlName="agentCode" placeholder="{{agent_text}} Code"
                                        class="form-control marginTop" (keyup)="showApplyBtn()"
                                        [readonly]="agentCodeParam">
                                    <button class="input-group-text btn btn-primary apply-btn"
                                        *ngIf="!display_agentcode_button" (click)="applyAgentCode()">Apply</button>
                                    <button class="input-group-text btn btn-primary apply-btn"
                                        *ngIf="display_agentcode_button"
                                        (click)="applyAgentMiddleFlow(0)">Remove</button>
                                </div>
                                <small class="errormsg">
                                    {{agentCodeErrorMsg}}
                                </small>
                            </div>
                            <div class="col-md-12">
                                <div class="info-box" *ngIf="agentInfo?.firstname">
                                    <p><b>Name:</b>&nbsp;<span *ngIf="agentInfo.firstname">{{ agentInfo.firstname
                                            }}</span> <span *ngIf="agentInfo.lastname">{{ agentInfo.lastname }}</span>
                                    </p>
                                    <p><span *ngIf="agentInfo.email"><b>Email:</b>&nbsp;{{ agentInfo.email }}</span></p>
                                    <p><span *ngIf="agentInfo.mobile"><b>Phone:</b>&nbsp;{{ agentInfo.mobile }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>

    <div class="row" *ngIf="isSubmitted">
        <div class="col-md-12">
            <div class="confirmation future_payment mt-6">
                <h4 class="mt-2">Thank You For Your Application</h4>
                <div class="mt-2"><b>{{ paymentMessage }}</b></div>
                <button class="btn btn-primary mt-3" (click)="backToEnrollment()">New Enrollment</button>
            </div>
        </div>
    </div>
</div>

<div class="loader" *ngIf="loader">
    <div id="loading"></div>
</div>

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharingService} from '../../../services/sharing.service';

@Component({
    selector: 'app-d2c-summary',
    templateUrl: './d2c-summary.component.html',
    styleUrls: ['./d2c-summary.component.scss']
})

export class D2cSummaryComponent implements OnInit {
    companyId: any;
    family_id: any;
    code: any;
    payment_info: any;
    order_details: any;
    total_payment_today: any;
    product_price: any;
    order_id: any;
    product_name: any;
    cart_data: any;
    cart_products: any;
    agent_data: any;
    loader: boolean = false;
    cart_fee: any[] = [];
    dateFormat: any = 'MM-dd-yyyy';
    members: any;
    monthly_contribution: any;
    btndisabled: boolean;
    groupCompanyId: any;
    parent_company_id: any;
    isRiderAvailable = false;
    mamberid: string;
    agentInfo: any = null;
    agent_text: any;
    futurePayment: any;
    isSubmitted = false;
    paymentMessage: any;
    company_key: any;
    enroll_btn: any;
    showDiscountSection = false;
    discountValue: any;
    discountType: any;
    discountError: any = '';
    onetimeAppFee = 0;
    settings: any;
    effective_start_date:any;
  agent_id_from_url_param: any;
  template: any;
  steps: { step: string; active: boolean; }[];
    constructor(private service: SharingService, private route: ActivatedRoute,
                private router: Router, private toastr: ToastrService) {
        this.family_id = this.route.snapshot.paramMap.get('family_id');
        this.mamberid = this.route.snapshot.paramMap.get('id');
        localStorage.removeItem('order_details');

        this.route.queryParams.subscribe(params => {
          if (Object.keys(params).length !== 0 && params.hasOwnProperty('agent_id')) {
            this.agent_id_from_url_param = params['agent_id'];
        }
        })
    }

    ngOnInit(): void {
        this.service.clientData.subscribe((data) => {
            if (data !== '') {
                this.discountType = 'code'; // It can be code or amount
                this.settings = data;
                this.companyId = data.company_id;
                this.parent_company_id = data.parent_company;
                this.agent_text = data.agent_text;
                this.enroll_btn = 'Enroll Now';
                this.getMemberSummary();
                this.template = data?.template;
                this.steps =  [
                  ...(this.template !== 'template_3' ? [{ step: 'Enrollment/Product Pricing', active: false }] : []),
                  { step: 'Payment Details', active: false },
                  { step: 'Terms & Condition', active: false },
                  { step: 'Summary', active: true },
                  { step: 'Confirmation', active: false }
                ];
            }
        });

        // Get Agent info from localStorage
        if (localStorage.getItem('agentInfo')) {
            this.agentInfo = JSON.parse(localStorage.getItem('agentInfo'));
        }
    }


    getMemberSummary(): void {
        const url = 'GetProductFamilyStagingInfo/' + this.companyId + '/' + this.family_id;
        this.service.getData(url).subscribe((Object: any) => {
            this.members = Object.members;
            this.effective_start_date=this.members[0]?.effective_start_date
            this.groupCompanyId = Object.group_company_id;
            this.payment_info = Object?.payment_info;
            let mm = this.payment_info.exp?.substring(0, 2);
            let yy = this.payment_info.exp?.substring(2);
            this.payment_info.mm = mm;
            this.payment_info.yy = yy;
            this.order_details = Object?.order_details;
            this.order_id = this.order_details.id;
            this.total_payment_today = Object?.cart_data[0]?.total;
            this.product_price = Object?.cart_products[0]?.total_amount;
            this.cart_products = Object?.cart_products;
            this.cart_data = Object?.cart_data;
            this.cart_fee = Object?.cart_fee;
            this.agent_data = Object?.agent_info;
            this.monthly_contribution = Object.monthly_contribution;
            this.futurePayment = Object.future_payment;

            // Take out the onetime application fee
            this.onetimeAppFee = this.cart_fee.find(item => item.name.toLowerCase() === 'enrollment fee')?.amount;

            if (this.members[0]?.enrollment_type === 'ACC' && this.settings?.discount_settings) {
                const discountConfig = JSON.parse(this.settings.discount_settings ?? {});
                if (discountConfig && discountConfig.enabled) {
                    this.showDiscountSection = true;
                    this.discountType = discountConfig.type;

                    if (this.cart_data[0].discount !== 0) {
                        this.showDiscountSection = false;
                    }
                }
            }

            this.loader = false;
        });
    }

    onPrevious(): void {
      if(!!this.agent_id_from_url_param){
        this.router.navigate(['/package/agreement', this.mamberid, this.family_id],{queryParams:{agent_id:this.agent_id_from_url_param}});
      }else{
        let origin = window.location.pathname.split('/')[1]
        this.router.navigate(['/'+origin+'/agreement', this.mamberid, this.family_id]);

      }
    }

    submit(): void {
        this.loader = true;
        const url = 'CreateProductOrderA2C';
        const payload = {
            parent_company_id: this.parent_company_id,
            acknowledge_by: this.members[0]?.firstname + ' ' + this.members[0]?.lastname,
            order_id: this.order_details?.id,
            company_id: this.companyId
        };
        this.service.postData(url, payload).subscribe((res: any) => {
            this.loader = false;
            if (res.process_payment) {
                this.CreateMemberShipACC();
                localStorage.setItem('parent_company_id', this.companyId);
                localStorage.setItem('group_company_id', this.groupCompanyId);
            } else {
                this.paymentMessage = res.message;
                this.isSubmitted = true;
                this.btndisabled = true;
            }
        }, (err) => {
            this.loader = false;
            this.toastr.error(err.error.message);
            this.btndisabled = false;
            if(this.template == 'template_3'){
              if (err.error?.payment_failed) {
                let origin = window.location.pathname.split('/')[1]
                this.router.navigate(['/'+ origin+'/payment-failure', this.companyId, this.family_id]);
              }
            }
            else if(this.template == 'package') {
                if (err.error?.payment_failed) {
                    let origin = window.location.pathname.split('/')[1]
                    this.router.navigate(['/'+ origin+'/payment-failure', this.members[0].member_id, this.family_id]);
                }
            }

        });
    }

    CreateMemberShipACC(): void {
        const url = 'CreateMembershipA2C';
        this.loader = true;
        const payload = {
            parent_company_id: this.parent_company_id,
            acknowledge_by: this.members[0]?.firstname + ' ' + this.members[0]?.lastname,
            order_id: this.order_id,
            company_id: this.companyId
        };
        this.service.postData(url, payload).subscribe((Object: any) => {
            this.loader = false;
            if (Object.status === true) {
                localStorage.setItem('order_details', JSON.stringify(Object));
                localStorage.setItem('parent_company_id', this.companyId);
                localStorage.setItem('group_company_id', this.groupCompanyId);
               if(Object?.client_redirect=='1' ){
                window.location.replace (Object?.client_redirect_url)
               }
                else if(!!this.agent_id_from_url_param){
                  this.router.navigate(['/package/confirmation', this.order_id, this.family_id],{queryParams:{agent_id:this.agent_id_from_url_param}});
                }else{
                  let origin = window.location.pathname.split('/')[1]
                  this.router.navigate(['/'+origin+'/confirmation', this.order_id, this.family_id]);
                }
            }
        }, (err) => {
            this.loader = false;
            this.toastr.error(err.error.message);
        });
    }

    backToEnrollment(): void {
        this.router.navigate(['/package/enrollment']);
    }

    removeDiscount(): boolean {
        this.discountError = '';
        this.discountValue = undefined;

        this.loader = true;
        const payload = {
            company_id: this.companyId,
            cart_id: this.cart_data[0]?.id,
            agent_id: this.agent_data?.id
        };

        const url = 'RemoveEnrollmentDiscountACC';
        this.service.postData(url, payload).subscribe((res: any) => {
            if (res.status) {
                this.showDiscountSection = true;
                this.getMemberSummary();
            } else {
                this.loader = false;
                this.toastr.error(res.message);
                this.btndisabled = false;
            }
        }, (err) => {
            this.loader = false;
            this.toastr.error(err.error.message);
            this.btndisabled = false;
        });

        return false;
    }

    // Apply discount function
    applyDiscount(): boolean {
        console.log('Discount value: ' + this.discountValue);
        if (this.discountValue) {
            this.discountError = 'Please enter a valid discount ' + this.discountType;
            return false;
        }

        this.discountError = '';
        if (this.discountType === 'amount') {
            if (this.discountValue < 0) {
                this.discountError = 'Please enter a valid amount';
                return false;
            } else if (this.discountType === 'amount' && this.discountValue > this.onetimeAppFee) {
                this.discountError = 'Discount can\'t exceed enrollment fee';
                return false;
            }
        }

        this.loader = true;
        const payload = {
            company_id: this.companyId,
            cart_id: this.cart_data[0]?.id,
            agent_id: this.agent_data?.id,
            discount_type: this.discountType,
            discount_value: this.discountValue ?? ''
        };

        this.discountValue = undefined;
        const url = 'CreateEnrollmentDiscountACC';
        this.service.postData(url, payload).subscribe((res: any) => {
            if (res.status) {
                this.showDiscountSection = false;
                this.getMemberSummary();
            } else {
                this.loader = false;
                this.toastr.error(res.message);
                this.btndisabled = false;
            }
        }, (err) => {
            this.loader = false;
            this.toastr.error(err.error.message);
            this.btndisabled = false;
        });

        return false;
    }
}

import { Component, EventEmitter, OnInit, Output , Input } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { SharingService } from '../services/sharing.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-reusable-applicationform',
  templateUrl: './reusable-applicationform.component.html',
  styleUrl: './reusable-applicationform.component.scss'
})
export class ReusableApplicationformComponent implements OnInit {
  applicationForm: FormGroup;
  showApplicationForm: boolean;
  applicationsubmitAttempt: boolean = false;
  companyId: any;
  @Input() applicationId: any;
  @Output() applicantdata = new EventEmitter<string>(); // Output event


  constructor(private formBuilder: UntypedFormBuilder, private service: SharingService, private toastr: ToastrService,
  ) {

  }

  ngOnInit(): void {

    if(this.applicationId){
      this.showApplicationForm = true;
    }

    this.service.clientData.subscribe((data) => {
      if (data != '') {
        this.companyId = data.company_id;

      }
    })

    this.applicationForm = this.formBuilder.group({
      applicationId: [this.applicationId, Validators.required],
      applicationEmail: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern("[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?")
      ]],
    })
  }

  newapplication(value) {
    this.applicationsubmitAttempt = true
    var url = 'GetClientApplicantByApplicationID';
     let obj={
      'application_id': value.applicationId,
      'email': value.applicationEmail,
      'company_id': this.companyId
    }
  
    this.service.postData(url, obj).subscribe((res: any) => {
      console.log(res);
      this.applicantdata.emit(res);
    }, err => {
      this.toastr.error(err?.error?.message);
    })
  }

  previousApplication(value) {

  }

  sendData() {
    this.applicantdata.emit("Data from Shared Component");
  }

  applicationFields(checked) {
    if (checked) {
      this.showApplicationForm = true;
    } else {
      this.showApplicationForm = false;
    }
  }
}

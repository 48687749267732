<div class="container">
    <div class="enrlmnt-demographic">
        <div class="stepper">
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-10">
            <form *ngIf="referralForm" [formGroup]="referralForm" (ngSubmit)="submitForm()">
                <div class="card shadow-sm mb-5" *ngIf="!isRequestSubmitted">
                    <div class="card-body">
                        <div>
                            <h5 class="form-title">{{ companyName }} Quotation Request Form</h5>
                            <div>One of our specialist will call you back.</div>
                            <hr>
                        </div>
                        <div class="row" *ngFor="let field of formFields;let i=index">
                            <div class="col-md-5 form-group">
                                <label [class.required]="field.is_required">{{ field.field_name }}</label>
                            </div>
                            <div class="col-md-7 form-group">
                                <input *ngIf="field.field_name !=='Phone' && field.field_type =='Text'" [type]="field.field_type"
                                    [formControlName]="field.field_name" class="form-control marginTop"
                                    [class.readonly_textbox]="shouldDisableField(i)"
                                    [readonly]="shouldDisableField(i) ? true : null">
                                <div *ngIf="field.field_name=='Phone' && field.field_type =='Text'">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <input [type]="field.field_type" numbersOnly  minlength="10" appPhonenumberValidation
                                                [formControlName]="field.field_name" 
                                                [readonly]="shouldDisableField(i) ? true : null"
                                                class="form-control marginTop" />
                                        </div>
                                        <div class="col-md-2 px-0 mt-2">
                                            <button type="button" class="btn btn-primary" *ngIf="!phone_verified"
                                                (click)="sendOtp(referralForm.value)">{{otp_text}}</button>
                                            <div *ngIf="phone_verified" class="verified"><i
                                                    class="bi bi-check2-circle"></i> Verified</div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="field.field_type =='select_dropdown'">
                                    <div class="row">
                                        <div class="col-md-12">
                                                <select   [class.readonly_textbox]="shouldDisableField(i)"
                                                [readonly]="shouldDisableField(i) ? true : null"  [formControlName]="field.field_name"
                                                  name="invoice_recurring_date" class="form-select">
                                                    <option value="" disabled selected>Select Time Slots</option>
                                                    <option *ngFor="let id of timeSlots" value="{{ id.time }}">{{ id.time }}</option>
                                                </select>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div
                                    *ngIf="field.is_required && referralForm.get(field.field_name).invalid && referralForm.get(field.field_name).dirty || submitAttempt">
                                    <small class="text-danger"
                                        *ngIf="referralForm.get(field.field_name).errors?.required">{{
                                        field.field_name }} is required</small>
                                </div>
                                <small
                                    *ngIf="field.field_key === 'email' && 
                                    ((referralForm.get(field.field_name)?.touched || submitAttempt) && 
                                    (referralForm.get(field.field_name)?.errors?.pattern || !referralForm.get(field.field_name)?.value))"
                                    class="text-danger">
                                    Please enter a valid Email Address
                                </small>
                                <small
                                    *ngIf="field.field_key === 'phone' && 
                                    ((referralForm.get(field.field_name)?.touched || submitAttempt) && 
                                    (referralForm.get(field.field_name)?.errors?.pattern || !referralForm.get(field.field_name)?.value))"
                                    class="text-danger">
                                    Mobile Number is invalid
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="px-4 mb-1 form-card-footer text-center" *ngIf="referralForm">
                        <p>By clicking on the submit button, you are giving express consent for {{ companyName }} Sales
                            Executive to contact you.</p>
                        <button type="submit" class="btn add-btn btn-primary mb-2" [disabled]="!display_btn">
                            Submit
                        </button>
                    </div>
                </div>
                <div class="card shadow-sm text-center message-window" *ngIf="isRequestSubmitted">
                    <p class="mb-4"><i class="bi bi-check check-icon"></i></p>
                    <h2>Thank you!</h2>
                    <h4>your request has been submitted successfully.</h4>
                    <p>Our team will review it and get back to you as soon as possible.</p>
                </div>
            </form>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>
<div *ngIf="loader" class="loader">
    <div id="loading"></div>
</div>
<p-dialog header="Verify Phone Number" [modal]="true" [(visible)]="display_otp" [style]="{ width: '30rem' }">
    <div class="row">
        <div class="col-md-12 text-center mb-3">
            Enter the 4-digit code sent to the mobile number
        </div>
        <div class="col-md-12 d-flex gap-2 justify-content-center">
            <p-inputOtp [(ngModel)]="otp_value" [integerOnly]="true" />
        </div>
        <div class="col-md-12 d-flex gap-2  mt-2 justify-content-center counter_time">
            OTP expires in {{ displayMinutes }}:{{ displaySeconds }} minutes.
        </div>
        <div class="row mt-3">
            <div class="col-lg-6 text-end">
                <button type="button" class="btn btn-outline-danger" (click)="cancelOTP()">Cancel</button>
            </div>
            <div class="col-md-6 ">
                <button type="button" class="btn btn-primary" (click)="submitOTP()">Verify OTP</button>
            </div>
        </div>
    </div>
</p-dialog>

import { Injectable } from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {GoogleTagManagerService} from 'angular-google-tag-manager';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

@Injectable({
    providedIn: 'root'
})

export class TrackingService {

    constructor(private router: Router, private gtmService: GoogleTagManagerService) {
        this.router.events.forEach(item => {
            if (item instanceof NavigationEnd) {
                const gtmTag = {
                    event: 'page_view',
                    pageName: item.url
                };
                this.gtmService.pushTag(gtmTag).then(r => {
                    console.log(r);
                });
            }
        });
    }

    processAndTrackOrder(data: any): void {
        if (data) {
            const details = data.order_details && data.order_details[0] ? data.order_details[0] : null;
            const paymentDetails = data.cart_data && data.cart_data[0] ? data.cart_data[0] : null;
            const cartProducts = data.cart_products && data.cart_products[0] ? data.cart_products[0] : null;

            if (details && paymentDetails && cartProducts) {
                const orderData = {
                    first_name: details.firstname || '',
                    last_name: details.lastname || '',
                    email: details.email || '',
                    state: details.shipping_state || '',
                    zip: details.shipping_zip || '',
                    member_id: details.member_id || '',
                    confirmation_number: details.confirmation_number || '',
                    purchase_amount: paymentDetails.subtotal || '',
                    plan: cartProducts.product_name || '',
                    page_url: window.location.href || ''
                };

                this.sendEvent('orderInformation', orderData);
            } else {
                console.error('Error: One or more essential pieces of order data are missing.');
            }
        } else {
            console.error('Error: No data found for order details.');
        }
    }

    sendEvent(eventName: string, data: object): void
    {
        const gtmTag = {
            event: eventName,
            value: data,
        };
        console.log(gtmTag);

        window.dataLayer.push(gtmTag);
        this.gtmService.pushTag(gtmTag).then(r => {
            console.log(r);
        });
    }
}

import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharingService } from 'src/app/services/sharing.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-cartconfirmation',
  templateUrl: './cartconfirmation.component.html',
  styleUrl: './cartconfirmation.component.scss'
})
export class CartconfirmationComponent {
  @ViewChild('orderInvoice') orderInvoiceElement: ElementRef;
  companyId: any;
  agent_text: any;
  route_company_name: any;
  orderDetails: any;
  paymentData: any;
  cartFee: any;
  cartData: any;
  memberDiscount: any;
  additionalMember: any;
  oneTimeFee: any;
  cartProducts: any;
  monthly_contribution: any;
  agentInfo: any;

  constructor(private service: SharingService, private router: Router,) {
    var data = JSON.parse(localStorage.getItem('order_details'));
    if (data != undefined && data != '') {
      this.orderDetails = data?.order_details;
      this.paymentData = data?.paymentdata
      this.cartFee = data?.cart_fee;
      this.cartData = data?.cart_data;
      this.memberDiscount = data?.agent_cart_discount;
      this.additionalMember = data?.additional_member_fee;
      this.oneTimeFee = data?.a2c_total_one_time_fee
      this.cartProducts = data?.cart_products;
      this.monthly_contribution = data?.monthly_contribution;
      if (data?.agent_info) {
        this.agentInfo = data?.agent_info;
      }
    }
  }

  ngOnInit(): void {
    this.service.clientData.subscribe((data) => {
      if (data != '') {
        this.companyId = data.company_id;
        this.agent_text = data.agent_text
        this.route_company_name = data.route_company
      }
    });
  }
  backToEnrollment(){
    this.router.navigate(['/d2c/enrollment']);
  }

  downloadSectionAsPDF() {
    const data = this.orderInvoiceElement.nativeElement;
    // Clone the element and apply A4 size styling
    const clonedData = data.cloneNode(true) as HTMLElement;
    clonedData.style.width = '300mm';
    clonedData.style.height = '297mm';
    clonedData.style.overflow = 'hidden';
    clonedData.style.position = 'fixed';
    clonedData.style.left = '-10000px'; // Position off-screen
    document.body.appendChild(clonedData);
    html2canvas(clonedData, {scale: 2, useCORS: true}).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 190; // Slightly less than A4 width to add margins
        const imgHeight = canvas.height * imgWidth / canvas.width;
        // Add the image to the PDF
        pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
        //Generate a date and time string for the filename
        const now = new Date();
        const dateString = formatDateToString(now); // Use the helper function to format the date
        pdf.save(`Order_Receipt_${dateString}.pdf`);
        // format the date as MMDDYYYYHHMMSS
        function formatDateToString(date) {
            const pad = (num) => (num < 10 ? '0' + num : num.toString());
            const month = pad(date.getMonth() + 1); // getMonth() is zero-based
            const day = pad(date.getDate());
            const year = date.getFullYear();
            const hours = pad(date.getHours());
            const minutes = pad(date.getMinutes());
            const seconds = pad(date.getSeconds());
            return `${month}${day}${year}${hours}${minutes}${seconds}`;
        }
        // Clean up: remove the cloned element
        document.body.removeChild(clonedData);
    }).catch(error => {
        console.error('Error generating PDF:', error);
    });
  }
}

import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {
    DemographicsDependentsComponent
} from './enrollment/template_1/demographics-dependents/demographics-dependents.component';
import {PhonenumberValidationDirective} from './services/phonenumber-validation.directive';
import {SocialSecurityDirective} from './services/social-security.directive';
import {AlphabetOnlyDirective} from './services/alphabet-only.directive';
import {PagenotfoundComponent} from './pagenotfound/pagenotfound.component';
import {ApplicationcartComponent} from './enrollment/template_1/applicationcart/applicationcart.component';
import {StatementofbeliefsComponent} from './enrollment/template_1/statementofbeliefs/statementofbeliefs.component';
import {PaymentDetailsComponent} from './enrollment/template_1/payment-details/payment-details.component';
import {SummaryComponent} from './enrollment/template_1/summary/summary.component';
import {
    HealthBasedQuestionsComponent
} from './enrollment/template_1/health-based-questions/health-based-questions.component';
import {ThankyouComponent} from './enrollment/template_1/thankyou/thankyou.component';
import {HealthquestionsComponent} from './healthquestions/healthquestions.component';
import {DirectenrollmentComponent} from './enrollment/directenrollment/directenrollment.component';
import {ProductsComponent} from './enrollment/template_2/products/products.component';
import {PaymentsComponent} from './enrollment/template_2/payments/payments.component';
import {BeneficiaryComponent} from './enrollment/template_2/beneficiary/beneficiary.component';
import {AgreementComponent} from './enrollment/template_2/agreement/agreement.component';
import {Disclosure1Component} from './enrollment/template_2/disclosure-1/disclosure-1.component';
import {ConfirmationComponent} from './enrollment/template_2/confirmation/confirmation.component';
import {MembersummaryComponent} from './enrollment/template_2/membersummary/membersummary.component';
import {Disclosure2Component} from './enrollment/template_2/disclosure-2/disclosure-2.component';

import {D2cEnrollmentComponent} from './enrollment/package/d2c-enrollment/d2c-enrollment.component';
import {PaymentComponent} from './enrollment/package/payment/payment.component';
import {D2cagreementComponent} from './enrollment/package/d2cagreement/d2cagreement.component';
import {D2cSummaryComponent} from './enrollment/package/d2c-summary/d2c-summary.component';
import {D2cConfirmationComponent} from './enrollment/package/d2c-confirmation/d2c-confirmation.component';
import { GroupMemberComponent } from './group-member/group-member.component';
import { AddMemberConfirmationComponent } from './confirmation/confirmation.component';
import { SinglePageTemplateComponent } from './enrollment/single-page-template/single-page-template.component';
import { CartconfirmationComponent } from './enrollment/single-page-template/cartconfirmation/cartconfirmation.component';
import { PaymentFailedComponent } from './enrollment/package/payment-failed/payment-failed.component';
import { AddGroupsComponent } from './add-groups/add-groups.component';

import {MemberReferralComponent } from './referral/member-referral/member-referral.component';

const routes: Routes = [
    {path: '', redirectTo: 'DirectMemberEnrollment', pathMatch: 'full'},
    {path: 'DirectMemberEnrollment', component: DirectenrollmentComponent},
    {path: 'DirectMemberEnrollment/:id/:family_id', component: DirectenrollmentComponent},
    {path: 'DirectMemberEnrollment/:agent_code', component: DirectenrollmentComponent},
    {path: 'home', component: HomeComponent},
    // ================Enrollment components================//
    // { path: 'DirectMemberEnrollment', component: SignUpComponent },
    // { path: 'DirectMemberEnrollment/:id', component: SignUpComponent },
    {path: 'demographic', component: DemographicsDependentsComponent},
    {path: 'demographictab/:cmpId/:memId/:cartId', component: DemographicsDependentsComponent},
    {path: 'statementofbeliefstab/:cmpId/:memId/:cartId', component: StatementofbeliefsComponent},
    {path: 'health-basedquestionstab/:cmpId/:memId/:cartId', component: HealthBasedQuestionsComponent},
    {path: 'health-basedquestions', component: HealthBasedQuestionsComponent},
    {path: 'applicationcart', component: ApplicationcartComponent},
    {path: 'statementofbeliefs', component: StatementofbeliefsComponent},
    {path: 'statementofbeliefs/:enc_id', component: StatementofbeliefsComponent},
    {path: 'statementofbeliefs/:id/:navigatedfrom', component: StatementofbeliefsComponent},
    {path: 'paymentdetails', component: PaymentDetailsComponent},
    {path: 'summary', component: SummaryComponent},
    {path: 'thankyou', component: ThankyouComponent},
    {path: 'health_questions', component: HealthquestionsComponent},
    // ============template company routes=================== Allstate=====//
    {path: 'products', component: ProductsComponent},
    {path: 'payments/:id/:memberid', component: PaymentsComponent},
    {path: 'payments/:id/:memberid', component: PaymentsComponent},
    {path: 'beneficiary/:id/:family_id', component: BeneficiaryComponent},
    {path: 'agrement/:id/:family_id', component: AgreementComponent},
    // { path: 'discloser', component:Disclosure1Component},
    {path: 'membersummary', component: MembersummaryComponent},
    {path: 'membersummary/:id/:family_id', component: MembersummaryComponent},
    {path: 'membersummary/:id/:family_id/:code', component: MembersummaryComponent},
    {path: 'confirmation/:orderId/:family_id', component: ConfirmationComponent},
    {path: 'confirmation/:orderId/:family_id/:code', component: ConfirmationComponent},

    {path: 'disclosure', component: Disclosure1Component},
    {path: 'disclosure/:id/:family_id', component: Disclosure1Component},
    {path: 'disclosure/:id/:family_id/:code', component: Disclosure1Component},
    {path: 'disclosure_two', component: Disclosure2Component},
    {path: 'disclosure_two/:id/:family_id/:code', component: Disclosure2Component},
    {path: 'disclosure_two/:id/:family_id', component: Disclosure2Component},

    {path: 'package/DirectMemberEnrollment', component: DirectenrollmentComponent},
    {path: 'package/DirectMemberEnrollment/:id/:family_id', component: DirectenrollmentComponent},
    {path: 'package/DirectMemberEnrollment/:agent_code', component: DirectenrollmentComponent},
    {path: 'package/enrollment', component: D2cEnrollmentComponent},
    {path: 'package/enrollment/:memberid/:family_id', component: D2cEnrollmentComponent},
    {path: 'package/enrollment/:agent_code', component: D2cEnrollmentComponent},
    {path: 'package/payment', component: PaymentComponent},
    {path: 'package/payment/:id', component: PaymentComponent},
    {path: 'package/payment/:id/:memberid', component: PaymentComponent},
    {path: 'package/agreement/:id/:family_id', component: D2cagreementComponent},
    {path: 'package/summary', component: D2cSummaryComponent},
    {path: 'package/summary/:id/:family_id', component: D2cSummaryComponent},
    {path: 'package/confirmation/:orderId/:family_id', component: D2cConfirmationComponent},

    {path: ':path/payment', component: PaymentComponent},
    {path: ':path/payment/:id', component: PaymentComponent},
    {path: ':path/payment/:id/:memberid', component: PaymentComponent},
    {path: ':path/payment-failure/:id/:family_id', component: PaymentFailedComponent},

    {path: ':path/agreement/:id/:family_id', component: D2cagreementComponent},
    {path: ':path/summary', component: D2cSummaryComponent},
    {path: ':path/summary/:id/:family_id', component: D2cSummaryComponent},
    {path: ':path/confirmation/:orderId/:family_id', component: D2cConfirmationComponent},

    { path: 'affiliate', loadChildren: () => import('./affiliate/affiliate.module').then(g => g.AffiliateModule) },

    { path: ':path/MemberEnrollment/:id', component: GroupMemberComponent },
    { path: 'confirmation/:id', component: AddMemberConfirmationComponent },
    { path: 'confirmation', component: AddMemberConfirmationComponent },

    { path: 'addgroup/:id', component: AddGroupsComponent },

    {path: ':path/enrollment', component: SinglePageTemplateComponent},//single page revolt flow
    { path: ':path/enrollment/:agent_code', component: SinglePageTemplateComponent },
    { path: 'order-confirmation/:id', component: CartconfirmationComponent },

    // ============Agency/Agent routes=================== ACA=====//
    { path: 'agency/:agency_id', loadChildren: () => import('./agentAgencyModule/agent-agency.module').then(g => g.AgentAgencyModule) },
    { path: 'agent/:agent_id', loadChildren: () => import('./agentAgencyModule/agent-agency.module').then(g => g.AgentAgencyModule) },
    { path: 'group', loadChildren: () => import('./group-agreements/group-agreements.module').then(g => g.GroupAgreementsModule) },

    // ============Referral routes=================== //
    {path: 'referral/member/:member_id/:program_id', component: MemberReferralComponent},
    {path: 'referral/member/:member_id/:program_id/:referral_form_id', component: MemberReferralComponent},

    // ==========wild card routing======================//
    {path: '**', component: PagenotfoundComponent},
];

@NgModule({
    // imports: [RouterModule.forRoot(routes, {}),],
    imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    exports: [RouterModule, PhonenumberValidationDirective, SocialSecurityDirective, AlphabetOnlyDirective],
    declarations: [PhonenumberValidationDirective, SocialSecurityDirective, AlphabetOnlyDirective],
})

export class AppRoutingModule {}

import { Component, Renderer2  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-payment-failed',
  templateUrl: './payment-failed.component.html',
  styleUrl: './payment-failed.component.scss'
})
export class PaymentFailedComponent {
  family_id: any;
  template: any;
  companyId: any;
  member_id: any;
  constructor(
    private service: SharingService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private renderer: Renderer2
    ) {
    this.family_id = this.route.snapshot.paramMap.get('family_id');
  }

  ngOnInit(): void {
    // Get the first element with class 'copyright' and add a CSS class
    const copyrightElements = document.getElementsByClassName('copyright');
    if (copyrightElements.length > 0) {
      this.renderer.addClass(copyrightElements[0], 'payment-failed-copyright');
    }
    this.service.clientData.subscribe((data) => {
      if (data !== '') {
        this.template = data?.template;
        this.companyId = data?.company_id
      }
    });
  }

  proceed(){
    if(this.template == 'template_3'){
        let origin = window.location.pathname.split('/')[1]
        this.router.navigate(['/'+ origin+'/payment', this.companyId, this.family_id]);
    }
    else if(this.template == 'package'){
      this.member_id = this.route.snapshot.paramMap.get('id');
      let origin = window.location.pathname.split('/')[1];
      this.router.navigate(['/'+ origin+'/payment', this.family_id, this.member_id]);
    }
  }

  ngOnDestroy(): void {
    // Remove the CSS class from the first 'copyright' element if it exists
    const copyrightElements = document.getElementsByClassName('copyright');
    if (copyrightElements.length > 0) {
      this.renderer.removeClass(copyrightElements[0], 'payment-failed-copyright');
    }
  }
}


import {SharingService} from 'src/app/services/sharing.service';
import {DatePipe, Location} from '@angular/common';
import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validators
} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-enrollment',
    // standalone: true,
    templateUrl: './enrollment.component.html',
    styleUrl: './enrollment.component.scss',
    providers: [DatePipe]
})
export class EnrollmentComponent {

    Form: UntypedFormGroup;
    submitAttempt: boolean;
    newForm: UntypedFormGroup;
    test = true;
    spouseForm = true;
    applicants: any = {};
    objectData: any = {};
    GetProducts: any = {};
    cartProductDetails = [];
    member_personal_data: any = {};
    Age: any;
    spouseAge: any;
    childAge: any;
    AGE: any = [];
    groupList: any = [];
    username: string;
    er: any;
    er1: any;
    weight: any;
    zipcode: any;
    ziperr: any;
    applicant: any;
    primaryWeight: any;
    primaryDate: any;
    primaryApplicant: any;
    spouseApplicant: any;
    spouseWeight: any;
    spouseDate: any;
    childError: string;
    spouseWtError: string;
    ageError: string;
    parentAge: string;
    parentAge1: string;
    date: Date;
    minDate: string;
    minimumDate: Date;
    invalidDates: Date[];
    invalidDates1: Date[];
    spouseAgeMsg: string;
    value: any;
    inValue: any;
    errtxt = '';
    spouceerrtxt = '';
    childerrtxt = '';
    error: any;
    childApplicant: any;
    spouseWgt: any;
    spouseGe: any;
    spouseSmk: any;
    spouseHe: any;
    spouseInc: any;
    childWgt: any;
    childG: any;
    childDob: Date;
    childSmk: any;
    inchesAllowmode = true;
    phnumbererrmsg = '';
    agentCodeErrorMsg = '';
    spouse_Id: any;
    arr: any[] = [];
    spousearr: any[] = [];
    primaryInfo: any;
    spouseInfo: any;
    childInfo: any;
    agent_Id: any;
    emailError: any;
    emailerrmsg = false;
    phoneerrmsg: any;
    errorMsgShow = true;
    public data: any[] = [];
    result = '';
    effectiveDate: any;
    companyName: any;
    membershipUrl: any;
    eternity = false;
    usaStates: any;
    usaStatesData: any;
    producStatesData: any;
    fellowship = false;
    companyId: any;
    openError = false;
    displayText = false;
    maxDate: Date;

    child_check: boolean;
    addchilddisabled: boolean;
    isActiveState = true;
    loader = false;
    // agentCodeParam: any;
    isAgentCodeApplied = false;
    applyBtnText = 'Apply';
    agentInfo: any = null;
    showAgentCodeSection = true;
    isEligible = true;
    isSpouseEligible = true;
    qtnsformgroup: UntypedFormGroup;
    subscriptions: Subscription[] = [];
    public isSameAddressControl: UntypedFormControl = new UntypedFormControl(false);
    public isSpouseAddressControl: UntypedFormControl = new UntypedFormControl(false);
    public isChildAddressControl: UntypedFormControl = new UntypedFormControl(false);
    status: any;
    dateFormat: string;
    ischeckedAddress = false;
    dateTimeFormat: string;
    toasterTimeout: any;
    isa2c = false;
    isacc = false;
    managersList: any[];
    group_id: any;
    stateStatus: any[] = [];
    // routeMemberId: any;
    memberPersonalData: any[] = [];
    primary_info: any;
    spouse_info: any;
    child_info: any;
    primaryAddressobj: any;
    spouseAddressobj: any;
    childAddressobj: any;
    parentCompanyId: any;
    route_company_name: any;
    nicotineChecked: any;
    // family_id: any;
    healthQuestions: any;
    questiononeForm = false;
    lstForm: any[] = [];

    finalSelectedhealthQtns: any[] = [];
    Policy_replacement_questions = false;
    finalobj: any = {
        company_id: '',
        family_id: '',
        member_personal_data: [] = []
    };
    member_health_issue_data: any;
    spouseHealthQuestions: any;
    hideSpouseDetails = false;
    spouse_health_issue_data: any = [];
    originalHealthQustions: any;
    @Input() routeMemberId: any;
    //@Input() agentCodeParam: any;
    @Input() family_id: any;
    group_name: any;
    agentCodeParam: any;
    group_code:any;
    temp_member_id:any;
    application_id:any;

    constructor(
        private datePipe: DatePipe,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private service: SharingService,
        private location: Location
    ) {
        // localStorage.clear();
        // this.routeMemberId = this.route.snapshot.paramMap.get('memberid');
        // this.agentCodeParam = this.route.snapshot.paramMap.get('agent_code');
        this.family_id = this.route.snapshot.paramMap.get('family_id');
        localStorage.removeItem('from_payment');
        this.route.queryParams.subscribe(params => {
            if (Object.keys(params).length !== 0 && params.hasOwnProperty('utm_aff')) {
                this.agentCodeParam = params['utm_aff'];
            }

            if (Object.keys(params).length !== 0 && params.hasOwnProperty('affiliate_code')) {
                this.agentCodeParam = params['affiliate_code'];
            }
            if (Object.keys(params).length !== 0 && params.hasOwnProperty('group_code')) {
                this.group_code = params['group_code'];
            }
        });
        this.service.clientData.subscribe((data) => {
            if (data !== '') {
                this.companyId = data.company_id;
                this.parentCompanyId = data.parent_company;
                this.route_company_name = data.company_key;
                this.group_name = data.company_key;
                this.getNoSaleProductUsaStates();
                localStorage.removeItem('memberdata');
                if (this.agentCodeParam) {
                    this.applyAgentCode(this.agentCodeParam);
                }
                if (this.family_id) {
                    this.previousEnrollment();
                }
            }
        });


    }

    private _hidePrimaryDetails = false;

    get hidePrimaryDetails(): boolean {
        return this._hidePrimaryDetails;
    }

    set hidePrimaryDetails(value: boolean) {
        this._hidePrimaryDetails = value;
        this.checkAndModifyControls();
    }

    private _spouse_check = false;

    get spouse_check(): boolean {
        return this._spouse_check;
    }

    set spouse_check(value: boolean) {
        this._spouse_check = value;
        this.checkAndModifyControls();
    }

    get spouseArray(): UntypedFormArray {
        return this.newForm.get('spouse_Array') as UntypedFormArray;
    }

    ngOnInit(): void {
        // Read the URL, and check for acc and d2c
        const url = window.location.href;
        // if (!(url.includes('/acc/') || url.includes('/d2c/'))) {
        //   this.router.navigate(['home']);
        // }


        // ==============past and future dates displae===========//
        this.minimumDate = new Date();
        this.maxDate = new Date();
        const today = new Date();
        const invalidDate = new Date();
        const invalidDate1 = new Date();
        invalidDate.setDate(today.getDate() - 1);
        invalidDate1.setDate(today.getDate() + 3);
        this.invalidDates = [today, invalidDate];
        this.invalidDates1 = [today, invalidDate1];
        // *********effective start data**************//
        this.newForm = this.formBuilder.group({
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            manager: [''],
            email: ['',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    ),
                ]),
            ],
            phone: ['', Validators.required],
            dob: ['', Validators.required,],
            age: ['', [Validators.required, Validators.min(18), Validators.max(70)]],
            feet: ['', ''],
            inch: ['',],
            startDate: [''],
            weight: ['', ''],
            address1: ['', Validators.required],
            address2: [''],
            active: [1],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{5}$')]],
            gender: ['', Validators.required],
            tobacco: ['', Validators.required],
            working_hours_20: ['', Validators.required],
            ssn: ['', Validators.required], // Validators.compose([isValidSSNNumber]
            relation: ['Primary'],
            agentCode: [this.agentCodeParam],
            member_id: [''],
            spouseHealthQtns: this.formBuilder.group({}),
            child_Array: this.formBuilder.array([]),
            spouse_Array: this.formBuilder.array([]),
            qtnsform_Array: this.formBuilder.array([]),
        });
        this.Form = this.formBuilder.group({});

        if (this.primaryInfo != null || this.primaryInfo != undefined) {
            this.newForm.controls.firstname.setValue(this.primaryInfo[0].firstname);
            this.newForm.controls.lastname.setValue(this.primaryInfo[0].lastname);
            this.newForm.controls.email.setValue(this.primaryInfo[0].email);
            const phone = this.primaryInfo[0].phone.substr(0, 3) + '-' + this.primaryInfo[0].phone.substr(3, 3) + '-' + this.primaryInfo[0].phone.substr(6, 4);
            this.newForm.controls.phone.setValue(phone);
            let height = this.primaryInfo[0].height;
            const n = height.toString().includes('.');
            if (n == false) {
                const str2 = '.0';
                height = height.toString().concat(str2);
            }
            const primaryHeight = height.toString();
            const inch = primaryHeight.substring(primaryHeight.lastIndexOf('.') + 1);
            const feet = primaryHeight.substring(0, primaryHeight.lastIndexOf('.'));
            this.newForm.controls.inch.setValue(inch);
            this.newForm.controls.feet.setValue(feet);
            this.newForm.controls.age.setValue(this.primaryInfo[0].age);
            this.newForm.controls.startDate.setValue(this.datePipe.transform(this.applicant.startdate, 'dd-MMM-yyyy'));
            this.newForm.controls.dob.setValue(this.datePipe.transform(this.primaryInfo[0].dob, 'MM/dd/yyyy'));
            this.newForm.controls.gender.setValue(this.primaryInfo[0].gender);
            this.newForm.controls.weight.setValue(this.primaryInfo[0].weight);
            this.newForm.controls.member_id.setValue(this.primaryInfo[0].member_id);
        }
        if (this.spouseInfo != null || this.spouseInfo != undefined) {
            if (this.spouseInfo.length > 0) {
                this.spouse_Array().push(this.newEmployee1());
                let height = this.spouseInfo[0].height;
                const n = height.toString().includes('.');
                if (n == false) {
                    const str2 = '.0';
                    height = height.toString().concat(str2);
                }
                const spouseHeight = height.toString();
                const Inch = spouseHeight.substring(spouseHeight.lastIndexOf('.') + 1);
                const Feet = spouseHeight.substring(0, spouseHeight.lastIndexOf('.'));
                this.newForm.controls['spouse_Array']['controls'][0].patchValue({
                    spouseFirstname: this.spouseInfo[0].firstname,
                    spouseDob: this.datePipe.transform(this.spouseInfo[0].dob, 'MM/dd/yyyy'),
                    spouseWeight: this.spouseInfo[0].weight,
                    spouseInch: Inch,
                    spouseFeet: Feet,
                    spouseAge: this.spouseInfo[0].age,
                    spouseGender: this.spouseInfo[0].gender,
                    spouse_id: this.spouseInfo[0].member_id
                });
            }
        }
        if (this.childInfo != null || this.childInfo != undefined) {
            if (this.childInfo.length != 0) {

                for (let i = 0; i < this.childInfo.length; i++) {
                    this.child_Array().push(this.newChild());
                    let height = this.childInfo[i].height;
                    const n = height.toString().includes('.');
                    if (n == false) {
                        const str2 = '.0';
                        height = height.toString().concat(str2);
                    }
                    const childHeight = height.toString();
                    const Inch = childHeight.substring(childHeight.lastIndexOf('.') + 1);
                    const Feet = childHeight.substring(0, childHeight.lastIndexOf('.'));
                    this.newForm.controls['child_Array']['controls'][i].patchValue({
                        childFirstname: this.childInfo[i].firstname,
                        childDob: this.datePipe.transform(this.childInfo[i].dob, 'MM/dd/yyyy'),
                        childGender: this.childInfo[i].gender,
                        childAge: this.childInfo[i].age,
                        childInch: Inch,
                        childFeet: Feet,
                        childWeight: this.childInfo[i].weight,
                        child_id: this.childInfo[i].member_id
                    });
                }
            }
        }
    }

    sameAddressControl(value, feature, index?) {

        if (value && feature == 'child') {
            if (this.child_Array().length > 0) {
                for (let i = 0; i < this.child_Array().length; i++) {
                    this.child_Array().controls[index]['controls'].address1.setValue(this.newForm.controls.address1.value);
                    this.child_Array().controls[index]['controls'].address2.setValue(this.newForm.controls.address2.value);
                    this.child_Array().controls[index]['controls'].city.setValue(this.newForm.controls.city.value);
                    this.child_Array().controls[index]['controls'].zip.setValue(this.newForm.controls.zip.value);
                    this.child_Array().controls[index]['controls'].state.setValue(this.newForm.controls.state.value);

                }
            }
        }
        if (!value && feature == 'child') {
            if (this.child_Array().length > 0) {
                for (let i = 0; i < this.child_Array().length; i++) {
                    this.child_Array().controls[index]['controls'].address1.setValue('');
                    this.child_Array().controls[index]['controls'].address2.setValue('');
                    this.child_Array().controls[index]['controls'].city.setValue('');
                    this.child_Array().controls[index]['controls'].zip.setValue('');
                    this.child_Array().controls[index]['controls'].state.setValue('');

                }
            }
        }
        if (value && feature == 'spouse') {
            if (this.spouse_Array().length > 0) {
                this.newForm.controls['spouse_Array']['controls'][0].get('address1').setValue(this.newForm.controls.address1.value),
                    this.newForm.controls['spouse_Array']['controls'][0].get('address2').setValue(this.newForm.controls.address2.value),
                    this.newForm.controls['spouse_Array']['controls'][0].get('city').setValue(this.newForm.controls.city.value),
                    this.newForm.controls['spouse_Array']['controls'][0].get('zip').setValue(this.newForm.controls.zip.value);
                this.newForm.controls['spouse_Array']['controls'][0].get('state').setValue(this.newForm.controls.state.value);
            }
        }
        if (!value && feature == 'spouse') {
            if (this.spouse_Array().length > 0) {
                this.newForm.controls['spouse_Array']['controls'][0].get('address1').setValue(''),
                    this.newForm.controls['spouse_Array']['controls'][0].get('address2').setValue(''),
                    this.newForm.controls['spouse_Array']['controls'][0].get('city').setValue(''),
                    this.newForm.controls['spouse_Array']['controls'][0].get('zip').setValue('');
                this.newForm.controls['spouse_Array']['controls'][0].get('state').setValue('');
            }
        }
    }

    async getNoSaleProductUsaStates() {
        try {
            const url = 'ListNoSaleProductUsaStatesD2C/' + this.companyId;
            const usaStatesResponse: any = await this.service.getData(url).toPromise();
            if (usaStatesResponse && usaStatesResponse.data && usaStatesResponse.data.length > 0) {
                this.producStatesData = usaStatesResponse.data;
                this.usaStates = usaStatesResponse.data.map(state => ({
                    name: state.name,
                    state: state.state,
                    active: state.active
                }));
                this.usaStatesData = usaStatesResponse.data;
            } else {
                this.toastr.error(usaStatesResponse?.message || 'Failed to fetch USA states.');
                return;
            }

            const body = {company_id: this.companyId};
            const productStatesResponse: any = await this.service.postData('ProductStatesList', body).toPromise();

            if (productStatesResponse && productStatesResponse.status) {
                const statesArray = this.producStatesData;
                const mappedStates = productStatesResponse.data.map(item => {
                    const state = statesArray.find(state => state.id === item.state_id);
                    return state ? {name: state.name, state: state.state, active: state.active} : null;
                });
                this.usaStates = mappedStates.filter(state => state !== null);
            } else {
                this.toastr.clear();
                this.toastr.error(productStatesResponse?.message || 'Failed to fetch product states.', '', {
                    closeButton: true,
                    timeOut: this.toasterTimeout,
                });
            }
        } catch (error) {
            if (error.error?.message === 'Unauthenticated.') {
                this.openError = true;
            } else {
                console.error(error);
            }
        }
    }

    checkIfActiveState(val) {
        this.usaStates.forEach(state => {
            if (state.name == val.target.value) {
                if (state.active == 0) {
                    this.isActiveState = false;
                    this.toastr.clear();
                    this.toastr.error('Thank you for your interest. At this time, Product is not available to the residents of this state.', '', {
                        closeButton: true,
                        timeOut: this.toasterTimeout,
                    });
                } else {
                    this.isActiveState = true;
                }
            }
        });
    }

    getHealthQuestions() {

        const url = 'ListHealthQuestions';
        const body = {
            company_id: this.companyId
        };
        this.service.postData(url, body).subscribe((obj: any) => {
            this.healthQuestions = JSON.parse(JSON.stringify(obj.data));
            this.spouseHealthQuestions = JSON.parse(JSON.stringify(obj.data));
            this.originalHealthQustions = JSON.parse(JSON.stringify(obj.data));
            this.Policy_replacement_questions = true;
            this.healthQuestions.forEach(element => {
                element.health_issue.forEach(ele => {
                    if (ele.custom_message != null) {
                        element.color = ele.color;
                    }

                });

            });
            this.spouseHealthQuestions.forEach(element => {
                element.health_issue.forEach(ele => {
                    if (ele.custom_message != null) {
                        element.color = ele.color;
                    }

                });

            });
            // this.healthQuestions.forEach((element, idx) => {
            //   element.id = this.healthQuestions.indexOf(element) + 1;
            // })
            // this.buildForm(obj.data)
            const group: any = {};
            this.healthQuestions.forEach(element => {
                element.value = false;
                this.newForm.addControl(
                    'question_id_' + element.id,
                    this.formBuilder.control('', [Validators.required])
                );

                element.health_issue?.forEach(ele => {
                    ele.health_issue_options?.forEach(elem => {


                        this.newForm.addControl(
                            'question_' + elem.id,
                            this.formBuilder.control('')
                        );
                    });
                });
            });

            this.spouseHealthQuestions.forEach(element => {
                element.value = false;

                (this.newForm.get('spouseHealthQtns') as UntypedFormGroup).addControl(
                    'question_id_' + element.id,
                    this.formBuilder.control('')
                );
                element.health_issue?.forEach(ele => {
                    ele.health_issue_options?.forEach(elem => {
                        (this.newForm.get('spouseHealthQtns') as UntypedFormGroup).addControl(
                            'question_' + elem.id,
                            this.formBuilder.control('')
                        );


                    });
                });
            });

            if (this.family_id != undefined && this.family_id != '') {
                this.member_health_issue_data.forEach(item => {
                    if (item.answer == 'Yes') {

                        if (!!item.fields) {
                            item.fields.forEach(field => {
                                const fieldName = `question_${field.health_issue_id}`;
                                if (this.newForm.contains(fieldName)) {
                                    this.newForm.get(fieldName)?.setValue(field.value);
                                }
                            });
                        }


                        this.healthQuestions.forEach(qtn => {
                            if (qtn.id == item.health_id) {
                                qtn.value = true;
                                qtn.health_issue.forEach(qtns => {
                                    if (qtns.value == 'Yes') {
                                        if (qtns.can_enroll == 0) {
                                            this.hidePrimaryDetails = true;
                                        }
                                        if (!!qtns.health_issue_options) {
                                            qtns.health_issue_options?.forEach(element => {
                                                item.fields.forEach(field => {
                                                    if (field.health_issue_id == element.id) {
                                                        element.enteredvalue = field.value;
                                                    }
                                                });
                                            });
                                        }
                                    }
                                });
                            }
                        });
                    }
                    const controlName = `question_id_${item.health_id}`;
                    if (this.newForm.contains(controlName)) {
                        this.newForm.get(controlName)?.setValue(item.answer);
                    }
                });


                if (!!this.spouse_health_issue_data && this.spouse_health_issue_data.length > 0) {
                    this.spouse_health_issue_data.forEach(item => {
                        if (item.answer == 'Yes') {
                            item.fields.forEach(field => {
                                const fieldName = `question_${field.health_issue_id}`;
                                if ((this.newForm.get('spouseHealthQtns') as UntypedFormGroup).contains(fieldName)) {
                                    (this.newForm.get('spouseHealthQtns') as UntypedFormGroup).get(fieldName)?.setValue(field.value);
                                }
                            });
                            this.spouseHealthQuestions.forEach(qtn => {
                                if (qtn.id == item.health_id) {
                                    qtn.value = true;
                                    qtn.health_issue.forEach(qtns => {
                                        if (qtns.value == 'Yes') {
                                            if (qtns.can_enroll == 0) {
                                                this.hideSpouseDetails = true;
                                            }
                                            qtns.health_issue_options.forEach(element => {
                                                item.fields.forEach(field => {
                                                    if (field.health_issue_id == element.id) {
                                                        element.enteredvalue = field.value;
                                                    }
                                                });
                                            });
                                        }
                                    });
                                }
                            });
                        }
                        const controlName = `question_id_${item.health_id}`;
                        if ((this.newForm.get('spouseHealthQtns') as UntypedFormGroup).contains(controlName)) {
                            (this.newForm.get('spouseHealthQtns') as UntypedFormGroup).get(controlName)?.setValue(item.answer);
                        }
                    });
                }
            }
        }, err => {

        });

    }

    /**
     * Check if all form controls starting with 'question_' are valid.
     * @returns {boolean} True if all such controls are valid, otherwise false.
     */
    areQuestionControlsValid(): boolean {
        let allValid = true;
        const controls = this.newForm.controls;

        for (const key in controls) {
            if (controls.hasOwnProperty(key) && key.startsWith('question_id_')) {
                if (!controls[key].valid) {
                    allValid = false;
                    break; // Exit the loop early if any control is invalid
                }
            }
        }

        return allValid;
    }

    /**
     * Check if all form controls starting with 'question_id_' in the 'spouseHealthQtns' group are valid.
     * @returns {boolean} True if all such controls are valid, otherwise false.
     */
    areSpouseQuestionControlsValid(): boolean {
        let allValid = true;

        // Explicitly cast the control to FormGroup
        const group = this.newForm.get('spouseHealthQtns') as UntypedFormGroup;

        if (!group || !(group instanceof UntypedFormGroup)) {
            return false; // Safeguard: return false if it's not a FormGroup
        }

        // Access controls from the FormGroup
        const controls = group.controls;

        for (const key in controls) {
            if (controls.hasOwnProperty(key) && key.startsWith('question_id_')) {
                if (!controls[key].valid) {
                    allValid = false;
                    break; // Exit the loop early if any control is invalid
                }
            }
        }

        return allValid;
    }

    toggleswitch(item) {
        const val = (item.value == 'Yes');
        if (val) {
            // Set validators to required if val is true
            this.healthQuestions.forEach(elem => {
                if (elem.id == item.health_id) {
                    elem.health_issue.forEach(element => {
                        if (element.value == 'Yes') {
                            if (!!element.health_issue_options && element.health_issue_options.length > 0) {
                                element.health_issue_options.forEach(option => {
                                    const controlName = `question_${option.id}`;
                                    if (this.newForm.contains(controlName)) {
                                        this.newForm.get(controlName)?.setValidators(Validators.required);
                                        this.newForm.get(controlName)?.updateValueAndValidity();
                                    }
                                });
                            }


                        }
                    });
                }
            });
        } else {
            // Clear validators if val is false
            this.healthQuestions.forEach(elem => {
                if (elem.id == item.health_id) {
                    elem.health_issue.forEach(element => {
                        if (element.value == 'Yes') {
                            if (!!element.health_issue_options && element.health_issue_options.length > 0) {
                                element.health_issue_options.forEach(option => {
                                    const controlName = `question_${option.id}`;
                                    if (this.newForm.contains(controlName)) {
                                        this.newForm.get(controlName)?.clearValidators();
                                        this.newForm.get(controlName)?.updateValueAndValidity();
                                    }
                                });
                            }

                        }
                    });
                }
            });
        }

        this.newForm;

        setTimeout(() => {
            const filteredqtns = [];
            this.healthQuestions.forEach(element => {
                if (element.value == true) {
                    element.health_issue?.forEach(elem => {
                        if (elem.can_enroll == 0) {
                            filteredqtns.push(element);
                        }

                    });
                }

            });

            if (filteredqtns.length > 0) {
                const filtereddata = filteredqtns.every(obj =>
                    obj.health_issue.some(nestedObj => nestedObj.can_enroll == 0)
                );
                if (filtereddata == true) {
                    this.hidePrimaryDetails = true;


                    // this.newForm.controls.manager.setValue(''); future use
                    // this.newForm.controls.dob.setValue('');
                    // this.newForm.controls.age.setValue('');
                    // this.newForm.controls.feet.setValue('');
                    // this.newForm.controls.inch.setValue('');
                    // this.newForm.controls.startDate.setValue('');
                    // this.newForm.controls.address1.setValue('');
                    // this.newForm.controls.address2.setValue('');
                    // this.newForm.controls.city.setValue('');
                    // this.newForm.controls.state.setValue('');
                    // this.newForm.controls.zip.setValue('');
                    // this.newForm.controls.gender.setValue('');
                    // this.newForm.controls.tobacco.setValue('');
                    // this.newForm.controls.working_hours_20.setValue('');
                    // this.newForm.controls.ssn.setValue('');
                    // this.newForm.disable();
                }
            } else {
                this.hidePrimaryDetails = false;
                // this.newForm.enable();
            }
        }, 10);
    }

    toggleSpouseswitch(item) {
        const val = (item.value == 'Yes');
        if (val) {
            // Set validators to required if val is true
            this.spouseHealthQuestions.forEach(elem => {
                if (elem.id == item.health_id) {
                    elem.health_issue.forEach(element => {
                        if (element.value == 'Yes') {
                            if (!!element.health_issue_options && element.health_issue_options.length > 0) {
                                element.health_issue_options.forEach(option => {
                                    const controlName = `question_${option.id}`;
                                    if ((this.newForm.get('spouseHealthQtns') as UntypedFormGroup).contains(controlName)) {
                                        this.newForm.get(controlName)?.setValidators(Validators.required);
                                        this.newForm.get(controlName)?.updateValueAndValidity();
                                    }
                                });
                            }


                        }
                    });
                }
            });
        } else {
            // Clear validators if val is false
            this.spouseHealthQuestions.forEach(elem => {
                if (elem.id == item.health_id) {
                    elem.health_issue.forEach(element => {
                        if (element.value == 'Yes') {
                            if (!!element.health_issue_options && element.health_issue_options.length > 0) {
                                element.health_issue_options.forEach(option => {
                                    const controlName = `question_${option.id}`;
                                    if ((this.newForm.get('spouseHealthQtns') as UntypedFormGroup).contains(controlName)) {
                                        this.newForm.get(controlName)?.clearValidators();
                                        this.newForm.get(controlName)?.updateValueAndValidity();
                                    }
                                });
                            }

                        }
                    });
                }
            });
        }

        this.newForm;

        setTimeout(() => {
            const spousefilteredqtns = [];
            this.spouseHealthQuestions.forEach(element => {
                if (element.value == true) {
                    element.health_issue?.forEach(elem => {
                        if (elem.can_enroll == 0) {
                            spousefilteredqtns.push(element);
                        }

                    });
                }

            });

            if (spousefilteredqtns.length > 0) {
                const filtereddata = spousefilteredqtns.every(obj =>
                    obj.health_issue.some(nestedObj => nestedObj.can_enroll == 0)
                );
                if (filtereddata == true) {
                    this.hideSpouseDetails = true;

                    this.newForm.controls['spouse_Array']['controls'][0].get('spouseFirstname').setValue('');
                    this.newForm.controls['spouse_Array']['controls'][0].get('spouseLastname').setValue('');
                    this.newForm.controls['spouse_Array']['controls'][0].get('spouseDob').setValue('');
                    this.newForm.controls['spouse_Array']['controls'][0].get('address1').setValue('');
                    this.newForm.controls['spouse_Array']['controls'][0].get('address2').setValue('');
                    this.newForm.controls['spouse_Array']['controls'][0].get('city').setValue('');
                    this.newForm.controls['spouse_Array']['controls'][0].get('state').setValue('');
                    this.newForm.controls['spouse_Array']['controls'][0].get('zip').setValue('');
                    this.newForm.controls['spouse_Array']['controls'][0].get('spouseGender').setValue('');
                    this.newForm.controls['spouse_Array']['controls'][0].get('spouseFeet').setValue('');
                    this.newForm.controls['spouse_Array']['controls'][0].get('spouseInch').setValue('');
                    this.newForm.controls['spouse_Array']['controls'][0].get('spousetobacco').setValue('');
                    this.newForm.controls['spouse_Array']['controls'][0].get('spouse_working_hours_20').setValue('');
                    this.newForm.controls['spouse_Array']['controls'][0].get('spouseSsn').setValue('');
                    this.newForm.controls['spouse_Array']['controls'][0].get('spouseAge').setValue('');
                    this.newForm.controls['spouse_Array']['controls'][0].get('spouseWeight').setValue('');
                    this.newForm.controls['spouse_Array']['controls'][0].get('checkBox').setValue('');

                    // this.newForm.disable();
                }
            } else {
                this.hideSpouseDetails = false;
                // this.newForm.enable();
            }
        }, 10);
    }

    removeValidators() {
        // this.newForm.controls.['firstname'].clearValidators();
        // element.controls.date_of_diagnosis.updateValueAndValidity({ emitEvent: false });
    }

    applicationFields(data, i, value) {

        if (data == true) {
            this.questiononeForm = true;
        } else {
            this.questiononeForm = false;
        }
    }

    previousEnrollment() {
        const url = 'GetBeneficiaryInfoD2C/' + this.companyId + '/' + this.family_id;
        this.service.getData(url).subscribe((Object: any) => {
           
            this.autoFillApplication(Object);

        }, err => {
            console.log(err);
        });
    }

    autoFillApplication(Object)
    {
        this.memberPersonalData = Object.members;
        const primaryMemberId = Object.members.find(member => member.relation === 'Primary')?.member_id;
        const spouseMemberId = Object.members.find(member => member.relation === 'Spouse')?.member_id;
        // Group health issue data by primary and spouse
        const primaryHealthIssues = Object.member_health_issue_data?.filter(issue => issue.member_id === primaryMemberId);
        const spouseHealthIssues = Object.member_health_issue_data?.filter(issue => issue.member_id === spouseMemberId);
        this.member_health_issue_data = primaryHealthIssues;
        this.spouse_health_issue_data = spouseHealthIssues;
        this.primary_info = this.memberPersonalData.filter(x => x.relation == 'Primary');
        this.spouse_info = this.memberPersonalData.filter(x => x.relation == 'Spouse');
        this.child_info = this.memberPersonalData.filter(x => x.relation == 'Child');

        this.primaryAddressobj = {
            address1: this.primary_info[0]?.address1,
            address2: this.primary_info[0]?.address2,
            city: this.primary_info[0]?.city,
            state: this.primary_info[0]?.state,
            zip: this.primary_info[0]?.zip
        };

        this.spouseAddressobj = {
            address1: this.spouse_info[0]?.address1,
            address2: this.spouse_info[0]?.address2,
            city: this.spouse_info[0]?.city,
            state: this.spouse_info[0]?.state,
            zip: this.spouse_info[0]?.zip
        };

        const result = JSON.stringify(this.primaryAddressobj) === JSON.stringify(this.spouseAddressobj);

        if (result) {
            this.isSpouseAddressControl.setValue(true);
        }


        this.newForm.controls.firstname.setValue(this.primary_info[0]?.firstname);
        this.newForm.controls.lastname.setValue(this.primary_info[0]?.lastname);
        this.newForm.controls.email.setValue(this.primary_info[0]?.email);
        this.newForm.controls.phone.setValue(this.primary_info[0]?.phone);
        this.newForm.controls.dob.setValue(this.datePipe.transform(this.primary_info[0]?.dob, 'MM/dd/yyyy'));
        this.newForm.controls.age.setValue(this.primary_info[0]?.age);
        this.newForm.controls.gender.setValue(this.primary_info[0]?.gender);
        this.newForm.controls.address1.setValue(this.primary_info[0]?.address1);

        this.newForm.controls.address1.setValue(this.primary_info[0]?.address1);
        this.newForm.controls.address2.setValue(this.primary_info[0]?.address2);
        this.newForm.controls.city.setValue(this.primary_info[0]?.city);
        this.newForm.controls.state.setValue(this.primary_info[0]?.state);
        this.newForm.controls.zip.setValue(this.primary_info[0]?.zip);
        this.newForm.controls.tobacco.setValue(this.primary_info[0]?.smoker);
        this.newForm.controls.working_hours_20.setValue(this.primary_info[0]?.working_hours_20);
        this.newForm.controls.ssn.setValue(this.primary_info[0]?.ssn);
        this.newForm.controls.member_id.setValue(this.primary_info[0]?.member_id);
        if (this.spouse_info.length > 0) {
            this.spouse_check = true;
            this.addEmployee(true, 'spouse');

            (document.getElementById('spousecheckbox') as HTMLInputElement).click();

            (document.getElementById('spousecheckbox') as HTMLInputElement).checked = true;
            for (let i = 0; i < this.spouse_Array().controls.length; i++) {
                this.newForm.controls['spouse_Array']['controls'][i].get('spouseFirstname').setValue(this.spouse_info[0]?.firstname),
                    this.newForm.controls['spouse_Array']['controls'][i].get('spouseLastname').setValue(this.spouse_info[0]?.lastname),
                    this.newForm.controls['spouse_Array']['controls'][i].get('spouseDob').setValue(
                        this.datePipe.transform(this.spouse_info[0]?.dob, 'MM/dd/yyyy')),
                    this.newForm.controls['spouse_Array']['controls'][i].get('spouseAge').setValue(this.spouse_info[0]?.age),
                    this.newForm.controls['spouse_Array']['controls'][i].get('spouseGender').setValue(this.spouse_info[0]?.gender);
                this.newForm.controls['spouse_Array']['controls'][i].get('address1').setValue(this.spouse_info[0]?.address1),
                    this.newForm.controls['spouse_Array']['controls'][i].get('address2').setValue(this.spouse_info[0]?.address2);
                this.newForm.controls['spouse_Array']['controls'][i].get('city').setValue(this.spouse_info[0]?.city);
                this.newForm.controls['spouse_Array']['controls'][i].get('state').setValue(this.spouse_info[0]?.state);
                this.newForm.controls['spouse_Array']['controls'][i].get('zip').setValue(this.spouse_info[0]?.zip);
                this.newForm.controls['spouse_Array']['controls'][i].get('spousetobacco').setValue(this.spouse_info[0]?.smoker);
                this.newForm.controls['spouse_Array']['controls'][i].get('spouse_working_hours_20').setValue(this.spouse_info[0]?.working_hours_20);
                this.newForm.controls['spouse_Array']['controls'][i].get('spouseSsn').setValue(this.spouse_info[0]?.ssn);
                this.newForm.controls['spouse_Array']['controls'][i].get('spouse_id').setValue(this.spouse_info[0]?.member_id);
                if (!!this.spouse_info[0]?.email && !!this.spouse_info[0]?.phone) {

                    // Assuming `newForm` is a FormGroup and 'spouse_Array' is the key for a FormArray inside it
                    const spouseArray = this.newForm.get('spouse_Array') as UntypedFormArray;

                    // Access the first FormGroup in the FormArray
                    const spouseFormGroup = spouseArray.at(0) as UntypedFormGroup;

                    // Adding new FormControl 'spouseEmail' to the FormGroup
                    spouseFormGroup.addControl(
                        'spouseEmail',
                        new UntypedFormControl('', Validators.compose([
                            Validators.required,
                            Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                        ]))
                    );

                    // Adding new FormControl 'spousePhone' to the FormGroup
                    spouseFormGroup.addControl(
                        'spousePhone',
                        new UntypedFormControl('', Validators.required)
                    );

                    // Optionally, you can directly set values without setTimeout unless there's a specific need
                    spouseFormGroup.get('spouseEmail').setValue(this.spouse_info[0]?.email);
                    spouseFormGroup.get('spousePhone').setValue(this.spouse_info[0]?.phone);

                }
                this.newForm.controls['spouse_Array']['controls'][i].get('checkBox').setValue('true');

            }
        } else {
            this.spouse_check = false;
        }
        if (this.child_info.length > 0) {
            this.displayText = true;
            (document.getElementById('childcheckbox') as HTMLInputElement).checked = true;
            for (let i = 0; i < this.child_info.length; i++) {
                this.child_Array().push(this.newChild());
                this.newForm.controls['child_Array']['controls'][i].get('childFirstname').setValue(this.child_info[i]?.firstname),
                    this.newForm.controls['child_Array']['controls'][i].get('childLastname').setValue(this.child_info[i]?.lastname),
                    this.newForm.controls['child_Array']['controls'][i].get('childDob').setValue(
                        this.datePipe.transform(this.child_info[i]?.dob, 'MM/dd/yyyy')),
                    this.newForm.controls['child_Array']['controls'][i].get('childAge').setValue(this.child_info[i]?.age),
                    this.newForm.controls['child_Array']['controls'][i].get('childGender').setValue(this.child_info[i]?.gender),
                    this.newForm.controls['child_Array']['controls'][i].get('childSsn').setValue(this.child_info[i]?.ssn),
                    this.newForm.controls['child_Array']['controls'][i].get('address1').setValue(this.child_info[i]?.address1),
                    this.newForm.controls['child_Array']['controls'][i].get('address2').setValue(this.child_info[i]?.address2);
                this.newForm.controls['child_Array']['controls'][i].get('city').setValue(this.child_info[i]?.city);
                this.newForm.controls['child_Array']['controls'][i].get('state').setValue(this.child_info[i]?.state);
                this.newForm.controls['child_Array']['controls'][i].get('zip').setValue(this.child_info[i]?.zip);
                this.newForm.controls['child_Array']['controls'][i].get('child_id').setValue(this.child_info[i]?.member_id);
                this.newForm.controls['child_Array']['controls'][i].get('checkBox').setValue('true');
            }
        }
        this.cdr.detectChanges();
        this.child_info.forEach((element, i) => {
            this.childAddressobj = {
                address1: element.address1,
                address2: element.address2,
                city: element.city,
                state: element.state,
                zip: element.zip
            };
            const childAddressresult = JSON.stringify(this.primaryAddressobj) === JSON.stringify(this.childAddressobj);
            if (childAddressresult) {
                setTimeout(() => {
                    const x = (document.getElementById('childaddresscheckbox' + i) as HTMLInputElement);
                    if (x != null) {
                        (document.getElementById('childaddresscheckbox' + i) as HTMLInputElement)?.click();
                        (document.getElementById('childaddresscheckbox' + i) as HTMLInputElement).checked = true;
                    }
                }, 50);
            }
        });

        // If Agent info is available in the response
        if (Object.agent_info) {
            this.agentInfo = Object.agent_info;
            if (this.agentInfo.code) {
                this.isAgentCodeApplied = true;
                this.agentCodeParam = this.agentInfo.code;
                this.agent_Id = this.agentInfo.id;
                this.newForm.controls.agentCode.setValue(this.agentCodeParam);
                this.getHealthQuestions();
            }

        }
    }

    newChild(): UntypedFormGroup {
        return this.formBuilder.group({
            childFirstname: ['', Validators.required],
            childLastname: ['', Validators.required],
            childDob: ['', Validators.required],
            childGender: ['', Validators.required],
            childSsn: ['',], // isValidSSNNumber
            address1: ['', Validators.required],
            address2: [''],
            active: [1],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{5}$')]],
            childFeet: ['', ''],
            childInch: ['',],
            childAge: ['', [Validators.required, Validators.min(0), Validators.max(26)]],
            child_id: [''],
            childWeight: ['', ''],
            relation: ['Child'],
            checkBox: [],
            disabled: ['']
        });
    }

    onItemSelect(e) {
        this.group_id = e.value.value;

    }

    newEmployee1(): UntypedFormGroup {
        return this.formBuilder.group({
            spouseFirstname: ['', Validators.required],
            spouseLastname: ['', Validators.required],
            spouseDob: ['', Validators.required],
            address1: ['', Validators.required],
            address2: [''],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{5}$')]],
            spouseGender: ['', Validators.required],
            spouseFeet: ['', ''],
            spouseInch: ['',],
            spousetobacco: ['', Validators.required],
            spouse_working_hours_20: ['', Validators.required],
            spouseSsn: ['',], // isValidSSNNumber
            spouseAge: ['', [Validators.required, Validators.min(18), Validators.max(70)]],
            spouse_id: ['',],
            spouseWeight: ['', ''],
            relation: ['Spouse'],
            checkBox: [],
            disabled: ['']
        });
    }

    child_Array(): UntypedFormArray {
        return this.newForm.get('child_Array') as UntypedFormArray;
    }

    spouse_Array(): UntypedFormArray {
        return this.newForm.get('spouse_Array') as UntypedFormArray;
    }

    qtnsform_Array(): UntypedFormArray {
        return this.newForm.get('qtnsform_Array') as UntypedFormArray;
    }

    // ================Add and remove spouse and child================//
    addEmployee(event, va?) {
        if (event) {
            if (va == 'child') {
                this.child_Array().push(this.newChild());
            } else {
                this.spouse_check = true;
                if (this.spouse_Array().length <= 0) {
                    this.spouse_Array().push(this.newEmployee1());
                    this.newEmployee1();
                    this.spouseForm = false;
                    if (!!this.spouseHealthQuestions) {
                        this.spouseHealthQuestions.forEach(element => {
                            element.value = false;

                            (this.newForm.get('spouseHealthQtns') as UntypedFormGroup).addControl(
                                'question_id_' + element.id,
                                this.formBuilder.control('')
                            );
                            element.health_issue?.forEach(ele => {
                                ele.health_issue_options?.forEach(elem => {
                                    (this.newForm.get('spouseHealthQtns') as UntypedFormGroup).addControl(
                                        'question_' + elem.id,
                                        this.formBuilder.control('')
                                    );


                                });
                            });
                        });
                    }

                }
            }
        } else {
            this.spouse_Array().removeAt(0);
            (this.newForm.get('spouseHealthQtns') as UntypedFormGroup).reset();
            this.spouse_check = false;
            this.isSpouseEligible = true;
        }

        Object.keys((this.newForm.get('spouseHealthQtns') as UntypedFormGroup).controls).forEach(key => {
            if (key.startsWith('question_id_')) {
                const control = (this.newForm.get('spouseHealthQtns') as UntypedFormGroup).get(key);

                if (this.spouse_check) {
                    control.setValidators(Validators.required);
                } else {
                    control.clearValidators();
                }

                control.updateValueAndValidity();
            }
        });


    }

    addChild(event, va) {
        if (event.target.checked == true) {
            this.displayText = true;
            this.addchilddisabled = true;
            this.child_Array().push(this.newChild());
        } else {
            this.child_check = false;
            this.addchilddisabled = false;
        }
    }

    add_child() {
        this.child_Array().push(this.newChild());
        if (this.isChildAddressControl.value) {
            this.sameAddressControl(this.isChildAddressControl.value, 'child');
        }
    }

    // ==================Remove form Array==================//
    removeEmployee(empIndex, childValue) {
        this.test = true;
        if (childValue.child_id != '') {
            this.arr.push(childValue);
        }
        this.child_Array().removeAt(empIndex);
        if (this.child_Array().length == 0) {
            this.child_check = false;
            this.addchilddisabled = false;
            this.displayText = false;
        }
    }

    emailValidation(value) {

        if (this.emailError != null || this.phoneerrmsg != null) {
            this.errorMsgShow = false;
        }
    }

    InputChange(ControlName) {
        const ControlValue = this.newForm.controls[ControlName].value;
        for (let i = 0; i < this.spouse_Array.length; i++) {
            if (this.newForm.controls['child_Array']['controls'][i].controls.checkBox.value) {
                this.newForm.controls['child_Array']['controls'][i].controls[ControlName].setValue(ControlValue);
            }
        }
        for (let i = 0; i < this.child_Array.length; i++) {
            if (this.newForm.controls['spouse_Array']['controls'][i].controls.checkBox.value) {
                this.newForm.controls['spouse_Array']['controls'][i].controls[ControlName].setValue(ControlValue);
            }
        }

    }

    checkBoXChange(index, arrayName) {
        if (this.newForm.controls[arrayName]['controls'][index].controls.checkBox.value) {
            this.newForm.controls[arrayName]['controls'][index].patchValue({
                disabled: true,
                address1: this.newForm.controls['address1'].value ?  this.newForm.controls['address1'].value:  this.newForm.controls['spouse_Array']['controls'][0]?.controls['address1'].value,
                address2: this.newForm.controls['address2'].value ? this.newForm.controls['address2'].value:  this.newForm.controls['spouse_Array']['controls'][0]?.controls['address2'].value,
                city: this.newForm.controls['city'].value ? this.newForm.controls['city'].value:  this.newForm.controls['spouse_Array']['controls'][0]?.controls['city'].value,
                state: this.newForm.controls['state'].value ? this.newForm.controls['state'].value:  this.newForm.controls['spouse_Array']['controls'][0]?.controls['state'].value,
                zip: this.newForm.controls['zip'].value ? this.newForm.controls['zip'].value:  this.newForm.controls['spouse_Array']['controls'][0]?.controls['zip'].value,
            });
        } else {
            this.newForm.controls[arrayName]['controls'][index].patchValue({
                disabled: false,
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
            });
        }
    }

    getValidators(field: string): any[] {
        const validatorsMap = {
            firstname: [Validators.required],
            lastname: [Validators.required],
            email: Validators.compose([
                Validators.required,
                Validators.pattern(
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
            ]),
            phone: [Validators.required],
            dob: [Validators.required],
            age: [Validators.required, Validators.min(18), Validators.max(70)],
            address1: [Validators.required],
            address2: [],
            city: [Validators.required],
            state: [Validators.required],
            zip: [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{5}$')],
            gender: [Validators.required],
            tobacco: [Validators.required],
            working_hours_20: [Validators.required],
            ssn: [Validators.required], // Validators.compose([isValidSSNNumber]),
            relation: [],
            agentCode: [],
            member_id: [],
            active: [],  // Assuming no validators are required for this field
            startDate: [],  // Assuming no validators are required for this field
            feet: [],  // Assuming no validators are required for this field
            inch: [],  // Assuming no validators are required for this field
            weight: []  // Assuming no validators are required for this field
        };

        // Return the validators if field exists, otherwise return an empty array
        return validatorsMap[field] || [];
    }

    checkAndModifyControls() {
        setTimeout(() => {
            const group = this.spouseArray.at(0) as UntypedFormGroup;
            if (!!group) {
                if (this.hidePrimaryDetails && this.spouse_check) {
                    // Add 'spouseEmail' control if it does not exist
                    if (!group.contains('spouseEmail')) {
                        group.addControl('spouseEmail', new UntypedFormControl('', Validators.compose([
                            Validators.required,
                            Validators.pattern(
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            ),
                        ])));
                    }

                    // Add 'spousePhone' control if it does not exist
                    if (!group.contains('spousePhone')) {
                        group.addControl('spousePhone', new UntypedFormControl('', Validators.required));
                    }
                } else {
                    // Remove 'spouseEmail' control if it exists
                    if (group?.contains('spouseEmail')) {
                        group.removeControl('spouseEmail');
                    }

                    // Remove 'spousePhone' control if it exists
                    if (group?.contains('spousePhone')) {
                        group.removeControl('spousePhone');
                    }
                }
            }
        }, 3000);

    }

    accenrollment(values) {
        if (this.areQuestionControlsValid() && this.areSpouseQuestionControlsValid()) {
            this.submitAttempt = true;
            if (this.hidePrimaryDetails) {
                const state = this.usaStates.filter(x => x.name == values.spouse_Array[0].state);
                localStorage.removeItem('state');
                localStorage.setItem('state', state[0].id);
            } else {
                const state = this.usaStates.filter(x => x.name == values.state);
                localStorage.removeItem('state');
                localStorage.setItem('state', state[0].id);
            }
            const alwaysValidate = ['firstname', 'lastname', 'email', 'phone'];

            Object.keys(this.newForm.controls).forEach(field => {
                const control = this.newForm.get(field);

                if (alwaysValidate.includes(field)) {
                    // Always set validators for these fields
                    control.setValidators(this.getValidators(field));
                } else {
                    if (this.hidePrimaryDetails) {
                        // Clear validators if hidePrimaryDetails is true
                        control.clearValidators();
                    } else {
                        // Apply conditional validators
                        control.setValidators(this.getValidators(field));
                    }
                }

                // Update the validity of the control
                control.updateValueAndValidity();
            });
            if (this.hideSpouseDetails) {
                this.spouse_Array().removeAt(0);
            }
            if (this.newForm.valid) {
                this.member_personal_data = {};
                this.loader = true;
                this.submitAttempt = false;
                this.objectData.company_id = this.companyId;
                this.objectData.group_id = this.group_id;
                this.objectData.group_name = this.group_name;
                this.objectData.group_code = this.group_code;
                this.objectData.product_company_id = this.companyId;
                this.objectData.application_id = this.application_id;
                // this.objectData.request_type = 'acc'
                this.objectData.agent_id = this.agent_Id;
                // this.objectData.agent_code = values.agentCode;
                this.objectData.member_personal_data = new Array<any>();
                this.member_personal_data.firstname = values.firstname;
                this.member_personal_data.lastname = values.lastname;
                this.member_personal_data.email = values.email;
                this.member_personal_data.phone = values.phone.replace(/[^0-9\.]+/g, '');
                this.member_personal_data.relation = 'Primary';
                this.member_personal_data.member_id = values.member_id;
                // if (!this.hidePrimaryDetails) { future use
                    this.member_personal_data.smoker = values.tobacco;
                    this.member_personal_data.working_hours_20 = values.working_hours_20;
                    this.member_personal_data.ssn = values.ssn;
                    this.member_personal_data.weight = values.weight;
                    this.member_personal_data.address1 = values.address1;
                    this.member_personal_data.address2 = values.address2;
                    this.member_personal_data.city = values.city;
                    this.member_personal_data.state = values.state;
                    this.member_personal_data.zip = values.zip;
                    this.member_personal_data.dob = this.datePipe.transform(values.dob, 'yyyy-MM-dd');
                    this.member_personal_data.age = values.age;
                    this.member_personal_data.gender = values.gender;
                    this.member_personal_data.height = values.feet + '.' + values.inch;
                    this.objectData.member_personal_data.push(this.member_personal_data);
                // } else {
                //     this.objectData.member_personal_data.push(this.member_personal_data);
                // }

                if (!this.hideSpouseDetails && this.spouse_check) {
                    for (let k = 0; k < values.spouse_Array.length; k++) { // insert
                        // On This condition we need to work after TEST demo
                        // if(values.spouse_Array[k].spouse_working_hours_20 == 'Yes') {
                        this.member_personal_data = {};
                        this.member_personal_data.firstname = values.spouse_Array[k].spouseFirstname;
                        this.member_personal_data.lastname = values.spouse_Array[k].spouseLastname;
                        this.member_personal_data.smoker = values.spouse_Array[k].spousetobacco;
                        this.member_personal_data.working_hours_20 = values.spouse_Array[k].spouse_working_hours_20;
                        this.member_personal_data.member_id = values.spouse_Array[k].spouse_id;
                        if (this.hidePrimaryDetails) {
                            this.member_personal_data.spouse_as_primary = 1;
                            this.member_personal_data.email = values.spouse_Array[k].spouseEmail;
                            this.member_personal_data.phone = values.spouse_Array[k].spousePhone;

                        }
                        this.member_personal_data.relation = 'Spouse';
                        this.member_personal_data.weight = values.spouse_Array[k].spouseWeight;
                        this.member_personal_data.dob = this.datePipe.transform(values.spouse_Array[k].spouseDob, 'yyyy-MM-dd');
                        this.member_personal_data.age = values.spouse_Array[k].spouseAge;
                        this.member_personal_data.gender = values.spouse_Array[k].spouseGender;
                        this.member_personal_data.ssn = values.spouse_Array[k].spouseSsn;
                        this.member_personal_data.active = 1;
                        this.member_personal_data.address1 = values.spouse_Array[k].address1;
                        this.member_personal_data.address2 = values.spouse_Array[k].address2;
                        this.member_personal_data.city = values.spouse_Array[k].city;
                        this.member_personal_data.zip = values.spouse_Array[k].zip;
                        this.member_personal_data.state = values.spouse_Array[k].state;
                        this.member_personal_data.height = values.spouse_Array[k].spouseFeet + '.' + values.spouse_Array[k].spouseInch;
                        this.objectData.member_personal_data.push(this.member_personal_data);
                        // }
                    }
                    if (this.spousearr.length > 0) {// for remove option
                        for (let z = 0; z < this.spousearr.length; z++) {
                            this.member_personal_data = {};
                            this.member_personal_data.firstname = this.spousearr[z].spouseFirstname;
                            this.member_personal_data.lastname = this.spousearr[z].spouseLastname;
                            this.member_personal_data.smoker = '';
                            this.member_personal_data.ssn = '';
                            this.member_personal_data.member_id = this.spousearr[z].spouse_id;
                            this.member_personal_data.relation = 'Spouse';
                            this.member_personal_data.weight = this.spousearr[z].spouseWeight;
                            this.member_personal_data.dob = this.datePipe.transform(this.spousearr[z].spouseDob, 'yyyy-MM-dd');
                            this.member_personal_data.age = this.spousearr[z].spouseAge;

                            this.member_personal_data.gender = this.spousearr[z].spouseGender;
                            this.member_personal_data.active = 0;
                            this.member_personal_data.height = this.spousearr[z].spouseFeet + '.' + this.spousearr[z].spouseInch;
                            this.objectData.member_personal_data.push(this.member_personal_data);
                        }
                    }
                }
                if (!this.hidePrimaryDetails || !this.hideSpouseDetails) {
                    for (let i = 0; i < values.child_Array.length; i++) {
                        this.member_personal_data = {};
                        this.member_personal_data.firstname = values.child_Array[i].childFirstname;
                        this.member_personal_data.lastname = values.child_Array[i].childLastname;
                        this.member_personal_data.smoker = 'no';
                        this.member_personal_data.relation = 'Child';
                        this.member_personal_data.member_id = values.child_Array[i].child_id;
                        this.member_personal_data.weight = values.child_Array[i].childWeight;
                        this.member_personal_data.dob = this.datePipe.transform(values.child_Array[i].childDob, 'yyyy-MM-dd');
                        this.member_personal_data.age = values.child_Array[i].childAge;
                        this.member_personal_data.gender = values.child_Array[i].childGender;
                        this.member_personal_data.ssn = values.child_Array[i].childSsn;
                        this.member_personal_data.active = 1;
                        this.member_personal_data.address1 = values.child_Array[i]?.address1;
                        this.member_personal_data.address2 = values.child_Array[i]?.address2;
                        this.member_personal_data.city = values.child_Array[i]?.city;
                        this.member_personal_data.state = values.child_Array[i]?.state;
                        this.member_personal_data.zip = values.child_Array[i]?.zip;
                        this.member_personal_data.height = values.child_Array[i].childFeet + '.' + values.child_Array[i].childInch;
                        this.objectData.member_personal_data.push(this.member_personal_data);
                    }
                    if (this.arr.length > 0) {// for remove option
                        for (let j = 0; j < this.arr.length; j++) {
                            this.member_personal_data = {};
                            this.member_personal_data.firstname = this.arr[j].childFirstname;
                            this.member_personal_data.lastname = this.arr[j].childLastname;
                            this.member_personal_data.smoker = '';
                            this.member_personal_data.ssn = '';
                            this.member_personal_data.relation = 'Child';
                            this.member_personal_data.member_id = this.arr[j].child_id;
                            this.member_personal_data.weight = this.arr[j].childWeight;
                            this.member_personal_data.dob = this.datePipe.transform(this.arr[j].childDob, 'yyyy-MM-dd');
                            this.member_personal_data.age = this.arr[j].childAge;
                            this.member_personal_data.gender = this.arr[j].childGender;
                            this.member_personal_data.active = 0;
                            this.member_personal_data.height = this.arr[j].childFeet + '.' + this.arr[j].childInch;
                            this.objectData.member_personal_data.push(this.member_personal_data);
                        }
                    }
                }
                let finalObject: any = {};
                finalObject = this.objectData;
                const url = 'AddMemberD2C';

                this.service.postData(url, finalObject).subscribe((Object: any) => {
                    localStorage.setItem('acc_member_id', Object.member_personal_data[0]?.member_id);
                    if (Object != null || Object != undefined) {
                        this.loader = false;
                        this.submitHealthQts(Object.member_personal_data[0]?.member_id, Object.member_personal_data[0]?.family_id, Object);
                        localStorage.setItem('memberdata', JSON.stringify(Object));
                        localStorage.setItem('family_type', Object.family_type);
                        localStorage.setItem('user_company_id', Object.user_company_id);
                        localStorage.setItem('family_id', Object.member_personal_data[0].family_id);
                        localStorage.setItem('enrollment_type', Object.member_personal_data[0].enrollment_type);
                        this.router.navigate(['/products']);
                        // if (this.location.path().includes('d2c')) {
                        //   this.router.navigate([this.route_company_name + '/d2c/memberproduct']);
                        // }
                        // if (this.location.path().includes('acc')) {
                        //   this.router.navigate([this.route_company_name + '/acc/memberproduct']);
                        // }
                    }
                }, err => {
                    if (err.error.message == 'Unauthenticated.') {
                        this.openError = true;
                        this.loader = false;
                        this.toastr.clear();
                        this.toastr.error(err.error.message, '', {
                            closeButton: true,
                            timeOut: this.toasterTimeout,
                        });
                    } else {
                        this.loader = false;
                        this.errorMsgShow = true;
                        this.emailError = err.error.email;
                        this.phoneerrmsg = err.error.phone;

                        this.loader = false;
                        this.toastr.clear();
                        this.toastr.error(err.error.message, '', {
                            closeButton: true,
                            timeOut: this.toasterTimeout,
                        });
                    }
                });
            }
        } else {
            this.toastr.clear();
            this.toastr.error('Answer All Policy Questions', '', {
                closeButton: true,
                timeOut: this.toasterTimeout,
            });
        }


    }

    Reset_form() {
        this.applicant = '';
        this.newForm.reset();
        this.isSpouseAddressControl.setValue(false);
        this.isChildAddressControl.setValue(false);
        localStorage.removeItem('cartData_Id');
        localStorage.removeItem('cartProducts_productId');
    }

    // ===============Age Calculation==========//
    onSearchChange(searchValue: string): void {

        if (searchValue !== '') {
            const convertAge = new Date(searchValue);
            const timeDiff = Math.abs(Date.now() - convertAge.getTime());
            this.Age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
            this.newForm.controls.age.setValue(this.Age);
            if (this.Age <= 17) {
                this.parentAge = 'Applicant  can\'t be younger than 18 years of age';
            }
            if (this.Age > 70) {
                this.parentAge = 'Applicant  can\'t be elder than 70 age';
            }
            if (this.Age < 71 && this.Age > 18) {
                this.parentAge = '';
            }
        }
    }

    spouseAgeCal(searchValue, Index: number, dob): void {
        if (searchValue !== '') {
            const convertAge = new Date(searchValue);
            const timeDiff = Math.abs(Date.now() - convertAge.getTime());
            this.spouseAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
            const formcontrol = this.spouse_Array();
            formcontrol.controls[Index].get('spouseAge').setValue(this.spouseAge);
            if (this.spouseAge <= 17) {
                this.spouseAgeMsg = 'Applicant  can\'t be younger than 18 years of age';
            }
            if (this.spouseAge > 70) {
                this.spouseAgeMsg = 'Applicant  can\'t be elder than 70';
            }
            if (this.spouseAge < 71 && this.spouseAge > 18) {
                this.spouseAgeMsg = '';
            }
        }
    }

    childAgeCal(searchValue, Index: number, dob): void {
        const id = 'dob' + Index;
        if (id == dob) {
            if (searchValue !== '') {
                {
                    const convertAge = new Date(searchValue);
                    const timeDiff = Math.abs(Date.now() - convertAge.getTime());
                    this.childAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
                    if (this.childAge > 26) {
                        (document.getElementById('childDOBerror' + Index) as HTMLInputElement).innerHTML = 'Child over 26 years age should buy their own plan.';
                    }
                    if (this.childAge < 26) {
                        (document.getElementById('childDOBerror' + Index) as HTMLInputElement).innerHTML = '';
                    }
                }
                const formcontrol = this.child_Array();
                formcontrol.controls[Index].get('childAge').setValue(this.childAge);
            } else {
                this.childAge = '';
                this.ageError = '';
                const formcontrol = this.child_Array();
                formcontrol.controls[Index].get('childAge').setValue(this.childAge);

            }
        }
    }

    onSearch(wght: number, value) {
        if (value == 'primary') {
            if (wght >= 501 || wght < 1) {
                this.er = 'Weight range 1-500 lb';
            } else {
                this.er = '';
                this.weight = wght;
            }
        }

        if (value == 'spouse') {
            if (wght >= 501 || wght < 1) {
                this.spouseWtError = 'Weight range 1-500 lb';
            } else {
                this.spouseWtError = '';
                this.weight = wght;
            }
        }
    }


    getManagers() {
        const url = 'GetAgentGroups/' + this.agent_Id;
        this.managersList = [];
        this.service.getData(url).subscribe((Object: any) => {
            if (Object !== null) {
                this.managersList = Object.data;
                this.managersList.forEach(el => {
                    el.expanded = false;
                });
                this.data = this.managersList.map(value => {

                    return {
                        label: value.group_name,
                        value: value.group_id

                    };
                });


            }
        }, err => {
            this.toastr.clear();
            this.toastr.error(err.error.message, '', {
                closeButton: true,
                timeOut: this.toasterTimeout,
            });


        });
    }


    // ================number validation==============//
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }


    zipCodeValiadtion(zip: any) {
        if (zip == '00000') {
            this.ziperr = 'Please enter valid zipcode';
        } else {
            this.ziperr = '';
            this.zipcode = zip;
        }

    }

    onfocusoutZip() {
        const value = (document.getElementById('zipcode') as HTMLInputElement).value;
        if (value.length != 5 && value.length !== 0) {
            this.ziperr = 'Zip Code has to be 5 digits required';
        }
    }


    onselectFeet(feature) {
        if (feature == 'applicant') {
            this.value = (document.getElementById('ft') as HTMLInputElement).value;
            this.inValue = (document.getElementById('in') as HTMLInputElement).value;
            if (this.value > 10 || this.value == 0) {
                this.errtxt = 'Height range should be 1-10 Ft';
            }
            if (this.value > 10 && this.inValue > 11) {
                this.errtxt = 'Height range should be less than 11 Ft && Inch range should be less than 12 inch';
            }
            if (this.value < 11 && this.inValue > 11) {
                this.errtxt = 'Inch should be than 12 inch';
            }
            if (this.value < 11 && this.inValue < 12 && this.value != 0) {

                this.errtxt = '';
            }
        }
        if (feature == 'spouce') {
            this.value = (document.getElementById('spouceft') as HTMLInputElement).value;
            this.inValue = (document.getElementById('spoucein') as HTMLInputElement).value;
            if (this.value > 10 || this.value == 0) {
                this.spouceerrtxt = 'Height range should  1 to 11 Ft';
            }
            if (this.value > 10 && this.inValue > 11) {
                this.spouceerrtxt = 'Height range should be less than 11 Ft && Inch range should be less than 12 inch';
            }
            if (this.value < 11 && this.inValue > 11) {
                this.spouceerrtxt = 'Inch should be than 12 inch';
            }
            if (this.value < 11 && this.inValue < 12 && this.value != 0) {
                this.spouceerrtxt = '';
            }
        }
    }

    onselectIn(feature) {
        if (feature == 'applicant') {
            this.inValue = (document.getElementById('in') as HTMLInputElement).value;
            this.value = (document.getElementById('ft') as HTMLInputElement).value;
            if (this.value == 0) {
                this.errtxt = 'Height range should be 1-10 Ft';
            }
            if (this.value != 0) {
                if (this.inValue > 11) {
                    this.errtxt = 'Inch range should be less than 12 Inch';
                }
                if (this.value > 10 && this.inValue > 11) {
                    this.errtxt = 'Inch range should be less than 12 &&  Height range should be less than 11 ';
                }
                if (this.value > 10 && this.inValue < 12) {
                    this.errtxt = 'Height range should be less than 11';
                }
                if (this.value < 11 && this.inValue < 12) {
                    this.errtxt = '';
                }
            }
        }
        if (feature == 'spouce') {
            this.inValue = (document.getElementById('spoucein') as HTMLInputElement).value;
            this.value = (document.getElementById('spouceft') as HTMLInputElement).value;
            if (this.value == 0) {
                this.spouceerrtxt = 'Height range should  1 to 11 Ft';
            }
            if (this.value != 0) {
                if (this.inValue > 11) {
                    this.spouceerrtxt = 'Inch range should be less than 12 Inch';
                }
                if (this.value > 10 && this.inValue > 11) {
                    this.spouceerrtxt = 'Inch range should be less than 12 &&  Height range should be less than 11 ';
                }
                if (this.value > 10 && this.inValue < 12) {
                    this.spouceerrtxt = 'Height range should be less than 11';
                }
                if (this.value < 11 && this.inValue < 12) {
                    this.spouceerrtxt = '';
                }
            }

        }
    }

    onleavePhonenumber(value) {
        const mobile = (document.getElementById('phone-number') as HTMLInputElement).value;
        if (mobile.length < 12 && mobile.length > 0) {
            this.phnumbererrmsg = 'Please enter a 10 digit number';
        }
        if (mobile.length >= 12) {
            this.phnumbererrmsg = '';
        }
        this.userTempData(this.newForm.value)
    }

    userTempData(value) {
        if (value.firstname != '' && value.lastname != '' && value.email != '' && value.phone != '') {
            this.objectData.company_id = this.companyId;
            this.objectData.product_company_id = this.parentCompanyId;
            this.objectData.group_id = this.group_id;
            this.objectData.group_code = this.group_code;
            this.objectData.group_name = this.group_name;
            // this.objectData.request_type = 'acc'
            // if (this.hideAgentSection && this.agent_id_from_url_param) {
            //     this.objectData.agent_id = this.agent_id_from_url_param;
            // } else {
                this.objectData.agent_id = this.agent_Id;
            // }
            this.objectData.member_personal_data = new Array<any>();
            this.member_personal_data.firstname = value.firstname;
            this.member_personal_data.lastname = value.lastname;
            this.member_personal_data.email = value.email;
            this.member_personal_data.phone = value.phone.replace(/[^0-9\.]+/g, '');
            if (this.temp_member_id != '') {
                this.member_personal_data.member_id = this.temp_member_id;
            }
            this.member_personal_data['relation'] = "Primary";
            this.objectData.member_personal_data.push(this.member_personal_data);
            var finalObject: any = {}
            finalObject = this.objectData;
            var url = 'AddMemberD2C';
            this.service.postData(url, finalObject).subscribe((Object: any) => {
                if (Object != null) {
                    this.newForm.controls.member_id.setValue(Object.member_personal_data[0].member_id);
                    if (Object.member_personal_data[0].member_id != '') {
                        this.temp_member_id = Object.member_personal_data[0].member_id
                    }
                    this.application_id = Object.application_id;
                }
            }, err => {
                this.errorMsgShow = true;
                this.emailError = err.error.email;
                this.phoneerrmsg = err.error.phone;
                if (this.emailError != undefined) {
                    this.toastr.error(this.emailError);
                } else {
                    if (err && err.error && err.error.message) {
                        this.toastr.error(err.error.message);
                    }
                }
            })
        }
    }
    onselectChildFeet(feature, i, event) {
        this.inValue = (document.getElementById('fields' + i) as HTMLInputElement).value;
        this.value = (document.getElementById('field' + i) as HTMLInputElement).value;
        const id = 'field' + i;
        if (event.target.id = id) {
            this.error = 'error' + i;
            if (this.value == 0 && this.inValue == '') {
                (document.getElementById('error' + i) as HTMLInputElement).innerHTML = 'Inches Field is required';
                this.inchesAllowmode = false;
            }
            if (this.value > 10 && this.value != 0) {
                (document.getElementById('error' + i) as HTMLInputElement).innerHTML = 'Height range should be less than 11 Ft';
                this.inchesAllowmode = false;
            }
            if (this.value < 10 && this.value != 0) {
                (document.getElementById('error' + i) as HTMLInputElement).innerHTML = '';
                this.inchesAllowmode = true;
            }
            if (this.value > 10 && this.inValue > 11 && this.value != 0) {
                (document.getElementById('error' + i) as HTMLInputElement).innerHTML = 'Height range should be less than 11 Ft && Inch range should be less than 12 inch';
                this.inchesAllowmode = false;
            }
            if (this.value < 11 && this.inValue > 11 && this.value != 0) {
                (document.getElementById('error' + i) as HTMLInputElement).innerHTML = 'Inch should be than 12 inch';
                this.inchesAllowmode = false;
            }
            if (this.value < 11 && this.inValue < 12 && this.value != 0) {
                (document.getElementById('error' + i) as HTMLInputElement).innerHTML = '';
                this.inchesAllowmode = true;
            }
        }
    }

    onselectChildInch(feature, i, event) {
        this.inValue = (document.getElementById('fields' + i) as HTMLInputElement).value;
        const id = 'fields' + i;
        this.value = (document.getElementById('field' + i) as HTMLInputElement).value;
        if (event.target.id = id) {
            this.error = 'error' + i;
            if (this.inValue > 11) {
                (document.getElementById('error' + i) as HTMLInputElement).innerHTML = 'Inch range should be less than 12 Inch';
            }
            if (this.inValue < 11) {
                (document.getElementById('error' + i) as HTMLInputElement).innerHTML = '';
            }
            if (this.value > 10 && this.inValue > 11) {
                (document.getElementById('error' + i) as HTMLInputElement).innerHTML = 'Inch range should be less than 12 &&  Height range should be less than 11 ';
            }
            if (this.value > 10 && this.inValue < 12) {
                (document.getElementById('error' + i) as HTMLInputElement).innerHTML = 'Height range should be less than 11';
            }
            if (this.value < 11 && this.inValue < 12) {
                (document.getElementById('error' + i) as HTMLInputElement).innerHTML = '';
            }
        }

    }

    onselectChildWeight(i, event) {
        this.value = (document.getElementById('weightfield' + i) as HTMLInputElement).value;
        const id = 'weightfield' + i;
        if (event.target.id = id) {
            this.error = 'childerror' + i;
            if (this.value >= 501 || this.value < 1) {
                (document.getElementById('childerror' + i) as HTMLInputElement).innerHTML = 'Weight range 1-500 lb';
            }
            if (this.value <= 500 && this.value >= 1) {
                (document.getElementById('childerror' + i) as HTMLInputElement).innerHTML = '';
                this.error = '';
            }

        }
    }

    onfocusoutphnumber(feature, i, event) {
        if (feature == 'primary') {
            const text = (document.getElementById('phnumbererrmsg') as HTMLInputElement).innerHTML;
            const value = (document.getElementById('phnumber') as HTMLInputElement).value;

            if (value.length < 12) {
                (document.getElementById('phnumbererrmsg') as HTMLInputElement).innerHTML = 'Please enter valid Phone number';
            }
            if (value.length >= 12) {
                (document.getElementById('phnumbererrmsg') as HTMLInputElement).innerHTML = '';
            }
        }
    }

    onkeyupPhone(e) {
        const phnumbervalue = (document.getElementById('phnumber') as HTMLInputElement).value;
        const transformedInput = phnumbervalue.replace(/^0*/g, '');

    }

    // ===============Age Calculation==========//
    onSearchChangeT(value) {
        if (value.dob != null) {
            const convertAge = new Date(value.dob);
            const timeDiff = Math.abs(Date.now() - convertAge.getTime());
            this.Age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
            this.newForm.controls.age.setValue(this.Age);
            if (value.guardianCheckbox == true) {
                if (this.Age <= 26) {
                    this.parentAge = 'Applicant  can\'t be younger than 26 years of age';
                }
            } else {
                if (this.Age <= 17) {
                    this.parentAge = 'Applicant  can\'t be younger than 18 years of age';
                }
            }
            if (this.Age > 70) {
                this.parentAge = 'Applicant can\'t be older than 70 years of age';
            }
            if (this.Age < 71 && this.Age > 18) {
                this.parentAge = '';
            }
        }
    }

    spouseAgeCalT(value, Index = 0) {
        if (value.spouse_Array[0].spouseDob) {
            const convertAge = new Date(value.spouse_Array[0].spouseDob);
            const timeDiff = Math.abs(Date.now() - convertAge.getTime());
            this.spouseAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
            const formcontrol = this.spouse_Array();
            formcontrol.controls[Index].get('spouseAge').setValue(this.spouseAge);
            if (this.spouseAge <= 17) {
                this.spouseAgeMsg = 'Applicant  can\'t be younger than 18 years of age';
            }
            if (this.spouseAge > 70) {
                this.spouseAgeMsg = 'Applicant can\'t be older than 70 years of age';
            }
            if (this.spouseAge < 70 && this.spouseAge > 18) {
                this.spouseAgeMsg = '';
            }
        }
    }

    childAgeCalT(searchValue, Index: number, dob, va): void {
        const id = 'dob' + Index;
        if (id == dob) {
            if (va.child_Array.length > 0) {
                {
                    for (let x = 0; x < va.child_Array.length; x++) {
                        const convertAge = new Date(va.child_Array[x].childDob);
                        const timeDiff = Math.abs(Date.now() - convertAge.getTime());
                        this.childAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
                        if (this.childAge > 26) {
                            (document.getElementById('childDOBerror' + Index) as HTMLInputElement).innerHTML = 'Child over 26 years age should buy their own plan.';
                        }
                        if (this.childAge < 26) {
                            (document.getElementById('childDOBerror' + Index) as HTMLInputElement).innerHTML = '';
                        }
                    }
                }
                const formcontrol = this.child_Array();
                formcontrol.controls[Index].get('childAge').setValue(this.childAge);
            } else {
                this.childAge = '';
                this.ageError = '';
                const formcontrol = this.child_Array();
                formcontrol.controls[Index].get('childAge').setValue(this.childAge);
            }
        }
    }

    // Function, called when agent code gets changed
    showApplyBtn() {
        if (!this.agentCodeParam) {
            this.agentCodeErrorMsg = '';
            this.isAgentCodeApplied = false;
            this.agent_Id = undefined;
            this.agentInfo = null;
            // this.getHealthQuestions();
        }
    }

    // Function to apply agent code
    applyAgentCode(code = null) {
        // If code is not recevied in the parameter, then read from FORM
        if (!code) {
            code = this.newForm.value.agentCode;
        }

        if (code) {
            this.agent_Id = undefined;
            this.applyBtnText = 'Applying...';
            this.agentCodeErrorMsg = '';
            this.loader = true;

            const formData: FormData = new FormData();
            formData.append('agent_code', code);

            const url = 'ApplyAgentCode/' + code;
            this.subscriptions.push(this.service.getData(url).subscribe((response: any) => {
                this.loader = false;
                this.applyBtnText = 'Apply';
                if (response.status == true) {
                    this.Policy_replacement_questions = true;
                    this.getHealthQuestions();
                    this.isAgentCodeApplied = true;
                    this.agent_Id = response.data.id;
                    this.agentInfo = response.data;
                    localStorage.setItem('agentInfo', JSON.stringify(this.agentInfo));
                } else {
                    this.isAgentCodeApplied = false;
                    this.agentCodeErrorMsg = response.message;
                    this.agent_Id = undefined;
                    this.agentInfo = null;
                }
            }, (err) => {
                this.loader = false;
                if (err.error.message == 'Unauthenticated.') {
                    this.openError = true;
                } else {
                    console.log(err);
                }
            }));
        } else {
            this.isAgentCodeApplied = false;
        }
    }

    // Function to remove applied agent code - not needed as of now
    /*removeAgentCode(){
      this.agentCodeErrorMsg = "";
      this.isAgentCodeApplied = false;
      this.agent_Id = undefined;
      this.newForm.controls['agentCode'].setValue("");
      this.agentInfo = null;
    }*/

    public atLeastOneValidator: any = (control: UntypedFormGroup): ValidationErrors | any => {
        const controls = control.controls;
        if (controls) {
            const theOne = Object.keys(controls).findIndex(key => controls[key].value !== '');
            if (theOne === -1) {
                return {
                    atLeastOneRequired: {
                        text: ''
                    }
                };
            }
        }
    }

    updateEligibilty(relation) {
        const formData = this.newForm.value;
        if (relation == 1) {
            this.isEligible = (formData.working_hours_20 == 'Yes') ? true : false;
        } else {
            for (let k = 0; k < formData.spouse_Array.length; k++) {
                this.isSpouseEligible = (formData.spouse_Array[k].spouse_working_hours_20 == 'Yes') ? true : false;
            }
        }
    }

    ngOnDestroy() {
        this.newForm.reset();
        this.subscriptions.forEach(element => {
            element.unsubscribe();
        });
    }

    onFousoutInputQtns(value, e) {
        value.enteredvalue = e;
    }

    hasSpouseAsPrimary = (obj) => {
        return obj.hasOwnProperty('spouse_as_primary') && obj.spouse_as_primary === 1;
    }


    submitHealthQts(member_id, family_id, familyInfo) {
        this.submitAttempt = true;
        if (this.healthQuestions != undefined) {
            const is_spouse_as_primary = this.hasSpouseAsPrimary(familyInfo.member_personal_data[0]);
            this.finalobj.member_personal_data = [];
            this.finalobj.company_id = this.companyId;
            this.finalobj.family_id = family_id;

            this.healthQuestions.forEach(element => {
                if (element.value) {
                    element.health_issue.forEach(elem => {
                        if (elem.value == 'Yes') {
                            const obj = {
                                member_id,
                                health_issue_id: elem?.health_issue_id,
                                health_id: elem?.health_id,
                                active: 1
                            };
                            this.finalobj.member_personal_data.push(obj);
                            if (!!elem.health_issue_options) {

                                elem.health_issue_options?.forEach(elemen => {
                                    const obj = {
                                        member_id,
                                        health_issue_id: elemen?.id,
                                        health_issue_description: elemen?.enteredvalue,
                                        health_id: elem?.health_id,
                                        active: 1
                                    };
                                    this.finalobj.member_personal_data.push(obj);
                                });
                            }


                        }
                    });
                } else {
                    element.health_issue.forEach(elem => {
                        if (elem.value == 'No') {
                            const obj = {
                                member_id,
                                health_issue_id: elem?.health_issue_id,
                                health_id: elem?.health_id,
                                active: 1
                            };
                            this.finalobj.member_personal_data.push(obj);

                        }
                    });
                }
            });


            if (this.spouseHealthQuestions != undefined && !this.hideSpouseDetails) {
                let spouse;
                if (!is_spouse_as_primary) {
                    spouse = familyInfo.member_personal_data.find(person => person.relation === 'Spouse');
                } else {
                    spouse = familyInfo.member_personal_data.find(person => person.relation === 'Primary');
                }
                if (spouse) {
                    this.spouseHealthQuestions.forEach(element => {
                        if (element.value) {
                            element.health_issue.forEach(elem => {
                                if (elem.value == 'Yes') {
                                    const obj = {
                                        member_id: spouse.member_id,
                                        health_issue_id: elem?.health_issue_id,
                                        health_id: elem?.health_id,
                                        active: 1
                                    };
                                    this.finalobj.member_personal_data.push(obj);
                                    elem.health_issue_options.forEach(elemen => {
                                        const obj = {
                                            member_id: spouse.member_id,
                                            health_issue_id: elemen?.id,
                                            health_issue_description: elemen?.enteredvalue,
                                            health_id: elem?.health_id,
                                            active: 1
                                        };
                                        this.finalobj.member_personal_data.push(obj);
                                    });
                                }
                            });
                        } else {
                            element.health_issue.forEach(elem => {
                                if (elem.value == 'No') {
                                    const obj = {
                                        member_id: spouse.member_id,
                                        health_issue_id: elem?.health_issue_id,
                                        health_id: elem?.health_id,
                                        active: 1
                                    };
                                    this.finalobj.member_personal_data.push(obj);

                                }
                            });
                        }
                    });
                }
            }
            const url = 'CreateMemberHealthIssue';
            this.service.postData(url, this.finalobj).subscribe((Object: any) => {

            }, err => {
                console.log(err);
            });
        }
    }

    handleApplicantData(e)
    {
        e.members = e.member_personal_data;
        delete e.member_personal_data;
        this.autoFillApplication(e);

    }
}


import { HttpClient } from '@angular/common/http';
import { Provider } from '@angular/core';
import { GoogleTagManagerConfiguration, GoogleTagManagerService } from 'angular-google-tag-manager';

export function initializeApp(
    http: HttpClient, 
    googleTagManagerConfiguration: GoogleTagManagerConfiguration
): () => Promise<any> {
    return (): Promise<any> => {
        return new Promise((resolve, reject) => {
            let url: string;
            const splitURL = window.location.host.split('.');
            this.company_path = splitURL[0];
            if (origin.includes('localhost')) {
                url = 'https://revolthealth.dev.vasquezplatform.com/vasquez-api/api/GetCompanySettings/revolthealth';
            } else {
                url = window.location.origin + '/vasquez-api/api/GetCompanySettings';
            }

            http.get(url).subscribe(
                (obj: any) => {
                    obj.data = decodeCompanySettings(obj.data);
                    if (obj.data.analytics_config) {
                        obj.data.analytics_config = JSON.parse(obj.data?.analytics_config);
                        if (obj.data?.analytics_config && obj.data?.analytics_config.provider && obj.data?.analytics_config.id) {
                            if (obj.data.analytics_config.provider === 'gtm') {
                                console.log("gtmID", obj.data.analytics_config.id)
                                googleTagManagerConfiguration.set({
                                    id: obj.data.analytics_config.id
                                });
                                //googleTagManagerService.addGtmToDom()
                            } else if (obj.data.analytics_config.provider === 'pixel') {
                                // Future implementation for Facebook Pixel
                            }
                        } else {
                            console.log('Invalid analytics config');
                        }
                    }
                    resolve(obj);
                },
                (error) => reject(error)
            );
        });
    };
}

function decodeCompanySettings(res): object {
    res = JSON.parse(atob(atob(res)));
    res.api_Key = atob(res.api_Key);
    res.client_secret = atob(res.client_secret);
    return res;
}

import {Component, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharingService} from 'src/app/services/sharing.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrl: './confirmation.component.scss'
})
export class ConfirmationComponent {
    @ViewChild('orderInvoice') orderInvoiceElement: ElementRef;
    companyId: any;
    orderId: any;
    orderDetails: any[] = [];
    monthly_contribution: any;
    companyName: any;
    toasterTimeout: any;
    family_id: string;
    cartFee: any;
    cartData: any;
    memberDiscount: any;
    additionalMember: any;
    oneTimeFee: any;
    cartProducts: any[] = [];
    paymentData: any[] = []
    members: any;
    loader: boolean = false;
    isRiderAvailable: boolean = false
    A2Ccode: any;
    agentInfo: any = null;
    primary_info: any;
    spouse_info: any;
    child_info: any;
    limit: any;

    constructor(private service: SharingService, private route: ActivatedRoute, private router: Router, private toastr: ToastrService) {
        this.A2Ccode = this.route.snapshot.paramMap.get('code');

        this.orderId = this.route.snapshot.paramMap.get('orderId');
        let isRider = localStorage.getItem('isRiderAvailable')
        if (!!isRider && isRider == 'yes') {
            this.isRiderAvailable = true
        } else {
            this.isRiderAvailable = false;
        }
    }


    ngOnInit(): void {
        this.service.clientData.subscribe((data) => {
            if (data != '') {
                this.companyId = data.company_id;

                this.CreateMemberShipACC()
            }
        })

        // Remove Agent info from localStorage
        if (localStorage.getItem('agentInfo')) {
            localStorage.removeItem('agentInfo');
        }
    }

    CreateMemberShipACC() {
        var url = 'CreateMembershipA2C';
        this.loader = true;
        let parent_company_id = localStorage.getItem("parent_company_id");
        let groupCompanyId = localStorage.getItem("group_company_id");


        let payload = {
            order_id: this.orderId,
            company_id: this.companyId

        }
        this.service.postData(url, payload).subscribe((Object: any) => {
            if (Object.status) {
                this.loader = false;
                this.orderDetails = Object.order_details;
                this.paymentData = Object?.paymentdata
                this.cartFee = Object.cart_fee;
                this.cartData = Object.cart_data;
                this.memberDiscount = Object.agent_cart_discount;
                this.additionalMember = Object.additional_member_fee;
                this.oneTimeFee = Object.a2c_total_one_time_fee
                this.cartProducts = Object.cart_products;
                this.primary_info = this.cartProducts.filter(x => x['relation'] == "Primary");
                this.spouse_info = this.cartProducts.filter(x => x['relation'] == "Spouse");
                this.child_info = this.cartProducts.filter(x => x['relation'] == "Child");
                if (this.primary_info) {
                    this.limit = this.primary_info.length + this.spouse_info.length
                }
                this.cartProducts.forEach(element => {
                    element.name = element.firstname + ' ' + element.lastname;
                    element.child_length = this.child_info.length,
                        element.col_length = this.limit;
                });
                this.monthly_contribution = Object.monthly_contribution;

                if (Object.agent_info) {
                    this.agentInfo = Object.agent_info;
                }
            }
        }, (err) => {
            this.loader = false;
            this.toastr.error(err.error.message);
        });
    }

    downloadSectionAsPDF() {
        const data = this.orderInvoiceElement.nativeElement;
        // Clone the element and apply A4 size styling
        const clonedData = data.cloneNode(true) as HTMLElement;
        clonedData.style.width = '300mm';
        clonedData.style.height = '297mm';
        clonedData.style.overflow = 'hidden';
        clonedData.style.position = 'fixed';
        clonedData.style.left = '-10000px'; // Position off-screen
        document.body.appendChild(clonedData);
        html2canvas(clonedData, {scale: 2, useCORS: true}).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 200; // Slightly less than A4 width to add margins
            const imgHeight = canvas.height * imgWidth / canvas.width;
            // Add the image to the PDF
            pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
            //Generate a date and time string for the filename
            const now = new Date();
            const dateString = formatDateToString(now); // Use the helper function to format the date
            pdf.save(`Order_Receipt_${dateString}.pdf`);
            // format the date as MMDDYYYYHHMMSS
            function formatDateToString(date) {
                const pad = (num) => (num < 10 ? '0' + num : num.toString());
                const month = pad(date.getMonth() + 1); // getMonth() is zero-based
                const day = pad(date.getDate());
                const year = date.getFullYear();
                const hours = pad(date.getHours());
                const minutes = pad(date.getMinutes());
                const seconds = pad(date.getSeconds());
                return `${month}${day}${year}${hours}${minutes}${seconds}`;
            }
            // Clean up: remove the cloned element
            document.body.removeChild(clonedData);
        }).catch(error => {
            console.error('Error generating PDF:', error);
        });
    }

    orderInfo() {
        // var url = 'GetMemberSummaryACC/' + this.jwtHelper.companyId() + '/' + this.family_id
        //  this.service.getData(url).subscribe((Object: any) => {
        //   if (Object.status) {

        //   }
        // });
    }
}

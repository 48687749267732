import {Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {SharingService} from '../../../services/sharing.service';
import {TestService} from '../../../services/behaviourService';
import {DatePipe} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {SessionService} from '../../../services/session.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Dialog} from 'primeng/dialog';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [DatePipe]
})
export class SignUpComponent implements OnInit {

    @ViewChild('myDialog') myDialog: Dialog;
    companyId: any;
    newForm: UntypedFormGroup;
    applicationForm: UntypedFormGroup;
    showApplicationForm: boolean = false;
    applicationsubmitAttempt: boolean = false;
    submitAttempt: boolean = false;
    minimumDate: Date;
    invalidDates: Date[];
    invalidDates1: Date[];
    uniqueNames: any;
    value: any;
    inValue: any;
    errtxt: string = "";
    spouceerrtxt: string;
    Age: number;
    parentAge: string;
    er: string;
    weight: number;
    spouseAge: number;
    spouseAgeMsg: string;
    childAge: any;
    ageError: string;
    error: string;
    inchesAllowmode: boolean;
    spouseWtError: string;
    objectData: any = {}
    tempObjectData: any = {}
    member_personal_data1: any = {}
    applicant: any;
    planeNames: any[] = [];
    showData: boolean = false
    plansInfo: any;
    plandata: any[] = [];
    productDetails: any = {};
    applicantInfo: any;
    spouseInfo: any;
    childInfo: any;
    arr: any[] = []
    spousearr: any[] = []
    testCase: number;
    primaryArr: any[] = []
    firstname: any;
    loader: boolean = false;
    emailError: any;
    emailerrmsg: boolean = false;
    phoneerrmsg: any;
    errorMsgShow: boolean = true;
    primaryInfo: any;
    indexvalueRe: any;
    disableSpouseArray: boolean = false;
    disableChildArray: boolean = false
    showPlanButton: boolean = true;
    effectiveDate: any;
    companyInfo: any;
    companyName: any;
    groupName: any;
    groupNAME: void;
    groupId: any;
    groupData: any;
    spouseHide: boolean = true;
    checkedCondition: boolean = false;
    disableGuardianFlow: boolean = true;
    referral_id: any;
    referralCode: any
    displayPlans: any;
    plans: any[] = [];
    member_info: any[] = [];
    planeData: any;
    feetArray = [
        {id: 0, feet: '0'}, {id: 1, feet: '1'}, {id: 2, feet: '2'}, {id: 3, feet: '3'}, {id: 4, feet: '4'},
        {id: 5, feet: '5'}, {id: 6, feet: '6'}, {id: 7, feet: '7'}, {id: 8, feet: '8'}, {id: 9, feet: '9'}, {
            id: 10,
            feet: '10'
        },
    ]
    inchArray = [
        {id: 0, inch: '0', inchValue: '00'}, {id: 1, inch: '1', inchValue: '01'}, {id: 2, inch: '2', inchValue: '02'},
        {id: 3, inch: '3', inchValue: '03'}, {id: 4, inch: '4', inchValue: '04'}, {id: 5, inch: '5', inchValue: '05'},
        {id: 6, inch: '6', inchValue: '06'}, {id: 7, inch: '7', inchValue: '07'}, {id: 8, inch: '8', inchValue: '08'},
        {id: 9, inch: '9', inchValue: '09'}, {id: 10, inch: '10', inchValue: '10'}, {
            id: 11,
            inch: '11',
            inchValue: '11'
        },
    ]
    agent_id: any = 0;
    guardianDependent: any;
    groupUrl: boolean = false;
    textboxDisabled: boolean = false;
    disableTextbox = false;
    states: any;
    nicotinetextdisplay: boolean;
    textdisplay: boolean;
    statestextdisplay: boolean;
    selectedValue: any;
    application_id: any;
    application_email: any;
    isApplicationCheckboxChecked: boolean = false;
    groupedData: any[] = [];
    serviceCountArray: any[] = []
    serviceItemCountArray: any[] = [];
    programs_data: any[] = [];
    plandata_data: any[] = [];
    plandata_new: any[] = []
    ambassadorCorePlans: any[] = [];
    ambassadorPlusPlans: any[] = [];
    objectkeys = Object.keys;
    maxNumber: any;
    agentInfo: any;
    group_discount_info: any;
    enrollment_type: any;
    display_newcode: boolean = false;
    display_programs: boolean = false;
    groupcode: any
    agentcode: any
    service_data: any[] = [];
    applicationNewID: any;
    discountObj = {
        discount: []
    };
    formatCouponcode: any = {
        name: ''
    }
    resourceURL = '';
    cart_data: any;
    programs: any;
    selectedPlan: any;
    displayPrograms: boolean;
    nicotineSelected: boolean = false;
    nicotineChecked: boolean = false;
    temp_member_id: any;
    showSpouse: boolean = false;
    showChild: boolean = false;
    maxDate: Date;
    typeOfCode: any;
    fields: any[] = [{id: 'one', label: 'agent_code', value: 1}]
    newobj = {id: 'two', label: 'group_code', value: 2}
    selectedGroup_AgentType: any[] = [];
    apiURL: any;
    see_all_features: any;
    program_name: any;
    statesDisplayText: any;
    producerCode: any;
    isSubmitAllowed: boolean = true;
    agency_company_id: any;
    textchange: boolean = true;
    display: boolean = false;
    plan_details_popup: boolean = false;
    service_newData: any;
    planDetails: any;
    isD2C: boolean;
  enrollmentURL: any;
    otp_notification: any;

    constructor(private objectService: TestService,
                private formBuilder: UntypedFormBuilder,
                private router: Router,
                private service: SharingService,
                private services: TestService,
                private datePipe: DatePipe,
                private route: ActivatedRoute,
                private toastr: ToastrService,
                private sessionService: SessionService,
                private renderer: Renderer2,
                private elementRef: ElementRef,
                private sanitizer: DomSanitizer
    ) {
        this.route.params.subscribe(params => {
            this.producerCode = params['agent_code'];
        });

        this.newForm = this.formBuilder.group({
            firstname: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
            middlename: ['', Validators.pattern('^[a-zA-Z \-\']+')],
            lastname: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
            email: ['', [
                Validators.required,
                Validators.email,
                Validators.pattern("[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?")
            ]],
            phone: ['', Validators.required],
            dob: ['', Validators.required,],
            age: ['', [Validators.required, Validators.min(18), Validators.max(64)]],
            startDate: ['', Validators.required],
            gender: ['', Validators.required],
            relation: ['Primary'],
            member_id: [''],
            state: ['', Validators.required],
            nicotine: [''],
            application_id: [''],
            child_Array: this.formBuilder.array([]),
            spouse_Array: this.formBuilder.array([]),
            guardianCheckbox: [''],
            otp_notification_check: []
        })
        this.minimumDate = new Date();
        this.applicationForm = this.formBuilder.group({
            applicationId: ['', Validators.required],
            applicationEmail: ['', [
                Validators.required,
                Validators.email,
                Validators.pattern("[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?")
            ]],

        })


        this.objectService.pushheaderCondition("showheader");
        this.newForm.get("startDate").valueChanges.subscribe(value => {
            if (value != '') {
                this.startDate(value);
            }
        });
        this.fields.forEach(x => {
            this.newForm.addControl(x.id, new UntypedFormControl(''));
        });
        //=============Fetch group name from url for group concept==========//
        localStorage.removeItem('urlGroupName');
        localStorage.setItem('nicotineselected', 'No')
        let id = this.route.snapshot.paramMap.get('agent_code');
        this.isD2C = false
        if (!!id) {
            this.isD2C = id.includes('GRP') || id.includes('PDR') || id.includes('MKT') ? false : true;
        }
        if (this.isD2C) {
            this.groupName = id
            localStorage.setItem('urlGroupName', this.groupName)
        } else {
            this.newForm.controls.one.setValue(this.producerCode)
            if (id && id.includes('PDR')) {
                this.groupId = this.producerCode;
                this.groupInfoAgent('one', 0)
                this.isSubmitAllowed = true
            }
            if (!!id && id != undefined && id != null) {

            }
        }
        let partiallyApplied = this.route.snapshot?.queryParams?.type == 'application' ? true : false;
        if (partiallyApplied) {
            this.applicationFields(true)
        }
        this.service.pushAgentGroupInfo("");
        this.service.clientData.subscribe((data) => {
            if (data != '' && this.companyInfo == undefined) {
                this.companyInfo = data;
                this.otp_notification=data?.sms_notification
                this.apiURL = this.companyInfo.apiURL
                this.companyId = this.companyInfo.company_id;
                this.companyName = this.companyInfo.company_name;
                this.enrollmentURL = data.enrollmentURL
                this.statesDisplayText = (this.companyName == 'Hope Health Share') ? 'Hope Health Share' : 'Joppa Health Share';
                this.effectiveStartDate();
                // if (this.groupName != null || this.groupName != undefined) {
                //     this.showApplicationForm = true;
                // }
                this.stateresidency();
            }
        })

        this.objectService.demotoA2C.subscribe(result => { //demographic to plans page
            if (result != '') {
                this.applicant = result;
                if (this.applicant != null || this.applicant != "" || this.applicant != undefined) {
                    this.primaryInfo = this.applicant.member_personal_data.filter(x => x['relation'] == "Primary");
                    this.childInfo = this.applicant.member_personal_data.filter(x => x['relation'] == "Child");
                    this.spouseInfo = this.applicant.member_personal_data.filter(x => x['relation'] == "Spouse");
                }
                if (this.newForm && this.newForm.controls) {
                    this.newForm.controls.dob.enable();
                }

                if (this.applicant.member_personal_data) { // removed length ==1 becoz it is blocking spouse and child data
                    this.showPlanButton = true;
                    setTimeout(() => {
                        //  document.getElementById("getmyplan1").click();
                        this.applicant_data("0");
                    }, 100);
                } else {
                    this.showPlanButton = false;
                    // setTimeout(() => {
                    //   document.getElementById("getmyplan").click();
                    // }, 100);
                }

            } else {
                localStorage.removeItem('planupdate');
            }
        })
    }

    ngOnInit(): void {
        // D2C applicationId fetch
        let D2CUrl = this.enrollmentURL + '/' + localStorage.getItem('urlGroupName') + "?type=application"
        if (D2CUrl) {
            setTimeout(() => {
                let group_name = localStorage.getItem('urlGroupName')
                if (group_name != 'null' && group_name != 'undefined' && group_name != null && group_name != undefined) {
                    this.applicationForm.controls.applicationId.setValue(localStorage.getItem('urlGroupName'))

                }
            }, 100)
        }

        sessionStorage.removeItem('questionList');
        sessionStorage.removeItem('cartplan');
        sessionStorage.removeItem('cart_id');
        sessionStorage.removeItem('member_id');
        sessionStorage.removeItem('company');
        sessionStorage.removeItem('healthQUestions');
        window.scrollTo(0, 0);
        let today = new Date();
        this.maxDate = new Date();
        let invalidDate1 = new Date();
        invalidDate1.setDate(today.getDate() + 3);
        this.invalidDates1 = [today, invalidDate1];

    }

    focusOnErrorField(): void {
        const errorField = this.elementRef.nativeElement.querySelector('#firstname');
        if (errorField) {
            this.renderer.selectRootElement(errorField).focus();
        }
    }

    stateresidency() {
        var url = 'GetD2CStates';
        this.service.getData(url).subscribe((Object: any) => {
            this.states = Object.data;
        }, err => {
            this.toastr.error(err?.error?.message);
        })
    }

    startDate(value) {
        if (value != '') {
            this.newForm.controls.dob.enable();
        } else {
            this.newForm.controls.dob.disable();
        }
    }

    onOptionsSelected(value) {
        this.states.forEach(element => {
            if (element.name == value) {
                if (element.flag == '0') {
                    this.newForm.controls.firstname.disable();
                    this.newForm.controls.middlename.disable();
                    this.newForm.controls.lastname.disable();
                    this.newForm.controls.email.disable();
                    this.newForm.controls.phone.disable();
                    this.newForm.controls.gender.disable();
                    this.newForm.controls.startDate.disable();
                    this.newForm.controls.nicotine.disable();
                    this.newForm.controls.application_id.disable();
                    this.disableChildArray = true;
                    this.disableSpouseArray = true;
                    this.disableTextbox = true;
                    this.statestextdisplay = true;
                } else {
                    this.newForm.controls.firstname.enable();
                    this.newForm.controls.middlename.enable();
                    this.newForm.controls.lastname.enable();
                    this.newForm.controls.email.enable();
                    this.newForm.controls.phone.enable();
                    this.newForm.controls.gender.enable();
                    this.newForm.controls.startDate.enable();
                    this.newForm.controls.nicotine.enable();
                    this.newForm.controls.application_id.enable();
                    this.disableTextbox = false;
                    this.submitAttempt = false;
                    this.disableChildArray = false;
                    this.disableSpouseArray = false;
                    this.statestextdisplay = false;
                }
            }
        });
    }

    tobacooFields(event) {
        if (event.target.checked == true) {
            this.nicotineChecked = true;
            localStorage.setItem('nicotineselected', 'Yes')
            this.textdisplay = true;
            if (this.companyName != 'Joppa Health Share') {
                this.newForm.controls.firstname.disable();
                this.newForm.controls.middlename.disable();
                this.newForm.controls.lastname.disable();
                this.newForm.controls.email.disable();
                this.newForm.controls.phone.disable();
                this.newForm.controls.gender.disable();
                this.newForm.controls.startDate.disable();
                this.newForm.controls.state.disable();
                this.newForm.controls.application_id.disable();
                this.disableChildArray = true;
                this.disableSpouseArray = true;
                this.disableTextbox = true;
                this.nicotinetextdisplay = true;
            }
        } else {
            this.nicotineChecked = false;
            localStorage.setItem('nicotineselected', 'NO')
            this.newForm.controls.firstname.enable();
            this.newForm.controls.middlename.enable();
            this.newForm.controls.lastname.enable();
            this.newForm.controls.email.enable();
            this.newForm.controls.phone.enable();
            this.newForm.controls.gender.enable();
            this.newForm.controls.startDate.enable();
            this.newForm.controls.state.enable();
            this.newForm.controls.application_id.enable();
            this.disableTextbox = false;
            this.submitAttempt = false;
            this.disableChildArray = false;
            this.disableSpouseArray = false
            this.textdisplay = false;
            this.nicotinetextdisplay = false;
        }
    }

    newapplication(value) {
        this.applicationsubmitAttempt = true;
        if (this.applicationForm.valid) {
            let body = {
                company_id: this.companyId,
                application_id: this.applicationForm.get('applicationId').value,
                email: this.applicationForm.get('applicationEmail').value,
            }
            var url = 'GetApplicantByApplicationID';
            this.service.postData(url, body).subscribe((newObj: any) => {
                this.applicant = newObj;
                let spouseArray = <UntypedFormArray>this.newForm.controls['spouse_Array'];
                spouseArray.clear();
                let childArray = <UntypedFormArray>this.newForm.controls['child_Array'];
                childArray.clear();
                if (this.applicant != null || this.applicant != "" || this.applicant != undefined) {
                    this.primaryInfo = this.applicant.member_personal_data.filter(x => x['relation'] == "Primary");
                    this.childInfo = this.applicant.member_personal_data.filter(x => x['relation'] == "Child");
                    this.spouseInfo = this.applicant.member_personal_data.filter(x => x['relation'] == "Spouse");
                    this.cart_data = this.applicant.cart_data;
                }
                this.newForm.controls.dob.enable();
                if (this.applicant.member_personal_data.length == 1) {
                    this.showPlanButton = true;
                    // setTimeout(() => {
                    //   document.getElementById("getmyplan1").click();
                    // }, 100);
                } else {
                    this.showPlanButton = false;
                    // setTimeout(() => {
                    //   document.getElementById("getmyplan").click();
                    // }, 100);
                }
                this.applicant_data("0")
            }, err => {
                this.toastr.error(err.error.message);
            })
        }


    }

    //============group Info Api================//
    removeField(i, data) {

        this.fields[i].data = [];
        this.fields.splice(i, 1);
        let newcode = this.newForm.controls[data].value;
        if (newcode != undefined) {
            this.getDiscount(i, newcode, 'remove')
        }
        if (newcode.includes('GRP')) {
            this.groupData = [];
            this.group_discount_info = '';
            this.groupId = '';
        } else if (newcode.includes('MKT') || newcode.includes('PDR')) {
            this.agent_id = '';
            this.agentInfo = [];
        }
        this.newForm.removeControl(data);
        this.display_newcode = false;
    }

    groupInfoAgent(data, i: any) {
        this.agent_id = 0;

        if (data != null) {
            if (this.newForm && this.newForm.controls) {
                if (this.newForm.controls[data] != undefined) {
                    this.formatCouponcode.name = this.newForm.controls[data].value
                    var firsttext = this.newForm.controls[data].value.substr(0, this.newForm.controls[data].value.indexOf('-'));
                    this.formatCouponcode.name = this.formatCouponcode.name.replace(firsttext, firsttext.toUpperCase());
                    this.groupName = this.formatCouponcode.name;

                } else {
                    this.groupName = data;
                    if (this.groupName.includes('MKT') || this.groupName.includes('PDR') || this.groupName.includes('GRP')) {
                        this.newForm.controls.one.setValue(this.groupName)
                    } else {
                        this.newForm.controls.one.setValue('')
                    }
                }
            }
        }

        if (!!((this.route.snapshot.paramMap.get('id'))) && !((this.route.snapshot.paramMap.get('id'))?.includes('PDR')) && (this.newForm.controls.email.value == '' || this.newForm.controls.email.value == undefined || this.newForm.controls.email.value == null)) {
            //  this.toastr.error("Email Id is required")
        } else {
            if (this.groupName != null && this.groupName != '') {
                if (this.groupName.includes('GRP') && this.newForm.controls.email.value != '') {
                    let body = {
                        group_id: this.groupName,
                        email: this.newForm.controls.email.value,
                    }
                    var url = 'GetGroup';
                    this.service.postData(url, body).subscribe((Object: any) => {
                        if (Object != null) {
                            localStorage.setItem('urlGroupName', Object.data[0].groupID)
                            if (Object.data[0].group_id != undefined || Object.data[0].group_id != null) {
                                this.referralCode = Object.data[0].groupID;
                                this.groupId = Object.data[0].group_id;
                                this.groupData = Object.data;
                                this.group_discount_info = Object.group_discount_info;
                                this.typeOfCode = this.groupData[0].type;
                                this.disableGuardianFlow = false;
                                this.service.pushAgentGroupInfo(this.groupData);
                                this.groupUrl = true;
                                if (this.group_discount_info.length > 0) {
                                    const last = this.group_discount_info[this.group_discount_info.length - 1];
                                    this.groupData[0].g2c_discount = last.g2c_discount;
                                }
                                this.fields[i]['data'] = [];
                                this.fields[i].data.push(...this.groupData);
                                this.getDiscount(i, this.groupName, 'add');
                            }
                        }
                    }, err => {
                        this.toastr.error(err.error.message)
                    })
                } else if (this.groupName.includes('MKT') || this.groupName.includes('PDR')) {
                    var url = 'GetAgentInfoByCode/' + this.groupName;
                    this.service.getDataFromUrl(url).subscribe((Object: any) => {
                        if (Object != null) {
                            this.agency_company_id = Object.data[0]?.company_id;
                            if (parseInt(Object.data[0]['acc_access']) == 0) {
                                this.isSubmitAllowed = false;
                                this.newForm.disable();
                                let msg = 'Enrollment via this Producer code is not allowed';
                                this.toastr.error(msg);
                            } else {

                                this.isSubmitAllowed = true;
                                localStorage.setItem('urlGroupName', Object.data[0].code)
                                if (Object.data[0].agent_id != undefined || Object.data[0].agent_id != null) {
                                    Object.data[0].name = Object.data[0].firstname + " " + Object.data[0].lastname;
                                    this.referralCode = Object.data[0].code;
                                    this.agent_id = Object.data[0].agent_id;
                                    this.checkProducerOnboardingStatus(this.agent_id)


                                    setTimeout(() => {
                                        this.fields.forEach(element => {
                                            element.data.forEach(ele => {
                                            });

                                        });
                                    }, 10);
                                    this.agentInfo = Object.data;
                                    this.typeOfCode = this.agentInfo[0].type;
                                    this.service.pushAgentGroupInfo(this.agentInfo);
                                    this.fields[i]['data'] = [];
                                    this.fields[i].data.push(...this.agentInfo);
                                    this.getDiscount(i, this.groupName, 'add');
                                }
                            }

                        }
                    }, err => {
                        this.toastr.error(err.error.Message)
                    })
                } else {
                    let body = {
                        application_id: this.groupName
                    }
                    if (Number(this.groupName)) {
                        this.newForm.controls.application_id.setValue(this.groupName);
                        this.applicationForm.controls.applicationId.setValue(this.groupName);
                        this.newapplication(body);
                    } else {
                        this.toastr.error("Code Doesn't Exist")
                    }
                }
            } else {
                localStorage.removeItem('groupinfo');
                localStorage.removeItem('urlGroupName');
            }
        }

    }

    checkProducerOnboardingStatus(agent_id) {
        let payload = {
            agent_id: agent_id
        }
        let url = 'CheckProducerOnboardingStatusD2C'
        this.service.postData(url, payload).subscribe((res: any) => {
            if (res.status) {
                if (res?.onboarding_status == "Pending" || res?.onboarding_status == "Disabled") {
                    this.toastr.error(res?.message);
                    this.newForm.disable();
                    this.isSubmitAllowed = false;

                } else {
                    this.isSubmitAllowed = true;
                }
            }
        }, (err) => {
            this.toastr.error(err.error.message);
        })
    }

    onleaveGroupAgentcode(e, i) {
        //   console.log(e);
        //   console.log(i);
        //  let x= (<HTMLInputElement>document.getElementById("agent-groupcode" + i)).value;
        //  console.log(x);
        //  if(x !="")
        //  {
        //   if(this.discountObj.discount.length>0)
        //   {
        //   this.discountObj.discount[i].code=x
        //   }

        //  }
        //   console.log(this.fields);
        //   console.log(this.discountObj);
    }

    getDiscount(i, groupname, feature) {
        var url = 'GetDiscount';
        if (feature == "add") {
            let discountobj = {
                code: groupname
            }
            if (this.discountObj.discount[i]) {
                this.discountObj.discount[i] = discountobj;
            } else {
                this.discountObj.discount.push(discountobj);
            }
        }

        if (feature == "remove") {
            this.discountObj.discount.splice(i, 1)
        }
        this.service.postData(url, this.discountObj).subscribe((Object: any) => {
            this.fields.forEach(function (element, idx, array) {
                if (element.data[0]?.g2c_discount != undefined) {
                    element.data[0].g2cdiscount = Object.group_discount
                }

            });

        }, err => {
            if (feature == "remove") {
                this.discountObj.discount.splice(i, 1)
            }
            // this.toastr.error(err.message);
        })
    }

    spouse_Array(): UntypedFormArray {
        return this.newForm.get("spouse_Array") as UntypedFormArray
    }

    newEmployee1(): UntypedFormGroup {
        return this.formBuilder.group({
            spouseFirstName: ['', Validators.required],
            spouseMiddleName: [''],
            spouseLastName: ['', Validators.required],
            spouseDob: ['', Validators.required],
            spouseGender: ['', Validators.required],
            spouseAge: ['', [Validators.required, Validators.min(18), Validators.max(64)]],
            spouse_id: ['',],
            relation: ['Spouse'],
            spouseCheckBox: [true]
        })
    }

    newChild(): UntypedFormGroup {
        return this.formBuilder.group({
            childFirstName: ['', Validators.required],
            childMiddleName: [''],
            childLastName: ['', Validators.required],
            childDob: ['', Validators.required],
            childGender: ['', Validators.required],
            childAge: ['', [Validators.required, Validators.min(0), Validators.max(26)]],
            childDisability: [false],
            child_id: [''],
            relation: ['Child'],
            childCheckBox: [true]
        });
    }

    child_Array(): UntypedFormArray {
        return this.newForm.get("child_Array") as UntypedFormArray
    }

    addGroupAndAgent() {
        this.fields.push(this.newobj);
        this.fields.forEach(x => {
            this.newForm.addControl(x.id, new UntypedFormControl(''));
        });
        //this.display_newcode=true;
    }

    //*********effective start date**************//
    effectiveStartDate() {
        var url = 'GetEffectiveStartDates/' + this.companyId
        this.service.getData(url).subscribe((Object: any) => {
            this.effectiveDate = Object.data;
            if (this.applicant != undefined) {
                this.newForm.controls.dob.enable();
            } else {
                this.newForm.controls.dob.disable();
            }
        }, err => {
            this.toastr.error(err?.error?.message);
        })
    }

    //===========Guardion flow check box===========//
    guardianCheckbox(checkedValue) {

        if (checkedValue == true) {
            this.addDependent();
            this.spouseHide = false;
        } else {
            this.spouseHide = true;
            this.child_Array().removeAt(0);
            this.showChild = false;
            this.showPlanButton = true;
        }
    }

    addDependent() { // add dependent

        this.showPlanButton = false;
        this.showChild = true;
        if (this.child_Array().length <= 0) {
            //     this.disableChildArray = true
            this.child_Array().push(this.newChild());
        }
    }

    //**********Child check box*********88 */
    childCheckbox(checkedValue, childValue, values) {
        if (checkedValue == true) {
            if (childValue != '' || childValue != undefined) {
                if (childValue.child_id != '') {
                    this.formSubmit(values)
                } else {
                    // this.formSubmit(values)
                }
            }
        } else {
            if (childValue.child_id != '') {
                this.arr.push(childValue);
                this.formSubmit(values)
            }
        }
    }

    //************Spouse Check box***********/
    spouseCheckbox(checkedValue, spouseValues, values) {
        if (checkedValue == true) {
            if (spouseValues != '' || spouseValues != undefined) {
                if (spouseValues.spouse_id != '') {
                    this.formSubmit(values)
                } else {
                    //  this.formSubmit(values)
                }
            }
        } else {
            if (spouseValues.spouse_id != '') {
                this.spousearr.push(spouseValues);
                this.formSubmit(values)
            }
        }
    }

    //************************add chilkd*******************/
    addEmployee(va) {
        if (va == 'child') {
            this.child_Array().push(this.newChild());
        }
    }


    //**************Add new ***********************/
    addSpouse() {//add new spouse
        this.showPlanButton = false;
        if (this.spouse_Array().length <= 0) {
            this.disableSpouseArray = true
            this.spouse_Array().push(this.newEmployee1());
        }
        this.disableGuardianFlow = false;
    }

    addChildren() {// add child
        this.showPlanButton = false;
        this.showChild = true;
        if (this.spouse_Array().length >= 0) {
            this.disableChildArray = true
            this.child_Array().push(this.newChild());
        }
    }

    //==================Remove form Array==================//
    removeEmployee(empIndex, childValue, values) {//remove child Array
        this.indexvalueRe = empIndex
        if (childValue.child_id != '') {
            this.arr.push(childValue);
            this.formSubmit(values)
        }
        this.child_Array().removeAt(empIndex);
        if (values.child_Array.length == 1) {
            this.disableChildArray = false
            this.showChild = false;
            if (values.spouse_Array.length == 0) {
                this.showPlanButton = true;
            }
        }
    }

    removeEmployee1(empIndex1, spouseValues, values) {//remove spouse array
        this.disableSpouseArray = false;
        this.disableGuardianFlow = true;
        if (spouseValues.spouse_id != '') {
            this.spousearr.push(spouseValues);
            this.formSubmit(values)
        }
        this.spouse_Array().removeAt(empIndex1);
        if (values.child_Array.length == 0) {
            this.showPlanButton = true;
        }
    }

    emailValidation(value) {
        if (this.emailError != null || this.phoneerrmsg != null) {
            this.errorMsgShow = false;
        }
    }

    enrollCancelMember(value) {
        this.display = false;
        let body = {
            company_id: this.companyId,
            email: value,
        }
        var url = 'EnrollCancelMember';
        this.service.postData(url, body).subscribe((newObj: any) => {
            this.display = true;
            this.textchange = true;
            this.applicant = newObj;
        }, err => {
            //  this.toastr.error(err.error.message);
        })
    }

    process() {
        this.display = false;
        this.textchange = true;
        if (this.applicant != null || this.applicant != "" || this.applicant != undefined) {
            this.primaryInfo = this.applicant.member_personal_data.filter(x => x['relation'] == "Primary");
            this.childInfo = this.applicant.member_personal_data.filter(x => x['relation'] == "Child");
            this.spouseInfo = this.applicant.member_personal_data.filter(x => x['relation'] == "Spouse");
            this.cart_data = this.applicant.cart_data;
            this.applicant_data("1")
        }
    }

    cancel() {
        this.newForm.disable();
        this.applicationForm.disable();
        this.display = true;
        this.textchange = false;
        this.applicant = "";
    }

    becomeMember() {
        this.display = false;
        this.newForm.enable();
        this.newForm.reset();
        this.applicationForm.enable();
        this.applicationForm.reset();
        this.submitAttempt = false;
        this.groupName = false;
        this.isSubmitAllowed = true;
        this.showApplicationForm = false;
        this.applicationsubmitAttempt = false;
        this.showChild = false;
        this.disableSpouseArray = false;
        this.disableChildArray = false;
        this.newForm.controls.state.setValue("");
        this.newForm.controls.startDate.setValue("");
        this.spouse_Array().removeAt(0);
        for (var i = 0; i < this.childInfo.length; i++) {
            this.child_Array().removeAt(i);
        }

    }

    userTempData(value) {
        if (value.firstname != '' && value.lastname != '' && value.email != '' && value.phone != '') {
            this.tempObjectData.company_id = this.companyId
            this.tempObjectData.application_id = this.application_id
            this.tempObjectData.type_of_save = 'true';
            this.tempObjectData.member_personal_data = new Array<any>();
            this.member_personal_data1['relation'] = "Primary";
            this.member_personal_data1.firstname = value.firstname;
            this.member_personal_data1.middlename = value.middlename;
            this.member_personal_data1.lastname = value.lastname;
            this.member_personal_data1.state = value.state
            this.member_personal_data1.email = value.email;
            if (this.temp_member_id != '') {
                this.member_personal_data1.member_id = this.temp_member_id;
            }
            this.member_personal_data1.phone = value.phone.replace(/[^0-9\.]+/g, "");
            this.tempObjectData.member_personal_data.push(this.member_personal_data1);
            var finalObject: any = {}
            finalObject = this.tempObjectData;
            var url = 'CreateMemberD2C';
            this.service.postData(url, finalObject).subscribe((Object: any) => {
                if (Object != null) {
                    this.newForm.controls.member_id.setValue(Object.member_personal_data[0].member_id);
                    if (Object.member_personal_data[0].member_id != '') {
                        this.temp_member_id = Object.member_personal_data[0].member_id
                    }
                    this.application_id = Object.application_id;
                }
            }, err => {
                this.errorMsgShow = true;
                this.emailError = err.error.email;
                this.phoneerrmsg = err.error.phone;
                if (this.emailError != undefined) {
                    this.toastr.error(this.emailError);
                } else {
                    if (err && err.error && err.error.message) {
                        this.toastr.error(err.error.message);
                    }
                }
            })
        }
    }

    formSubmit(values) {
        if (this.indexvalueRe != null) {
            this.submitAttempt = false;
            this.formSubmit1(values)
        } else {
            this.submitAttempt = true;
            this.formSubmit1(values)
        }
    }

    formSubmit1(values) {
        this.selectedGroup_AgentType = [];
        this.submitAttempt = true;
        if (values.guardianCheckbox == true) {
            if (values.height == '') {
                // var height = values.height == '';
                // var weight = values.weight == '';
                var gender = values.gender == '';
            } else {
                // var height = values.height == undefined;
                // var weight = values.weight == undefined;
                var gender = values.gender == undefined;
            }
        }
        if (this.newForm.valid && values.phone.length == 12 || gender) {
            this.objectData.company_id = this.companyId
            if (this.applicant != undefined) {
                this.objectData.zipcode = this.applicant.zipcode;
            } else {
                this.objectData.zipcode = '';
            }

            this.fields.forEach(element => {
                element.data?.forEach(ele => {
                    if (ele.agent_id) {
                        this.agent_id = ele.agent_id
                    }
                    if (ele.group_id) {
                        this.groupId = ele.group_id
                    }
                    this.selectedGroup_AgentType.push(ele.type);
                });

            });


            if (this.selectedGroup_AgentType.includes("Group")) {
                this.objectData.group_id = this.groupId //groupId
            } else {
                this.objectData.group_id = "" //groupId
            }

            if (this.selectedGroup_AgentType.includes("Producer")) {

                this.objectData.agent_id = this.agent_id;
            } else {
                this.objectData.agent_id = "";
            }

            if (this.selectedGroup_AgentType.includes("Producer") && this.selectedGroup_AgentType.includes("Group")) {
                this.objectData.group_id = this.groupId //groupId
                this.objectData.agent_id = this.agent_id;
            }

            this.objectData.startdate = values.startDate;
            if (this.applicationForm.get('applicationId').value != '' && this.applicationForm.get('applicationId').value != 'undefined') {
                this.objectData.application_id = this.applicationForm.get('applicationId').value;
            } else {
                this.objectData.application_id = this.application_id
            }
            this.objectData.agent_id = this.agent_id;
            if (!!this.agency_company_id) {
                this.objectData.agency_company_id = this.agency_company_id
            }
            this.objectData.referral_id = this.referral_id; //referral
            this.objectData.member_personal_data = new Array<any>();
            this.member_personal_data1['relation'] = "Primary";
            this.member_personal_data1.member_id = values.member_id;
            this.member_personal_data1.firstname = values.firstname;
            this.member_personal_data1.middlename = values.middlename;
            this.member_personal_data1.lastname = values.lastname;
            this.member_personal_data1.smoker = "";
            this.member_personal_data1.state = values.state;
            if(values.otp_notification_check !==null ){
            this.member_personal_data1.sms_enabled = values.otp_notification_check ? 1:0;
            }
            this.member_personal_data1.dob = this.datePipe.transform(values.dob, "MM/dd/yyyy");
            this.member_personal_data1.age = values.age;
            this.member_personal_data1.gender = values.gender;
            this.member_personal_data1.email = values.email;
            this.member_personal_data1.phone = values.phone.replace(/[^0-9\.]+/g, "");
            this.objectData.member_personal_data.push(this.member_personal_data1);
            for (var k = 0; k < values.spouse_Array.length; k++) { //insert
                if (values.spouse_Array[k].spouseCheckBox == true) {
                    this.member_personal_data1 = {};
                    this.member_personal_data1.firstname = values.spouse_Array[k].spouseFirstName;
                    this.member_personal_data1.middlename = values.spouse_Array[k].spouseMiddleName;
                    this.member_personal_data1.lastname = values.spouse_Array[k].spouseLastName;
                    this.member_personal_data1.smoker = "";
                    this.member_personal_data1.member_id = values.spouse_Array[k].spouse_id;
                    this.member_personal_data1['relation'] = "Spouse";
                    this.member_personal_data1.dob = this.datePipe.transform(values.spouse_Array[k].spouseDob, "MM/dd/yyyy");
                    this.member_personal_data1.age = values.spouse_Array[k].spouseAge;
                    this.member_personal_data1.gender = values.spouse_Array[k].spouseGender;
                    this.member_personal_data1.active = 1;
                    this.objectData.member_personal_data.push(this.member_personal_data1);
                }
            }
            if (this.spousearr.length > 0) {//for remove option
                for (var z = 0; z < this.spousearr.length; z++) {
                    this.member_personal_data1 = {};
                    this.member_personal_data1.firstname = this.spousearr[z].spouseFirstName;
                    this.member_personal_data1.middlename = this.spousearr[z].spouseMiddleName;
                    this.member_personal_data1.lastname = this.spousearr[z].spouseLastName;
                    this.member_personal_data1.smoker = "";
                    this.member_personal_data1.member_id = this.spousearr[z].spouse_id;
                    this.member_personal_data1['relation'] = "Spouse";
                    this.member_personal_data1.dob = this.datePipe.transform(this.spousearr[z].spouseDob, "MM/dd/yyyy");
                    this.member_personal_data1.age = this.spousearr[z].spouseAge;

                    this.member_personal_data1.gender = this.spousearr[z].spouseGender;
                    this.member_personal_data1.active = 0;
                    this.objectData.member_personal_data.push(this.member_personal_data1);
                }
                this.spousearr = [];
            }
            for (var i = 0; i < values.child_Array.length; i++) {
                if (values.child_Array[i].childCheckBox == true) {
                    this.member_personal_data1 = {};
                    this.member_personal_data1.firstname = values.child_Array[i].childFirstName;
                    this.member_personal_data1.middlename = values.child_Array[i].childMiddleName;
                    this.member_personal_data1.lastname = values.child_Array[i].childLastName;
                    this.member_personal_data1.smoker = "";
                    this.member_personal_data1['relation'] = "Child";
                    this.member_personal_data1.member_id = values.child_Array[i].child_id;
                    this.member_personal_data1.dob = this.datePipe.transform(values.child_Array[i].childDob, "MM/dd/yyyy");
                    this.member_personal_data1.age = values.child_Array[i].childAge;
                    this.member_personal_data1.disability = values.child_Array[i].childDisability ? 'Yes' : 'No';

                    this.member_personal_data1.gender = values.child_Array[i].childGender;
                    this.member_personal_data1.active = 1;
                    this.objectData.member_personal_data.push(this.member_personal_data1);
                }
            }
            if (this.arr.length > 0) {//for remove option
                for (var j = 0; j < this.arr.length; j++) {
                    this.member_personal_data1 = {};
                    this.member_personal_data1.firstname = this.arr[j].childFirstName;
                    this.member_personal_data1.middlename = this.arr[j].childMiddleName;
                    this.member_personal_data1.lastname = this.arr[j].childLastName;
                    this.member_personal_data1.smoker = "";
                    this.member_personal_data1['relation'] = "Child";
                    this.member_personal_data1.member_id = this.arr[j].child_id;
                    this.member_personal_data1.dob = this.datePipe.transform(this.arr[j].childDob);
                    this.member_personal_data1.age = this.arr[j].childAge;
                    this.member_personal_data1.disability = this.arr[j].childDisability ? 'Yes' : 'No';
                    this.member_personal_data1.gender = this.arr[j].childGender;
                    this.member_personal_data1.active = 0;
                    this.objectData.member_personal_data.push(this.member_personal_data1);
                }
                this.arr = [];
            }
            this.loader = true;
            var finalObject: any = {}
            finalObject = this.objectData;
            var url = 'CreateMemberD2C';
            this.service.postData(url, finalObject).subscribe((Object: any) => {
                if (Object !== null) {
                    this.applicantInfo = Object.member_personal_data;
                    this.application_id = Object.application_id;
                    var primaryInfo = this.applicantInfo.filter(x => x['relation'] == "Primary");
                    this.childInfo = this.applicantInfo.filter(x => x['relation'] == "Child");
                    this.spouseInfo = this.applicantInfo.filter(x => x['relation'] == "Spouse");
                    if (primaryInfo != null || primaryInfo != undefined) {
                        this.enrollment_type = primaryInfo[0].enrollment_type;
                        this.newForm.controls.firstname.setValue(primaryInfo[0].firstname);
                        this.newForm.controls.middlename.setValue(primaryInfo[0].middlename);
                        this.newForm.controls.lastname.setValue(primaryInfo[0].lastname);
                        this.newForm.controls.email.setValue(primaryInfo[0].email);
                        var phone = primaryInfo[0].phone.substr(0, 3) + "-" + primaryInfo[0].phone.substr(3, 3) + "-" + primaryInfo[0].phone.substr(6, 4);
                        this.newForm.controls.phone.setValue(phone);
                        this.newForm.controls.gender.setValue(primaryInfo[0].gender);
                        this.newForm.controls.state.setValue(primaryInfo[0].state);
                        this.newForm.controls.age.setValue(primaryInfo[0].age);
                        this.effectiveDate;
                        this.newForm.controls.startDate.setValue(this.datePipe.transform(Object.startdate, "dd-MMM-yyyy"));
                        this.newForm.controls.dob.setValue(this.datePipe.transform(primaryInfo[0].dob, "MM/dd/yyyy"));
                        this.newForm.controls.member_id.setValue(primaryInfo[0].member_id);
                        this.firstname = primaryInfo[0].firstname;
                    }
                    if (this.spouseInfo.length > 0) {
                        this.disableSpouseArray = true
                        this.newForm.controls['spouse_Array']['controls'][0].patchValue({
                            spouseFirstName: this.spouseInfo[0].firstname,
                            spouseMiddleName: this.spouseInfo[0].middlename,
                            spouseLastName: this.spouseInfo[0].lastname,
                            spouseDob: this.datePipe.transform(this.spouseInfo[0].dob, "MM/dd/yyyy"),
                            spouseAge: this.spouseInfo[0].age,
                            spouseGender: this.spouseInfo[0].gender,
                            spouse_id: this.spouseInfo[0].member_id
                        })
                    }
                    if (this.childInfo.length > 0) {
                        this.disableChildArray = true
                        for (var i = 0; i < this.childInfo.length; i++) {
                            this.newForm.controls['child_Array']['controls'][i].patchValue({
                                childFirstName: this.childInfo[i].firstname,
                                childMiddleName: this.childInfo[i].middlename,
                                childLastName: this.childInfo[i].lastname,
                                childDob: this.datePipe.transform(this.childInfo[i].dob, "MM/dd/yyyy"),
                                childGender: this.childInfo[i].gender,
                                childAge: this.childInfo[i].age,
                                childDisability: this.childInfo[i].disability,
                                child_id: this.childInfo[i].member_id
                            })
                        }
                    }
                    this.loader = true;
                    var data: any = {
                        enrollment_type: this.enrollment_type,
                        startdate: Object.startdate,
                        member_personal_data: this.applicantInfo,
                        agent_id: this.agent_id,
                        group_id: this.groupId
                    }

                    this.fields.forEach(element => {
                        element.data?.forEach(ele => {
                            if (ele.agent_id) {
                                this.agent_id = ele.agent_id
                            }
                            if (ele.group_id) {
                                this.groupId = ele.group_id
                            }
                            this.selectedGroup_AgentType.push(ele.type);
                        });

                    });


                    if (this.selectedGroup_AgentType.includes("Group")) {
                        data.group_id = this.groupId //groupId
                    } else {
                        data.group_id = "" //groupId
                    }

                    // if (this.selectedGroup_AgentType.includes("Producer")) {
                    //   data.agent_id = this.agent_id;
                    // }
                    // else {
                    //   data.agent_id = "";
                    // }

                    if (this.selectedGroup_AgentType.includes("Producer") && this.selectedGroup_AgentType.includes("Group")) {
                        data.group_id = this.groupId //groupId
                        data.agent_id = this.agent_id;
                    }


                    this.plandata_data = [];
                    var url = 'Plan/List/' + this.companyId;
                    if (!!this.agency_company_id) {
                        data.agency_company_id = this.agency_company_id
                    }
                    this.service.postData(url, data).subscribe((Object: any) => {
                        if (Object !== null) {
                            this.submitAttempt = false;
                            this.loader = false;
                            this.plansInfo = Object;
                            this.planDetails = this.plansInfo.plan_details;
                            if (this.plansInfo.data.length > 0) {
                                for (var i = 0; i < this.plansInfo.data.length; i++) {
                                    if (this.plansInfo.data[i].family_info.includes(',')) {
                                        // Split the string by commas
                                        var namesArray = this.plansInfo.data[i].family_info.split(',');
                                        // Capitalize the first letter of each name
                                        var capitalizedNames = namesArray.map(function (name) {
                                            return name.trim().charAt(0).toUpperCase() + name.slice(1);
                                        });
                                        // Join the names back into a string, separated by commas
                                        var outputNames = capitalizedNames.join(', ');
                                        this.plansInfo.data[i].family_info = outputNames;
                                    } else {
                                        this.plansInfo.data[i].family_info = this.plansInfo.data[i].family_info[0].toUpperCase() + this.plansInfo.data[i].family_info.slice(1);
                                    }

                                }
                            }
                            this.displayPrograms = Object.display_programs;
                            this.displayPlans = this.plansInfo.ar;
                            this.programs = this.plansInfo.programs
                            if (!!this.programs && this.programs.length > 0) {
                                this.selectedPlan = this.programs[0]?.name;
                                this.programs.forEach(element => {
                                    element.index = this.programs.indexOf(element) + 1;
                                });
                            }
                            if (this.programs.length > 0) {
                                for (var i = 0; i < this.plansInfo.data.length; i++) {
                                    if (this.plansInfo.data[i].program_id == this.programs[0].id) {
                                        this.plandata_data.push(this.plansInfo.data[i]);
                                    }
                                }
                                this.programs_data = this.plandata_data.reduce((total, item) => {
                                    const existingGroup = total.find((group) => group.sub_program_name === item.sub_program_name);
                                    this.display_programs = true;
                                    if (existingGroup) {
                                        existingGroup.data.push(item);
                                    } else {
                                        total.push({
                                            sub_program_name: item.sub_program_name,
                                            data: [item],
                                        });
                                    }
                                    return total;
                                }, []);


                            } else {
                                this.plandata = this.plansInfo.data
                            }

                            this.plandata.forEach(el => {
                                el['formatedservice'] = JSON.parse(el.services);
                                el.formatedservice.forEach((element, i) => {
                                    element.planId = el.plan_id
                                    if (i == 0) {
                                        el.fee = element
                                    }
                                });
                            });
                            this.groupedData = this.objectkeys(this.plandata[0]).map(key => ({
                                [key]: this.plandata.map(obj => {
                                    if (key == "services") {
                                        return JSON.parse(obj[key]);
                                    } else {
                                        return obj[key]
                                    }
                                })
                            }));
                            let familyInfo = this.groupedData.splice(8, 1);
                            this.groupedData.splice(3, 0, familyInfo[0]);

                            let obj: any = {
                                plans: this.groupedData[2].Name
                            }
                            this.groupedData.splice(6, 0, obj);
                            this.serviceCountArray = Array(this.groupedData[8]['services'].length).fill(0).map((_, index) => index);
                            this.serviceItemCountArray = Array(this.groupedData[8]['services'][0].length).fill(0).map((_, index) => index);
                            window.scrollBy(0, 240);
                            setTimeout(() => {
                                let applicantsinfo = [];
                                this.plandata.forEach(elem => {
                                    let info = document.getElementById("applicantsinfo" + elem.plan_id)
                                    applicantsinfo.push(info.offsetHeight);
                                    if (applicantsinfo.length == this.plandata.length) {
                                        let maxNumber = Math.max(...applicantsinfo);
                                        this.plandata.forEach(elemen => {
                                            document.getElementById("applicantsinfo" + elemen.plan_id).style.height = maxNumber + "px";
                                        });
                                    }
                                });
                                this.serviceItemCountArray.forEach((element, i) => {
                                    let planArr = [];
                                    this.serviceCountArray.forEach(ele => {
                                        if (i != 0) {
                                            let x = document.getElementById("list" + element + this.plandata[ele].formatedservice[ele].planId)
                                            planArr.push(x.offsetHeight);
                                            if (planArr.length == this.plandata.length) {
                                                this.maxNumber = Math.max(...planArr);
                                                this.serviceCountArray.forEach(ele => {
                                                    document.getElementById("list" + element + this.plandata[ele].formatedservice[ele].planId).style.height = this.maxNumber + "px";
                                                });
                                            }
                                        }
                                    });
                                });
                            }, 2000);
                        }
                    }, err => {
                        this.loader = false;
                        this.toastr.error(err?.error?.message);
                    })
                }
            }, err => {
                this.loader = false;
                this.errorMsgShow = true;
                this.emailError = err.error.email;
                this.phoneerrmsg = err.error.phone;
                if (this.emailError != undefined) {
                    this.toastr.error(this.emailError);
                } else {
                    if (err && err.error && err.error.message) {
                        this.toastr.error(err.error.message);
                    }
                }
            })
        } else {
            if (this.newForm.controls.firstname.errors || this.newForm.controls.lastname.errors
                || this.newForm.controls.email.errors || this.newForm.controls.phone.errors
                || this.newForm.controls.dob.errors || this.newForm.controls.age.errors
                || this.newForm.controls.startDate.errors || this.newForm.controls.gender.errors || this.newForm.controls.state.errors) {
                this.focusOnErrorField();
            }
        }
    }

    planstabs(id, subModule, item, index) {
        this.plandata_data = []
        this.selectedPlan = item.name;
        subModule.active = !subModule.active;
        if (index == 0) {
            this.display_programs = true;
            for (var i = 0; i < this.plansInfo.data.length; i++) {
                if (this.plansInfo.data[i].program_id == this.programs[0].id) {
                    this.plandata_data.push(this.plansInfo.data[i]);
                }
            }
            this.programs_data = this.plandata_data.reduce((total, item) => {
                const existingGroup = total.find((group) => group.sub_program_name === item.sub_program_name);
                if (existingGroup) {
                    existingGroup.data.push(item);
                } else {
                    total.push({
                        sub_program_name: item.sub_program_name,
                        data: [item],
                    });
                }
                return total;
            }, []);
        } else {
            //  this.plandata_new = this.plansInfo.data.filter(x => x['program_id'] == id);
            this.programs_data = this.plansInfo.data.filter(x => x['program_id'] == id).reduce((total, item) => {
                const existingGroup = total.find((group) => group.sub_program_name === item.sub_program_name);
                if (existingGroup) {
                    existingGroup.data.push(item);
                } else {
                    total.push({
                        sub_program_name: item.sub_program_name,
                        data: [item],
                    });
                }
                return total;
            }, []);
            if (this.programs_data.length > 1) {
                this.display_programs = true;
            } else {
                this.display_programs = false;
                this.plandata_new = this.plansInfo.data.filter(x => x['program_id'] == id);
            }
        }
    }

    addToCart(value) {// addto cart

        var program = localStorage.setItem("program_id", value.program_id);
        var updatePlan = localStorage.getItem('planupdate');
        var summaryToPlan = localStorage.getItem('summaryPlanupdate')
        if (updatePlan == 'updateplan' || this.cart_data != undefined) {//cart update
            if (summaryToPlan == 'summarytoPlan') {
                this.productDetails.company_id = this.companyId;
                this.productDetails.enrollment_type = this.enrollment_type;
                this.productDetails.group_id = this.groupId
                this.productDetails.agent_id = this.agent_id
                this.productDetails.cart_type = "enrollment";
                this.productDetails.family_id = this.plansInfo.member_personal_data[0].family_id;
                this.productDetails.nicotine = localStorage.getItem('nicotineselected');
                this.plans = [];
                this.member_info = [];
                if (value.plan_info != null) {
                    for (var i = 0; i < value.plan_info.length; i++) {
                        var member_info = value.plan_info[i].member_info;
                        this.member_personal_data1 = {};
                        this.member_personal_data1.product_pricing_id = value.plan_info[i].product_pricing_id;
                        this.member_personal_data1.plan_price = value.plan_info[i].price;
                        this.member_personal_data1.additional_member = value.plan_info[i].additional_member;
                        this.member_personal_data1.quantity = 1;
                        this.member_personal_data1.member_info = member_info;
                        this.plans.push(this.member_personal_data1)
                    }
                } else {
                    this.member_personal_data1 = {};
                    var member_info = value.member_info;
                    this.member_personal_data1.member_info = member_info;
                    this.member_personal_data1.product_pricing_id = value.product_pricing_id;
                    this.member_personal_data1.plan_price = value.price;
                    this.member_personal_data1.additional_member = value.additional_member;
                    this.member_personal_data1.quantity = 1;
                    this.member_personal_data1.member_info = member_info;
                    this.plans.push(this.member_personal_data1)
                }
                var plan = {
                    plans: this.plans
                }
                var finalObject: any = {};
                finalObject = Object.assign({}, plan, this.productDetails,);
                if (this.applicant.member_personal_data.length == this.plansInfo.member_personal_data.length) {
                    var url = 'UpdateCartPlan' + '/' + this.applicant.cart_data[0].id;
                    this.service.putData(url, finalObject).subscribe((newObj: any) => {
                        this.planeData = newObj;
                        var url = 'GetMemberDependents/' + this.companyId + '/' + newObj.member_personal_data[0].member_id + '/' + newObj.cart_data[0].id;
                        this.service.getData(url).subscribe((Object: any) => {
                            this.applicationCart();
                            localStorage.removeItem('planupdate');
                            localStorage.removeItem('summaryPlanupdate')
                            this.objectService.paymentMethod(Object);
                            this.router.navigate(['demographic', {routeTo: "summary"}]);
                        })
                    }, err => {
                        this.toastr.error(err?.error?.message);
                    })
                } else {
                    var url = 'UpdateCartPlan' + '/' + this.applicant.cart_data[0].id;
                    this.service.putData(url, finalObject).subscribe((newObj: any) => {
                        this.planeData = newObj;
                        var url = 'GetMemberDependents/' + this.companyId + '/' + newObj.member_personal_data[0].member_id + '/' + newObj.cart_data[0].id;
                        this.service.getData(url).subscribe((Object: any) => {
                            this.applicationCart();
                            sessionStorage.setItem('d2cFlow', 'd2c');
                            this.service.pushD2c('D2C');
                            this.services.addCartPlan(Object);
                            this.objectService.paymentMethod(Object);
                            this.router.navigate(['demographic']);
                            this.submitAttempt = false;
                        })
                    }, err => {
                        this.toastr.error(err?.error?.message);
                    })
                }
            } else {
                this.productDetails.nicotine = localStorage.getItem("nicotineselected");
                this.productDetails.company_id = this.companyId;
                this.productDetails.enrollment_type = this.enrollment_type
                this.productDetails.group_id = this.groupId
                this.productDetails.agent_id = this.agent_id
                this.productDetails.cart_type = "enrollment";
                this.productDetails.family_id = this.plansInfo.member_personal_data[0].family_id;
                this.plans = [];
                if (value.plan_info != null) {
                    for (var i = 0; i < value.plan_info.length; i++) {
                        var member_info = value.plan_info[i].member_info;
                        this.member_personal_data1 = {};
                        this.member_personal_data1.product_pricing_id = value.plan_info[i].product_pricing_id;
                        this.member_personal_data1.plan_price = value.plan_info[i].price;
                        this.member_personal_data1.additional_member = value.plan_info[i].additional_member;
                        this.member_personal_data1.quantity = 1;
                        this.member_personal_data1.member_info = member_info;
                        this.plans.push(this.member_personal_data1)
                    }
                } else {
                    this.member_personal_data1 = {};
                    var member_info = value.member_info;
                    this.member_personal_data1.member_info = member_info;
                    this.member_personal_data1.product_pricing_id = value.product_pricing_id;
                    this.member_personal_data1.plan_price = value.price;
                    this.member_personal_data1.additional_member = value.additional_member;
                    this.member_personal_data1.quantity = 1;
                    this.plans.push(this.member_personal_data1)
                }
                var plan = {
                    plans: this.plans
                }
                var finalObject: any = {};
                finalObject = Object.assign({}, plan, this.productDetails,);
                var url = 'UpdateCartPlan' + '/' + this.applicant.cart_data[0].id;
                this.service.putData(url, finalObject).subscribe((newObj: any) => {
                    this.planeData = newObj;
                    var url = 'GetMemberDependents/' + this.companyId + '/' + newObj.member_personal_data[0].member_id + '/' + newObj.cart_data[0].id;
                    this.service.getData(url).subscribe((Object: any) => {
                        this.applicationCart();
                        localStorage.removeItem('planupdate')
                        sessionStorage.setItem('d2cFlow', 'd2c');
                        this.service.pushD2c('D2C');
                        this.services.addCartPlan(Object);
                        this.router.navigate(['demographic']);
                        this.submitAttempt = false;
                    })
                }, err => {
                    this.toastr.error(err?.error?.message);
                })
            }
        } else {
            this.productDetails.nicotine = localStorage.getItem('nicotineselected');
            this.productDetails.company_id = this.companyId;
            this.productDetails.enrollment_type = this.enrollment_type;
            this.productDetails.group_id = this.groupId
            this.productDetails.agent_id = this.agent_id
            this.productDetails.cart_type = "enrollment";
            this.productDetails.family_id = this.plansInfo.member_personal_data[0].family_id;
            this.plans = [];
            this.member_info = [];
            if (value.plan_info != null) {
                for (var i = 0; i < value.plan_info.length; i++) {
                    var member_info = value.plan_info[i].member_info;
                    this.member_personal_data1 = {};
                    this.member_personal_data1.product_pricing_id = value.plan_info[i].product_pricing_id;
                    this.member_personal_data1.plan_price = value.plan_info[i].price;
                    this.member_personal_data1.additional_member = value.plan_info[i].additional_member;
                    this.member_personal_data1.quantity = 1;
                    this.member_personal_data1.member_info = member_info;
                    this.plans.push(this.member_personal_data1)
                }
            } else {
                this.member_personal_data1 = {};
                var member_info = value.member_info;
                this.member_personal_data1.member_info = member_info;
                this.member_personal_data1.product_pricing_id = value.product_pricing_id;
                this.member_personal_data1.plan_price = value.price;
                this.member_personal_data1.additional_member = value.additional_member;
                this.member_personal_data1.quantity = 1;
                this.plans.push(this.member_personal_data1)
            }
            var plan = {
                plans: this.plans
            }
            var finalObject: any = {};
            finalObject = Object.assign({}, plan, this.productDetails);
            var url = 'AddCartPlan';
            this.service.postData(url, finalObject).subscribe((newObj: any) => {
                this.planeData = newObj;
                var url = 'GetMemberDependents/' + this.companyId + '/' + newObj.member_personal_data[0].member_id + '/' + newObj.cart_data[0].id;
                this.service.getData(url).subscribe((Object: any) => {
                    this.applicationCart();
                    sessionStorage.setItem('d2cFlow', 'd2c');
                    this.service.pushD2c('D2C');
                    this.services.addCartPlan(Object);
                    this.services.appCartmemberInfoInfo(this.companyId, newObj.member_personal_data[0].member_id, newObj.cart_data[0].id);
                    this.router.navigate(['demographic']);
                    this.submitAttempt = false;
                }, err => {
                    console.log(err)
                })
            }, err => {
                this.toastr.error(err?.error?.message);
            })
        }
    }

    applicationCart() {
        var url = 'GetCartPlan/' + this.companyId + '/' + this.planeData.cart_data[0].id + '/' + this.planeData.member_personal_data[0].family_id;
        this.service.getData(url).subscribe((Object: any) => {
            if (!!Object) {
                this.services.appCartInfo(Object);
                sessionStorage.setItem("cartplan", JSON.stringify(Object));
                this.sessionService.setData('company', this.companyId);
                this.sessionService.setData('member_id', this.planeData.member_personal_data[0].member_id);
                this.sessionService.setData('cart_id', this.planeData.cart_data[0].id);
                this.submitAttempt = false;
            }
        }, err => {
            this.toastr.error(err?.error?.message);
        })
    }

    //===============Age Calculation==========//
    onSearchChangeT(value) {
        if (value.dob != null) {
            var dob = new Date(this.datePipe.transform(this.newForm.controls.startDate.value, "MM/dd/yyyy"));
            var endDt = new Date(this.datePipe.transform(value.dob, "MM/dd/yyyy")) || new Date();
            this.Age = new Date(dob.getTime() - endDt.getTime()).getUTCFullYear() - 1970
            // let selectedDate = this.datePipe.transform(value.dob, "dd-MMM-yyyy");
            // this.Age = moment().diff(selectedDate, 'years');

            // const convertAge = new Date(value.dob);
            // console.log(convertAge);
            // const timeDiff = Math.abs(Date.now() - convertAge.getTime());
            // console.log(timeDiff);
            // this.Age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
            this.newForm.controls.age.setValue(this.Age);
            if (value.guardianCheckbox == true) {
                if (this.Age <= 26) {
                    this.parentAge = "Applicant  can't be younger than 26 years of age";
                }
            } else {
                if (this.Age <= 17) {
                    this.parentAge = "Applicant  can't be younger than 18 years of age";
                }
            }
            if (this.Age > 64) {
                this.parentAge = "Applicant can't be older than 65 years of age";
            }
            if (this.Age < 66 && this.Age > 18) {
                this.parentAge = "";
            }
        }
    }

    onSearchChange(searchValue: string, checkValue): void {
        if (searchValue !== "") {
            var dob = new Date(this.datePipe.transform(this.newForm.controls.startDate.value, "MM/dd/yyyy"));
            var endDt = new Date(this.datePipe.transform(searchValue, "MM/dd/yyyy")) || new Date();
            this.Age = new Date(dob.getTime() - endDt.getTime()).getUTCFullYear() - 1970
            // const convertAge = new Date(searchValue);
            // const timeDiff = Math.abs(Date.now() - convertAge.getTime());
            // this.Age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
            this.newForm.controls.age.setValue(this.Age);
            if (checkValue.guardianCheckbox == true) {
                if (this.Age <= 26) {
                    this.parentAge = "Applicant  can't be younger than 26 years of age";
                }
                if (this.Age > 64) {
                    this.parentAge = "Applicant can't be older than 65 years of age";
                }
                if (this.Age < 65 && this.Age > 25) {
                    this.parentAge = "";
                }
            } else {
                if (this.Age <= 17) {
                    this.parentAge = "Applicant  can't be younger than 18 years of age";
                }
                if (this.Age > 64) {
                    this.parentAge = "Applicant can't be older than 65 years of age";
                }
                if (this.Age < 65 && this.Age > 18) {
                    this.parentAge = "";
                }
            }

        }
    }

    spouseAgeCal(searchValue, Index: number): void {
        if (searchValue !== "") {
            // const convertAge = new Date(searchValue);
            // const timeDiff = Math.abs(Date.now() - convertAge.getTime());
            // this.spouseAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);

            var dob = new Date(this.datePipe.transform(this.newForm.controls.startDate.value, "MM/dd/yyyy"));
            var endDt = new Date(this.datePipe.transform(searchValue, "MM/dd/yyyy")) || new Date();
            this.spouseAge = new Date(dob.getTime() - endDt.getTime()).getUTCFullYear() - 1970

            // this.newForm.controls.spouse_Age.setValue(this.spouseAge);
            const formcontrol = this.spouse_Array()
            formcontrol.controls[Index].get('spouseAge').setValue(this.spouseAge);
            if (this.spouseAge <= 17) {
                this.spouseAgeMsg = "Applicant  can't be younger than 18 years of age";
            }
            if (this.spouseAge > 64) {
                this.spouseAgeMsg = "Applicant can't be older than 65 years of age";
            }
            if (this.spouseAge < 66 && this.spouseAge > 18) {
                this.spouseAgeMsg = "";
            }
        }
    }

    spouseAgeCalT(value, Index = 0) {
        if (value.spouse_Array[0].spouseDob) {
            // const convertAge = new Date(value.spouse_Array[0].spouseDob);
            // const timeDiff = Math.abs(Date.now() - convertAge.getTime());
            // this.spouseAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
            var dob = new Date(this.datePipe.transform(this.newForm.controls.startDate.value, "MM/dd/yyyy"));
            var endDt = new Date(this.datePipe.transform(value.spouse_Array[0].spouseDob, "MM/dd/yyyy")) || new Date();
            this.spouseAge = new Date(dob.getTime() - endDt.getTime()).getUTCFullYear() - 1970

            const formcontrol = this.spouse_Array()
            formcontrol.controls[Index].get('spouseAge').setValue(this.spouseAge);
            if (this.spouseAge <= 17) {
                this.spouseAgeMsg = "Applicant  can't be younger than 18 years of age";
            }
            if (this.spouseAge > 64) {
                this.spouseAgeMsg = "Applicant can't be older than 65 years of age";
            }
            if (this.spouseAge < 65 && this.spouseAge > 18) {
                this.spouseAgeMsg = "";
            }
        }
    }

    childAgeCal(searchValue, Index: number, dob): void {
        let id = "dob" + Index;
        if (id == dob) {
            if (searchValue !== "") {
                {
                    // const convertAge = new Date(searchValue);
                    // const timeDiff = Math.abs(Date.now() - convertAge.getTime());
                    // this.childAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
                    var newdob = new Date(this.datePipe.transform(this.newForm.controls.startDate.value, "MM/dd/yyyy"));
                    var endDt = new Date(this.datePipe.transform(searchValue, "MM/dd/yyyy")) || new Date();
                    this.childAge = new Date(newdob.getTime() - endDt.getTime()).getUTCFullYear() - 1970

                    if (this.childAge > 26) {
                        (<HTMLInputElement>document.getElementById("childDOBerror" + Index)).innerHTML = "Child(ren) over the age 26 need to enroll in their own Program as the Primary Member";
                    }
                    if (this.childAge < 26) {
                        (<HTMLInputElement>document.getElementById("childDOBerror" + Index)).innerHTML = "";
                    }
                }
                const formcontrol = this.child_Array()
                formcontrol.controls[Index].get('childAge').setValue(this.childAge);
            } else {
                this.childAge = "";
                this.ageError = ""
                const formcontrol = this.child_Array()
                formcontrol.controls[Index].get('childAge').setValue(this.childAge)
            }
        }
    }

    childAgeCalT(searchValue, Index: number, dob, va): void {
        let id = "dob" + Index;
        if (id == dob) {
            if (va.child_Array.length > 0) {
                {
                    for (var x = 0; x < va.child_Array.length; x++) {
                        // const convertAge = new Date(va.child_Array[x].childDob);
                        // const timeDiff = Math.abs(Date.now() - convertAge.getTime());
                        // this.childAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);

                        var newdob = new Date(this.datePipe.transform(this.newForm.controls.startDate.value, "MM/dd/yyyy"));
                        var endDt = new Date(this.datePipe.transform(va.child_Array[x].childDob, "MM/dd/yyyy")) || new Date();
                        this.childAge = new Date(newdob.getTime() - endDt.getTime()).getUTCFullYear() - 1970

                        if (this.childAge > 26) {
                            (<HTMLInputElement>document.getElementById("childDOBerror" + Index)).innerHTML = "Child(ren) over the age 26 need to enroll in their own Program as the Primary Member";
                        }
                        if (this.childAge < 26) {
                            (<HTMLInputElement>document.getElementById("childDOBerror" + Index)).innerHTML = "";
                        }
                    }
                }
                const formcontrol = this.child_Array()
                formcontrol.controls[Index].get('childAge').setValue(this.childAge);
            } else {
                this.childAge = "";
                this.ageError = ""
                const formcontrol = this.child_Array()
                formcontrol.controls[Index].get('childAge').setValue(this.childAge)
            }
        }
    }

    onfocusoutphnumber(feature) {
        if (feature == "primary") {
            let text = (<HTMLInputElement>document.getElementById("phnumbererrmsg")).innerHTML;
            let value = (<HTMLInputElement>document.getElementById("phnumber")).value;
            if (value.length < 12) {
                (<HTMLInputElement>document.getElementById("phnumbererrmsg")).innerHTML = "Please enter a valid phone number";
            }
            if (value.length >= 12) {
                (<HTMLInputElement>document.getElementById("phnumbererrmsg")).innerHTML = "";
            }
        }
    }

    onkeyupPhone(e) {
        let phnumbervalue = (<HTMLInputElement>document.getElementById("phnumber")).value;
        let transformedInput = phnumbervalue.replace(/^0*/g, '');
    }

    onPastePhone(event: ClipboardEvent) {
        const clipboardData = event.clipboardData;
        const pastedText = clipboardData.getData('text');
        let specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
        let numberRegex = /^[\d-]+$/;
        if (specialCharacterRegex.test(pastedText) || !numberRegex.test(pastedText)) {
            event.preventDefault();
        }
    }

    //================number validation==============//
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    ngOnDestroy() {
        localStorage.removeItem('planupdate')
    }

    applicant_data(flag) {
        if (this.applicant != undefined && this.applicant != null) {
            if (this.applicant.application_id != undefined) {
                this.isApplicationCheckboxChecked = true;
                this.application_id = this.applicant.application_id
                if (flag === "0") {
                    this.showApplicationForm = true;
                    this.groupName = true;
                    this.applicationForm.controls.applicationId.setValue(this.applicant.application_id);
                    this.applicationForm.controls.applicationEmail.setValue(this.primaryInfo[0].email);
                }
                let body = {
                    application_id: this.applicant.application_id
                }
                this.newapplication(body);
            }
            if (this.applicant.agent_details != undefined && this.applicant.agent_details != null) {
                this.agent_id = this.applicant.agent_details[0].agent_id;
                var code = this.applicant.agent_details[0].code;
                this.groupInfoAgent(code, '0')
            }
            if (this.applicant.group_info != undefined) {
                var code = this.applicant.group_info[0].groupID;
                this.groupInfoAgent(code, '0')
            }
        }
        if (this.primaryInfo != null || this.primaryInfo != undefined) {
            this.newForm.controls.firstname.setValue(this.primaryInfo[0].firstname);
            this.newForm.controls.middlename.setValue(this.primaryInfo[0].middlename);
            this.newForm.controls.lastname.setValue(this.primaryInfo[0].lastname);
            this.newForm.controls.email.setValue(this.primaryInfo[0].email);
            var phone = this.primaryInfo[0].phone.substr(0, 3) + "-" + this.primaryInfo[0].phone.substr(3, 3) + "-" + this.primaryInfo[0].phone.substr(6, 4);
            this.newForm.controls.phone.setValue(phone);
            this.newForm.controls.age.setValue(this.primaryInfo[0].age);
            this.newForm.controls.state.setValue(this.primaryInfo[0].state);
            // Convert the applicant's start date to "dd-MMM-yyyy" format
            const formattedStartDate = this.datePipe.transform(this.applicant.startdate, "dd-MMM-yyyy");

            // Check if the formatted start date is present in the effectiveDate array
            const isStartDateEffective = this.effectiveDate.some(dateObj => dateObj.date === formattedStartDate);

            // Set the form control value based on whether the start date is in the effectiveDate array
            if (isStartDateEffective) {
                this.newForm.controls.startDate.setValue(formattedStartDate);
            } else {
                // Set the start date as blank if it's not in the effectiveDate array
                this.newForm.controls.startDate.setValue('');

                // Marked the control as touched to show the error
                this.newForm.controls.startDate.markAsTouched();
            }
            this.newForm.controls.dob.setValue(this.datePipe.transform(this.primaryInfo[0].dob, "MM/dd/yyyy"));
            this.newForm.controls.gender.setValue(this.primaryInfo[0].gender);
            this.newForm.controls.member_id.setValue(this.primaryInfo[0].member_id);
            this.firstname = this.primaryInfo[0].firstname;
        }
        if (this.spouseInfo != null || this.spouseInfo != undefined) {
            if (this.spouseInfo.length > 0) {
                this.disableSpouseArray = true
                this.spouse_Array().push(this.newEmployee1());
                this.newForm.controls['spouse_Array']['controls'][0].patchValue({
                    spouseFirstName: this.spouseInfo[0].firstname,
                    spouseMiddleName: this.spouseInfo[0].middlename,
                    spouseLastName: this.spouseInfo[0].lastname,
                    spouseDob: this.datePipe.transform(this.spouseInfo[0].dob, "MM/dd/yyyy"),
                    spouseAge: this.spouseInfo[0].age,
                    spouseGender: this.spouseInfo[0].gender,
                    spouse_id: this.spouseInfo[0].member_id,
                })
            }
        }
        if (this.childInfo != null || this.childInfo != undefined) {
            if (this.childInfo.length > 0) {
                this.disableChildArray = true
                this.showChild = true;
            }
            for (var i = 0; i < this.childInfo.length; i++) {
                this.child_Array().push(this.newChild());
                this.newForm.controls['child_Array']['controls'][i].patchValue({
                    childFirstName: this.childInfo[i].firstname,
                    childMiddleName: this.childInfo[i].middlename,
                    childLastName: this.childInfo[i].lastname,
                    childDob: this.datePipe.transform(this.childInfo[i].dob, "MM/dd/yyyy"),
                    childGender: this.childInfo[i].gender,
                    childAge: this.childInfo[i].age,
                    child_id: this.childInfo[i].member_id,
                    childDisability: this.childInfo[i].disability
                })
            }
        }
    }

    applicationFields(checked) {
        if (checked) {
            this.showApplicationForm = true;
        } else {
            this.showApplicationForm = false;
        }
    }

    downloadReferenceGuide(name) {
        let type = name.toLowerCase().includes('premium') ? 'premium' : 'plus';
        let payload_guide = {
            file_type: "health-guide-" + type
        }
        let url = 'GetHcsmReference';
        this.service.downloadPostData(url, payload_guide).subscribe((obj: any) => {
            let binaryData = [];
            binaryData.push(obj)
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: obj.type}));
            downloadLink.setAttribute('download', "health-guide-" + type + ".pdf");
            document.body.appendChild(downloadLink);
            downloadLink.click();
        })
        let payload_brochure = {
            file_type: "health-brochure-" + type
        }
        this.service.downloadPostData(url, payload_guide).subscribe((obj: any) => {
            let binaryData = [];
            binaryData.push(obj)
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: obj.type}));
            downloadLink.setAttribute('download', "health-brochure-" + type + ".pdf");
            document.body.appendChild(downloadLink);
            downloadLink.click();
        })
    }

    onDialogShow() {
        const dialogContent = this.myDialog.container.querySelector('.p-dialog-content');
        if (dialogContent) {
            dialogContent.scrollTop = 0;
        }
    }

    seeAllFeatures(item) {
        if (item.feature_link) {
            this.program_name = item.sub_program_name + ' ' + item.Name
            this.resourceURL = item.feature_link;
            window.open(this.resourceURL, '_blank');
        } else {
            this.service_data = [];
            this.plan_details_popup = true;
            this.service_data.push(item);
            this.service_newData = JSON.parse(item.services)
        }
    }

}


        
        <!-- Application ID -->
        <div class=""   >
            <!-- <app-incomplete-applicationflow  [applicant]="applicantdata"></app-incomplete-applicationflow>    -->
            <div class="row mb-2">
                <div class="form-group col-auto">
                    <div class="return-user-check">
                        <label class="switch-label">Do you have a previously incomplete
                            application? </label>
                        <label class="switch ml-4">
                            <input (change)="applicationFields($event.target.checked)" [checked]="showApplicationForm"
                                 type="checkbox">
                            <div class="slider-applicant round"></div>
                        </label>
                    </div>
                </div>
            </div>
            <form *ngIf="showApplicationForm " class="mb-3 mt-2" [formGroup]="applicationForm">
                <div *ngIf="showApplicationForm"
                     class="row">
                    <div class="col-lg-3 form-group">
                        <div class="head_txt1"> Application ID <span class="fieldMan">*</span></div>
                        <input class="form-control"
                               formControlName="applicationId"
                               id="applicationId"
                               maxlength="25"
                               placeholder="Application ID"
                               type="text">
                        <div *ngIf="!applicationForm.controls.applicationId.valid && (applicationForm.controls.applicationId.touched || applicationsubmitAttempt)"
                             class="errormsg">
                            Application ID is required
                        </div>
                    </div>
                    <div class="col-lg-3 form-group">
                        <div class="head_txt1"> Email <span class="fieldMan">*</span></div>
                        <input class="form-control"
                               formControlName="applicationEmail"
                               placeholder="Email"
                               type="text">
                        <div *ngIf="!applicationForm.controls.applicationEmail.valid && (applicationForm.controls.applicationEmail.touched || applicationsubmitAttempt)"
                             class="errormsg">
                            Email is required
                        </div>
                    </div>
                    <div class="col-lg-3 form-group applicationBtn ">
                        <button (click)="newapplication(applicationForm.value)"
                                class="btn btn-sm btn-primary btn-hope-two "
                                type="button">
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </div>

                <!-- Application ID -->

 
import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharingService } from 'src/app/services/sharing.service';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
@Component({
  selector: 'app-member-referral',
  templateUrl: './member-referral.component.html',
  styleUrl: './member-referral.component.scss'
})
export class MemberReferralComponent {
  submitAttempt = false;
  referralForm: FormGroup | undefined;
  companyName = null;
  minimumDate = new Date();
  loader = false;
  formFields = [];
  memberId: any;
  programId: any;
  referralFormId: any;
  isRequestSubmitted = false;
  emailFieldIndex: number = -1;
  display_btn: boolean = false;
  display_otp: boolean = false;
  otp_value: '';
  otpExpirationTime: number = 120; // Time in seconds for OTP expiry (2 minutes)
  displayMinutes: string = '02'; // Display minutes
  displaySeconds: string = '00'; // Display seconds
  timerSubscription: Subscription | null = null;
  counter: any;
  companyId: any;
  otp_text = 'Send OTP';
  userphone: any;
  phone_verified:boolean=false;
  referred_email: any;
  timeSlots:any[]=[
    
  ]
  constructor(
    private formBuilder: FormBuilder,
    private service: SharingService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    // Fetch the parameters
    this.route.params.subscribe(params => {
      this.memberId = params['member_id'];
      this.programId = params['program_id'];
      this.referralFormId = params['referral_form_id'];
    });

    this.service.clientData.subscribe((data) => {
      if (data != '') {
        this.companyName = data.company_display_name;
        this.companyId = data.company_id;
        this.getFormFields();
        this.startOtpTimer();
      }
    });

  }

  startOtpTimer(): void {
    this.timerSubscription = interval(1000).subscribe(() => {
      if (this.otpExpirationTime > 0) {
        this.otpExpirationTime--; // Decrease the time by 1 second
        this.updateDisplayTime(); // Update the time to be displayed
      } else {
        // Handle OTP expiration (e.g., show an alert, disable the Verify button)
        this.stopTimer();
        console.log("OTP has expired");
        // Add your logic for OTP expiration, e.g., showing a message
      }
    });
  }

  updateDisplayTime(): void {
    const minutes = Math.floor(this.otpExpirationTime / 60);
    const seconds = this.otpExpirationTime % 60;

    this.displayMinutes = this.formatTime(minutes);
    this.displaySeconds = this.formatTime(seconds);
  }

  formatTime(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }

  stopTimer(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe(); // Stop the timer
    }
  }

  ngOnDestroy(): void {
    this.display_otp = false;
    this.stopTimer(); // Cleanup when the component is destroyed
  }

  getFormFields() {
    let url = `GetMemberReferralForm/${this.memberId}/${this.programId}`;
    url += this.referralFormId != undefined && this.referralFormId != null ? "/" + this.referralFormId : "";

    this.service.getData(url).subscribe((res: any) => {
      if (res.status) {
        if (res.data) {
          this.formFields = res.data;
         this.getClientCallTiming();
          this.buildForm(this.formFields);
        }
      }
    }, err => {
      console.log(err);
    });
  }

  getClientCallTiming() {
    let url = 'GetClientCallTiming/'+this.companyId ;
    this.service.getData(url).subscribe((res: any) => {
      this.timeSlots=res.data
    }, err => {
      console.log(err);
    });
  }



  // Build the form dynamically
  buildForm(fields: any[]) {
    this.emailFieldIndex = fields.findIndex(field => field.field_name === 'Phone');
    const group: any = {};
    fields.forEach(field => {
      const control = this.formBuilder.control(
        field?.field_value || '',
        this.bindValidations(field)
      );
      group[field.field_name] = control;
    });

    this.referralForm = this.formBuilder.group(group);
  }

  // Bind validations based on the field metadata
  bindValidations(field: any) {
    const validators = [];
    if (field.is_required) {
      if (field.field_name === 'Email') {
        validators.push(Validators.required,Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
      }
      validators.push(Validators.required);
    }
    return validators;
  }

  submitForm() {
    this.submitAttempt = true;
    if (this.referralForm.valid) {
      this.isRequestSubmitted = false;
      this.loader = true;
      let formData = this.referralForm.value;
      let obj: any = {};
      this.formFields.forEach(field => {
        const fieldName = field.field_name; // FormControlName
        const fieldId = field.field_id;     // The field_id from the API response
        if (formData[fieldName] !== undefined) {
          obj[fieldId] = formData[fieldName];  // Map field_id to the corresponding form value
        }
      });
      this.submitAttempt = false;
      let body = {
        referral_program_id: this.programId,
        member_id: this.memberId,
        referral_form_id: this.referralFormId != undefined && this.referralFormId != null ? this.referralFormId : 0,
        referred_to: obj,
         otp_verified :true
      }
      var url = 'SaveMemberReferredForm'
      this.service.postData(url, body).subscribe((res: any) => {
        if (res != null) {
          this.loader = false;
          this.isRequestSubmitted = true;
        }
      }, err => {
        this.loader = false;
        this.toastr.error(err.error.message);
      });
    }

  }
  shouldDisableField(index: number): boolean {
    return this.emailFieldIndex !== -1 && index > this.emailFieldIndex;
  }
  sendOtp(value) {
    this.otp_value = "";
    var payload = {
      company_id: this.companyId,
      phone: value?.Phone
    }
    this.userphone = value?.Phone
    this.referred_email=value?.Email;
    var url = 'GenerateMobileVerificationOTP';
    this.service.postData(url, payload).subscribe((obj: any) => {
      if (obj) {
        this.display_otp = true;
        this.otp_text = 'Resend OTP'
        this.otpExpirationTime = obj.expiration_seconds;
        this.toastr.success(obj.message);
      }
    }, err => {
      this.toastr.error(err.error.message);
    })

  }
  submitOTP() {
    var payload = {
      company_id: this.companyId,
      phone: this.userphone,
      otp: this.otp_value,
      member_id:this.memberId,
      company_referral_program_id:this.programId,
      referred_email:this.referred_email
    }
    var url = 'ValidatePublicOtp';
    this.service.postData(url, payload).subscribe((obj: any) => {
      if (obj) {
        if(obj.status==true){
          this.phone_verified=true;
          this.display_btn = true;
          this.emailFieldIndex = -1;
          this.display_otp = false;
          this.toastr.success(obj.message);
        }else{
          this.toastr.error(obj.message);
        }
       
      }
    }, err => {
      this.toastr.error(err.error.message);
    })
  }
  cancelOTP() {
    this.display_otp = false;
  }
}

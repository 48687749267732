<div class="row user-information-card">
    <div class="container">
        <div class="enrlmnt-demographic">
            <div class="stepper">
                <div class="step active"></div>
                <div class="step active"></div>
                <div class="step active"></div>
                <div class="step active"></div>
                <div class="step active"></div>
                <div class="step active"></div>
            </div>
        </div>
        <div class="progress_bar">
            <ul [ngClass]="{'progessbar': A2Ccode, 'progess-bar': !A2Ccode}" class=" mb-7">
                <li *ngIf="!A2Ccode">Enrollment</li>
                <li *ngIf="!A2Ccode">Products</li>
                <li *ngIf="!A2Ccode ">Payment Details</li>
                <li>Beneficiary</li>
                <li class="active">Agreement</li>
                <li>Disclosure 1</li>
                <li>Disclosure 2</li>
                <li>Summary</li>
                <li>Confirmation</li>
            </ul>
        </div>
    </div>
    <div class="container">
        <!--Agent Info Section-->
        <div *ngIf="agentInfo?.id>0" class="card shadow-sm mb-3">
            <div class="card-body">
                <h6 class="title-mainhead">
                    <span>Agent Information</span>
                </h6>

                <div class="row">
                    <div class="col-md-6">
                        <div class="info-box">
                            <p><b>Name:</b>&nbsp;<span *ngIf="agentInfo.firstname">{{ agentInfo.firstname }}</span>
                                <span *ngIf="agentInfo.lastname">{{ agentInfo.lastname }}</span></p>
                            <p><span *ngIf="agentInfo.email"><b>Email:</b>&nbsp;{{ agentInfo.email }}</span></p>
                            <p><span *ngIf="agentInfo.mobile"><b>Phone:</b>&nbsp;{{ agentInfo.mobile }}</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card p-4">
            <div class="container">
                <div *ngIf="pdfSrc" class=" " id="pdfViewer">
                    <ngx-extended-pdf-viewer [height]="'73vh'" [showHandToolButton]="true" [showHandToolButton]="true"
                      [showPresentationModeButton]="true" [showPresentationModeButton]="true" [showToolbar]="true"
                      [src]="pdfSrc" [textLayer]="true" [textLayer]="true" useBrowserLocale="true"
                      [showSidebarButton]="false" [showFindButton]="false" [showPagingButtons]="true"
                      [showZoomButtons]="true" [showPresentationModeButton]="false" [showOpenFileButton]="false"
                      [showPrintButton]="false" [showDownloadButton]="false" [showBookmarkButton]="false"
                      [showSecondaryToolbarButton]="false" [showHandToolButton]="false" [showScrollingButton]="false"
                      [showRotateButton]="false" [showSelectToolButton]="false" [showTextLayer]="false"
                      [showTextEditor]="false" [showStampEditor]="false" [showDrawEditor]="false"
                      [showAnnotationTools]="false" [showSpreadButton]="false" [showPageBorders]="true"
                      [textLayer]="false" [enablePrint]="false" [enableDownload]="false">
                    </ngx-extended-pdf-viewer>
                </div>
            </div>
        </div>
        <br>
        <div class="d-flex justify-content-between px-5">
            <button (click)="onPrevious()"
                    class="btn btn-outline-primary ">Previous
            </button>
            <button (click)="export()" class="btn btn-primary">Next</button>
        </div>
    </div>
</div>


<div *ngIf="loader" class="loader">
    <div id="loading"></div>
</div>

<div class="container">
    <div class="row user-information-card">
      <div class="container">
        <div class="enrlmnt-demographic">
            <div class="stepper">
                <div class="step active" *ngFor="let item of steps"></div>
            </div>
        </div>
        <div class="progress_bar">
            <ul class="progess-bar mb-7">
                <li *ngFor="let item of steps" [ngClass]="item.active ? 'active' : '' ">{{item.step}}</li>
            </ul>
        </div>
    

        <!--Agent Info Section-->
        <div class="row">
            <div class="col-12">


        <div *ngIf="agentInfo?.id>0" class="card shadow-sm mb-3">
            <div class="card-body">
                <h6 class="title-mainhead">
                    <span>{{ agent_text }}  Information</span>
                </h6>

                <div class="row">
                    <div class="col-md-6">
                        <div class="info-box">
                            <p><b>Name:</b>&nbsp;<span *ngIf="agentInfo.firstname">{{ agentInfo.firstname }}</span>
                                <span
                                        *ngIf="agentInfo.lastname">{{ agentInfo.lastname }}</span></p>
                            <p><span *ngIf="agentInfo.email"><b>Email:</b>&nbsp;{{ agentInfo.email }}</span></p>
                            <p><span *ngIf="agentInfo.mobile"><b>Phone:</b>&nbsp;{{ agentInfo.mobile }}</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card p-4" id="enrollment-form-card">
            <div class="container">
                <div class=" " id="pdfViewer">
                    <ngx-extended-pdf-viewer [height]="'73vh'" [showHandToolButton]="true"
                      [showPresentationModeButton]="true" [showToolbar]="true" [src]="pdfSrc" [textLayer]="true"
                      [useBrowserLocale]="true" [showSidebarButton]="false" [showFindButton]="false"
                      [showPagingButtons]="true" [showZoomButtons]="true" [showPresentationModeButton]="false"
                      [showOpenFileButton]="false" [showPrintButton]="false" [showDownloadButton]="false"
                      [showBookmarkButton]="false" [showSecondaryToolbarButton]="false" [showHandToolButton]="false"
                      [showScrollingButton]="false" [showRotateButton]="false" [showSelectToolButton]="false"
                      [showTextLayer]="false" [showTextEditor]="false" [showStampEditor]="false"
                      [showDrawEditor]="false" [showAnnotationTools]="false" [showSpreadButton]="false"
                      [showPageBorders]="true" [textLayer]="false" [enablePrint]="false" [enableDownload]="false">
                    </ngx-extended-pdf-viewer>
                </div>
            </div>
        </div>
         </div>
        </div>
        <br>
        <div class="row mt-2">
            <div class="col-md-12 mt-2 mb-3 terms-box">
                <div class="form-check">
                    <input (change)="selectTerms()" id="terms" class="form-check-input" type="checkbox"/>
                    <label class="form-check-label" for="terms">&nbsp; I agree to the
                      <span
                      *ngIf="settings.company_display_name && settings.company_name!= 'ICON' && settings.company_name!='Health Freedom'">{{ settings.company_name }} Supported by
                      </span>
                      <span
                        *ngIf="settings.company_display_name">{{ settings.company_display_name }}</span>
                        Terms & Conditions</label>
                    </div>
                <div *ngIf="showTermsError" class="text-danger"><small>Please accept the terms and conditions</small>
                </div>
            </div>

            <div class="col-12">

                <div class="d-flex w-100 justify-content-between ">
                    <button (click)="onPrevious()" class="btn btn-outline-primary">
                        Previous
                    </button>
                    <button (click)="export()" class="btn btn-primary">Next</button>
                </div>
            </div>

        </div>
        </div>
    </div>
</div>

<div *ngIf="loader" class="loader">
    <div id="loading"></div>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgencyAgentService {
  agencyDetail = new BehaviorSubject<any>(null);
  getAgencyDetail = this.agencyDetail.asObservable();

  agentDetail = new BehaviorSubject<any>(null);
  getAgentDetail = this.agentDetail.asObservable();

  private agentId = new BehaviorSubject<any>(null);
  getAgentId = this.agentId.asObservable();

  isAgency = new BehaviorSubject<boolean>(null);
  getIsAgency = this.isAgency.asObservable();

  constructor() {}

  setAgencyDetail(data: any) {
    this.agencyDetail.next(data);
  }

  setIsAgency(data: boolean) {
    this.isAgency.next(data);
  }

  setAgentDetail(data: any) {
    this.agentDetail.next(data);
  }
 
}

import {DatePipe, Location} from '@angular/common';
import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharingService} from 'src/app/services/sharing.service';

@Component({
    selector: 'app-payments',
    // standalone: true,
    // imports: [],
    templateUrl: './payments.component.html',
    styleUrl: './payments.component.scss',
    providers: [DatePipe]
})
export class PaymentsComponent {
    loader: boolean = false;
    paymentType = new FormGroup({
        type: new FormControl('credit')
    });
    choosenPayemntType: string;
    Credit_Form: any;
    Banking_Form: any;
    Ach_Form: any;
    shipping_address_form: any;
    submitCardAttempt: boolean;
    notValidCardNumber: boolean;
    checkCardValid: boolean;
    enterTwoDigitMonth: boolean;
    submitBankingAttempt: boolean;
    enterValidYear: boolean;
    enterValidMonth: boolean;
    invalidMonthEnteredValidation: boolean;
    enteredRoutingNumber: any;
    reEnteredRoutingNumber: any;
    enteredAccountNumber: any;
    reEnteredAccountNumber: any;
    routingNumberMismatch: boolean = false;
    routingNumberField: any = "";
    accountNumberMismatch: boolean = false;
    accountNumberField: any = "";
    routing_number: any;
    account_number: any;
    showShippingAddress: boolean = true;
    country_arr = ['UnitedStates'];
    cardType: string;
    enteredMonth: any;
    value: any;
    billing_date: any;
    minDate: Date;
    maxDate: Date;
    stateStatus: any[] = [];
    subscriptions: any[] = [];
    usaStates: any;
    paymentTypes = [
        {name: 'Credit Card', value: 'credit'},
        {name: 'Debit Card', value: 'debit'},
        {name: 'Bank ACH', value: 'ach'},
    ]
    orderDetails: any;
    companyId: any;
    obj: any = {};
    family_id: string;
    isShippingChecked: boolean = true
    routeMemberId: any;
    cvvErr: any;
    card_number_Err: any;
    exp_date_err: any;
    selectedform: any;
    routeEntroll: any;
    public shippingAddressControl: FormControl = new FormControl(true);
    route_company_name: any;
    agentInfo: any = null;
    billingDate: Date;
    recurringBillingDate: Date;
    recurringBillingDay: any;
    disableDates: (date: Date) => boolean;
    billingMinDate: Date;
    billingMaxDate: Date;
    recurringMinDate: Date;
    recurringMaxDate: Date;
    disabledDates: any[];
    RecurringStartDate: any = 1;
    RecurringEndDate: any = 25;
    RecurringDates: any = [];
    billingDates: any = [];
    effectiveDate1: any;
    effectiveDate2: any;
    selectedEffectiveStartDate: any;
    paymentDate: any;
    selectedBillingDate: any;

    constructor(
        private formBuilder: FormBuilder,
        private service: SharingService,
        private toastr: ToastrService,
        private datePipe: DatePipe,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location
    ) {
        localStorage.removeItem('from_payment')
        this.routeMemberId = this.route.snapshot.paramMap.get('memberid');
        this.routeEntroll = this.route.snapshot.queryParams['enrollment'];
        this.Credit_Form = this.formBuilder.group({
            name: ['', Validators.required],
            cardNumber: ['', Validators.required,],
            expiryMonth: ['', Validators.required],
            expiryYear: ['', Validators.required],
            cvv: ['', Validators.required],


        });
        this.Banking_Form = this.formBuilder.group({
            type: ['', Validators.required],
            name: ['', Validators.required],
            routingNumber: ['', Validators.required],
            reenterRouting: ['', Validators.required],
            accountNumber: ['', Validators.required],
            reenterAccount: ['', Validators.required],
            addresscheckbox: [''],

        });
        this.shipping_address_form = this.formBuilder.group({

            address1: [''],
            address2: [''],
            city: [''],
            state: [''],
            recurringBillingDate: [''], //, Validators.required
            recurringBillingDay: ['', Validators.required],
            effectiveStartDate: ['', Validators.required],
            zip: ['']
        })

        //this.calculateEffectiveDates();
        //this.generateBillingDates();
        const now = new Date();
        this.billing_date = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());

        this.family_id = this.route.snapshot.paramMap.get('id');
        // this.maxDate = new Date(now);
        // this.maxDate.setDate(now.getDate() + 60);
        // this.minDate = now;
        const today = new Date();
        const lastDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate(); // Get the last day of the current month
        this.billingMinDate = today;
        this.billingMaxDate = today; // Set max date to 25th of current month
        this.recurringMinDate = today;
        this.billingDate = new Date();
        this.recurringMaxDate = new Date(today.getFullYear(), today.getMonth(), 25); // Set max date to 25th of current month

        if (today.getDate() > 25) {
            // If today is after the 25th, set the min and max dates for the Recurring Billing Date calendar to April 1st to 25th
            const nextMonth = today.getMonth() + 2; // Add 2 to get to the next month (February is 1, so March is 2)
            const nextYear = today.getFullYear();
            this.recurringMinDate = new Date(nextYear, nextMonth, 1);
            this.recurringMaxDate = new Date(nextYear, nextMonth, 25);
        }

        this.subscriptions.push(this.service.clientData.subscribe((data) => {
                if (data != '') {
                    this.companyId = data.company_id;
                    this.route_company_name = data.route_company;
                    this.getUSstates();
                    this.getBillingDates();
                }
            })
        )
    }

    ngOnInit(): void {

        this.shippingAddressControl.valueChanges.subscribe(value => {

            if (!value) {
                this.shipping_address_form.controls.address1.setValidators(Validators.required);
                this.shipping_address_form.controls.address1.updateValueAndValidity({emitEvent: false});

                this.shipping_address_form.controls.city.setValidators(Validators.required);
                this.shipping_address_form.controls.city.updateValueAndValidity({emitEvent: false});

                this.shipping_address_form.controls.state.setValidators(Validators.required);
                this.shipping_address_form.controls.state.updateValueAndValidity({emitEvent: false});

                this.shipping_address_form.controls.zip.setValidators([Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{5}$")]);
                this.shipping_address_form.controls.zip.updateValueAndValidity({emitEvent: false});
            } else {
                this.shipping_address_form.controls.address1.clearValidators(Validators.required);
                this.shipping_address_form.controls.address1.updateValueAndValidity({emitEvent: false});

                this.shipping_address_form.controls.city.clearValidators(Validators.required);
                this.shipping_address_form.controls.city.updateValueAndValidity({emitEvent: false});

                this.shipping_address_form.controls.state.clearValidators(Validators.required);
                this.shipping_address_form.controls.state.updateValueAndValidity({emitEvent: false});

                this.shipping_address_form.controls.zip.clearValidators([Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{5}$")]);
                this.shipping_address_form.controls.zip.updateValueAndValidity({emitEvent: false});
            }

            this.onSelectBillingDate();

        });
        let primaryInfo = localStorage.getItem('cartdetails');
        const storedBillingDate = localStorage.getItem('billingDate');
        const storedrecurringDate = localStorage.getItem('recurringBillingDate');
        if (primaryInfo != null) {
            let userDetails = JSON.parse(primaryInfo);
            let userInfo = userDetails.member_personal_data.filter(x => x['relation'] == "Primary");
            this.Credit_Form.controls.name.setValue(userInfo[0].firstname + ' ' + userInfo[0].lastname);
            this.Banking_Form.controls.name.setValue(userInfo[0].firstname + ' ' + userInfo[0].lastname);
        }

        if (storedBillingDate !== null) {
            const parsedDate = new Date(storedBillingDate);
            if (!isNaN(parsedDate.getTime())) { // Check if the parsed date is valid
                this.billingDate = parsedDate;
            } else {
                this.billingDate = new Date();
            }
        } else {
            this.billingDate = new Date();
        }

        if (storedrecurringDate !== null) {

            const storedDate = storedrecurringDate;
            const dateStringWithoutQuotes = storedDate.replace(/"/g, '');

            const year = Number(dateStringWithoutQuotes.substring(0, 4));
            const month = Number(dateStringWithoutQuotes.substring(5, 7)) - 1
            const day = Number(dateStringWithoutQuotes.substring(8, 10));
            const hours = Number(dateStringWithoutQuotes.substring(11, 13));
            const minutes = Number(dateStringWithoutQuotes.substring(14, 16));
            const seconds = Number(dateStringWithoutQuotes.substring(17, 19));
            const milliseconds = Number(dateStringWithoutQuotes.substring(20, 23));
            const parsedDate = new Date(year, month, day, hours, minutes, seconds, milliseconds);
            this.recurringBillingDate = parsedDate;

        } else {
            this.recurringBillingDate = new Date();
        }

        var element = localStorage.getItem('from_Beneficiary');
        if (element == 'Beneficiary') {
            this.previousEnrollment();
        }
        this.paymentType.value.type = 'credit';
        this.choosenPayemntType = 'credit'

        // Get Agent info from localStorage
        if (localStorage.getItem('agentInfo')) {
            this.agentInfo = JSON.parse(localStorage.getItem('agentInfo'));
        }

        // Get recurring state/end dates lmit from localStorage
        if (localStorage.getItem('recurring_start_date')) {
            this.RecurringStartDate = localStorage.getItem('recurring_start_date');
        }

        if (localStorage.getItem('recurring_end_date')) {
            this.RecurringEndDate = localStorage.getItem('recurring_end_date');
        }
    }

    previousEnrollment() {
        var url = 'GetBeneficiaryInfoD2C/' + this.companyId + '/' + this.family_id
        this.service.getData(url).subscribe((Object: any) => {
            this.orderDetails = Object;
            if (Object.status) {
                if (Object.payment_info.payment_type == "BANK") {
                    this.paymentType.controls['type'].setValue("ach");
                    (<HTMLInputElement>document.getElementById("paymentach")).click();
                    this.Banking_Form.controls.routingNumber.setValue(Object.payment_info.routing_number);
                    this.Banking_Form.controls.reenterRouting.setValue(Object.payment_info.routing_number);
                    this.Banking_Form.controls.accountNumber.setValue(Object.payment_info.account_number);
                    this.Banking_Form.controls.reenterAccount.setValue(Object.payment_info.account_number);
                    this.Banking_Form.controls.type.setValue(Object.payment_info.account_type);

                } else {
                    let number = Object.payment_info.exp;
                    let [mm, yy] = `${number}`.match(/(\d{2})(\d{2})/).slice(1).map(Number);
                    this.Credit_Form.controls.cardNumber.setValue(Object.payment_info.card);
                    this.Credit_Form.controls.expiryMonth.setValue(mm);
                    this.Credit_Form.controls.expiryYear.setValue(yy);
                }

                // Payment Date
                if (this.orderDetails?.order_details?.payment_date) {
                    let d = new Date(this.orderDetails?.order_details?.payment_date);
                    let year = d.getFullYear();
                    let month = d.getMonth();
                    let day = d.getDate();
                    let pd = new Date(year, month, day);
                    this.selectedBillingDate = this.datePipe.transform(pd, 'mediumDate');
                }

                // Recurring Billing Date
                if (this.orderDetails?.order_details?.id) {
                    this.shipping_address_form.controls.recurringBillingDay.setValue(this.orderDetails?.order_details?.recurring_billing_day);
                }

                // Effective start date
                if (this.orderDetails?.order_details?.effective_start_date) {

                    /*let d = new Date(this.orderDetails?.order_details?.effective_start_date);
                    let year = d.getFullYear();
                    let month = d.getMonth();
                    let day = d.getDate();
                    let esd = new Date(year, month, day);
                    this.selectedEffectiveStartDate = esd.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });*/

                    this.selectedEffectiveStartDate = this.orderDetails?.order_details?.effective_start_date;
                    if (this.selectedEffectiveStartDate == this.effectiveDate1)
                        this.updateEffectiveStartDate(1);
                    else
                        this.updateEffectiveStartDate(2);
                }
            }
        });
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    getUSstates() {
        var url = 'ListUsaStatesACC';
        this.service.getData(url).subscribe((Object: any) => {
            if (Object !== null) {
                this.usaStates = Object.data;
            }
        }, err => {
            console.log(err);
            this.toastr.error(err.error.message);
        })
    }

    getBillingDates() {
        var url = 'GetBillingDates/' + this.companyId;
        this.service.getData(url).subscribe((Object: any) => {
            if (Object !== null) {
                // Billing Dates
                this.billingDates = Object.billing_dates;
                if (this.billingDates.length) {
                    this.selectedBillingDate = this.billingDates[0];

                    // Set the billing date
                    let d = new Date(this.billingDates[0]);
                    let year = d.getFullYear();
                    let month = d.getMonth();
                    let day = d.getDate();
                    this.billing_date = new Date(year, month, day);
                }

                // Effective Start Dates
                let eDates = Object.effective_start_dates;
                if (eDates.length > 1) {
                    this.effectiveDate1 = eDates[0];
                    this.effectiveDate2 = eDates[1];
                } else {
                    this.effectiveDate1 = eDates[0];

                    // Update the effective start date as selected, if only one date is available
                    this.updateEffectiveStartDate(1);
                }

                this.RecurringDates = Object.recurring_dates;
                this.shipping_address_form.controls.recurringBillingDay.setValue(Object.recurring_payment_day);
            }
        }, err => {
            console.log(err);
            this.toastr.error(err.error.message);
        })
    }

    checkIfActiveState(val) {
    }

    removeSpaces(event: ClipboardEvent) {
        event.preventDefault();
        const pastedText = event.clipboardData?.getData('text/plain').replace(/\s/g, '');
        document.execCommand('insertText', false, pastedText);
    }

    onKeyup(item) {
        let ccnum = item.replace(/\s/g, "");
        // this.checkCardValid=false;
        var ccCheckRegExp = /[^\d\s-]/;
        var isValid = !ccCheckRegExp.test(ccnum);
        var i;
        if (isValid) {
            var cardNumbersOnly = ccnum.replace(/[\s-]/g, "");
            var cardNumberLength = cardNumbersOnly.length;
            var arrCheckTypes = ['visa', 'mastercard', 'amex', 'discover', 'dinners', 'jcb'];
            for (i = 0; i < arrCheckTypes.length; i++) {
                var lengthIsValid = false;
                var prefixIsValid = false;
                var prefixRegExp;
                switch (arrCheckTypes[i]) {
                    case "mastercard":
                        lengthIsValid = (cardNumberLength === 16);
                        prefixRegExp = /5[1-5][0-9]|(2(?:2[2-9][^0]|2[3-9]|[3-6]|22[1-9]|7[0-1]|72[0]))\d*/;
                        this.cardType = arrCheckTypes[i]
                        break;

                    case "visa":
                        lengthIsValid = (cardNumberLength === 16 || cardNumberLength === 13);
                        prefixRegExp = /^4/;
                        this.cardType = arrCheckTypes[i]
                        break;

                    case "amex":
                        lengthIsValid = (cardNumberLength === 15);
                        prefixRegExp = /^3([47])/;
                        this.cardType = arrCheckTypes[i]
                        break;

                    case "discover":
                        lengthIsValid = (cardNumberLength === 15 || cardNumberLength === 16);
                        prefixRegExp = /^(6011|5)/;
                        this.cardType = arrCheckTypes[i]
                        break;

                    case "dinners":
                        lengthIsValid = (cardNumberLength === 14);
                        prefixRegExp = /^(300|301|302|303|304|305|36|38)/;
                        break;

                    case "jcb":
                        lengthIsValid = (cardNumberLength === 15 || cardNumberLength === 16);
                        prefixRegExp = /^(2131|1800|35)/;
                        break;

                    default:
                        prefixRegExp = /^$/;
                }
                prefixIsValid = prefixRegExp.test(cardNumbersOnly);
                isValid = prefixIsValid && lengthIsValid;
                // Check if we found a correct one
                if (isValid) {
                    this.notValidCardNumber = false;
                    break;
                }
            }
        }

        if (!isValid) {
            this.notValidCardNumber = true;
            return false;
        }

        // Remove all dashes for the checksum checks to eliminate negative numbers
        ccnum = ccnum.replace(/[\s-]/g, "");
        // Checksum ("Mod 10")
        // Add even digits in even length strings or odd digits in odd length strings.
        var checksum = 0;
        for (i = (2 - (ccnum.length % 2)); i <= ccnum.length; i += 2) {
            checksum += parseInt(ccnum.charAt(i - 1));
        }
        // Analyze odd digits in even length strings or even digits in odd length strings.
        for (i = (ccnum.length % 2) + 1; i < ccnum.length; i += 2) {
            var digit = parseInt(ccnum.charAt(i - 1)) * 2;
            if (digit < 10) {
                checksum += digit;
            } else {
                checksum += (digit - 9);
            }
        }
        if ((checksum % 10) === 0) {
            this.checkCardValid = true;
        }
        return (checksum % 10) === 0;
    }

    onPrevious() {
        this.router.navigateByUrl('/products');
        // if (this.location.path().includes('d2c')) {
        //   this.router.navigateByUrl(this.route_company_name + '/d2c/memberproduct');
        // }
        // if (this.location.path().includes('acc')) {
        //   this.router.navigateByUrl(this.route_company_name + '/acc/memberproduct');
        // }
        localStorage.setItem('from_payment', 'Payment Details')
    }

    Next() {
        this.cvvErr = undefined;
        this.card_number_Err = undefined;
        this.exp_date_err = undefined;
        let expiryMonth = this.Credit_Form.value.expiryMonth.toString();
        let expiryYear = this.Credit_Form.value.expiryYear.toString();
        let exp_date_card = expiryMonth + expiryYear;
        this.obj = {
            primary_member_id: localStorage.getItem("primary_member_id"),
            cart_id: localStorage.getItem("cart_id"),
            payment_option: "CARD",
            payment_date: this.datePipe.transform(this.billing_date, "yyyy-MM-dd"),
            card_type: this.paymentType.value.type,
            card_number: this.Credit_Form.value.cardNumber,
            exp_date: exp_date_card,
            cvv: this.Credit_Form.value.cvv,
            name_on_card: this.Credit_Form.value.name,
            company_id: this.companyId,
            recurring_billing_date: this.shipping_address_form.value.recurringBillingDay,
            effective_start_date: this.datePipe.transform(this.shipping_address_form.value.effectiveStartDate, "yyyy-MM-dd"),
        }

        if (this.choosenPayemntType == "credit" || this.choosenPayemntType == "debit") {
            if (!this.isShippingChecked) {
                this.obj['billing_as_shipping'] = 'N'
                this.obj['billing_address1'] = this.shipping_address_form.value.address1
                this.obj['billing_address2'] = this.shipping_address_form.value.address2
                this.obj['billing_city'] = this.shipping_address_form.value.city
                this.obj['billing_state'] = this.shipping_address_form.value.state
                this.obj['billing_zip'] = this.shipping_address_form.value.zip
            }
            if (this.isShippingChecked) {
                let cartDetails = localStorage.getItem("cartdetails");
                let parsedcartdetails = JSON.parse(cartDetails);
                parsedcartdetails.member_personal_data.forEach(ele => {

                    this.obj['billing_as_shipping'] = 'Y'
                    this.obj['billing_address1'] = ele.address1,
                        this.obj['billing_address2'] = ele.address2
                    this.obj['billing_city'] = ele.city
                    this.obj['billing_state'] = ele.state
                    this.obj['billing_zip'] = ele.zip
                });
            }


        } else {
            this.obj = {
                primary_member_id: localStorage.getItem("primary_member_id"),
                cart_id: localStorage.getItem("cart_id"),
                payment_option: "BANK",
                payment_date: this.datePipe.transform(this.billing_date, "yyyy-MM-dd"),
                routing_number: this.Banking_Form.value.routingNumber,
                account_number: this.Banking_Form.value.accountNumber,
                account_type: this.Banking_Form.value.type,
                name_on_card: this.Banking_Form.value.name,
                company_id: this.companyId,
                recurring_billing_date: this.shipping_address_form.value.recurringBillingDay,
                effective_start_date: this.datePipe.transform(this.shipping_address_form.value.effectiveStartDate, "yyyy-MM-dd"),
            }
            if (!this.isShippingChecked) {
                this.obj['billing_as_shipping'] = 'N'
                this.obj['billing_address1'] = this.shipping_address_form.value.address1
                this.obj['billing_address2'] = this.shipping_address_form.value.address2
                this.obj['billing_city'] = this.shipping_address_form.value.city
                this.obj['billing_state'] = this.shipping_address_form.value.state
                this.obj['billing_zip'] = this.shipping_address_form.value.zip
            }
            if (this.isShippingChecked) {
                let cartDetails = localStorage.getItem("cartdetails");
                let parsedcartdetails = JSON.parse(cartDetails);
                this.obj['billing_as_shipping'] = 'Y'
                this.obj['billing_address1'] = this.shipping_address_form.value.address1,
                    this.obj['billing_address2'] = this.shipping_address_form.value.address2,
                    this.obj['billing_city'] = this.shipping_address_form.value.city,
                    this.obj['billing_state'] = this.shipping_address_form.value.state,
                    this.obj['billing_zip'] = this.shipping_address_form.value.zip,
                    delete this.obj.name_on_card;
                this.obj['name_on_account'] = this.Credit_Form.value.name

                // parsedcartdetails.member_personal_data.forEach(ele => {
                //   this.obj['billing_as_shipping'] = 'Y'
                //     this.Credit_Form.controls.Banking_Form.setValue(ele['billing_address1']);
                //     this.Credit_Form.controls.Banking_Form.setValue(ele['billing_city']);
                //     this.Credit_Form.controls.Banking_Form.setValue(ele['billing_zip']);
                //     this.Credit_Form.controls.Banking_Form.setValue(ele['billing_state']);
                //      });
            }

        }
        if (this.orderDetails?.order_details?.id != undefined) {
            this.obj.order_id = this.orderDetails?.order_details?.id;
        }
        if (this.paymentType.controls['type'].value == "credit" || this.paymentType.controls['type'].value == "debit") {
            // this.selectedform = "Credit_Form";
            this.submitCardAttempt = true;
        }
        if (this.paymentType.controls['type'].value == "ach" || this.paymentType.controls['type'].value == "credit" || this.paymentType.controls['type'].value == "debit") {
            this.submitCardAttempt = true;
            this.selectedform = "Banking_Form";
            this.Banking_Form.get('name').markAsTouched();
            this.Banking_Form.get('routingNumber').markAsTouched();
            this.Banking_Form.get('reenterRouting').markAsTouched();

            this.Banking_Form.get('accountNumber').markAsTouched();

            this.Banking_Form.get('reenterAccount').markAsTouched();
            this.Banking_Form.get('type').markAsTouched();

        }
        let proceedToCallAPI = false;
        if (this.obj.payment_option === 'CARD') {
            let cvverror = (<HTMLInputElement>document.getElementById("cvverror")).innerHTML;
            if ((this.isShippingChecked || (!this.isShippingChecked && this.shipping_address_form.valid)) && this.Credit_Form.valid && (!this.notValidCardNumber) && (!this.enterValidMonth && !this.invalidMonthEnteredValidation && this.enterValidYear) && !this.enterTwoDigitMonth && cvverror == "") {
                proceedToCallAPI = true;
            } else {
                proceedToCallAPI = false;
            }
        } else {
            if ((this.isShippingChecked || (!this.isShippingChecked && this.shipping_address_form.valid)) && this.Banking_Form.valid && (!this.routingNumberMismatch && !this.accountNumberMismatch)) {
                proceedToCallAPI = true;
            } else {
                proceedToCallAPI = false;
            }
        }
        if (!proceedToCallAPI) {
            this.loader = false;
            return;
        }
        let url = "CreateMemberPaymentMethod";

        if ((this.Credit_Form.valid || this.Banking_Form.valid) && this.shipping_address_form.valid) {
            this.service.postData(url, this.obj).subscribe(
                (Object: any) => {
                    if (Object.status) {
                        this.router.navigate(['/beneficiary', this.routeMemberId, this.family_id]);
                        // if (this.location.path().includes('d2c')) {
                        //   this.router.navigate([this.route_company_name + '/d2c/memberbeneficiary', this.routeMemberId, this.family_id]);
                        // }
                        // if (this.location.path().includes('acc')) {
                        //   this.router.navigate([this.route_company_name + '/acc/memberbeneficiary', this.routeMemberId, this.family_id]);
                        // }
                    }
                }, err => {

                    this.loader = false;
                    this.toastr.error(err.error.message);
                    if (err.error?.errors?.cvv) {
                        this.cvvErr = err.error?.errors?.cvv
                    }
                    if (err.error?.errors?.card_number) {
                        this.card_number_Err = err.error?.errors?.card_number
                    }
                    if (err.error?.errors?.exp_date) {
                        this.exp_date_err = err.error?.errors?.exp_date
                    }
                })
        }


    }


    oncvvfocusout() {
        if (this.cardType != '') {
            if (this.cardType == "visa" || this.cardType == "mastercard") {
                // this.cvvLength = 3;
                this.value = (<HTMLInputElement>document.getElementById("cvvvalue")).value;
                if (this.value.length > 3) {
                    (<HTMLInputElement>document.getElementById("cvverror")).innerHTML = "Please enter valid 3 digit cvv number";
                }
                if (this.value.length <= 3) {
                    (<HTMLInputElement>document.getElementById("cvverror")).innerHTML = "";
                }
                //  this.objectService.pushcardType(this.cardType)
                // this.objectService.pushcardCheckCondition(this.checkCardValid)
            } else if (this.cardType == "amex") {
                this.value = (<HTMLInputElement>document.getElementById("cvvvalue")).value;
                if (this.value.length > 4) {
                    (<HTMLInputElement>document.getElementById("cvverror")).innerHTML = "Please enter valid cvv number";
                }
                if (this.value.length <= 4) {
                    (<HTMLInputElement>document.getElementById("cvverror")).innerHTML = "";
                }
                //  this.objectService.pushcardType(this.cardType)
                // this.objectService.pushcardCheckCondition(this.checkCardValid)
            }
        }
    }

    onCheckboxChange() {
        let cartDetails = localStorage.getItem("cartdetails");
        let parsedcartdetails = JSON.parse(cartDetails);
        if (this.isShippingChecked) {
            parsedcartdetails.member_personal_data.forEach(ele => {
                this.Credit_Form.controls.billing_address1.setValue(ele['billing_address1']);
                this.Credit_Form.controls.billing_city.setValue(ele['billing_city']);
                this.Credit_Form.controls.billing_zip.setValue(ele['billing_zip']);
                this.Credit_Form.controls.billing_state.setValue(ele['billing_state']);
            });
        }
    }

    onKeyUpEnterRoutingNumber(value) {
        this.enteredRoutingNumber = value;
        if (!this.routingNumberMismatch) {
            if (this.enteredRoutingNumber != this.reEnteredRoutingNumber) {
                this.routingNumberMismatch = true;
            } else {
                this.routingNumberMismatch = false;
            }
        }
        if (this.routingNumberMismatch) {
            if (this.enteredRoutingNumber == this.reEnteredRoutingNumber) {
                this.routingNumberMismatch = false;
            } else {
                this.routingNumberMismatch = true;
            }
        }
    }

    onKeyUpRoutingNumberMismatch(value) {
        this.reEnteredRoutingNumber = value;
        if (this.enteredRoutingNumber == value) {
            this.routingNumberMismatch = false;
        } else {
            this.routingNumberMismatch = true;
        }
        if (this.routing_number) {
            if (this.routing_number == value || this.enteredRoutingNumber == value) {
                this.routingNumberMismatch = false;
            } else {
                this.routingNumberMismatch = true;
            }
        }
    }

    onKeyUpEnterAccountNumber(value) {
        this.enteredAccountNumber = value;
        if (!this.accountNumberMismatch) {
            if (this.enteredAccountNumber != this.reEnteredAccountNumber) {
                this.accountNumberMismatch = true;
            } else {
                this.accountNumberMismatch = false;
            }
        }
        if (this.accountNumberMismatch) {
            if (this.enteredAccountNumber == this.reEnteredAccountNumber) {
                this.accountNumberMismatch = false;
            } else {
                this.accountNumberMismatch = true;
            }
        }
    }

    onKeyUpAccountNumberMismatch(value) {
        this.reEnteredAccountNumber = value;
        if (this.enteredAccountNumber == value) {
            this.accountNumberMismatch = false;
        } else {
            this.accountNumberMismatch = true;
        }
        if (this.account_number) {
            if (this.account_number == value || this.enteredAccountNumber == value) {
                this.accountNumberMismatch = false;
            } else {
                this.accountNumberMismatch = true;
            }
        }
    }

    onKeyupExpiryMonth(input) {
        if (input.length == 1) {
            this.enterTwoDigitMonth = true;
        }
        if (input.length == 2) {
            this.enterTwoDigitMonth = false;
        }
        this.enterValidMonth = false;
        //   let enteredYear = this.cartService.getYear();
        // if(input.length == 2  )
        // {
        var ccCheckRegExp = /^(0?[1-9]|1[012])$/;
        var isValid = ccCheckRegExp.test(input);
        this.enteredMonth = input;
        var currentDate = new Date();
        var cuurentMonth = currentDate.getMonth() + 1;
        var f = currentDate.getFullYear();
        const dateString = f.toString();
        const currentYear = dateString.substring(dateString.length - 2);
        if (!isValid) {
            this.enterValidMonth = true;
        }
    }

    onKeyupExpiryYear(input) {
        this.enterValidYear = false;
        var currentDate = new Date(); //this returns the current datetime
        var f = currentDate.getFullYear();
        const dateString = f.toString();
        const year = dateString.substring(dateString.length - 2);
        //this.cartService.pushYear(input);
        if (input >= year) {
            this.enterValidYear = true;
        }
        if (input == year) {
            var m = currentDate.getMonth() + 1;
            if (this.enteredMonth >= m) {
                this.invalidMonthEnteredValidation = false;
            } else {
                this.invalidMonthEnteredValidation = true;
            }
        }
        if (input > year) {
            this.invalidMonthEnteredValidation = false;
        }
    }

    onSelectBillingDate(): void {
        if (this.billingDate) {
            // Check if Billing Date is after the 25th
            if (this.billingDate.getDate() > 25) {
                // If yes, set the min and max dates for the Recurring Billing Date calendar to April 1st to 25th
                const nextMonth = this.billingDate.getMonth() + 1; // Add 2 to get to the next month (February is 1, so March is 2)
                const nextYear = this.billingDate.getFullYear();
                this.recurringMinDate = new Date(nextYear, nextMonth, 1);
                this.recurringMaxDate = new Date(nextYear, nextMonth, 25);
            } else {
                // If not, set the min and max dates back to the default (current month's 1st to 25th)
                const currentMonth = this.billingDate.getMonth() + 1;
                const currentYear = this.billingDate.getFullYear();
                this.recurringMinDate = new Date(currentYear, currentMonth, 1);
                this.recurringMaxDate = new Date(currentYear, currentMonth, 25);
            }

            // If the Recurring Billing Date is selected and it's not within the valid range, reset it
            if (this.recurringBillingDate &&
                (this.recurringBillingDate < this.recurringMinDate || this.recurringBillingDate > this.recurringMaxDate)) {
                this.recurringBillingDate = null;
            }

            if (this.billingDate instanceof Date && !isNaN(this.billingDate.getTime())) {
                localStorage.setItem("billingDate", JSON.stringify(this.billingDate));
            } else {
                console.error("Invalid billingDate:", this.billingDate);
            }

        }
    }

    chageRecurringDate() {
        //localStorage.setItem("recurringBillingDay", this.shipping_address_form.value.recurringBillingDay);
    }

    changeBillingDate(date) {
        let d = new Date(date);

        // Extract the components
        let year = d.getFullYear();
        let month = d.getMonth();
        let day = d.getDate();

        // Create a new date object using the extracted components
        this.billing_date = new Date(year, month, day);
    }

    updateEffectiveStartDate(opt) {
        let date = this.effectiveDate1;
        if (opt == 2) {
            date = this.effectiveDate2;
        }

        let d = new Date(date);

        // Extract the components
        let year = d.getFullYear();
        let month = d.getMonth();
        let day = d.getDate();
        let esDate = new Date(year, month, day);
        this.shipping_address_form.controls.effectiveStartDate.setValue(esDate);
    }

    onSelectrecurringBillingDate(): void {
        if (this.recurringBillingDate instanceof Date && !isNaN(this.recurringBillingDate.getTime())) {
            const dateToStore = new Date(this.recurringBillingDate);
            dateToStore.setDate(dateToStore.getDate() + 1);
            localStorage.setItem("recurringBillingDate", JSON.stringify(dateToStore.toISOString()));
        } else {
            console.error("Invalid recurringBillingDate:", this.recurringBillingDate);
        }
    }

    /*calculateEffectiveDates(){
      let today = new Date();
      let currentMonth = today.getMonth();

      // Set the threshold day (the day of the month when we switch to the next month)
      let thresholdDay = 25;

      // Determine the threshold month based on the current date and threshold day
      let thresholdMonth = currentMonth;
      if (today.getDate() >= thresholdDay) {
          // If the current date is on or after the threshold day, increment the month
          thresholdMonth++;
      }

      // Check if the threshold month is December (11)
      // If yes, reset it to January (0) for the next year
      if (thresholdMonth === 12) {
          thresholdMonth = 0;
      }

      // Define the number of upcoming months to consider
      let numberOfUpcomingMonths = (today.getDate() < thresholdDay && currentMonth === thresholdMonth) ? 2 : 1;

      // Get the upcoming dates
      for (let i = 1; i <= numberOfUpcomingMonths; i++) {
          let upcomingMonth = (thresholdMonth + i) % 12; // Calculate the next month index (taking care of wrapping to the next year)
          let upcomingDate = new Date(today.getFullYear(), upcomingMonth, 1); // 1st day of the upcoming month
          let desiredDate = upcomingDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });

          if (i === 1){
            this.effectiveDate1 = desiredDate;
          }
          else
            this.effectiveDate2 = desiredDate;
      }

      // Update the effective start date as selected, if only one date is available
      if(numberOfUpcomingMonths == 1) {
        this.updateEffectiveStartDate(1);
      }
    }

    generateBillingDates(){
      let today = new Date();
      let currentDay = today.getDate();
      let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
      let endDay = Math.min(25, lastDayOfMonth); // Limit end day to the 25th of the month
      this.billingDates = Array.from({ length: endDay - currentDay + 1 }, (_, index) => {
        const date = new Date(today.getFullYear(), today.getMonth(), currentDay + index);
        return this.datePipe.transform(date, 'mediumDate');
      });
      this.selectedBillingDate = this.billingDates[0];
    }*/

}


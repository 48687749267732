<!--Agent Info Section-->
<div class="container">
    <div class="enrlmnt-demographic">
        <div class="stepper">
            <div class="step active"></div>
            <div class="step active"></div>
            <div class="step active"></div>
        </div>
    </div>
    
    <div class="download_text">
        <span (click)="downloadSectionAsPDF()">Download your copy </span>
    </div>

    <div #orderInvoice> 
    <div class="column col-lg-12" id="content" *ngIf="orderDetails !==undefined">
        <div class="confirmation">
            <div class="justify-content-center ">
                <i class="ri-check-fill successIcon "></i>  <span class="payment_margin"> Thank you for your payment</span>
            </div>
            <h5>You will receive a welcome email to access your member portal within
                24 hours.
            </h5>
        </div>
        <div class="my-invoices" id="orderslist">
            <div class="inner-container clearfix" id="invoice"
                style=" background-color: white !important;padding: 4px;">
                <div class="container-fluid pdf_cs">
                    <div class="row">
                        <div class="col-lg-10">
                            <h2 *ngFor="let item of orderDetails">Hi, {{item.acknowledge_by}}</h2>
                        </div>

                    </div>
                    <div class="invoice-box">
                        <div class="row">
                            <div class="column  col-md-4 col-sm-12 col-xs-12" style="padding-right: 0;">
                                <div>
                                    <h3>Billed To:</h3>
                                    <ul class="invoice-info" *ngFor="let item of orderDetails">
                                        <li>
                                            {{item.firstname}}
                                            {{item.lastname}}
                                        </li>
                                        <li>
                                            <span class="addres-td">
                                                <span>{{ item.billing_address1 }}, </span>
                                                <span *ngIf="item?.billing_address2"> {{ item.billing_address2 }},
                                                </span>
                                                <span>{{ item.billing_city }}, {{ item.billing_state }}, {{
                                                    item.billing_zip }} </span>
                                            </span>
                                        </li>

                                        <li>
                                            {{item.email}}
                                        </li>
                                        <li>
                                            {{item.mobile}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="column   col-md-5 col-sm-12 col-xs-12" style="padding: 0;">
                                <div *ngFor="let item of orderDetails">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td class="width50percent">
                                                    Order ID
                                                </td>
                                                <td class="width50percent">
                                                    <span class="rightspace">:</span> {{item.order_id}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="width50percent">
                                                    Date & Time
                                                </td>
                                                <td class="width50percent">
                                                    <span class="rightspace">:</span>
                                                    {{item.created_at | date: 'd-MMM-y, h:mm:ss a'}}

                                                </td>
                                            </tr>
                                            <tr *ngIf="paymentData !==undefined">
                                                <td class="width50percent">
                                                    Payment Method
                                                </td>
                                                <td class="width50percent">
                                                    <span class="rightspace">:</span>
                                                    {{orderDetails[0]?.payment_type}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="paymentData[0].payment_type =='CARD' && paymentData !==undefined">
                                                <td class="width50percent">
                                                    Card Number
                                                </td>
                                                <td class="width50percent">
                                                    <span class="rightspace">:</span> {{paymentData[0]?.card}}
                                                </td>
                                            </tr>
                                            <tr
                                                *ngIf="this.paymentData[0]?.payment_type !=='CARD' && paymentData !==undefined">
                                                <td class="width50percent">
                                                    <div>Routing Number</div>
                                                    <div>Account Number</div>
                                                </td>
                                                <td class="width50percent">
                                                    <div>
                                                        <span class="rightspace">:</span> {{paymentData[0]?.routing}}
                                                    </div>
                                                    <div>
                                                        <span class="rightspace">:</span> {{paymentData[0]?.account}}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="paymentData !==undefined">
                                                <td class="width50percent">
                                                    Confirmation Number
                                                </td>
                                                <td>
                                                    <span class="rightspace">:</span>
                                                    {{orderDetails[0]?.confirmation_number}}

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-12 d-none">
                                <p style="margin-bottom: 2px;" *ngFor="let item of orderDetails"> <span
                                        style="font-size: 19px; margin-right: 2%;"> Terms
                                        &
                                        Conditions</span> <span><span>Accepted By :</span>
                                        {{item.acknowledge_by}} </span> </p>
                            </div>
                            <div class="table-responsive " *ngIf="cartProducts.length>0">
                                <table class="table">
                                        <tr class="table_head">
                                            <th style="width: 49%; border-right: 2px solid white">
                                                <strong>Item</strong>
                                            </th>
                                            <th style="width: 50%; text-align: end;"><strong>Price</strong></th>
                                        </tr>
                                    <tbody>
                                        <tr *ngFor="let item of cartProducts">
                                            <td class="font_bold">{{item.product_name}}
                                                <div *ngFor="let member of item.family_info" class="applicantName">
                                                    {{member.firstname | titlecase}}
                                                </div>
                                            </td>
                                            <td style="text-align: end;"> {{item.total_price | currency:'USD'}}
                                            </td>
                                        </tr>
                                        <tr *ngFor="let item of cartFee">
                                            <td class="font_bold">{{item.name}} </td>
                                            <td style="text-align: end;"> {{item.amount | currency:'USD'}}</td>
                                        </tr>
                                        <tr *ngIf="cartData && cartData[0].discount > 0">
                                            <td class="font_bold">Enrollment Fee Discount </td>
                                            <td style="text-align: end;">
                                                -{{ cartData[0]?.discount | currency: "USD" }}
                                            </td>
                                        </tr>

                                        <tr *ngIf="memberDiscount?.length >0">
                                            <td class="font_bold"> Member Discount </td>
                                            <td style="text-align: end;" *ngFor="let item of cartData">
                                                {{item.discount |
                                                currency:'USD'}}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-weight: bold;"> Total Payments Today</td>
                                            <td style="text-align: end; font-weight: bold;"
                                                *ngFor="let item of cartData">
                                                {{item.total | currency:'USD'}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-weight: bold;"> Monthly Contribution</td>
                                            <td style="text-align: end; font-weight: bold;">
                                                {{monthly_contribution | currency: "USD" }}
                                            </td>
                                        </tr>

                                        <tr>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-none"
                                style="padding: 15px 0;font-size: 15px;text-align: center;font-weight: bold;">A copy
                                of your receipt has been emailed to you. You will also receive an email with
                                instructions to set up your Member account access.
                                If these emails do not arrive promptly,
                                please check your spam folder.</div>

                            <div class="card shadow-sm mb-3" *ngIf="agentInfo?.id>0">
                                <div class="card-body">
                                    <h6 class="title-mainhead">
                                        <span>{{agent_text}} Information</span>
                                    </h6>
                        
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="info-box">
                                                <p><b>Name:</b>&nbsp;<span *ngIf="agentInfo.firstname">{{ agentInfo.firstname }}</span> <span
                                                        *ngIf="agentInfo.lastname">{{ agentInfo.lastname }}</span></p>
                                                <p><span *ngIf="agentInfo.email"><b>Email:</b>&nbsp;{{ agentInfo.email }}</span></p>
                                                <p><span *ngIf="agentInfo.mobile"><b>Phone:</b>&nbsp;{{ agentInfo.mobile }}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <div class="row bg-white m-0 p-2 pt-3">
        <div class="col-md-12 text-center">
            <button class="btn btn-primary mt-5" (click)="backToEnrollment()">New
                Enrollment</button>
        </div>
    </div>
</div>
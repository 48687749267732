<div class="container">
    <div class="">
        <div class="enrlmnt-demographic">
            <div class="stepper">
                <div class="step active"></div>
                <div class="step active"></div>
                <div class="step active"></div>
                <div class="step active"></div>
                <div class="step active"></div>
                <div class="step active"></div>
            </div>
        </div>
        <div class="progress_bar">
            <ul class="progess-bar mb-7">
                <li class="active">Enrollment</li>
                <li>Products</li>
                <li>Payment Details</li>
                <li>Beneficiary</li>
                <li>Agreement</li>
                <li>Disclosure 1</li>
                <li>Disclosure 2</li>
                <li>Summary</li>
                <li>Confirmation</li>
            </ul>
        </div>
    </div>

    <div class="my-4 ms-3">
        <app-reusable-applicationform (applicantdata)="handleApplicantData($event)"></app-reusable-applicationform>
    </div>
     
    <div class="row">
        <div class="col-md-12 mb-3">
            <form [formGroup]="newForm" class="primengDateNTable">
                <!--Agent code box, Display once code applied via URL -->
                <div *ngIf="showAgentCodeSection" class="card shadow-sm mb-3">
                    <div class="card-body">
                        <h6 class="title-mainhead">
                            <span>Agent Identifier</span>
                        </h6>

                        <div class="row">
                            <div class="col-md-6 form-group code-field">
                                <label class=""
                                ><span>Agent Code </span
                                ><span class="optional">(Optional)</span></label
                                >
                                <div class="input-group">
                                    <input
                                            (keyup)="showApplyBtn()"
                                            [readonly]="agentCodeParam"
                                            class="form-control marginTop"
                                            formControlName="agentCode"
                                            placeholder="Agent Code"
                                            type="text"
                                    />

                                    <button
                                            *ngIf="isAgentCodeApplied"
                                            class="input-group-text applied"
                                    >
                                        Applied
                                    </button>
                                    <button
                                            (click)="applyAgentCode()"
                                            *ngIf="!isAgentCodeApplied"
                                            class="input-group-text"
                                    >
                                        {{ applyBtnText }}
                                    </button>
                                </div>
                                <small class="errormsg">
                                    {{ agentCodeErrorMsg }}
                                </small>
                                <!--<a *ngIf="isAgentCodeApplied" (click)="removeAgentCode()" href="javascript:void(0)" class="remove-code text-danger">Remove</a>-->
                            </div>
                            <div class="col-md-6">
                                <div *ngIf="agentInfo?.firstname" class="info-box">
                                    <p>
                                        <b>Name:</b>&nbsp;<span *ngIf="agentInfo.firstname">{{
                                            agentInfo.firstname
                                        }}</span>
                                        <span *ngIf="agentInfo.lastname">{{
                                                agentInfo.lastname
                                            }}</span>
                                    </p>
                                    <p>
                    <span *ngIf="agentInfo.email"
                    ><b>Email:</b>&nbsp;{{ agentInfo.email }}</span
                    >
                                    </p>
                                    <p>
                    <span *ngIf="agentInfo.mobile"
                    ><b>Phone:</b>&nbsp;{{ agentInfo.mobile }}</span
                    >
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="Policy_replacement_questions" class="card shadow-sm">
                    <div class="card-body">
                    <h6 class="title-mainhead">
                        <span>Primary Applicant Information</span>
                    </h6>

                    <div class="row">
                        <div class="col-md-3 form-group">
                            <label class=" "
                            ><span>First Name</span> <span class="fieldMan">*</span>
                            </label>
                            <input
                                    class="form-control marginTop"
                                    formControlName="firstname"
                                    maxlength="25"
                                    placeholder="First Name"
                                    type="text"
                            />
                            <small
                                    *ngIf="
                  !newForm.controls['firstname'].valid &&
                  (newForm.controls['firstname'].touched || submitAttempt)
                "
                                    class="errormsg"
                            >
                                First Name is required.
                            </small>
                        </div>

                        <div class="col-md-3 form-group">
                            <label class=" "
                            ><span>Last Name</span> <span class="fieldMan">*</span>
                            </label>
                            <input
                                    class="form-control marginTop"
                                    formControlName="lastname"
                                    maxlength="25"
                                    placeholder="Last Name"
                                    type="text"
                            />
                            <small
                                    *ngIf="
                  !newForm.controls['lastname'].valid &&
                  (newForm.controls['lastname'].touched || submitAttempt)
                "
                                    class="errormsg"
                            >
                                Last Name is required.
                            </small>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class=" "
                            ><span>Email</span> <span class="fieldMan">*</span></label
                            >
                            <input
                                    class="form-control marginTop"
                                    formControlName="email"
                                    placeholder="Email"
                                    type="text"
                            />
                            <small *ngIf="errorMsgShow" class="errormsg">
                                {{ emailError }}
                            </small>
                            <small
                                    *ngIf="
                  !newForm.controls['email'].valid &&
                  (newForm.controls['email'].touched || submitAttempt)
                "
                                    class="errormsg"
                            >
                                Email is required.
                            </small>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class=" "
                            ><span>Phone </span> <span class="fieldMan">*</span></label
                            >
                            <input
                            (focusout)="onleavePhonenumber(newForm.value)"
                                    (keypress)="
                  emailValidation($event.target.value); numberOnly($event)
                "
                                    appPhonenumberValidation
                                    autocomplete="off"
                                    class="form-control marginTop"
                                    formControlName="phone"
                                    id="phone-number"
                                    maxlength="12"
                                    placeholder="Phone"
                                    type="text"
                            />
                            <small *ngIf="errorMsgShow" class="errormsg">
                                {{ phoneerrmsg }}</small
                            >
                            <small
                                    *ngIf="
                  !newForm.controls['phone'].valid &&
                  (newForm.controls['phone'].touched || submitAttempt)
                "
                                    class="errormsg"
                            >
                                Phone is required.
                            </small>
                            <small class="errormsg">
                                {{ phnumbererrmsg }}
                            </small>
                        </div>
                    </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-8 form-group">
                                        <label class=""
                                        >DOB
                                            <span class="fieldMan">*</span> (MM/DD/YYYY)</label
                                        >
                                        <p-calendar
                                                (onInput)="onSearchChangeT(newForm.value)"
                                                (onSelect)="onSearchChange($event)"
                                                [disabledDates]="invalidDates1"
                                                [maxDate]="minimumDate"
                                                [monthNavigator]="true"
                                                [showIcon]="true"
                                                [style]="{
                  width: '100%',
                  display: 'inline-flex',
                  height: '52px'
                }"
                                                [yearNavigator]="true"
                                                [yearRange]="'1952:' + maxDate.getFullYear()"
                                                formControlName="dob"
                                                inputId="navigators"
                                                inputId="icon"
                                                placeholder="mm/dd/yyyy"
                                        >
                                        </p-calendar>
                                        <small
                                                *ngIf="
                  !newForm.controls['dob'].valid &&
                  (newForm.controls['dob'].touched || submitAttempt)
                "
                                                class="errormsg"
                                                style="margin-top: 10px"
                                        >
                                            DOB is required
                                        </small>
                                        <small class="errormsg mt-2">{{ parentAge }}</small>
                                        <small class="errormsg mt-2">{{ parentAge1 }}</small>
                                        <br/>
                                        <small class="text_info mb-0">
                                            <i
                                                    class="fa fa-question-circle-o"
                                                    data-bs-placement="top"
                                                    data-bs-toggle="tooltip"
                                                    title="Age is automatically calculated from the DOB."
                                            ></i>
                                            Age is automatically calculated from the DOB.
                                        </small>
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <label class="alignCenter">Age</label>
                                        <input
                                                [readonly]="true"
                                                class="form-control"
                                                formControlName="age"
                                                placeholder="0"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="d-block"
                                >Gender <span class="fieldMan">*</span></label
                                >
                                <div class="form-check form-check-inline mt-2">
                                    <input
                                            class="form-check-input"
                                            formControlName="gender"
                                            type="radio"
                                            value="Male"
                                    />
                                    <label class="form-check-label"> Male</label>
                                </div>
                                <div class="form-check form-check-inline mt-2">
                                    <input
                                            class="form-check-input"
                                            formControlName="gender"
                                            type="radio"
                                            value="Female"
                                    />
                                    <label class="form-check-label"> Female</label>
                                </div>
                                <!-- <div class="form-check form-check-inline mt-2">
                                                  <input class="form-check-input" type="radio" value="Unspecified"
                                                      formControlName="gender">
                                                  <label class="form-check-label"> Unspecified</label>
                                              </div> -->

                                <small
                                        *ngIf="
              !newForm.controls['gender'].valid &&
              (newForm.controls['gender'].touched || submitAttempt)
            "
                                        class="errormsg"
                                >
                                    Gender is required
                                </small>
                            </div>

                            <div class="col-md-3 form-group">
                                <label class="">SSN <span class="fieldMan">*</span></label>
                                <input
                                        autocomplete="off"
                                        class="form-control marginTop"
                                        formControlName="ssn"
                                        id="social-security"
                                        maxlength="11"
                                        numbersOnly
                                        placeholder="Social Security Number"
                                        socialSecurity
                                        type="text"
                                />
                                <!-- <span
                                  class="errormsg"
                                  *ngIf="newForm.get('ssn').errors?.['invalidssn'] && (newForm.get('ssn').dirty || newForm.get('ssn').touched )"
                                > -->
                                <span
                                        *ngIf="
            !newForm.controls['ssn'].valid &&
            (newForm.controls['ssn'].touched ||
              submitAttempt)
          "
                                        class="errormsg"
                                >
            Please enter valid 9 digit SSN number
          </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h6 class="title-head">
                                    <span>Answer the below Questions:</span>
                                </h6>
                            </div>
                            <div class="col-md-1">
                                <p class="question-lable">Question 1:</p>
                            </div>
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label class="d-block"
                                    >Has the employee used tobacco in the last 12 months?
                                        <span class="fieldMan">*</span></label
                                    >
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <div class="form-check form-check-inline mt-2">
                                        <input
                                                class="form-check-input"
                                                formControlName="tobacco"
                                                type="radio"
                                                value="yes"
                                        />
                                        <label class="form-check-label"> Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline mt-2">
                                        <input
                                                class="form-check-input"
                                                formControlName="tobacco"
                                                type="radio"
                                                value="no"
                                        />
                                        <label class="form-check-label"> No</label>
                                    </div>
                                    <small
                                            *ngIf="
                !newForm.controls['tobacco'].valid &&
                (newForm.controls['tobacco'].touched ||
                  submitAttempt)
              "
                                            class="errormsg"
                                    >
                                        This field is required
                                    </small>
                                </div>
                            </div>

                            <div class="col-md-1">
                                <p class="question-lable">Question 2:</p>
                            </div>
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label class="d-block"
                                    >I certify that I am actively at work now, for wage or
                                        profit, and I have worked at least 20 hours each week
                                        performing all duties of my regular occupation at my
                                        regular place of employment for at least the last 3
                                        months except for minor illness or injury of 1 week or
                                        less, or normal pregnancy.<span class="fieldMan"
                                        >*</span
                                        ></label
                                    >
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <div class="form-check form-check-inline mt-2">
                                        <input
                                                (change)="updateEligibilty(1)"
                                                class="form-check-input"
                                                formControlName="working_hours_20"
                                                type="radio"
                                                value="Yes"
                                        />
                                        <label class="form-check-label"> Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline mt-2">
                                        <input
                                                (change)="updateEligibilty(1)"
                                                class="form-check-input"
                                                formControlName="working_hours_20"
                                                type="radio"
                                                value="No"
                                        />
                                        <label class="form-check-label"> No</label>
                                    </div>
                                    <small
                                            *ngIf="
                !newForm.controls['working_hours_20'].valid &&
                (newForm.controls['working_hours_20'].touched ||
                  submitAttempt)
              "
                                            class="errormsg"
                                    >
                                        This field is required
                                    </small>

                                    <small *ngIf="!isEligible" class="errormsg">
                                        You are not eligible for enrollment
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6 form-group has-float-label">
                                <label class="form-control-placeholder" for="address1"
                                >Address1<span class="fieldMan">*</span></label
                                >
                                <input
                                        (blur)="InputChange('address1')"
                                        autocomplete="off"
                                        class="form-control"
                                        formControlName="address1"
                                        id="address1"
                                        placeholder="Address1"
                                        type="text"
                                />

                                <div
                                        *ngIf="
              !newForm.controls['address1'].valid &&
              (newForm.controls['address1'].touched ||
                submitAttempt)
            "
                                        class="errormsg"
                                >
                                    Address is required
                                </div>
                            </div>
                            <div class="col-md-6 form-group has-float-label">
                                <label class="form-control-placeholder" for="address2"
                                >Apt/Suite
                                </label>
                                <input
                                        autocomplete="off"
                                        class="form-control"
                                        formControlName="address2"
                                        id="address2"
                                        placeholder="Apt/Suite"
                                        type="text"
                                />
                            </div>
                            <div class="col-md-4 form-group has-float-label">
                                <label
                                        class="required form-control-placeholder"
                                        for="city"
                                >City
                                </label>
                                <input
                                        autocomplete="off"
                                        class="form-control"
                                        formControlName="city"
                                        id="city"
                                        placeholder="City"
                                        type="text"
                                />
                                <div
                                        *ngIf="
              !newForm.controls['city'].valid &&
              (newForm.controls['city'].touched || submitAttempt)
            "
                                        class="errormsg"
                                >
                                    City is required
                                </div>
                            </div>
                            <div class="col-md-4 form-group has-float-label">
                                <label class="required form-label" for="state">
                                    State
                                </label>
                                <select
                                        (change)="checkIfActiveState($event)"
                                        aria-label="Default select example"
                                        class="form-control form-select"
                                        formControlName="state"
                                        id="state"
                                >
                                    <option Value="" disabled>Select Your State</option>
                                    <option
                                            *ngFor="let item of usaStates"
                                            [value]="item.name"
                                    >
                                        {{ item.name }}
                                    </option>
                                </select>

                                <div
                                        *ngIf="
              !newForm.controls['state'].valid &&
              (newForm.controls['state'].touched || submitAttempt)
            "
                                        class="errormsg"
                                >
                                    State is required
                                </div>
                            </div>
                            <div class="col-md-2 form-group has-float-label">
                                <label class="required form-label" for="zip">
                                    Zip Code
                                </label>
                                <input
                                        class="form-control"
                                        formControlName="zip"
                                        id="zip"
                                        maxlength="5"
                                        numbersOnly
                                        placeholder="Zip Code"
                                        type="text"
                                />

                                <div
                                        *ngIf="
              !newForm.controls['zip'].valid &&
              (newForm.controls['zip'].touched || submitAttempt)
            "
                                        class="errormsg"
                                >
                                    Zip Code is required
                                </div>
                                <span
                                        *ngIf="
              newForm.controls['zip'].hasError('pattern') &&
              newForm.controls['zip'].dirty
            "
                                        class="errormsg"
                                >Please enter valid 5 digit zip code</span
                                >
                            </div>
                        </div>
                  </div>
                </div>
                <div *ngIf="Policy_replacement_questions" class="card shadow-sm mb-3">
                    <div class="card-body">
                        <h6 class="title-mainhead">
              <span
              >Policy replacement questions
                <span class="fieldMan"> *</span></span
              >
                        </h6>
                        <div></div>
                        <div>
                            <div *ngFor="let list of healthQuestions; let i = index">
                                <div class="row">
                                    <div class="col-md-10">
                                        <p class="health-qstn">
                                            Question {{ i + 1 }}) {{ list.name }}
                                            <span class="fieldMan"> <span> * </span> </span>
                                        </p>

                                        <div *ngFor="let item of list.health_issue">
                                            <div *ngIf="list.value == true" class="row display mb-2">
                        <span *ngIf="item?.custom_message" class="text-danger">
                          {{ item?.custom_message }}
                          <div>You may continue with enrollment of your spouse and child(ren).</div>
                        </span>
                                                <div
                                                        *ngFor="let test_value of item.health_issue_options"
                                                        class="col-md-4 form-group"
                                                >
                          <span *ngIf="test_value.value != undefined">
                            <label class="">{{ test_value?.value }} </label>
                            <input
                                    (focusout)="
                                onFousoutInputQtns(
                                  test_value,
                                  $event.target.value
                                )
                              "
                                    [formControlName]="'question_' + test_value?.id"
                                    [name]="test_value.id"
                                    autocomplete="off"
                                    class="form-control newform"
                                    maxlength="25"
                                    placeholder="{{ test_value?.value }}"
                                    type="text"
                            />
                          </span>

                                                    <small
                                                            *ngIf="
                              !newForm.controls['question_' + test_value?.id]
                                .valid &&
                              (newForm.controls['question_' + test_value?.id]
                                .touched ||
                                submitAttempt)
                            "
                                                            class="errormsg"
                                                    >
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="row">
                                            <div class="form-group">
                                                <!-- Iterate over health_issue items -->
                                                <div
                                                        *ngFor="let item of list.health_issue"
                                                        class="form-check form-check-inline"
                                                >
                                                    <!-- Correctly bind value and ensure the name is consistent for radio group -->
                                                    <input
                                                            (change)="
                              list.value = item.value == 'Yes';
                              toggleswitch(item)
                            "
                                                            [formControlName]="'question_id_' + list.id"
                                                            [id]="'question_id_' + item.health_issue_id"
                                                            [name]="'question_id_' + list.id"
                                                            [value]="item.value"
                                                            class="form-check-input"
                                                            type="radio"
                                                    />
                                                    <label
                                                            [for]="'question_id_' + item.health_issue_id"
                                                            class="form-check-label"
                                                    >{{ item.value }}</label
                                                    >
                                                </div>
                                                <!-- Error message display -->
                                                <small
                                                        *ngIf="
                            !newForm.controls['question_id_' + list.id].valid &&
                            (newForm.controls['question_id_' + list.id]
                              .touched ||
                              submitAttempt)
                          "
                                                        class="errormsg"
                                                >
                                                    Field is required.
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="hidePrimaryDetails" class="card shadow-sm mb-3">
                    <div class="card-body">
                        <ng-container>
                            <div class="d-flex justify-content-center">
                                <h5 class="text-danger">
                                    Primary not qualified for online enrollment. Please contact Allstate at support&#64;allstate.com for help
                                    <div class="text-center mt-1">You may continue with enrollment of your spouse and child(ren).</div>
                                </h5>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div *ngIf="areQuestionControlsValid()">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <ng-container *ngIf="!hidePrimaryDetails">
                                <ng-container *ngIf="!Policy_replacement_questions">

                                   


                                    <h6 class="title-mainhead">
                                        <span>Primary Applicant Information</span>
                                    </h6>

                                    <div class="row">
                                        <div class="col-md-3 form-group">
                                            <label class=" "
                                            ><span>First Name</span> <span class="fieldMan">*</span>
                                            </label>
                                            <input
                                                    class="form-control marginTop"
                                                    formControlName="firstname"
                                                    maxlength="25"
                                                    placeholder="First Name"
                                                    type="text"
                                            />
                                            <small
                                                    *ngIf="
                          !newForm.controls['firstname'].valid &&
                          (newForm.controls['firstname'].touched ||
                            submitAttempt)
                        "
                                                    class="errormsg"
                                            >
                                                First Name is required.
                                            </small>
                                        </div>

                                        <div class="col-md-3 form-group">
                                            <label class=" "
                                            ><span>Last Name</span> <span class="fieldMan">*</span>
                                            </label>
                                            <input
                                                    class="form-control marginTop"
                                                    formControlName="lastname"
                                                    maxlength="25"
                                                    placeholder="Last Name"
                                                    type="text"
                                            />
                                            <small
                                                    *ngIf="
                          !newForm.controls['lastname'].valid &&
                          (newForm.controls['lastname'].touched ||
                            submitAttempt)
                        "
                                                    class="errormsg"
                                            >
                                                Last Name is required.
                                            </small>
                                        </div>
                                        <div class="col-md-3 form-group">
                                            <label class=" "
                                            ><span>Email</span>
                                                <span class="fieldMan">*</span></label
                                            >
                                            <input
                                                    class="form-control marginTop"
                                                    formControlName="email"
                                                    placeholder="Email"
                                                    type="text"
                                            />
                                            <small *ngIf="errorMsgShow" class="errormsg">
                                                {{ emailError }}
                                            </small>
                                            <small
                                                    *ngIf="
                          !newForm.controls['email'].valid &&
                          (newForm.controls['email'].touched || submitAttempt)
                        "
                                                    class="errormsg"
                                            >
                                                Email is required.
                                            </small>
                                        </div>
                                        <div class="col-md-3 form-group">
                                            <label class=" "
                                            ><span>Phone </span>
                                                <span class="fieldMan">*</span></label
                                            >
                                            <input
                                                    (focusout)="onleavePhonenumber()"
                                                    (keypress)="
                          emailValidation($event.target.value);
                          numberOnly($event)
                        "
                                                    appPhonenumberValidation
                                                    autocomplete="off"
                                                    class="form-control marginTop"
                                                    formControlName="phone"
                                                    id="phone-number"
                                                    maxlength="12"
                                                    placeholder="Phone"
                                                    type="text"
                                            />
                                            <small *ngIf="errorMsgShow" class="errormsg">
                                                {{ phoneerrmsg }}</small
                                            >
                                            <small
                                                    *ngIf="
                          !newForm.controls['phone'].valid &&
                          (newForm.controls['phone'].touched || submitAttempt)
                        "
                                                    class="errormsg"
                                            >
                                                Phone is required.
                                            </small>
                                            <small class="errormsg">
                                                {{ phnumbererrmsg }}
                                            </small>
                                        </div>
                                    </div>
                              
                                <div class="personalInfo">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-8 form-group">
                                                    <label class=""
                                                    >DOB
                                                        <span class="fieldMan">*</span> (MM/DD/YYYY)</label
                                                    >
                                                    <p-calendar
                                                            (onInput)="onSearchChangeT(newForm.value)"
                                                            (onSelect)="onSearchChange($event)"
                                                            [disabledDates]="invalidDates1"
                                                            [maxDate]="minimumDate"
                                                            [monthNavigator]="true"
                                                            [showIcon]="true"
                                                            [style]="{
                              width: '100%',
                              display: 'inline-flex',
                              height: '52px'
                            }"
                                                            [yearNavigator]="true"
                                                            [yearRange]="'1952:' + maxDate.getFullYear()"
                                                            formControlName="dob"
                                                            inputId="navigators"
                                                            inputId="icon"
                                                            placeholder="mm/dd/yyyy"
                                                    >
                                                    </p-calendar>
                                                    <small
                                                            *ngIf="
                              !newForm.controls['dob'].valid &&
                              (newForm.controls['dob'].touched || submitAttempt)
                            "
                                                            class="errormsg"
                                                            style="margin-top: 10px"
                                                    >
                                                        DOB is required
                                                    </small>
                                                    <small class="errormsg mt-2">{{ parentAge }}</small>
                                                    <small class="errormsg mt-2">{{ parentAge1 }}</small>
                                                    <br/>
                                                    <small class="text_info mb-0">
                                                        <i
                                                                class="fa fa-question-circle-o"
                                                                data-bs-placement="top"
                                                                data-bs-toggle="tooltip"
                                                                title="Age is automatically calculated from the DOB."
                                                        ></i>
                                                        Age is automatically calculated from the DOB.
                                                    </small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class="alignCenter">Age</label>
                                                    <input
                                                            [readonly]="true"
                                                            class="form-control"
                                                            formControlName="age"
                                                            placeholder="0"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label class="d-block"
                                            >Gender <span class="fieldMan">*</span></label
                                            >
                                            <div class="form-check form-check-inline mt-2">
                                                <input
                                                        class="form-check-input"
                                                        formControlName="gender"
                                                        type="radio"
                                                        value="Male"
                                                />
                                                <label class="form-check-label"> Male</label>
                                            </div>
                                            <div class="form-check form-check-inline mt-2">
                                                <input
                                                        class="form-check-input"
                                                        formControlName="gender"
                                                        type="radio"
                                                        value="Female"
                                                />
                                                <label class="form-check-label"> Female</label>
                                            </div>
                                            <!-- <div class="form-check form-check-inline mt-2">
                                                              <input class="form-check-input" type="radio" value="Unspecified"
                                                                  formControlName="gender">
                                                              <label class="form-check-label"> Unspecified</label>
                                                          </div> -->

                                            <small
                                                    *ngIf="
                          !newForm.controls['gender'].valid &&
                          (newForm.controls['gender'].touched || submitAttempt)
                        "
                                                    class="errormsg"
                                            >
                                                Gender is required
                                            </small>
                                        </div>

                                        <div class="col-md-3 form-group">
                                            <label class="">SSN <span class="fieldMan">*</span></label>
                                            <input
                                                    autocomplete="off"
                                                    class="form-control marginTop"
                                                    formControlName="ssn"
                                                    id="social-security"
                                                    maxlength="11"
                                                    numbersOnly
                                                    placeholder="Social Security Number"
                                                    socialSecurity
                                                    type="text"
                                            />
                                            <!-- <span
                                              class="errormsg"
                                              *ngIf="newForm.get('ssn').errors?.['invalidssn'] && (newForm.get('ssn').dirty || newForm.get('ssn').touched )"
                                            > -->
                                            <span
                                                    *ngIf="
                        !newForm.controls['ssn'].valid &&
                        (newForm.controls['ssn'].touched ||
                          submitAttempt)
                      "
                                                    class="errormsg"
                                            >
                        Please enter valid 9 digit SSN number
                      </span>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <h6 class="title-head">
                                                <span>Answer the below Questions:</span>
                                            </h6>
                                        </div>
                                        <div class="col-md-1">
                                            <p class="question-lable">Question 1:</p>
                                        </div>
                                        <div class="col-md-9">
                                            <div class="form-group">
                                                <label class="d-block"
                                                >Has the employee used tobacco in the last 12 months?
                                                    <span class="fieldMan">*</span></label
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check form-check-inline mt-2">
                                                    <input
                                                            class="form-check-input"
                                                            formControlName="tobacco"
                                                            type="radio"
                                                            value="yes"
                                                    />
                                                    <label class="form-check-label"> Yes</label>
                                                </div>
                                                <div class="form-check form-check-inline mt-2">
                                                    <input
                                                            class="form-check-input"
                                                            formControlName="tobacco"
                                                            type="radio"
                                                            value="no"
                                                    />
                                                    <label class="form-check-label"> No</label>
                                                </div>
                                                <small
                                                        *ngIf="
                            !newForm.controls['tobacco'].valid &&
                            (newForm.controls['tobacco'].touched ||
                              submitAttempt)
                          "
                                                        class="errormsg"
                                                >
                                                    This field is required
                                                </small>
                                            </div>
                                        </div>

                                        <div class="col-md-1">
                                            <p class="question-lable">Question 2:</p>
                                        </div>
                                        <div class="col-md-9">
                                            <div class="form-group">
                                                <label class="d-block"
                                                >I certify that I am actively at work now, for wage or
                                                    profit, and I have worked at least 20 hours each week
                                                    performing all duties of my regular occupation at my
                                                    regular place of employment for at least the last 3
                                                    months except for minor illness or injury of 1 week or
                                                    less, or normal pregnancy.<span class="fieldMan"
                                                    >*</span
                                                    ></label
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <div class="form-check form-check-inline mt-2">
                                                    <input
                                                            (change)="updateEligibilty(1)"
                                                            class="form-check-input"
                                                            formControlName="working_hours_20"
                                                            type="radio"
                                                            value="Yes"
                                                    />
                                                    <label class="form-check-label"> Yes</label>
                                                </div>
                                                <div class="form-check form-check-inline mt-2">
                                                    <input
                                                            (change)="updateEligibilty(1)"
                                                            class="form-check-input"
                                                            formControlName="working_hours_20"
                                                            type="radio"
                                                            value="No"
                                                    />
                                                    <label class="form-check-label"> No</label>
                                                </div>
                                                <small
                                                        *ngIf="
                            !newForm.controls['working_hours_20'].valid &&
                            (newForm.controls['working_hours_20'].touched ||
                              submitAttempt)
                          "
                                                        class="errormsg"
                                                >
                                                    This field is required
                                                </small>

                                                <small *ngIf="!isEligible" class="errormsg">
                                                    You are not eligible for enrollment
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-md-6 form-group has-float-label">
                                            <label class="form-control-placeholder" for="address1"
                                            >Address1<span class="fieldMan">*</span></label
                                            >
                                            <input
                                                    (blur)="InputChange('address1')"
                                                    autocomplete="off"
                                                    class="form-control"
                                                    formControlName="address1"
                                                    id="address1"
                                                    placeholder="Address1"
                                                    type="text"
                                            />

                                            <div
                                                    *ngIf="
                          !newForm.controls['address1'].valid &&
                          (newForm.controls['address1'].touched ||
                            submitAttempt)
                        "
                                                    class="errormsg"
                                            >
                                                Address is required
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-group has-float-label">
                                            <label class="form-control-placeholder" for="address2"
                                            >Apt/Suite
                                            </label>
                                            <input
                                                    autocomplete="off"
                                                    class="form-control"
                                                    formControlName="address2"
                                                    id="address2"
                                                    placeholder="Apt/Suite"
                                                    type="text"
                                            />
                                        </div>
                                        <div class="col-md-4 form-group has-float-label">
                                            <label
                                                    class="required form-control-placeholder"
                                                    for="city"
                                            >City
                                            </label>
                                            <input
                                                    autocomplete="off"
                                                    class="form-control"
                                                    formControlName="city"
                                                    id="city"
                                                    placeholder="City"
                                                    type="text"
                                            />
                                            <div
                                                    *ngIf="
                          !newForm.controls['city'].valid &&
                          (newForm.controls['city'].touched || submitAttempt)
                        "
                                                    class="errormsg"
                                            >
                                                City is required
                                            </div>
                                        </div>
                                        <div class="col-md-4 form-group has-float-label">
                                            <label class="required form-label" for="state">
                                                State
                                            </label>
                                            <select
                                                    (change)="checkIfActiveState($event)"
                                                    aria-label="Default select example"
                                                    class="form-control form-select"
                                                    formControlName="state"
                                                    id="state"
                                            >
                                                <option Value="" disabled>Select Your State</option>
                                                <option
                                                        *ngFor="let item of usaStates"
                                                        [value]="item.name"
                                                >
                                                    {{ item.name }}
                                                </option>
                                            </select>

                                            <div
                                                    *ngIf="
                          !newForm.controls['state'].valid &&
                          (newForm.controls['state'].touched || submitAttempt)
                        "
                                                    class="errormsg"
                                            >
                                                State is required
                                            </div>
                                        </div>
                                        <div class="col-md-2 form-group has-float-label">
                                            <label class="required form-label" for="zip">
                                                Zip Code
                                            </label>
                                            <input
                                                    class="form-control"
                                                    formControlName="zip"
                                                    id="zip"
                                                    maxlength="5"
                                                    numbersOnly
                                                    placeholder="Zip Code"
                                                    type="text"
                                            />

                                            <div
                                                    *ngIf="
                          !newForm.controls['zip'].valid &&
                          (newForm.controls['zip'].touched || submitAttempt)
                        "
                                                    class="errormsg"
                                            >
                                                Zip Code is required
                                            </div>
                                            <span
                                                    *ngIf="
                          newForm.controls['zip'].hasError('pattern') &&
                          newForm.controls['zip'].dirty
                        "
                                                    class="errormsg"
                                            >Please enter valid 5 digit zip code</span
                                            >
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                            </ng-container>
                            </ng-container>
                            <h6 class="title-head">
                                <input
                                        (change)="addEmployee($event.target.checked, 'spouse')"
                                        [checked]="spouse_check"
                                        class=""
                                        id="spousecheckbox"
                                        type="checkbox"
                                />
                                <span> Spouse/Domestic Partner</span>
                                <div class="sub_text">
                                    Please select the check box to add spouse
                                </div>
                            </h6>
                            <div *ngIf="spouse_check" formGroupName="spouseHealthQtns">
                                <div *ngFor="let list of spouseHealthQuestions; let i = index">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <p class="health-qstn">
                                                Question {{ i + 1 }}) {{ list.name }}
                                                <span class="fieldMan"> <span> * </span> </span>
                                            </p>

                                            <div *ngFor="let item of list.health_issue">
                                                <div
                                                        *ngIf="list.value == true"
                                                        class="row display mb-2"
                                                >
                          <span
                                  *ngIf="item?.custom_message"
                                  class="text-danger"
                          >
                            {{ item?.custom_message }}
                          </span>
                                                    <div
                                                            *ngFor="let test_value of item.health_issue_options"
                                                            class="col-md-4 form-group"
                                                    >
                            <span *ngIf="test_value.value != undefined">
                              <label class="">{{ test_value?.value }} </label>
                              <input
                                      (focusout)="
                                  onFousoutInputQtns(
                                    test_value,
                                    $event.target.value
                                  )
                                "
                                      [formControlName]="'question_' + test_value?.id"
                                      [name]="test_value.id"
                                      autocomplete="off"
                                      class="form-control newform"
                                      maxlength="25"
                                      placeholder="{{ test_value?.value }}"
                                      type="text"
                              />
                            </span>

                                                        <small
                                                                *ngIf="
                                !newForm.controls['question_' + test_value?.id]
                                  .valid &&
                                (newForm.controls['question_' + test_value?.id]
                                  .touched ||
                                  submitAttempt)
                              "
                                                                class="errormsg"
                                                        >
                                                            Field is required.
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="row">
                                                <div class="form-group">
                                                    <!-- Iterate over health_issue items -->
                                                    <div
                                                            *ngFor="let item of list.health_issue"
                                                            class="form-check form-check-inline"
                                                    >
                                                        <!-- Correctly bind value and ensure the name is consistent for radio group -->
                                                        <input
                                                                (change)="
                                list.value = item.value == 'Yes';
                                toggleSpouseswitch(item)
                              "
                                                                [formControlName]="'question_id_' + list.id"
                                                                [id]="'question_id_' + item.health_issue_id"
                                                                [name]="'question_id_' + list.id"
                                                                [value]="item.value"
                                                                class="form-check-input"
                                                                type="radio"
                                                        />
                                                        <label
                                                                [for]="'question_id_' + item.health_issue_id"
                                                                class="form-check-label"
                                                        >{{ item.value }}</label
                                                        >
                                                    </div>
                                                    <!-- Error message display -->
                                                    <small
                                                            *ngIf="
                              !newForm.controls['question_id_' + list.id]
                                .valid &&
                              (newForm.controls['question_id_' + list.id]
                                .touched ||
                                submitAttempt)
                            "
                                                            class="errormsg"
                                                    >
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="areSpouseQuestionControlsValid()">
                                <ng-container *ngIf="!hideSpouseDetails">
                                    <div
                                            *ngFor="
                      let spouseForm of spouse_Array().controls;
                      let empIndex1 = index
                    "
                                            class=" "
                                    >
                                        <div class="" formArrayName="spouse_Array">
                                            <div [formGroupName]="empIndex1" class="">
                                                <ng-container *ngIf="!hidePrimaryDetails">
                                                    <label class="d-flex gap-2 mb-3">
                                                        <input
                                                                (change)="
                                checkBoXChange(empIndex1, 'spouse_Array')
                              "
                                                                formControlName="checkBox"
                                                                type="checkbox"
                                                        />
                                                        <span>
                              Check if Spouse address is same as Primary
                              address</span
                                                        >
                                                    </label>
                                                </ng-container>

                                                <div class="row">
                                                    <div class="col-md-4 form-group">
                                                        <label class=""
                                                        >First Name <span class="fieldMan">*</span></label
                                                        >
                                                        <input
                                                                autocomplete="off"
                                                                class="form-control"
                                                                formControlName="spouseFirstname"
                                                                maxlength="25"
                                                                placeholder="First Name"
                                                                type="text"
                                                        />
                                                        <small
                                                                *ngIf="
                                !spouseForm.get('spouseFirstname').valid &&
                                (spouseForm.controls.spouseFirstname.touched ||
                                  submitAttempt)
                              "
                                                                class="errormsg"
                                                        >
                                                            First Name is required
                                                        </small>
                                                    </div>
                                                    <div class="col-md-4 form-group">
                                                        <label class=""
                                                        >Last Name <span class="fieldMan">*</span></label
                                                        >
                                                        <input
                                                                autocomplete="off"
                                                                class="form-control"
                                                                formControlName="spouseLastname"
                                                                maxlength="25"
                                                                placeholder="Last Name"
                                                                type="text"
                                                        />
                                                        <small
                                                                *ngIf="
                                !spouseForm.controls.spouseLastname.valid &&
                                (spouseForm.controls.spouseLastname.touched ||
                                  submitAttempt)
                              "
                                                                class="errormsg"
                                                        >
                                                            Last Name is required
                                                        </small>
                                                    </div>
                                                    <ng-container
                                                            *ngIf="
                              hidePrimaryDetails &&
                              spouseForm.controls?.spousePhone &&
                              spouseForm.controls?.spouseEmail
                            "
                                                    >
                                                        <div class="col-md-3 form-group">
                                                            <label class=""
                                                            >Email <span class="fieldMan">*</span></label
                                                            >
                                                            <input
                                                                    autocomplete="off"
                                                                    class="form-control"
                                                                    formControlName="spouseEmail"
                                                                    placeholder="Email"
                                                                    type="text"
                                                            />
                                                            <small
                                                                    *ngIf="
                                  spouseForm.controls?.spouseEmail?.errors
                                    ?.required &&
                                  (spouseForm.controls?.spouseEmail?.touched ||
                                    submitAttempt)
                                "
                                                                    class="errormsg"
                                                            >
                                                                Email is required
                                                            </small>
                                                        </div>

                                                        <div class="col-md-3 form-group">
                                                            <label class=""
                                                            >Phone <span class="fieldMan">*</span></label
                                                            >
                                                            <input
                                                                    (focusout)="onleavePhonenumber()"
                                                                    (keypress)="numberOnly($event)"
                                                                    appPhonenumberValidation
                                                                    autocomplete="off"
                                                                    class="form-control marginTop"
                                                                    formControlName="spousePhone"
                                                                    id="phone-number"
                                                                    maxlength="12"
                                                                    placeholder="Phone"
                                                                    type="text"
                                                            />
                                                            <small
                                                                    *ngIf="
                                  !spouseForm.controls?.spousePhone?.valid &&
                                  (spouseForm.controls?.spousePhone?.touched ||
                                    submitAttempt)
                                "
                                                                    class="errormsg"
                                                            >
                                                                Phone is required
                                                            </small>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-6 form-group mb-0">
                                                        <div class="row">
                                                            <div class="form-group col-md-8">
                                                                <label class=""
                                                                >DOB <span class="fieldMan">*</span>
                                                                    (MM/DD/YYYY)
                                                                </label>
                                                                <p-calendar
                                                                        (onInput)="spouseAgeCalT(newForm.value)"
                                                                        (onSelect)="
                                    spouseAgeCal(
                                      $event,
                                      empIndex1,
                                      'spouseDob' + empIndex1
                                    )
                                  "
                                                                        [disabledDates]="invalidDates1"
                                                                        [maxDate]="minimumDate"
                                                                        [monthNavigator]="true"
                                                                        [showIcon]="true"
                                                                        [style]="{
                                    width: '100%',
                                    display: 'inline-flex',
                                    height: '52px'
                                  }"
                                                                        [yearNavigator]="true"
                                                                        [yearRange]="'1952:' + maxDate.getFullYear()"
                                                                        formControlName="spouseDob"
                                                                        inputId="navigators"
                                                                        inputId="icon"
                                                                        inputId="{{ 'spouseDob' + empIndex1 }}"
                                                                        placeholder="mm/dd/yyyy"
                                                                >
                                                                </p-calendar>
                                                                <small
                                                                        *ngIf="
                                    !spouseForm.controls.spouseDob.valid &&
                                    (spouseForm.controls.spouseDob.touched ||
                                      submitAttempt)
                                  "
                                                                        class="errormsg mt-2"
                                                                >
                                                                    DOB is required
                                                                </small>

                                                                <small class="text_info mb-2">
                                                                    <i
                                                                            class="fa fa-question-circle-o"
                                                                            data-bs-placement="top"
                                                                            data-bs-toggle="tooltip"
                                                                            title="Age is automatically calculated from the DOB."
                                                                    ></i>
                                                                    Age is automatically calculated from the DOB.
                                                                </small>
                                                            </div>
                                                            <div class="col-md-4 form-group">
                                                                <label class="alignCenter">Age</label>
                                                                <input
                                                                        [readonly]="true"
                                                                        class="form-control"
                                                                        formControlName="spouseAge"
                                                                        placeholder="0"
                                                                        type="text"
                                                                />
                                                            </div>
                                                        </div>
                                                        <small class="errormsg mt-2">{{
                                                                spouseAgeMsg
                                                            }}</small>
                                                    </div>
                                                    <div class="form-group col-md-3">
                                                        <label class="d-block"
                                                        >Gender <span class="fieldMan">*</span></label
                                                        >
                                                        <div class="form-check form-check-inline mt-2">
                                                            <input
                                                                    class="form-check-input"
                                                                    formControlName="spouseGender"
                                                                    id="spousemale"
                                                                    type="radio"
                                                                    value="Male"
                                                            />
                                                            <label class="form-check-label" for="spousemale">
                                                                Male</label
                                                            >
                                                        </div>
                                                        <div class="form-check form-check-inline mt-2">
                                                            <input
                                                                    class="form-check-input"
                                                                    formControlName="spouseGender"
                                                                    id="spousefemale"
                                                                    type="radio"
                                                                    value="Female"
                                                            />
                                                            <label
                                                                    class="form-check-label"
                                                                    for="spousefemale"
                                                            >
                                                                Female</label
                                                            >
                                                        </div>
                                                        <!-- <div class="form-check form-check-inline mt-2">
                                                                              <input type="radio" class="form-check-input" value="Unspecified"
                                                                                  id="spousefemale" formControlName="spouseGender">
                                                                              <label class="form-check-label" for="spousefemale"> Unspecified</label>
                                                                          </div> -->
                                                        <small
                                                                *ngIf="
                                !spouseForm.controls.spouseGender.valid &&
                                (spouseForm.controls.spouseGender.touched ||
                                  submitAttempt)
                              "
                                                                class="errormsg"
                                                        >
                                                            Gender is required
                                                        </small>
                                                    </div>

                                                    <div class="col-md-3 form-group">
                                                        <label class=""><span>SSN </span></label>
                                                        <input
                                                                autocomplete="off"
                                                                class="form-control marginTop"
                                                                formControlName="spouseSsn"
                                                                id="spouse-security"
                                                                maxlength="11"
                                                                numbersOnly
                                                                placeholder="Social Security Number"
                                                                socialSecurity
                                                                type="text"
                                                        />
                                                        <span
                                                                *ngIf="
                                spouseForm.get('spouseSsn').errors
                                  ?.invalidssn &&
                                (spouseForm.get('spouseSsn').dirty ||
                                  spouseForm.get('spouseSsn').touched)
                              "
                                                                class="errormsg"
                                                        >
                              Please enter valid 9 digit SSN number</span
                                                        >
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <h6 class="title-head">
                                                            <span>Answer the below Questions:</span>
                                                        </h6>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <p class="question-lable">Question 1:</p>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <div class="form-group">
                                                            <label class="d-block"
                                                            >Has the employee's spouse used tobacco in the
                                                                last 12 months?
                                                                <span class="fieldMan">*</span></label
                                                            >
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-check form-check-inline mt-2">
                                                                <input
                                                                        class="form-check-input"
                                                                        formControlName="spousetobacco"
                                                                        type="radio"
                                                                        value="yes"
                                                                />
                                                                <label class="form-check-label"> Yes</label>
                                                            </div>
                                                            <div class="form-check form-check-inline mt-2">
                                                                <input
                                                                        class="form-check-input"
                                                                        formControlName="spousetobacco"
                                                                        type="radio"
                                                                        value="no"
                                                                />
                                                                <label class="form-check-label"> No</label>
                                                            </div>
                                                            <small
                                                                    *ngIf="
                                  !spouseForm.controls.spousetobacco.valid &&
                                  (spouseForm.controls.spousetobacco.touched ||
                                    submitAttempt)
                                "
                                                                    class="errormsg"
                                                            >
                                                                This field is required
                                                            </small>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-1">
                                                        <p class="question-lable">Question 2:</p>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <div class="form-group">
                                                            <label class="d-block"
                                                            >Is the employee's spouse actively at work now,
                                                                for wage or profit, and has he/she worked for at
                                                                least 20 hours each week performing all duties
                                                                of his/her regular occupation at his/her regular
                                                                place of employment for at least last 3 months
                                                                except for minor illness or injury of 1 week or
                                                                less, or normal pregnancy?
                                                                <span class="fieldMan">*</span></label
                                                            >
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="form-check form-check-inline mt-2">
                                                                <input
                                                                        (change)="updateEligibilty(2)"
                                                                        class="form-check-input"
                                                                        formControlName="spouse_working_hours_20"
                                                                        type="radio"
                                                                        value="Yes"
                                                                />
                                                                <label class="form-check-label"> Yes</label>
                                                            </div>
                                                            <div class="form-check form-check-inline mt-2">
                                                                <input
                                                                        (change)="updateEligibilty(2)"
                                                                        class="form-check-input"
                                                                        formControlName="spouse_working_hours_20"
                                                                        type="radio"
                                                                        value="No"
                                                                />
                                                                <label class="form-check-label"> No</label>
                                                            </div>
                                                            <small
                                                                    *ngIf="
                                  !spouseForm.controls.spouse_working_hours_20
                                    .valid &&
                                  (spouseForm.controls.spouse_working_hours_20
                                    .touched ||
                                    submitAttempt)
                                "
                                                                    class="errormsg"
                                                            >
                                                                This field is required
                                                            </small>

                                                            <!--<small class="errormsg" *ngIf="!isSpouseEligible">
                                                                                    You are not eligible for spouse enrollment
                                                                                </small>-->
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-6 form-group has-float-label">
                                                        <label
                                                                class="form-control-placeholder"
                                                                for="address1"
                                                        >Address1<span class="fieldMan">*</span>
                                                        </label>
                                                        <input
                                                                [class.stateclass]="
                                spouseForm.controls.disabled.value ? true : ''
                              "
                                                                autocomplete="off"
                                                                class="form-control"
                                                                formControlName="address1"
                                                                placeholder="Address1"
                                                                type="text"
                                                        />

                                                        <div
                                                                *ngIf="
                                !spouseForm.controls.address1.valid &&
                                (spouseForm.controls.address1.touched ||
                                  submitAttempt)
                              "
                                                                class="errormsg"
                                                        >
                                                            Address is required
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 form-group has-float-label">
                                                        <label
                                                                class="form-control-placeholder"
                                                                for="address1"
                                                        >Apt/Suite
                                                        </label>
                                                        <input
                                                                [class.stateclass]="
                                spouseForm.controls.disabled.value ? true : ''
                              "
                                                                autocomplete="off"
                                                                class="form-control"
                                                                formControlName="address2"
                                                                id="address2"
                                                                placeholder="Apt/Suite"
                                                                type="text"
                                                        />
                                                    </div>
                                                    <div class="col-md-4 form-group has-float-label">
                                                        <label
                                                                class="required form-control-placeholder"
                                                                for="city"
                                                        >City
                                                        </label>
                                                        <input
                                                                [class.stateclass]="
                                spouseForm.controls.disabled.value ? true : ''
                              "
                                                                autocomplete="off"
                                                                class="form-control"
                                                                formControlName="city"
                                                                id="city"
                                                                placeholder="City"
                                                                type="text"
                                                        />
                                                        <div
                                                                *ngIf="
                                !spouseForm.controls.city.valid &&
                                (spouseForm.controls.city.touched ||
                                  submitAttempt)
                              "
                                                                class="errormsg"
                                                        >
                                                            City is required
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 form-group has-float-label">
                                                        <label class="required form-label" for="state">
                                                            State
                                                        </label>
                                                        <select
                                                                (change)="checkIfActiveState($event)"
                                                                [class.stateclass]="
                                spouseForm.controls.disabled.value ? true : ''
                              "
                                                                aria-label="Default select example"
                                                                class="form-control form-select"
                                                                formControlName="state"
                                                                id="state"
                                                        >
                                                            <option Value="" disabled>
                                                                Select Your State
                                                            </option>
                                                            <option
                                                                    *ngFor="let item of usaStates"
                                                                    [value]="item.name"
                                                            >
                                                                {{ item.name }}
                                                            </option>
                                                        </select>

                                                        <div
                                                                *ngIf="
                                !spouseForm.controls.state.valid &&
                                (spouseForm.controls.state.touched ||
                                  submitAttempt)
                              "
                                                                class="errormsg"
                                                        >
                                                            State is required
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 form-group has-float-label">
                                                        <label class="required form-label" for="zip">
                                                            Zip Code
                                                        </label>
                                                        <input
                                                                [class.stateclass]="
                                spouseForm.controls.disabled.value ? true : ''
                              "
                                                                class="form-control"
                                                                formControlName="zip"
                                                                id="zip"
                                                                maxlength="5"
                                                                numbersOnly
                                                                placeholder="Zip Code"
                                                                type="text"
                                                        />

                                                        <div
                                                                *ngIf="
                                !spouseForm.controls.zip.valid &&
                                (spouseForm.controls.zip.touched ||
                                  submitAttempt)
                              "
                                                                class="errormsg"
                                                        >
                                                            Zip Code is required
                                                        </div>
                                                        <span
                                                                *ngIf="
                                spouseForm.controls['zip'].hasError(
                                  'pattern'
                                ) && spouseForm.controls['zip'].dirty
                              "
                                                                class="errormsg"
                                                        >Please enter valid 5 digit zip code</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>

                            <div *ngIf="hideSpouseDetails" class="card shadow-sm mb-3">
                                <div class="card-body">
                                    <ng-container>
                                        <div class="d-flex justify-content-center">
                                            <h5 class="text-danger">
                                                Spouse not qualified for online enrollment
                                            </h5>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <ng-container *ngIf="!hideSpouseDetails || !hidePrimaryDetails">
                                <hr/>
                                <h6 class="title-head">
                                    <input
                                            (change)="addChild($event, 'child')"
                                            [checked]="child_check"
                                            [disabled]="addchilddisabled"
                                            class=""
                                            id="childcheckbox"
                                            type="checkbox"
                                    />
                                    <span> Child Information</span>
                                    <div class="sub_text">
                                        Please select the check box to add child
                                    </div>
                                </h6>

                                <div formArrayName="child_Array">
                                    <div
                                            *ngFor="
                      let childForm of child_Array().controls;
                      let empIndex = index
                    "
                                            class="child-member-container"
                                    >
                                        <div class="form-card-header px-3 pt-3 mb-1">
                                            <h6 class="title">Child - {{ empIndex + 1 }}</h6>

                                            <span>
                        <button
                                (click)="removeEmployee(empIndex, childForm.value)"
                                class="btn-remove"
                                type="submit"
                        >
                          <i class="ri-delete-bin-7-line"></i>
                          Remove Child - {{ empIndex + 1 }}
                        </button>
                                                <!-- <i class="fa fa-trash-o delete_btn"
                                                                    (click)="removeEmployee(empIndex,childForm.value)"></i> -->
                      </span>
                                        </div>
                                        <div [formGroupName]="empIndex" class="card-body">
                                            <!-- *ngIf="!hidePrimaryDetails"  --future use -->
                                            <ng-container >
                                                <label class="d-flex gap-2 mb-3">
                                                    <input
                                                            (change)="checkBoXChange(empIndex, 'child_Array')"
                                                            formControlName="checkBox"
                                                            type="checkbox"
                                                    />
                                                    <span
                                                    >Check if Child address is same as Primary
                            address</span
                                                    >
                                                </label>
                                            </ng-container>
                                            <div class="row">
                                                <div class="col-md-4 form-group">
                                                    <label class=""
                                                    >First Name <span class="fieldMan">*</span></label
                                                    >
                                                    <input
                                                            autocomplete="off"
                                                            class="form-control"
                                                            formControlName="childFirstname"
                                                            maxlength="25"
                                                            placeholder="First Name"
                                                            type="text"
                                                    />
                                                    <small
                                                            *ngIf="
                              !childForm.controls.childFirstname.valid &&
                              (childForm.controls.childFirstname.touched ||
                                submitAttempt)
                            "
                                                            class="errormsg"
                                                    >
                                                        First Name is required
                                                    </small>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <label class=" "
                                                    ><span>Last Name</span>
                                                        <span class="fieldMan">*</span>
                                                    </label>
                                                    <input
                                                            class="form-control marginTop"
                                                            formControlName="childLastname"
                                                            maxlength="25"
                                                            placeholder="Last Name"
                                                            type="text"
                                                    />
                                                    <small
                                                            *ngIf="
                              !childForm.controls.childLastname.valid &&
                              (childForm.controls.childLastname.touched ||
                                submitAttempt)
                            "
                                                            class="errormsg"
                                                    >
                                                        Last Name is required.
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 form-group mb-0">
                                                    <div class="row">
                                                        <div class="col-md-8 form-group">
                                                            <label class=""
                                                            >DOB <span class="fieldMan">*</span>
                                                                (MM/DD/YYYY)
                                                            </label>
                                                            <p-calendar
                                                                    (onInput)="
                                  childAgeCalT(
                                    $event,
                                    empIndex,
                                    'dob' + empIndex,
                                    newForm.value
                                  )
                                "
                                                                    (onSelect)="
                                  childAgeCal(
                                    $event,
                                    empIndex,
                                    'dob' + empIndex
                                  )
                                "
                                                                    [disabledDates]="invalidDates1"
                                                                    [maxDate]="minimumDate"
                                                                    [monthNavigator]="true"
                                                                    [showIcon]="true"
                                                                    [style]="{
                                  width: '100%',
                                  display: 'inline-flex',
                                  height: '52px'
                                }"
                                                                    [yearNavigator]="true"
                                                                    [yearRange]="'1994:' + maxDate.getFullYear()"
                                                                    formControlName="childDob"
                                                                    inputId="navigators"
                                                                    inputId="{{ 'dob' + empIndex }}"
                                                                    placeholder="mm/dd/yyyy"
                                                            >
                                                            </p-calendar>
                                                            <small
                                                                    *ngIf="
                                  !childForm.controls.childDob.valid &&
                                  (childForm.controls.childDob.touched ||
                                    submitAttempt)
                                "
                                                                    class="errormsg mt-2"
                                                            >
                                                                DOB is required
                                                            </small>
                                                            <small
                                                                    class="errormsg mt-2"
                                                                    id="{{ 'childDOBerror' + empIndex }}"
                                                            >
                                                            </small>
                                                        </div>
                                                        <div class="col-md-4 form-group">
                                                            <label>Age</label>
                                                            <input
                                                                    [readonly]="true"
                                                                    class="form-control"
                                                                    formControlName="childAge"
                                                                    placeholder="0"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-2 form-group">
                                                    <label class="d-block"
                                                    >Gender <span class="fieldMan">*</span></label
                                                    >
                                                    <div class="form-check form-check-inline mt-2">
                                                        <input
                                                                class="form-check-input"
                                                                formControlName="childGender"
                                                                id="Child - {{ empIndex + 1 }}male"
                                                                type="radio"
                                                                value="Male"
                                                        />
                                                        <label
                                                                class="form-check-label"
                                                                for="Child - {{ empIndex + 1 }}male"
                                                        >Male</label
                                                        >
                                                    </div>
                                                    <div class="form-check form-check-inline mt-2">
                                                        <input
                                                                class="form-check-input"
                                                                formControlName="childGender"
                                                                id="Child - {{ empIndex + 1 }}female"
                                                                type="radio"
                                                                value="Female"
                                                        />
                                                        <label
                                                                class="form-check-label"
                                                                for="Child - {{ empIndex + 1 }}Female"
                                                        >Female</label
                                                        >
                                                    </div>
                                                    <!-- <div class="form-check form-check-inline mt-2">
                                                                          <input class="form-check-input" type="radio"
                                                                              id="Child - {{empIndex +1}}Unspecified" value="Unspecified"
                                                                              formControlName="childGender">
                                                                          <label class="form-check-label"
                                                                              for="Child - {{empIndex +1}}Unspecified">Unspecified</label>
                                                                      </div> -->
                                                    <small
                                                            *ngIf="
                              !childForm.controls.childGender.valid &&
                              (childForm.controls.childGender.touched ||
                                submitAttempt)
                            "
                                                            class="errormsg"
                                                    >
                                                        Gender is required
                                                    </small>
                                                </div>

                                                <div class="col-md-3 form-group">
                                                    <label class=""><span>SSN </span></label>
                                                    <input
                                                            attr.id="social-security-child-{{ empIndex }}"
                                                            autocomplete="off"
                                                            class="form-control marginTop"
                                                            formControlName="childSsn"
                                                            id="child-security"
                                                            maxlength="11"
                                                            numbersOnly
                                                            placeholder="Social Security Number"
                                                            socialSecurity
                                                            type="text"
                                                    />
                                                    <span
                                                            *ngIf="childForm.get('childSsn')?.errors?.['invalidssn'] && ( childForm.get('childSsn')?.dirty || childForm.get('childSsn')?.touched )"
                                                            class="errormsg"
                                                    >
                            Please enter valid 9 digit SSN number
                          </span>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-6 form-group has-float-label">
                                                        <label
                                                                class="form-control-placeholder"
                                                                for="address1"
                                                        >Address1<span class="fieldMan">*</span>
                                                        </label>
                                                        <input
                                                                [class.stateclass]="
                                childForm.controls.disabled.value ? true : ''
                              "
                                                                autocomplete="off"
                                                                class="form-control"
                                                                formControlName="address1"
                                                                id="address1"
                                                                placeholder="Address1 "
                                                                type="text"
                                                        />

                                                        <div
                                                                *ngIf="
                                !childForm.controls.address1.valid &&
                                (childForm.controls.address1.touched ||
                                  submitAttempt)
                              "
                                                                class="errormsg"
                                                        >
                                                            Address is required
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 form-group has-float-label">
                                                        <label
                                                                class="form-control-placeholder"
                                                                for="address1"
                                                        >Apt/Suite
                                                        </label>
                                                        <input
                                                                [class.stateclass]="
                                childForm.controls.disabled.value ? true : ''
                              "
                                                                autocomplete="off"
                                                                class="form-control"
                                                                formControlName="address2"
                                                                id="address2"
                                                                placeholder="Apt/Suite"
                                                                type="text"
                                                        />
                                                    </div>
                                                    <div class="col-md-4 form-group has-float-label">
                                                        <label
                                                                class="required form-control-placeholder"
                                                                for="city"
                                                        >City
                                                        </label>
                                                        <input
                                                                [class.stateclass]="
                                childForm.controls.disabled.value ? true : ''
                              "
                                                                autocomplete="off"
                                                                class="form-control"
                                                                formControlName="city"
                                                                id="city"
                                                                placeholder="City"
                                                                type="text"
                                                        />
                                                        <div
                                                                *ngIf="
                                !childForm.controls.city.valid &&
                                (childForm.controls.city.touched ||
                                  submitAttempt)
                              "
                                                                class="errormsg"
                                                        >
                                                            City is required
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 form-group has-float-label">
                                                        <label class="required form-label" for="state">
                                                            State
                                                        </label>
                                                        <select
                                                                (change)="checkIfActiveState($event)"
                                                                [class.stateclass]="
                                childForm.controls.disabled.value ? true : ''
                              "
                                                                aria-label="Default select example"
                                                                class="form-control form-select"
                                                                formControlName="state"
                                                                id="state"
                                                        >
                                                            <option Value="" disabled>
                                                                Select Your State
                                                            </option>
                                                            <option
                                                                    *ngFor="let item of usaStates"
                                                                    [value]="item.name"
                                                            >
                                                                {{ item.name }}
                                                            </option>
                                                        </select>

                                                        <div
                                                                *ngIf="
                                !childForm.controls.state.valid &&
                                (childForm.controls.state.touched ||
                                  submitAttempt)
                              "
                                                                class="errormsg"
                                                        >
                                                            State is required
                                                        </div>
                                                    </div>

                                                    <div class="col-md-2 form-group has-float-label">
                                                        <label class="required form-label" for="zip">
                                                            Zip Code
                                                        </label>
                                                        <input
                                                                [class.stateclass]="
                                childForm.controls.disabled.value ? true : ''
                              "
                                                                class="form-control"
                                                                formControlName="zip"
                                                                id="zip"
                                                                maxlength="5"
                                                                numbersOnly
                                                                placeholder="Zip Code"
                                                                type="text"
                                                        />

                                                        <div
                                                                *ngIf="
                                !childForm.controls.zip.valid &&
                                (childForm.controls.zip.touched ||
                                  submitAttempt)
                              "
                                                                class="errormsg"
                                                        >
                                                            Zip Code is required
                                                        </div>
                                                        <span
                                                                *ngIf="
                                childForm.controls['zip'].hasError('pattern') &&
                                childForm.controls['zip'].dirty
                              "
                                                                class="errormsg"
                                                        >Please enter valid 5 digit zip code</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <div
                                    (click)="add_child()"
                                    *ngIf="displayText"
                                    class="add_child"
                                    id="addchild"
                            >
                                + Add another Child
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="areQuestionControlsValid()">
                    <div
                            *ngIf="
              !hidePrimaryDetails ||
              (spouse_check &&
                !hideSpouseDetails &&
                areSpouseQuestionControlsValid())
            "
                            class="card-grid-footer grid-end mt-3 text-right"
                    >
                        <button
                                (click)="accenrollment(newForm.value)"
                                *ngIf="isEligible || spouse_check"
                                class="btn add-btn"
                                type="button"
                        >
                            Next
                        </button>
                    </div>
                </div>

                <!------[disabled]="!isActiveState"-->
            </form>
        </div>
    </div>
</div>

import { DatePipe } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, distinctUntilChanged, interval, startWith, switchMap, tap } from 'rxjs';
import SignaturePad from 'signature_pad';
import { isValidMobileNumber } from '../services/phone-number-validation';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from '@angular/common/http';
import { SharingService } from '../services/sharing.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-groups',
  templateUrl: './add-groups.component.html',
  styleUrl: './add-groups.component.scss'
})
export class AddGroupsComponent {

  Form !: any;
  submitAttempt: boolean = false;
  groupName: string | null;
  usaStates: any;
  message: any;
  companyId: any;
  DecryptData: any;
  showCheckboxError: any;
  firstDiv: boolean = true;
  secondDiv: boolean = false;
  thirdDiv: boolean = false;
  onTabClickData: any = 'esignature';
  signature: any;
  applicantname: any;
  showSignatureVerifyError: string;
  showSignatureVerifyError1: string;
  date = new Date();
  uploadedFile: any;
  imageUrl: any;
  signaturePad: SignaturePad;
  signatureImg: string;
  ipAddress: any;
  uploadedFilelogo: any;
  uploadedFileattachment: any;
  imageUrlLogo: any;
  imageUrlAttachment: any;
  @ViewChild("canvas", { static: true }) canvas: ElementRef;
  signatureVAlid: any;
  personContactNumber: any;
  displayDateandTime: any;
  date1: any;
  minimumDate !: Date;
  defaultValue: any = null;
  mailingAddressarr: any[] = [];
  finalMailingaddress: any[] = [];
  mergedArray: any;
  phonetype: any[] = [
    { id: 1, value: "Primary Number" },
    { id: 2, value: "Alternate Number" }
  ];
  cmmPremium: any[] = [
    { id: 1, value: "Employee" },
    { id: 2, value: "Employee + Spouse" },
    { id: 3, value: "Employee + Child" },
    { id: 4, value: "Family" },
    { id: 5, value: "Others" }
  ];
  filteredbenefitsArr: any[] = [];

  benefits: any[] = [

  ]
  emptyOwnerAddress: any[] = []
  ischeckedAddress: boolean = false;
  ischeckedownerAddress: boolean = false;
  finalAddress: any[] = [];
  finalownerAddress: any[] = [];
  emptyAddressobjects: any[] = [];
  est_date: any;
  invoiceUniqueIdTypes = [
    { name: "Foreign ID", value: "foreign_id" },
    { name: "Member ID", value: "MemberID" }
  ]
  uniqueGroupID_err:any;
  dealID_err:any;
  invoiceType_err:any;
  invoiceDate_err:any;
  invoiceDates = [];

  public isSameAddressControl: FormControl = new FormControl(false);
  public healthCheckControl: FormControl = new FormControl(false);
  public lifeCheckControl: FormControl = new FormControl(false);
  public disabilityCheckControl: FormControl = new FormControl(false);
  public dentalCheckControl: FormControl = new FormControl(false);
  public visionCheckControl: FormControl = new FormControl(false);
  public voluntaryCheckControl: FormControl = new FormControl(false);
  public otherCheckControl: FormControl = new FormControl(false);
  public groupadminCheckControl: FormControl = new FormControl(false);
  group_name_err: any;
  bankContent: any;
  bankContentAvailable: boolean = false;
  group_template: any;

  constructor(private formBuilder: FormBuilder,
    private deviceService: DeviceDetectorService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private service: SharingService,
    private toastr: ToastrService,
    ) {
    this.groupName = this.route.snapshot.paramMap.get('id');
    localStorage.removeItem('name')
  }

  ngOnInit(): void {
    for(let i = 1; i <= 28; i++){
      this.invoiceDates.push(i);
    }
    
    interval(1 * 60).subscribe(t => {
      const _date = new Date();
      this.est_date = _date.toLocaleString("en-US", { timeZone: "America/New_York" });
    })

    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip;
    });

    this.Form = this.formBuilder.group({
      name: ['', Validators.required],
      dba: [''],
      taxid: [''],
      typeofbusiness: [''],
      siccode: [''],
      group_code: [''],
      sftp_folder_name: [''],
      group_unique_id: [''],
      deal_id: [''],
      invoice_unique_id: [''],
      invoice_type: [''],
      invoice_recurring_date:[''],
      businessaddress: this.formBuilder.group({
        address1: [''],
        address2: [''],
        city: [''],
        state: [''],
        zipcode: [''],
      }),
      address: this.formBuilder.array([]),
      owneraddress: this.formBuilder.array([]),
      mailingaddress: this.formBuilder.group({
        address1: [''],
        address2: [''],
        city: [''],
        state: [''],
        zipcode: [''],
      }),
      aboutyouandcompany: [''],

      groupadminaddressgroup: this.formBuilder.group({
        firstname: [''],
        lastname: [''],
        title: [''],
        email: ['', Validators.compose([Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],//, Validators.pattern(this.emailPattern)
        primaryphone: ['', isValidMobileNumber],
        alternatephone: ['', isValidMobileNumber],
      }),

      healthcarrier: [''],
      monthlymedicalpremium: [''],
      plantype: [''],
      agreement: [''],
      enterText: [''],
      uploadImage: [''],

      owneraddressgroup: this.formBuilder.group({
        firstname: [''],
        lastname: [''],
        title: [''],
        email: ['', Validators.compose([Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],//, Validators.pattern(this.emailPattern)
        primaryphone: ['', isValidMobileNumber],
        alternatephone: ['', isValidMobileNumber],
      }),
      routingFormgroup: this.formBuilder.group({
        routingnumber: ['']
      }),

      accountFormgroup: this.formBuilder.group({
        accountnumber: ['']
      }),

      paymentsec: [''],
      bankname: [''],

      employeecontribution: [''],
      domocilestate: [''],
      nooflocations: [''],
      eligibleemployees: [''],
      healthplan: [''],
      workinghrs: [''],
      healthbenefits: this.formBuilder.group({
        deductible: [''],
        max_out_of_pocket: [''],
        current_carrier: ['']
      }),
      lifebenefits: this.formBuilder.group({
        benefit: [''],
        supplemental: [''],
        currentcarrier: ['']
      }),
      disabilitybenefits: this.formBuilder.group({
        std: [false],
        ltd: [false],
        currentcarrier: ['']
      }),
      dentalbenefits: this.formBuilder.group({
        orthodontia: [''],
        annualmax: [''],
        currentcarrier: ['']
      }),
      visionbenefits: this.formBuilder.group({

      }),
      voluntarybenefits: this.formBuilder.group({

      }),
      otherbenefits: this.formBuilder.group({

        otherbefefittextbox: ['']
      }),
      healthemployeedetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],

      }),
      healthspousedetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],
      }),

      healthchildrendetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],
      }),

      healthfamilydetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],
      }),

      // <-----Life--->
      lifeemployeedetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],

      }),
      lifespousedetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],
      }),

      lifechildrendetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],
      }),

      lifefamilydetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],
      }),
      // <-----Life--->

      // <-----disability--->
      disabilityemployeedetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],

      }),
      disabilityspousedetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],
      }),

      disabilitychildrendetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],
      }),

      disabilityfamilydetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],
      }),
      // <-----disability--->

      // <-----dental--->
      dentalemployeedetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],

      }),
      dentalspousedetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],
      }),

      dentalchildrendetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],
      }),

      dentalfamilydetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],
      }),
      // <-----dental--->

      // <!----Vision----->
      visionemployeedetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],

      }),
      visionspousedetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],
      }),

      visionchildrendetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],
      }),

      visionfamilydetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],
      }),

      // <!----Vision---->

      // <!----Voluntary---->
      voluntaryemployeedetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],

      }),
      voluntaryspousedetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],
      }),

      voluntarychildrendetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],
      }),

      voluntaryfamilydetails: this.formBuilder.group({
        family_type: [''],
        current: [''],
        renewal: [''],
        employer_contribution: [''],
      }),
      // <!----Voluntary---->

      renewaldate: [''],
      dissatisfactionplan: [''],
      futuregoals: [''],
      knownhealthissues: ['']

    });

    this.service.clientData.subscribe((data) => {
      if (data != '') {
        this.companyId = data.company_id;
        this.getUserInfo();
        this.getGroupTemplateSettings();
        this.getState();
        this.getBankContent()
      }
    })

    this.Form.get("paymentsec").valueChanges.subscribe(value => {
      if (value == "ACH") {
        this.Form.get("bankname").setValidators([Validators.required]);
        this.Form.get("routingFormgroup").get("routingnumber").setValidators([Validators.required]);
        this.Form.get("accountFormgroup").get("accountnumber").setValidators([Validators.required]);

      }
      else {
        this.Form.get('routingFormgroup').reset();
        this.Form.get('accountFormgroup').reset();
        this.Form.get("bankname").reset();

        this.Form.get("bankname").clearValidators();
        this.Form.get("routingFormgroup").get("routingnumber").clearValidators();
        this.Form.get("accountFormgroup").get("accountnumber").clearValidators();

        this.Form.get("bankname")
          .updateValueAndValidity({ emitEvent: false });
        this.Form.get("routingFormgroup").get("routingnumber")
          .updateValueAndValidity({ emitEvent: false });
        this.Form.get("accountFormgroup").get("accountnumber")
          .updateValueAndValidity({ emitEvent: false });
      }
    });

    this.healthCheckControl.valueChanges.subscribe(value => {
    })

    this.isSameAddressControl
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap(isSameAddress => {
          this.ischeckedAddress = true;
          if (isSameAddress) {
            return this.Form
              .get('businessaddress')
              .valueChanges
              .pipe(
                startWith(this.Form.get('businessaddress').value),
                tap(value =>
                  this.Form
                    .get('mailingaddress')
                    .setValue(value)
                )
              )
          } else {
            this.Form.get('mailingaddress').reset();
            this.ischeckedAddress = false;
            return EMPTY;
          }
        })

      )
      .subscribe();


    this.groupadminCheckControl
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap(isSameAddress => {
          this.ischeckedownerAddress = true;
          if (isSameAddress) {

            return this.Form
              .get('owneraddressgroup')
              .valueChanges
              .pipe(
                startWith(this.Form.get('owneraddressgroup').value),
                tap(value =>
                  this.Form
                    .get('groupadminaddressgroup')
                    .setValue(value)
                )
              )
          } else {
            this.Form.get('groupadminaddressgroup').reset();
            this.ischeckedownerAddress = false;
            return EMPTY;
          }
        })

      )
      .subscribe();


  }

  createOwnerAddress(): FormGroup {
    return this.formBuilder.group({
      firstname: [''],
      lastname: [''],
      title: [''],
      email: ['', Validators.compose([Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],//, Validators.pattern(this.emailPattern)
      primaryphone: ['', isValidMobileNumber],
      alternatephone: ['', isValidMobileNumber]
    })
  }


  createAddress(): FormGroup {
    return this.formBuilder.group({
      address1: [''],
      address2: [''],
      city: [''],
      state: [''],
      zipcode: [''],
    });
  }
  get address(): FormArray {
    return this.Form.get('address') as FormArray;
  };

  get owneraddress(): FormArray {
    return this.Form.get('owneraddress') as FormArray;
  }

  get healthbenefits() {
    return this.Form.get('healthbenefits') as FormGroup;

  }

  addAddress() {
    this.address.push(this.createAddress())
  }
  addownerAddress() {
    this.owneraddress.push(this.createOwnerAddress());
  }
  removeOwnerAddress(i) {
    this.owneraddress.removeAt(i);
  }

  matchingPasswords(routingno: string, confirmroutingno: string, accountno: string, confirmaccountno: string) {
    return (group: FormGroup) => {
      let routing = group.controls[routingno];
      let confirmrouting = group.controls[confirmroutingno];
      if (confirmrouting.errors && !confirmrouting.errors['mismatchedPasswords']) {
        return;
      }

      if (routing.value !== confirmrouting.value) {
        confirmrouting.setErrors({ mismatchedPasswords: true })
      }
      else {
        confirmrouting.setErrors(null)
      }
      let accountnumber = group.controls[accountno];
      let confirmaccount = group.controls[confirmaccountno];
      if (confirmaccount.errors && !confirmaccount.errors['mismatchedPasswords']) {
        return;
      }
      if (accountnumber.value !== confirmaccount.value) {
        confirmaccount.setErrors({ mismatchedPasswords: true })
      }
      else {
        confirmaccount.setErrors(null)
      }
    }
  }

  getGroupTemplateSettings() {
    var url = 'GetGroupAddTemplateSettings/' + this.companyId;
    this.service.getData(url).subscribe((res: any) => {
      if (res.status) {
        this.group_template = res?.data?.template;
        switch (this.group_template) {
          case 'template_1':
            this.Form.get("invoice_unique_id").reset();
            this.Form.get("invoice_unique_id").clearValidators();
            this.Form.get("invoice_unique_id")
            .updateValueAndValidity({ emitEvent: false });

            this.Form.get("businessaddress").get("address1").setValidators([Validators.required]);
            this.Form.get("businessaddress").get("city").setValidators([Validators.required]);
            this.Form.get("businessaddress").get("state").setValidators([Validators.required]);
            this.Form.get("businessaddress").get("zipcode").setValidators([Validators.required]);
            this.Form.get("groupadminaddressgroup").get("firstname").setValidators([Validators.required]);

            this.Form.get("businessaddress").get("address1").updateValueAndValidity({ emitEvent: false });
            this.Form.get("businessaddress").get("city").updateValueAndValidity({ emitEvent: false });
            this.Form.get("businessaddress").get("state").updateValueAndValidity({ emitEvent: false });
            this.Form.get("businessaddress").get("zipcode").updateValueAndValidity({ emitEvent: false });
            this.Form.get("groupadminaddressgroup").get("firstname").updateValueAndValidity({ emitEvent: false });
            break;

            default:
                this.Form.get("invoice_unique_id").setValidators([Validators.required]);
                this.Form.get("invoice_unique_id")
                .updateValueAndValidity({ emitEvent: false });
                this.Form.get("businessaddress").get("address1").clearValidators();
                this.Form.get("businessaddress").get("city").clearValidators();
                this.Form.get("businessaddress").get("state").clearValidators();
                this.Form.get("businessaddress").get("zipcode").clearValidators();
                this.Form.get("groupadminaddressgroup").get("firstname").clearValidators();

                this.Form.get("businessaddress").get("address1").reset();
                this.Form.get("businessaddress").get("city").reset();
                this.Form.get("businessaddress").get("state").reset();
                this.Form.get("businessaddress").get("zipcode").reset();
                this.Form.get("groupadminaddressgroup").get("firstname").reset();

                this.Form.get("businessaddress").get("address1").updateValueAndValidity({ emitEvent: false });
                this.Form.get("businessaddress").get("city").updateValueAndValidity({ emitEvent: false });
                this.Form.get("businessaddress").get("state").updateValueAndValidity({ emitEvent: false });
                this.Form.get("businessaddress").get("zipcode").updateValueAndValidity({ emitEvent: false });
                this.Form.get("groupadminaddressgroup").get("firstname").updateValueAndValidity({ emitEvent: false });

                break;

        }
      }
    }, err => {
      console.log(err);
      this.toastr.error(err.error.message);
    })
  }

  getState() {
    var url = 'GroupListSalableUsaStates/'+this.companyId;
    this.service.getData(url).subscribe((Object: any) => {
      if (Object !== null) {
        this.usaStates = Object.data;
      }
    }, err => {
      console.log(err);
      this.toastr.error(err.error.message);
    })
  }

  getBankContent() {
    var url = 'GetAddGroupBankingInfo/' + this.companyId;
    this.service.getData(url).subscribe((res: any) => {
      if (res.status) {
        if (res.data.length > 0) {
          this.bankContent = res.data.map(item => {
            return {
              heading: item.heading,
              paragraphs: Object.values(item.body)
            };
          });
          this.bankContentAvailable = true
        }
      }
    }, err => {
      console.log(err);
      this.toastr.error(err.error.message);
    })
  }

  getUserInfo() {
    let body = { type: "decrypt", encrypted_user_id: this.groupName }
    var url = 'DecryptUserInfo'
    this.service.postData(url, body).subscribe((Object: any) => {
      if (Object != null) {
        this.DecryptData = Object.user_id
      }
    }, err => {
      this.toastr.error(err.error.message);
    })
  }
  get businessaddress() {
    return this.Form.get('businessaddress') as FormGroup;

  }
  get groupadminaddressgroup() {
    return this.Form.get('groupadminaddressgroup') as FormGroup;

  }

  get owneraddressgroup() {
    return this.Form.get('owneraddressgroup') as FormGroup;

  }
  get mailingaddress() {
    return this.Form.get('mailingaddress') as FormGroup;
  }
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvas.nativeElement);
  }
  clear() {
    this.signaturePad.clear();
    this.signatureImg = '';
  }
  removeLogo() {
    this.uploadedFilelogo = undefined;
    this.Form.controls.uploadImage.setValue('');
  }
  uploadlogo(event) {
    this.uploadedFilelogo = event.target.files[0];
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrlLogo = event.target.result;
    }
    reader.readAsDataURL(this.uploadedFilelogo);
  }
  uploadDoc(event) {
    this.uploadedFileattachment = event.target.files[0];
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrlAttachment = event.target.result;
    }
    reader.readAsDataURL(this.uploadedFileattachment);
  }
  upload(event) {
    this.uploadedFile = event.target.files[0];
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
      this.message = '';
    }
    reader.readAsDataURL(this.uploadedFile);
  }
  onTabClick(data: any) {
    this.onTabClickData = data;
    if (data === 'text') {
      this.firstDiv = false;
      this.secondDiv = true;
      this.thirdDiv = false;
      this.uploadedFile = '';
      this.onfocusoutSignature();
      this.Form.controls.uploadImage.setValue('');
      this.signaturePad.clear();
    } else if (data === 'image') {
      this.firstDiv = false;
      this.secondDiv = false;
      this.thirdDiv = true;
      this.signature = '';
      this.Form.controls.enterText.setValue('');
      this.signaturePad.clear();
    } else if (data === 'esignature') {
      this.firstDiv = true;
      this.secondDiv = false;
      this.thirdDiv = false;
      this.imageUrl = '';
      this.uploadedFile = '';
      this.Form.controls.enterText.setValue('');
      this.Form.controls.uploadImage.setValue('');
    }
  }
  onFocusoutInput(feature, formgroup) {
    this.Form.controls[formgroup].value.family_type = feature;
  }
  onfocusoutSignature() {
    this.signature = (<HTMLInputElement>document.getElementById("signature")).value;
    this.message = '';
  }
  removeAddress(i) {
    this.address.removeAt(i);
  }
  signatureField(value) {
    if (this.signaturePad.toData().length > 0) {
      this.message = "";
      this.signatureVAlid = value;
    } else {
      this.message = "Signature is required";
    }
  }

  onAddGroup(value) {
    this.submitAttempt = true;
    for (let key in value.groupadminaddressgroup) {
      if (value.groupadminaddressgroup[key] == "null" || value.groupadminaddressgroup[key] == null)
        value.groupadminaddressgroup[key] = '';
    }
    this.finalMailingaddress = [];
    this.finalAddress = [];
    this.finalownerAddress = [];
    this.emptyAddressobjects = [];
    if (this.healthCheckControl.value) {
      var result = Object.keys(value.healthbenefits).map(e => ({ name: e, value: value.healthbenefits[e] }))
      let obj = {
        type: "",
        other_details: [],
        charges_details: []
      }
      this.benefits.push(obj);

      this.benefits[this.benefits.length - 1]['type'] = "Health";
      this.benefits[this.benefits.length - 1]['other_details'] = result;
      let healtharr: any = [];
      healtharr.push(value.healthchildrendetails);
      healtharr.push(value.healthemployeedetails);
      healtharr.push(value.healthfamilydetails);
      healtharr.push(value.healthspousedetails);
      this.benefits[this.benefits.length - 1]['charges_details'] = healtharr;
    }

    if (this.lifeCheckControl.value) {
      let obj = {
        type: "",
        other_details: [],
        charges_details: []
      }
      this.benefits.push(obj);
      var result = Object.keys(value.lifebenefits).map(e => ({ name: e, value: value.lifebenefits[e] }))

      this.benefits[this.benefits.length - 1]['type'] = "Life";
      this.benefits[this.benefits.length - 1]['other_details'] = result;
      let healtharr: any = [];
      healtharr.push(value.lifechildrendetails);
      healtharr.push(value.lifeemployeedetails);
      healtharr.push(value.lifefamilydetails);
      healtharr.push(value.lifespousedetails);
      this.benefits[this.benefits.length - 1]['charges_details'] = healtharr;
    }

    if (this.disabilityCheckControl.value) {
      let obj = {
        type: "",
        other_details: [],
        charges_details: []
      }
      this.benefits.push(obj);
      var result = Object.keys(value.disabilitybenefits).map(e => ({ name: e, value: value.disabilitybenefits[e] }))
      for (var i = 0; i < result.length; i++) {
        if (result[i].value == true) {
          result[i].value = 1
        }
        if (result[i].value == false) {
          result[i].value = 0;
        }

      }

      this.benefits[this.benefits.length - 1]['type'] = "Disability";
      this.benefits[this.benefits.length - 1]['other_details'] = result;
      let healtharr: any = [];
      healtharr.push(value.disabilitychildrendetails);
      healtharr.push(value.disabilityemployeedetails);
      healtharr.push(value.disabilityfamilydetails);
      healtharr.push(value.disabilityspousedetails);
      this.benefits[this.benefits.length - 1]['charges_details'] = healtharr;
    }


    if (this.dentalCheckControl.value) {
      let obj = {
        type: "",
        other_details: [],
        charges_details: []
      }
      this.benefits.push(obj);
      var result = Object.keys(value.dentalbenefits).map(e => ({ name: e, value: value.dentalbenefits[e] }))

      this.benefits[this.benefits.length - 1]['type'] = "Dental";
      this.benefits[this.benefits.length - 1]['other_details'] = result;
      let healtharr: any = [];
      healtharr.push(value.dentalchildrendetails);
      healtharr.push(value.dentalemployeedetails);
      healtharr.push(value.dentalfamilydetails);
      healtharr.push(value.dentalspousedetails);
      this.benefits[this.benefits.length - 1]['charges_details'] = healtharr;
    }

    if (this.visionCheckControl.value) {
      let obj = {
        type: "",
        other_details: [],
        charges_details: []
      }
      this.benefits.push(obj);
      var result = Object.keys(value.visionbenefits).map(e => ({ name: e, value: value.visionbenefits[e] }))

      this.benefits[this.benefits.length - 1]['type'] = "Vision";
      this.benefits[this.benefits.length - 1]['other_details'] = result;
      let healtharr: any = [];
      healtharr.push(value.visionchildrendetails);
      healtharr.push(value.visionemployeedetails);
      healtharr.push(value.visionfamilydetails);
      healtharr.push(value.visionspousedetails);
      this.benefits[this.benefits.length - 1]['charges_details'] = healtharr;
    }

    if (this.voluntaryCheckControl.value) {
      let obj = {
        type: "",
        other_details: [],
        charges_details: []
      }
      this.benefits.push(obj);
      var result = Object.keys(value.voluntarybenefits).map(e => ({ name: e, value: value.voluntarybenefits[e] }))

      this.benefits[this.benefits.length - 1]['type'] = "Voluntary";
      this.benefits[this.benefits.length - 1]['other_details'] = result;
      let healtharr: any = [];
      healtharr.push(value.voluntarychildrendetails);
      healtharr.push(value.voluntaryemployeedetails);
      healtharr.push(value.voluntaryfamilydetails);
      healtharr.push(value.voluntaryspousedetails);
      this.benefits[this.benefits.length - 1]['charges_details'] = healtharr;
    }
    if (this.otherCheckControl.value) {
      let obj = {
        type: "",
        other_details: [],
        charges_details: []
      }

      this.benefits.push(obj);
      let arr = [
        { name: "Other", value: value.otherbenefits.otherbefefittextbox }
      ]

      this.benefits[this.benefits.length - 1]['type'] = "Other";
      this.benefits[this.benefits.length - 1]['other_details'] = arr;

    }
    this.filteredbenefitsArr = this.benefits.reduce((unique, o) => {
      if (!unique.some(obj => obj.type === o.type)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.submitAttempt = true;
    if (this.signature != undefined && this.signature != '') {
      this.onAddGroup1(value)
      this.uploadedFile = '';
    }
    else if (this.uploadedFile != undefined && this.uploadedFile != '') {
      this.onAddGroup1(value);
      this.Form.controls.enterText.setValue('');
    }
    else if (this.signatureVAlid == 'signatureValid' && this.signaturePad.toData().length > 0) {
      this.onAddGroup1(value)
    }
    else {
      this.message = "Signature is required";
    }
  }


  onAddGroup1(value) {

    this.mergedArray = [];
    this.mailingAddressarr = [];
    this.finalMailingaddress = [];
    this.submitAttempt = true;
    let formData: FormData = new FormData();
    if (this.Form.valid) {
      if (this.personContactNumber != null) {
        this.personContactNumber = value.personContactNumber.replace(/[^0-9\.]+/g, "");
      }
      formData.append('group_name', value.name);
      formData.append('template', this.group_template);
      formData.append('company_id', this.companyId);
      formData.append('dba', value.dba);
      formData.append('business_type', value.typeofbusiness);
      formData.append('sic_code', value.siccode);
      formData.append('group_code', value.group_code);
      formData.append('sftp_folder_name', value.sftp_folder_name);
      formData.append('group_unique_id', value.group_unique_id);
      formData.append('deal_id', value.deal_id);
      if(this.group_template != 'template_1'){
        formData.append('invoice_unique_id', value.invoice_unique_id);
      }
      formData.append('invoice_type', value.invoice_type);
      formData.append('invoice_recurring_date', value.invoice_recurring_date);
      formData.append('created_by', this.DecryptData);
      formData.append('tax_id', value.taxid);
      formData.append('created_from', 'GroupLink');
      formData.append('company_info', value.aboutyouandcompany);
      formData.append('employee_benefit', value.employeecontribution);
      formData.append('esignature_type', 'text');
      formData.append('browser_name', this.deviceService.browser);
      formData.append('browser_version', this.deviceService.browser_version);
      if (value.paymentsec == "ACH") {
        formData.append('routing_number', value.routingFormgroup.routingnumber);
        formData.append('account_number', value.accountFormgroup.accountnumber);
        formData.append('bank_name', value.bankname);
      }

      formData.append('account_type', value.paymentsec);
      formData.append('add1', value.businessaddress.address1);
      formData.append('add2', value.businessaddress.address2);
      formData.append('city', value.businessaddress.city);
      formData.append('state', value.businessaddress.state);
      formData.append('zip', value.businessaddress.zipcode);
      formData.append('c_firstname', value.groupadminaddressgroup.firstname);
      formData.append('c_lastname', value.groupadminaddressgroup.lastname);
      if (value.groupadminaddressgroup.email != '' && value.groupadminaddressgroup.email != undefined) {
        formData.append('c_email', value.groupadminaddressgroup.email);
      }
      formData.append('c_title', value.groupadminaddressgroup.title);
      formData.append('c_mobile', value.groupadminaddressgroup.primaryphone);
      formData.append('c_alternate_phone', value.groupadminaddressgroup.alternatephone);
      formData.append('status', '5');
      formData.append('ip', this.ipAddress);

      formData.append("domocile_state", value.domocilestate);
      formData.append("location_no", value.nooflocations);
      formData.append("eligible_employee_no", value.eligibleemployees);
      formData.append("current_health_plan_employee_no", value.healthplan);
      formData.append("weekly_hours_to_eligible_for_h_plan", value.workinghrs);
      formData.append("any_known_health_issues", value.knownhealthissues);
      formData.append("dissatification_with_current_plan", value.dissatisfactionplan);
      formData.append("goals_for_future", value.futuregoals);
      value.renewaldate = this.datePipe.transform(value.renewaldate, "yyyy-MM-dd")
      if (value.renewaldate != null) {
        formData.append("renewal_date", value.renewaldate);
      }

      this.finalMailingaddress.push(value.mailingaddress);
      let res = this.finalMailingaddress.every(element => Object.values(element).every(val => val === ""));
      if (!res) {
        this.finalMailingaddress[0]["type"] = "Mail";
        this.mergedArray.push(...this.finalMailingaddress);
      }
      this.finalAddress = value.address;
      if (this.finalAddress.length > 0) {
        for (var i = 0; i < this.finalAddress.length; i++) {
          let result = Object.values(this.finalAddress[i]).every(x => x === null || x === "");
          if (result) {
            this.emptyAddressobjects.push(this.finalAddress[i]);
          }
        }
      }

      this.finalAddress = this.finalAddress.filter(item => !this.emptyAddressobjects.some(itemToBeRemoved => itemToBeRemoved === item))

      this.finalAddress.forEach(ele => {
        ele["type"] = "Additional"
      });
      this.mergedArray.push(...this.finalAddress);

      if (this.mergedArray.length > 0) {
        for (var i = 0; i < this.mergedArray.length; i++) {
          formData.append("other_add_type[" + i + "]", this.mergedArray[i].type);
          formData.append("other_add1[" + i + "]", this.mergedArray[i].address1);
          formData.append("other_add2[" + i + "]", this.mergedArray[i].address2);
          formData.append("other_city[" + i + "]", this.mergedArray[i].city);
          formData.append("other_state[" + i + "]", this.mergedArray[i].state);
          formData.append("other_zip[" + i + "]", this.mergedArray[i].zipcode);
        }
      }
      this.finalownerAddress.unshift(value.owneraddressgroup);
      this.finalownerAddress.push(...value.owneraddress);

      if (this.finalownerAddress.length > 0) {
        for (var i = 0; i < this.finalownerAddress.length; i++) {
          let result = Object.values(this.finalownerAddress[i]).every(x => x === null || x === "");
          if (result) {
            this.emptyOwnerAddress.push(this.finalownerAddress[i]);
          }
        }
      }
      this.finalownerAddress = this.finalownerAddress.filter(item => !this.emptyOwnerAddress.some(itemToBeRemoved => itemToBeRemoved === item))

      if (this.finalownerAddress.length > 0) {
        for (var i = 0; i < this.finalownerAddress.length; i++) {
          formData.append("o_firstname[" + i + "]", this.finalownerAddress[i].firstname);
          formData.append("o_lastname[" + i + "]", this.finalownerAddress[i].lastname);
          formData.append("o_title[" + i + "]", this.finalownerAddress[i].title);
          formData.append("o_email[" + i + "]", this.finalownerAddress[i].email);
          formData.append("o_mobile[" + i + "]", this.finalownerAddress[i].primaryphone);
          formData.append("o_alternate_phone[" + i + "]", this.finalownerAddress[i].alternatephone);
        }
      }
      if (this.filteredbenefitsArr.length > 0) {
        for (var i = 0; i < this.filteredbenefitsArr.length; i++) {
          formData.append("benefits[" + i + "][type]", this.filteredbenefitsArr[i].type);
          for (var j = 0; j < this.filteredbenefitsArr[i].other_details.length; j++) {
            formData.append("benefits[" + i + "][other_details][" + j + "][name]", this.filteredbenefitsArr[i].other_details[j].name);
            formData.append("benefits[" + i + "][other_details][" + j + "][value]", this.filteredbenefitsArr[i].other_details[j].value);

          }
          for (var k = 0; k < this.filteredbenefitsArr[i].charges_details.length; k++) {
            formData.append("benefits[" + i + "][charges_details][" + k + "][family_type]", this.filteredbenefitsArr[i].charges_details[k].family_type);
            formData.append("benefits[" + i + "][charges_details][" + k + "][current]", this.filteredbenefitsArr[i].charges_details[k].current);
            formData.append("benefits[" + i + "][charges_details][" + k + "][employer_contribution]", this.filteredbenefitsArr[i].charges_details[k].employer_contribution);
            formData.append("benefits[" + i + "][charges_details][" + k + "][renewal]", this.filteredbenefitsArr[i].charges_details[k].renewal);
          }
        }
      }

      if (this.signature) {
        formData.append('esignature', this.signature);
      } else if (this.onTabClickData == 'esignature') {
        this.signatureImg = this.signaturePad.toDataURL();
        formData.append('esignature', this.signatureImg);
        formData.append('esignature_type', 'esignature');
      } else if (this.onTabClickData === 'image') {
        formData.append('esignature', this.uploadedFile);
        formData.append('esignature_type', 'image');
      }
      if (this.uploadedFilelogo) {
        formData.append('logo', this.uploadedFilelogo);
      }


      var url = 'AddGroupPublic'
      this.service.postData(url, formData).subscribe((Object: any) => {
        if (Object != null) {
          this.toastr.success(Object.message);
          this.Form.reset();
          this.isSameAddressControl.setValue(false)
          this.healthCheckControl.setValue(false);
          this.lifeCheckControl.setValue(false)
          this.disabilityCheckControl.setValue(false)
          this.dentalCheckControl.setValue(false)
          this.visionCheckControl.setValue(false)
          this.voluntaryCheckControl.setValue(false)
          this.otherCheckControl.setValue(false);
          this.groupadminCheckControl.setValue(false);
          this.address.clear()
          this.owneraddress.clear()
          this.uploadedFile = '';
          this.uploadedFilelogo = '';
          this.signatureImg = '';
          this.submitAttempt = false;
          this.clear();
          localStorage.setItem('name', value.name)
          this.router.navigate(['confirmation', 'addgroup']);
        }
      }, err => {
        console.log(err);
        if (err.error.errors != null) {
          if(err.error.errors.c_email)
            this.toastr.error(err.error.errors.c_email);
          else
            this.toastr.error(err.error.message);
        }
        else {
          this.toastr.error(err.error.message);
        }

        if (!!err.error.errors && err.error.errors?.group_name) {
          this.group_name_err = err.error.errors?.group_name;
        }
        if (!!err.error.errors && err.error.errors?.group_unique_id) {
          this.uniqueGroupID_err = err.error.errors?.group_unique_id;
        }
        if (!!err.error.errors && err.error.errors?.deal_id) {
          this.dealID_err = err.error.errors?.deal_id;
        }   
        if (!!err.error.errors && err.error.errors?.invoice_type) {
          this.invoiceType_err = err.error.errors?.invoice_type;
        }
        if (!!err.error.errors && err.error.errors?.invoice_recurring_date) {
          this.invoiceDate_err = err.error.errors?.invoice_recurring_date;
        }
      })
    }
  }
}

import {Injectable} from '@angular/core';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    constructor() {
        window.dataLayer = window.dataLayer || [];
    }

    public appendGtmScript(id): void {
        const script = document.createElement('script');
        script.innerHTML = `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','` + id + `');`;
        document.head.appendChild(script);

        const noScript = document.createElement('noscript');
        script.innerHTML = `<iframe src=https://www.googletagmanager.com/ns.html?id=` + id + `\n' +
        'height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        document.head.appendChild(noScript);
    }
}

<div class="container">
  <div class="enrlmnt-demographic">
      <div class="stepper">
          <div class="step active"></div>
          <div class="step"></div>
          <div class="step"></div>
      </div>
  </div>

  <h5 class="form-title mb-4">New Group Onboarding:</h5>

  <form [formGroup]="Form">
    <div class="card shadow-sm mb-5">
        <div class="card-body">

            <h6 class="title-hr">
                <span>Group Information</span>
            </h6>

            <div class="row">
                <div class="col-md-4 form-group">
                    <label class="required">Name </label>
                    <input type="text" formControlName="name" class="form-control" placeholder="Enter Name">
                    <small class="errormsg"
                        *ngIf="!Form.controls.name.valid && (Form.controls.name.dirty || submitAttempt)">
                        Name is required
                    </small>
                    <small class="errormsg" *ngIf="!!group_name_err && group_name_err">
                      {{group_name_err}}
                    </small>
                </div>

                <div class="col-md-4 form-group">
                    <label>DBA</label>
                    <input type="text" formControlName="dba" class="form-control" placeholder="Enter DBA">

                </div>


                <div class="col-md-4 form-group">
                    <label class="">Tax ID(EIN) </label>
                    <input type="text" formControlName="taxid" class="form-control" placeholder="Enter Tax ID">
                </div>

                <div class="col-md-4 form-group" *ngIf="group_template != 'template_1'">
                    <label class="">Type of Business </label>
                    <input type="text" formControlName="typeofbusiness" class="form-control"
                        placeholder="Type of Business">
                </div>

                <div class="col-md-4 form-group" *ngIf="group_template != 'template_1'">
                    <label class="">SIC Code </label>
                    <input type="text" formControlName="siccode" class="form-control" placeholder="SIC Code">
                </div>
            
                    <div class="col-md-4 form-group" *ngIf="group_template != 'template_1'">
                     <label class="">Your Picture or Logo </label>
                    <div class="form-uploader-container">
                        <input type="file" multiple="multiple" accept="image/*" name="uploadFile" class="form-control"
                        (change)="uploadlogo($event)" id="uploadFIle" formControlName="uploadImage" >
                             </div>
                  </div>
                  <div class="col-md-4 form-group" *ngIf="group_template != 'template_1'">
                    <label class="">Group Code </label>
                    <input type="text" formControlName="group_code" class="form-control" placeholder="Group Code">
                </div>
                <div class="col-md-4 form-group" *ngIf="group_template != 'template_1'">
                  <label class="">SFTP Folder Name </label>
                  <input type="text" formControlName="sftp_folder_name" class="form-control" placeholder="SFTP Folder Name">
              </div>

                <div class="col-md-4 form-group">
                    <label>Group ID </label>
                    <input type="text" formControlName="group_unique_id" class="form-control" placeholder="Group ID">
                    <small class="errormsg" *ngIf="!!uniqueGroupID_err && uniqueGroupID_err">
                        {{uniqueGroupID_err}}
                    </small>
                </div>

                <div class="col-md-4 form-group">
                    <label>Deal ID</label>
                    <input type="text" formControlName="deal_id" class="form-control" placeholder="Deal ID">
                    <small class="errormsg" *ngIf="!!dealID_err && dealID_err">
                        {{dealID_err}}
                    </small>
                </div>

                <div class="col-md-4 form-group">
                    <label>Invoice Type</label>
                    <select formControlName="invoice_type" name="invoice_type" class="form-select">
                        <option value="" disabled selected>Select Invoice Type</option>
                        <option value="prepaid">Prepaid</option>
                        <option value="postpaid">Postpaid</option>
                    </select>
                    <small class="errormsg" *ngIf="!!invoiceType_err && invoiceType_err">
                        {{invoiceType_err}}
                    </small>
                </div>
            
                <div class="col-md-4 form-group">
                    <label>Invoice Recurring Date</label>
                    <select formControlName="invoice_recurring_date" name="invoice_recurring_date" class="form-select">
                        <option value="" disabled selected>Select Invoice Recurring Date</option>
                        <option *ngFor="let id of invoiceDates" value="{{ id }}">{{ id }}</option>
                    </select>
                    <small class="errormsg" *ngIf="!!invoiceDate_err && invoiceDate_err">
                        {{invoiceDate_err}}
                    </small>                
                </div>

              <div class="col-md-4 form-group" *ngIf="group_template != 'template_1'">
                <label [ngClass]="group_template != 'template_1' ? 'required' : ''">Invoice Unique Id </label>
                <select formControlName="invoice_unique_id" class="form-select">
                    <option value="" disabled selected>Select Invoice Unique Id</option>
                    <option *ngFor="let item of invoiceUniqueIdTypes" value="{{item.value}}">{{item.name}}</option>
                </select>
                <small class="errormsg"
                *ngIf="Form.controls['invoice_unique_id'].errors?.required && (Form.controls['invoice_unique_id'].touched || submitAttempt)">
                Invoice Unique Id is required
            </small>
            </div>
                <div class="col-md-4 " *ngIf="group_template != 'template_1' && uploadedFilelogo !=undefined">
                    <div class="form-uploader-container">
                      <div class="upload-preview">
                        <div class="preview-image">
                          <img *ngIf="imageUrlLogo" [src]="imageUrlLogo" class="displayImageLogo">
                        </div>
                        <i class="ri-delete-bin-line" (click)="removeLogo()"></i>
                        <i class="ri-eye-line"></i>
                      </div>
                    </div>
                  </div>
            </div>

            <h6 class="title-hr">
                <span>Address</span>
            </h6>
            <h5 class="mb-4">
                Business Address
            </h5>
            <ng-container formGroupName="businessaddress">
                <div class="row">
                    <div class="col-md-6 form-group">
                        <label [ngClass]="group_template == 'template_1' ? 'required' : ''">Address1 </label>
                        <input type="text" formControlName="address1" class="form-control"
                            placeholder="Enter Suite/flat/Building No#">
                            <small class="errormsg"
                            *ngIf="Form.get('businessaddress').get('address1').errors?.required && (Form.get('businessaddress').get('address1').dirty || submitAttempt)">
                            Address1 is required
                        </small>
                    </div>
                    <div class="col-md-6 form-group">
                        <label>Address2</label>
                        <input type="text" formControlName="address2" class="form-control"
                            placeholder="Enter Suite/flat/Building No#">
                    </div>
                    <div class="col-md-4 form-group">
                        <label  [ngClass]="group_template == 'template_1' ? 'required' : ''"> City </label>
                        <input type="text" formControlName="city" class="form-control" placeholder="Enter City">
                        <small class="errormsg"
                        *ngIf="Form.controls.businessaddress.controls.city.errors?.required && (Form.controls.businessaddress.controls.city.dirty || submitAttempt)">
                        City is required
                    </small>
                    </div>
                    <div class="col-md-4 form-group">
                        <label  [ngClass]="group_template == 'template_1' ? 'required' : ''">State </label>
                        <select formControlName="state" class="form-select">
                            <option value="" disabled selected>Select Your State</option>
                            <option *ngFor="let item of usaStates" value="{{item.name}}">{{item.name}}</option>
                        </select>
                        <small class="errormsg"
                        *ngIf="Form.controls.businessaddress.controls['state'].errors?.required && (Form.controls.businessaddress.controls.state.dirty || submitAttempt)">
                        State is required
                    </small>
                    </div>
                    <div class="col-md-4 form-group">
                        <label  [ngClass]="group_template == 'template_1' ? 'required' : ''">Zip Code </label>
                        <input type="text" formControlName="zipcode" numbersOnly class="form-control" maxlength="5"
                            minlength="5" placeholder="Enter Zip Code">
                        <small class="errormsg"
                            *ngIf="Form.controls.businessaddress.controls['zipcode'].errors?.required && (Form.controls.businessaddress.controls['zipcode'].touched || submitAttempt)">
                            Zip Code is required
                        </small>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="group_template != 'template_1'">

              <h5 class="mb-4">Mailing Address</h5>
              <label class="d-flex gap-2 mb-3">
                  <input type="checkbox" [formControl]="isSameAddressControl">
                  <span>Check if Mailing address is same as business address</span>
              </label>

              <ng-container formGroupName="mailingaddress">

                  <div class="row">
                      <div class="col-md-6 form-group">
                          <label>Address1 </label>
                          <input type="text" formControlName="address1" class="form-control"
                              [attr.disabled]="ischeckedAddress?'':null" placeholder="Enter Suite/flat/Building No#">

                      </div>
                      <div class="col-md-6 form-group">
                          <label>Address2</label>
                          <input type="text" formControlName="address2" class="form-control"
                              [attr.disabled]="ischeckedAddress?'':null" placeholder="Enter Suite/flat/Building No#">
                      </div>
                      <div class="col-md-4 form-group">
                          <label> City </label>
                          <input type="text" formControlName="city" [attr.disabled]="ischeckedAddress?'':null"
                              class="form-control" placeholder="Enter City">

                      </div>
                      <div class="col-md-4 form-group">
                          <label>State </label>
                          <select formControlName="state" class="form-select"
                              [attr.disabled]="ischeckedAddress?'':null">
                              <option value="" disabled selected>Select Your State</option>
                              <option *ngFor="let item of usaStates" value="{{item.name}}">{{item.name}}</option>
                          </select>

                      </div>
                      <div class="col-md-4 form-group">
                          <label>Zip Code </label>
                          <input type="text" formControlName="zipcode" [attr.disabled]="ischeckedAddress?'':null"
                              numbersOnly class="form-control" maxlength="5" minlength="5"
                              placeholder="Enter Zip Code">
                          <small class="errormsg"
                              *ngIf="!mailingaddress.controls['zipcode'].valid && (mailingaddress.controls['zipcode'].touched || submitAttempt)">
                              Zip Code is required
                          </small>
                      </div>
                  </div>
              </ng-container>

                              <button class="btn btn-primary d-flex gap-2 mb-3" (click)="addAddress()"><i
                                      class="ri-add-line mr-1"></i>Add Another Address</button>
            </ng-container>


            <div formArrayName="address" *ngIf="group_template != 'template_1'">
                <div *ngFor="let item of address.controls; let i = index;" class="dependent-card">
                    <div [formGroupName]="i">
                        <div class="  d-flex justify-content-between align-items-center">
                            <div>
                                <b>Another Address - {{i+1}}</b>
                            </div>

                        </div>
                        <div class="row mt-4">
                            <div class="col-md-6 form-group">
                                <label>Address1 </label>
                                <input type="text" formControlName="address1" class="form-control"
                                    placeholder="Enter Suite/flat/Building No#">


                            </div>
                            <div class="col-md-6 form-group">
                                <label>Address2</label>
                                <input type="text" formControlName="address2" class="form-control"
                                    placeholder="Enter Suite/flat/Building No#">
                            </div>
                            <div class="col-md-4 form-group">
                                <label> City </label>
                                <input type="text" formControlName="city" class="form-control"
                                    placeholder="Enter City">

                            </div>
                            <div class="col-md-4 form-group">
                                <label>State </label>
                                <select formControlName="state" class="form-select">
                                    <option value="" disabled selected>Select Your State</option>
                                    <option *ngFor="let item of usaStates" value="{{item.name}}">{{item.name}}
                                    </option>
                                </select>

                            </div>
                            <div class="col-md-4 form-group">
                                <label>Zip Code </label>
                                <input type="text" formControlName="zipcode" numbersOnly class="form-control"
                                    maxlength="5" minlength="5" placeholder="Zip Code">

                                <small class="errormsg"
                                    *ngIf="!item.get('zipcode')?.valid &&(item.get('zipcode')?.touched || submitAttempt )"
                                    class="text-danger">
                                    Zip Code is required.
                                </small>
                            </div>

                            <div class="col-md-12 text-end">
                                <button type="submit" class="btn-remove mb-3" (click)="removeAddress(i)"><i
                                        class="ri-user-unfollow-line"></i> Remove </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>








            <div class="col-md-12 form-group mt-4" *ngIf="group_template != 'template_1'">
                <label class="">Information about you or your company </label>
                <textarea class="form-control" formControlName="aboutyouandcompany" rows="3"
                    placeholder="Write here..."></textarea>
            </div>

            <div class="row">

            </div>

            <h6 class="title-hr">
                <span>Owner Information</span>
            </h6>
            <ng-container formGroupName="owneraddressgroup">
                <div class="row">
                    <div class="col-md-4 form-group">
                        <label  >First Name </label>
                        <input type="text" formControlName="firstname" class="form-control"
                            placeholder="Enter First Name">



                    </div>

                    <div class="col-md-4 form-group">
                        <label>Last Name </label>
                        <input type="text" formControlName="lastname" class="form-control"
                            placeholder="Enter Last Name">

                    </div>


                    <div class="col-md-4 form-group">
                        <label>Title </label>
                        <input type="text" formControlName="title" class="form-control" placeholder="Enter Title">

                    </div>


                    <div class="col-md-4 form-group">
                        <label>Email </label>
                        <input type="text" formControlName="email" class="form-control"
                            placeholder="Type Email Address">
                        <small class="errormsg"
                            *ngIf="Form.get('owneraddressgroup')?.errors?.['pattern'] && (Form.get('owneraddressgroup').get('email').touched || submitAttempt)">
                            Enter a valid E-mail
                        </small>
                    </div>

                    <div class="col-md-4 form-group">
                        <label>Primary Phone Number</label>
                        <input type="text" formControlName="primaryphone" numbersOnly maxlength="12"
                            class="form-control" placeholder="Enter Number"
                            [value]="Form.get('owneraddressgroup').get('primaryphone').value | phoneMask">
                        <small class="errormsg"
                            *ngIf="Form.get('owneraddressgroup').get('primaryphone').errors?.invalidmobile  && (Form.get('owneraddressgroup').get('primaryphone').touched || submitAttempt)">
                            Phone Number must be of 10 digits
                        </small>
                    </div>

                    <div class="col-md-4 form-group" *ngIf="group_template != 'template_1'">
                        <label>Alternate Phone Number</label>
                        <input type="text" formControlName="alternatephone" numbersOnly maxlength="12"
                            class="form-control" placeholder="Enter Number"
                            [value]="Form.get('owneraddressgroup').get('alternatephone').value | phoneMask">
                        <small class="errormsg"
                            *ngIf="Form.get('owneraddressgroup').get('alternatephone').errors?.invalidmobile  && (Form.get('owneraddressgroup').get('alternatephone').touched || submitAttempt)">
                            Phone Number must be of 10 digits
                        </small>
                    </div>

                </div>
            </ng-container>
            <button class="btn btn-primary d-flex gap-2 mb-3" (click)="addownerAddress()"><i
                    class="ri-add-line mr-1"></i>Add
                Owner </button>


            <div formArrayName="owneraddress">
                <div *ngFor="let item of owneraddress.controls; let i = index;" class="dependent-card">
                    <div [formGroupName]="i">
                        <div class="row">
                            <div class="col-md-4 form-group">
                                <label>First Name </label>
                                <input type="text" formControlName="firstname" class="form-control"
                                    placeholder="Enter First Name">


                            </div>
                            <div class="col-md-4 form-group">
                                <label>Last Name</label>
                                <input type="text" formControlName="lastname" class="form-control"
                                    placeholder="Enter Last Name">
                            </div>
                            <div class="col-md-4 form-group">
                                <label> Title </label>
                                <input type="text" formControlName="title" class="form-control"
                                    placeholder="Enter Title">

                            </div>

                            <div class="col-md-4 form-group">
                                <label> Email </label>
                                <input type="text" formControlName="email" class="form-control"
                                    placeholder="Type Email Address">

                                <small class="errormsg"
                                    *ngIf="item.get('email')?.errors?.['pattern'] && (item.get('email')?.touched || submitAttempt)">
                                    Enter a valid E-mail
                                </small>

                            </div>


                            <div class="col-md-4 form-group">
                                <label>Primary Phone Number</label>
                                <input type="text" formControlName="primaryphone" numbersOnly class="form-control"
                                    maxlength="12" placeholder="Enter Number"
                                    [value]="item.get('primaryphone')?.value | phoneMask">

                                <small class="errormsg"
                                    *ngIf="item.get('primaryphone')?.errors?.['invalidmobile'] && (item.get('primaryphone')?.touched || submitAttempt)">
                                    Phone Number must be of 10 digits
                                </small>

                            </div>
                            <div class="col-md-4 form-group"  *ngIf="group_template != 'template_1'">
                                <label>Alternate Phone Number</label>
                                <input type="text" formControlName="alternatephone" numbersOnly class="form-control"
                                    maxlength="12" placeholder="Enter Number"
                                    [value]="item.get('alternatephone')?.value | phoneMask">

                                <small class="errormsg"
                                    *ngIf="item.get('alternatephone')?.errors?.['invalidmobile'] && (item.get('alternatephone')?.touched || submitAttempt)">
                                    Phone Number must be of 10 digits
                                </small>

                            </div>
                            <div class="col-md-12 text-end">
                                <button type="submit" class="btn-remove mb-3" (click)="removeOwnerAddress(i)"><i
                                        class="ri-user-unfollow-line"></i> Remove </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <h6 class="title-hr">
                <span class="">Group Admin Person Information</span>
            </h6>

            <label class="d-flex gap-2 mb-4">
                <input type="checkbox" [formControl]="groupadminCheckControl">
                <span> Check this if the Primary owner is the Admin</span>
            </label>


            <div class="row">
                <ng-container formGroupName="groupadminaddressgroup">

                    <div class="col-md-4 form-group">
                        <label  [ngClass]="group_template == 'template_1' ? 'required' : ''">First Name </label>
                        <input type="text" formControlName="firstname"
                            [attr.disabled]="ischeckedownerAddress?'':null" class="form-control"
                            placeholder="Enter First Name">
                        <small class="errormsg"
                            *ngIf="Form.get('groupadminaddressgroup').get('firstname').errors?.required && (Form.get('groupadminaddressgroup').get('firstname').dirty || submitAttempt)">
                            First Name is required
                        </small>

                    </div>
                    <div class="col-md-4 form-group">
                        <label>Last Name </label>
                        <input type="text" formControlName="lastname"
                            [attr.disabled]="ischeckedownerAddress?'':null" class="form-control"
                            placeholder="Enter Last Name">

                    </div>
                    <div class="col-md-4 form-group">
                        <label>Title </label>
                        <input type="text" formControlName="title" class="form-control"
                            [attr.disabled]="ischeckedownerAddress?'':null" placeholder="Enter Title">

                    </div>

                    <div class="col-md-4 form-group">
                        <label >Email </label>
                        <input type="text" formControlName="email" [attr.disabled]="ischeckedownerAddress?'':null"
                            class="form-control" placeholder="Type Email Address">

                        <!-- <small class="errormsg"
                            *ngIf="!Form.get('groupadminaddressgroup').get('email').valid && (Form.get('groupadminaddressgroup').get('email').dirty || submitAttempt)">
                            Enter a valid E-mail
                        </small> -->

                    </div>
                    <div class="col-md-4 form-group">
                        <label>Primary Phone Number </label>
                        <input type="text" formControlName="primaryphone"
                            [attr.disabled]="ischeckedownerAddress?'':null" numbersOnly class="form-control"
                            maxlength="12" placeholder="Enter Number"
                            [value]="Form.get('groupadminaddressgroup').get('primaryphone').value | phoneMask">

                        <small class="errormsg"
                            *ngIf="Form.get('groupadminaddressgroup').get('primaryphone').errors?.invalidmobile  && (Form.get('groupadminaddressgroup').get('primaryphone').touched || submitAttempt)">
                            Phone Number must be of 10 digits
                        </small>


                    </div>

                    <div class="col-md-4 form-group" *ngIf="group_template != 'template_1'">
                        <label>Alternate Phone Number </label>

                        <input type="text" formControlName="alternatephone"
                            [attr.disabled]="ischeckedownerAddress?'':null" numbersOnly
                            [value]="Form.get('groupadminaddressgroup').get('alternatephone').value | phoneMask"
                            class="form-control" maxlength="12" placeholder="Enter Number">
                        <small class="errormsg"
                            *ngIf="Form.get('groupadminaddressgroup').get('alternatephone').errors?.invalidmobile  && (Form.get('groupadminaddressgroup').get('alternatephone').touched || submitAttempt)">
                            Phone Number must be of 10 digits
                        </small>
                    </div>
                </ng-container>

                <ng-container *ngIf="group_template != 'template_1'">
                <h6 class="title-hr">
                    <span>Other Details </span>
                </h6>
                <div class="col-md-12 form-group">
                    <label class="">Potential Employer Contribution for Employee Benefit </label>
                    <textarea class="form-control" formControlName="employeecontribution" rows="2"></textarea>
                </div>
                <div class="col-md-4 form-group">
                    <label class="">Domicile State </label>
                    <select class="form-select" formControlName="domocilestate">
                        <option value="" disabled selected>Select Your State</option>
                        <option *ngFor="let item of usaStates" value="{{item.name}}">{{item.name}}</option>
                    </select>
                </div>
                <div class="col-md-4 form-group">
                    <label class="">No of locations </label>
                    <input type="text" formControlName="nooflocations" numbersOnly class="form-control"
                        placeholder="Enter Number">
                </div>
                <div class="col-md-4 form-group">
                    <label class="">No of eligible employees </label>
                    <input type="text" formControlName="eligibleemployees" numbersOnly class="form-control"
                        placeholder="Enter Number">
                </div>
                <div class="col-md-6 form-group">
                    <label class="">Current no of employees on health plan </label>
                    <input type="text" formControlName="healthplan" class="form-control" numbersOnly
                        placeholder="Enter Number">
                </div>
                <div class="col-md-6 form-group">
                    <label class="">Weekly working hours to be eligible for benefit </label>
                    <input type="text" formControlName="workinghrs" class="form-control" numbersOnly
                        placeholder="Enter Number">
                </div>
                <div class="row  mt-3">


                    <div class="col-md-2 d-flex align-items-center ">
                        <label class="d-flex gap-2">
                            <h5>Current Benefits</h5>
                        </label>
                    </div>

                    <div class="col-md-3  form-group">


                    </div>

                    <div class="col-md-4 form-group">

                    </div>

                    <div class="col-md-3 form-group">

                    </div>

                    <!-- </div> -->
                </div>
                <ng-container formGroupName="healthbenefits">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-2 d-flex align-items-center ">
                                <label class="d-flex gap-2">
                                    <input type="checkbox" [formControl]="healthCheckControl"
                                        class="form-check-input">
                                    Health
                                </label>
                            </div>

                        </div>
                    </div>
                    <div class="row  mt-3">



                        <div class="col-md-4  form-group" *ngIf="healthCheckControl.value">
                            <label class="d-flex gap-2">
                                Deductible
                            </label>
                            <div class="input-group">
                                <span class="input-group-text"> $ </span><input type="text" appTwoDigitDecimaNumber
                                    formControlName="deductible" class="form-control" placeholder="Enter Amount">
                            </div>

                        </div>

                        <div class="col-md-4 form-group" *ngIf="healthCheckControl.value">
                            <label class="d-flex gap-2">

                                Max out of pocket
                            </label>
                            <div class="input-group">
                                <span class="input-group-text"> $ </span><input type="text" appTwoDigitDecimaNumber
                                    formControlName="max_out_of_pocket" class="form-control"
                                    placeholder="Enter Amount">
                            </div>
                        </div>

                        <div class="col-md-4 form-group" *ngIf="healthCheckControl.value">
                            <label class="d-flex gap-2">
                                Current Carrier

                            </label>
                            <input type="text" formControlName="current_carrier" class="form-control"
                                appTwoDigitDecimaNumber placeholder="Current Carrier">
                        </div>

                    </div>
                </ng-container>
                <div class="row  ">

                    <ng-container formGroupName="healthemployeedetails" *ngIf="healthCheckControl.value">

                        <div class="row">
                            <div class="col-md-12">
                                <label>
                                    <h5 class="employeehdng">Employee</h5>

                                </label>
                            </div>
                            <div class="col-md-4">

                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee','healthemployeedetails')">
                                </div>

                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee','healthemployeedetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee','healthemployeedetails')">
                                </div>
                            </div>
                        </div>



                    </ng-container>

                    <ng-container formGroupName="healthspousedetails" *ngIf="healthCheckControl.value">
                        <div class="row mt-3">


                            <div class="col-md-12">
                                <label>

                                    <h5 class="employeehdng"> Employee/Spouse</h5>
                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Spouse','healthspousedetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Spouse','healthspousedetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Spouse','healthspousedetails')">
                                </div>
                            </div>
                        </div>



                    </ng-container>

                    <ng-container formGroupName="healthchildrendetails" *ngIf="healthCheckControl.value">
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label>

                                    <h5 class="employeehdng"> Employee/Children</h5>
                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Children','healthchildrendetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Children','healthchildrendetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Children','healthchildrendetails')">
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container formGroupName="healthfamilydetails" *ngIf="healthCheckControl.value">
                        <div class="row mt-3 mb-4">
                            <div class="col-md-12">
                                <label>
                                    <h5 class="employeehdng">Family</h5>

                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Family','healthfamilydetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Family','healthfamilydetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Family','healthfamilydetails')">
                                </div>
                            </div>
                        </div>

                    </ng-container>






                </div>
                <ng-container formGroupName="lifebenefits">

                    <div class="row ">

                        <div class="col-md-12 ">
                            <label class="d-flex gap-2">
                                <input type="checkbox" [formControl]="lifeCheckControl" class="form-check-input">
                                Life
                            </label>
                        </div>
                        <div class="col-md-4 pt-3  form-group" *ngIf="lifeCheckControl.value">
                            <label class="d-flex gap-2">
                                Benefit
                            </label>

                            <div class="input-group" *ngIf="lifeCheckControl.value">
                                <span class="input-group-text"> $ </span><input type="text" appTwoDigitDecimaNumber
                                    formControlName="benefit" class="form-control" placeholder="Enter Amount">
                            </div>

                        </div>
                        <div class="col-md-4 pt-3 form-group" *ngIf="lifeCheckControl.value">
                            <label class="d-flex gap-2">

                                Supplememtal
                            </label>

                            <div class="input-group">
                                <span class="input-group-text"> $ </span><input type="text" appTwoDigitDecimaNumber
                                    formControlName="supplemental" class="form-control" placeholder="Enter Amount">
                            </div>
                        </div>
                        <div class="col-md-3 pt-3 form-group" *ngIf="lifeCheckControl.value">
                            <label class="d-flex gap-2">
                                Current Carrier

                            </label>
                            <input type="text" formControlName="currentcarrier" class="form-control"
                                appTwoDigitDecimaNumber placeholder="Current Carrier
                         ">
                        </div>

                        <!-- </div> -->
                    </div>
                </ng-container>
                <!--------Life-->
                <div class="row  ">

                    <ng-container formGroupName="lifeemployeedetails" *ngIf="lifeCheckControl.value">

                        <div class="row">
                            <div class="col-md-12">
                                <label>
                                    <h5 class="employeehdng">Employee</h5>

                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee','lifeemployeedetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee','lifeemployeedetails')">
                                </div>
                            </div>

                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee','lifeemployeedetails')">
                                </div>
                            </div>
                        </div>




                    </ng-container>

                    <ng-container formGroupName="lifespousedetails" *ngIf="lifeCheckControl.value">
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label>

                                    <h5 class="employeehdng"> Employee/Spouse</h5>
                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Spouse','lifespousedetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Spouse','lifespousedetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Spouse','lifespousedetails')">
                                </div>
                            </div>
                        </div>


                    </ng-container>

                    <ng-container formGroupName="lifechildrendetails" *ngIf="lifeCheckControl.value">

                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label>

                                    <h5 class="employeehdng"> Employee/Children</h5>
                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Children','lifechildrendetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Children','lifechildrendetails')">
                                </div>
                            </div>

                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        formControlName="employer_contribution" class="form-control"
                                        placeholder="Enter Amount" appTwoDigitDecimaNumber
                                        (focusout)="onFocusoutInput('Employee + Children','lifechildrendetails')">
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container formGroupName="lifefamilydetails" *ngIf="lifeCheckControl.value">
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label>
                                    <h5 class="employeehdng">Family</h5>

                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Family','lifefamilydetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Family','lifefamilydetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Family','lifefamilydetails')">
                                </div>
                            </div>
                        </div>

                    </ng-container>

                </div>
                <!--------Life-->
                <ng-container formGroupName="disabilitybenefits">

                    <div class="row  mt-3">

                        <div class="col-md-12 d-flex align-items-center ">
                            <label class="d-flex gap-2">
                                <input type="checkbox" [formControl]="disabilityCheckControl"
                                    class="form-check-input"> Disability
                            </label>
                        </div>
                        <div class="col-md-4 d-flex align-items-center" *ngIf="disabilityCheckControl.value">
                            <label class="d-flex gap-2">
                                <input type="checkbox" formControlName="std" class="form-check-input"> STD
                            </label>


                        </div>
                        <div class="col-md-4 d-flex align-items-center" *ngIf="disabilityCheckControl.value">
                            <label class="d-flex gap-2">

                                <input type="checkbox" formControlName="ltd" class="form-check-input"> LTD
                            </label>

                        </div>
                        <div class="col-md-4 form-group" *ngIf="disabilityCheckControl.value">
                            <label class="d-flex gap-2">
                                Current Carrier

                            </label>
                            <input type="text" formControlName="currentcarrier" appTwoDigitDecimaNumber
                                class="form-control" placeholder="Current Carrier
                             ">
                        </div>







                    </div>
                </ng-container>
                <!--------disability-->
                <div class="row  ">

                    <ng-container formGroupName="disabilityemployeedetails" *ngIf="disabilityCheckControl.value">

                        <div class="row">
                            <div class="col-md-12">
                                <label>
                                    <h5 class="employeehdng">Employee</h5>

                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee','disabilityemployeedetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee','disabilityemployeedetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee','disabilityemployeedetails')">
                                </div>
                            </div>
                        </div>



                    </ng-container>

                    <ng-container formGroupName="disabilityspousedetails" *ngIf="disabilityCheckControl.value">
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label>

                                    <h5 class="employeehdng"> Employee/Spouse</h5>
                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Spouse','disabilityspousedetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Spouse','disabilityspousedetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Spouse','disabilityspousedetails')">
                                </div>
                            </div>
                        </div>



                    </ng-container>

                    <ng-container formGroupName="disabilitychildrendetails" *ngIf="disabilityCheckControl.value">

                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label>

                                    <h5 class="employeehdng"> Employee/Children</h5>
                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Children','disabilitychildrendetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Children','disabilitychildrendetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        formControlName="employer_contribution" class="form-control"
                                        placeholder="Enter Amount" appTwoDigitDecimaNumber
                                        (focusout)="onFocusoutInput('Employee + Children','disabilitychildrendetails')">
                                </div>
                            </div>
                        </div>


                    </ng-container>

                    <ng-container formGroupName="disabilityfamilydetails" *ngIf="disabilityCheckControl.value">
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label>
                                    <h5 class="employeehdng">Family</h5>

                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Family','disabilityfamilydetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Family','disabilityfamilydetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Family','disabilityfamilydetails')">
                                </div>
                            </div>

                        </div>


                    </ng-container>

                </div>
                <!--------disability-->
                <ng-container formGroupName="dentalbenefits">

                    <div class="row mt-3">
                        <div class="col-md-12 d-flex align-items-center ">
                            <label class="d-flex gap-2">
                                <input type="checkbox" [formControl]="dentalCheckControl" class="form-check-input">
                                Dental
                            </label>
                        </div>


                        <div class="col-md-4 pt-3 form-group" *ngIf="dentalCheckControl.value">
                            <label class="d-flex gap-2">
                                Orthodontia
                            </label>

                            <div class="input-group" *ngIf="dentalCheckControl.value">
                                <span class="input-group-text"> $ </span><input type="text" appTwoDigitDecimaNumber
                                    formControlName="orthodontia" class="form-control" placeholder="Enter Amount">
                            </div>

                        </div>

                        <div class="col-md-4 pt-3 form-group" *ngIf="dentalCheckControl.value">
                            <label class="d-flex gap-2">

                                Annual Max
                            </label>

                            <div class="input-group">
                                <span class="input-group-text"> $ </span><input type="text" appTwoDigitDecimaNumber
                                    formControlName="annualmax" class="form-control" placeholder="Enter Amount">
                            </div>
                        </div>

                        <div class="col-md-4 pt-3 form-group" *ngIf="dentalCheckControl.value">
                            <label class="d-flex gap-2">
                                Current Carrier

                            </label>
                            <input type="text" formControlName="currentcarrier" class="form-control"
                                appTwoDigitDecimaNumber placeholder="Current Carrier">
                        </div>

                    </div>
                </ng-container>
                <!--------dental-->
                <div class="row  ">

                    <ng-container formGroupName="dentalemployeedetails" *ngIf="dentalCheckControl.value">
                        <div class="col-md-12">
                            <label>
                                <h5 class="employeehdng">Employee</h5>

                            </label>
                        </div>
                        <div class="col-md-4">
                            <label>Current</label>
                            <div class="input-group">
                                <span class="input-group-text"> $ </span><input type="text" appTwoDigitDecimaNumber
                                    formControlName="current" class="form-control" placeholder="Enter Amount"
                                    (focusout)="onFocusoutInput('Employee','dentalemployeedetails')">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>Renewal</label>
                            <div class="input-group">
                                <span class="input-group-text"> $ </span><input type="text" appTwoDigitDecimaNumber
                                    formControlName="renewal" class="form-control" placeholder="Enter Amount"
                                    (focusout)="onFocusoutInput('Employee','dentalemployeedetails')">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>Employer Contribution</label>
                            <div class="input-group">
                                <span class="input-group-text"> $ </span><input type="text"
                                    formControlName="employer_contribution" class="form-control"
                                    placeholder="Enter Amount" appTwoDigitDecimaNumber
                                    (focusout)="onFocusoutInput('Employee','dentalemployeedetails')">
                            </div>
                        </div>



                    </ng-container>

                    <ng-container formGroupName="dentalspousedetails" *ngIf="dentalCheckControl.value">
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label>

                                    <h5 class="employeehdng"> Employee/Spouse</h5>
                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Spouse','dentalspousedetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Spouse','dentalspousedetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Spouse','dentalspousedetails')">
                                </div>
                            </div>
                        </div>


                    </ng-container>

                    <ng-container formGroupName="dentalchildrendetails" *ngIf="dentalCheckControl.value">
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label>

                                    <h5 class="employeehdng"> Employee/Children</h5>
                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Children','dentalchildrendetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Children','dentalchildrendetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Children','dentalchildrendetails')">
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container formGroupName="dentalfamilydetails" *ngIf="dentalCheckControl.value">
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label>
                                    <h5 class="employeehdng">Family</h5>

                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Family','dentalfamilydetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Family','dentalfamilydetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Family','dentalfamilydetails')">
                                </div>
                            </div>
                        </div>

                    </ng-container>

                </div>
                <!--------dental-->
                <ng-container formGroupName="visionbenefits">

                    <div class="row mt-3">

                        <div class="col-md-12 d-flex align-items-center ">
                            <label class="d-flex gap-2">
                                <input type="checkbox" [formControl]="visionCheckControl" class="form-check-input">
                                Vision
                            </label>
                        </div>

                    </div>
                </ng-container>
                <div class="row  ">

                    <ng-container formGroupName="visionemployeedetails" *ngIf="visionCheckControl.value">

                        <div class="row pt-3">
                            <div class="col-md-12">
                                <label>
                                    <h5 class="employeehdng">Employee</h5>

                                </label>
                            </div>
                            <div class="col-md-4">

                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee','visionemployeedetails')">
                                </div>

                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee','visionemployeedetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee','visionemployeedetails')">
                                </div>
                            </div>
                        </div>



                    </ng-container>

                    <ng-container formGroupName="visionspousedetails" *ngIf="visionCheckControl.value">
                        <div class="row mt-3">


                            <div class="col-md-12">
                                <label>

                                    <h5 class="employeehdng"> Employee/Spouse</h5>
                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Spouse','visionspousedetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Spouse','visionspousedetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Spouse','visionspousedetails')">
                                </div>
                            </div>
                        </div>



                    </ng-container>

                    <ng-container formGroupName="visionchildrendetails" *ngIf="visionCheckControl.value">
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label>

                                    <h5 class="employeehdng"> Employee/Children</h5>
                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Children','visionchildrendetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Children','visionchildrendetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Children','visionchildrendetails')">
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container formGroupName="visionfamilydetails" *ngIf="visionCheckControl.value">
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label>
                                    <h5 class="employeehdng">Family</h5>

                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Family','visionfamilydetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Family','visionfamilydetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Family','visionfamilydetails')">
                                </div>
                            </div>
                        </div>

                    </ng-container>

                </div>
                <ng-container formGroupName="voluntarybenefits">

                    <div class="row mt-3">


                        <div class="col-md-12 d-flex align-items-center ">
                            <label class="d-flex gap-2">
                                <input type="checkbox" [formControl]="voluntaryCheckControl"
                                    class="form-check-input"> Voluntary
                            </label>
                        </div>



                    </div>

                </ng-container>
                <div class="row  ">

                    <ng-container formGroupName="voluntaryemployeedetails" *ngIf="voluntaryCheckControl.value">

                        <div class="row pt-3">
                            <div class="col-md-12">
                                <label>
                                    <h5 class="employeehdng">Employee</h5>

                                </label>
                            </div>
                            <div class="col-md-4">

                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee','voluntaryemployeedetails')">
                                </div>

                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee','voluntaryemployeedetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee','voluntaryemployeedetails')">
                                </div>
                            </div>
                        </div>



                    </ng-container>

                    <ng-container formGroupName="voluntaryspousedetails" *ngIf="voluntaryCheckControl.value">
                        <div class="row">


                            <div class="col-md-12">
                                <label>

                                    <h5 class="employeehdng"> Employee/Spouse</h5>
                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Spouse','voluntaryspousedetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Spouse','voluntaryspousedetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Spouse','voluntaryspousedetails')">
                                </div>
                            </div>
                        </div>



                    </ng-container>

                    <ng-container formGroupName="voluntarychildrendetails" *ngIf="voluntaryCheckControl.value">
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label>

                                    <h5 class="employeehdng"> Employee/Children</h5>
                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Children','voluntarychildrendetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Children','voluntarychildrendetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Employee + Children','voluntarychildrendetails')">
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container formGroupName="visionfamilydetails" *ngIf="voluntaryCheckControl.value">
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label>
                                    <h5 class="employeehdng">Family</h5>

                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Current</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="current" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Family','voluntaryfamilydetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Renewal</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="renewal" class="form-control"
                                        placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Family','voluntaryfamilydetails')">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Employer Contribution</label>
                                <div class="input-group">
                                    <span class="input-group-text"> $ </span><input type="text"
                                        appTwoDigitDecimaNumber formControlName="employer_contribution"
                                        class="form-control" placeholder="Enter Amount"
                                        (focusout)="onFocusoutInput('Family','voluntaryfamilydetails')">
                                </div>
                            </div>
                        </div>

                    </ng-container>

                </div>
                <ng-container formGroupName="otherbenefits">
                    <div class="row mt-3">
                        <div class="col-md-12 d-flex align-items-center ">
                            <label class="d-flex gap-2">
                                <input type="checkbox" [formControl]="otherCheckControl" class="form-check-input">
                                Other
                            </label>
                        </div>
                        <div class="col-md-12 mt-4  form-group" *ngIf="otherCheckControl.value">

                            <input type="text" class="form-control" formControlName="otherbefefittextbox">

                        </div>
                    </div>


                </ng-container>
                <div class="row mt-4">
                    <div class="col-md-12 form-group">
                        <label>
                            Renewal Date
                        </label>
                        <p-calendar [minDate]="minimumDate" formControlName="renewaldate" [disabledDays]="[0,6]"
                            [showIcon]="true" placeholder="mm/dd/yyyy">
                        </p-calendar>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 form-group">
                        <label>
                            Any Known Health Issues
                        </label>
                        <textarea autosize rows="3" formControlName="knownhealthissues" placeholder="Write here..."
                            class="form-control"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 form-group">
                        <label>
                            Dissatisfaction with current plan
                        </label>
                        <textarea autosize rows="3" formControlName="dissatisfactionplan"
                            placeholder="Enter reason.." class="form-control"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label>
                            Goals for future
                        </label>
                        <textarea autosize rows="3" formControlName="futuregoals" class="form-control"
                            placeholder="Write here..."></textarea>
                    </div>
                </div>
              </ng-container>

                <h6 class="title-hr">
                    <span class="">Payment details</span>
                </h6>
                <div class="row">
                    <div class="col-md-12 mb-4 ">
                        <div class="d-flex   ">
                            <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input " formControlName="paymentsec"
                                    value="check" id="check">
                                <label class="form-check-label" for="check"> Check </label>
                            </div>
                            <div class="form-check form-check-inline  ">
                                <input type="radio" class="form-check-input" formControlName="paymentsec"
                                    value="ACH" id="ach">
                                <label class="form-check-label" for="ach"> ACH</label>
                            </div>
                        </div>
                        <small class="errormsg "
                            *ngIf="!Form.controls.paymentsec.valid && (Form.controls.paymentsec.dirty || submitAttempt)">
                            Payment Selection is required
                        </small>
                    </div>


                </div>

                <div class="row" *ngIf="Form.get('paymentsec').value == 'ACH'">
                  <div *ngIf="bankContentAvailable">
                    <div *ngFor="let item of bankContent" class="mb-2">
                      <label class="d-flex gap-2 justify-content-center mb-1">
                        <h5>{{item.heading}}</h5>
                    </label>
                    <div *ngFor="let paragraph of item.paragraphs">
                      <p>{{ paragraph }}</p>
                    </div>
                    </div>
                  </div>
                    <div class="col-md-4 form-group">
                        <label class="required">Bank Name </label>
                        <input type="text" formControlName="bankname" class="form-control" placeholder="Bank Name">
                        <small class="errormsg"
                            *ngIf="!Form.controls.bankname.valid && (Form.controls.bankname.dirty || submitAttempt)">
                            Bank is required
                        </small>
                    </div>

                    <ng-container formGroupName="routingFormgroup">
                        <div class="col-md-4 form-group">
                            <label class="required">Routing Number</label>
                            <input type="text" autocomplete="new-password" numbersOnly formControlName="routingnumber"
                                BlockCopyPaste class="form-control" placeholder="Routing Number">

                            <div *ngIf="submitAttempt && Form.get('routingFormgroup').get('routingnumber').errors"
                                class="errormsg">
                                <div *ngIf="Form.get('routingFormgroup').get('routingnumber').errors.required"> Routing
                                    Number is required</div>
                            </div>

                        </div>
                        <!-- <div class="col-md-4 form-group">
                            <label class="required">Confirm Routing Number</label>
                            <input type="text" formControlName="confirmroutingnumber" numbersOnly BlockCopyPaste
                                class="form-control" placeholder="confirm routing number">

                            <div *ngIf="submitAttempt && Form.get('routingFormgroup').get('confirmroutingnumber').errors"
                                class="errormsg">
                                <div *ngIf="Form.get('routingFormgroup').get('confirmroutingnumber').errors.required">
                                    Confirm Routing Number is required
                                </div>

                                <div
                                    *ngIf="Form.get('routingFormgroup').get('confirmroutingnumber').errors.confirmedValidator">
                                    Routing Number must match
                                </div>
                            </div>

                        </div> -->
                    </ng-container>


                    <ng-container formGroupName="accountFormgroup">
                        <div class="col-md-4 form-group">
                            <label class="required">Account Number</label>
                            <input type="text" formControlName="accountnumber" numbersOnly BlockCopyPaste
                                class="form-control" placeholder="Account Number">

                            <div *ngIf="submitAttempt && Form.get('accountFormgroup').get('accountnumber').errors"
                                class="errormsg">
                                <div *ngIf="Form.get('accountFormgroup').get('accountnumber').errors.required">
                                    Account Number is required
                                </div>
                            </div>

                        </div>
                        <!-- <div class="col-md-4 form-group">
                            <label class="required">Confirm Account Number</label>
                            <input type="text" formControlName="confirmaccountnumber" numbersOnly BlockCopyPaste
                                class="form-control" placeholder="Confirm Account Number">


                            <div *ngIf="submitAttempt && Form.get('accountFormgroup').get('confirmaccountnumber').errors"
                                class="errormsg">
                                <div *ngIf="Form.get('accountFormgroup').get('confirmaccountnumber').errors.required">
                                    Confirm Account Number is required
                                </div>

                                <div
                                    *ngIf="Form.get('accountFormgroup').get('confirmaccountnumber').errors.confirmedValidator">
                                    Account Number must match
                                </div>
                            </div>
                        </div> -->

                    </ng-container>

                </div>

                <div class="col-md-12 form-group">
                    <h6 class="title-hr">
                        <span>Signature<span class="text-danger">*</span></span>
                    </h6>


                    <ul class="nav nav-tabs" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="home-tab" data-bs-toggle="tab"
                                data-bs-target="#home-tab-pane" type="button" role="tab"
                                aria-controls="home-tab-pane" aria-selected="true"
                                (click)="onTabClick('esignature')">
                                Draw
                            </button>
                        </li>
                        <!-- <li class="nav-item" role="presentation"> //future use
                            <button class="nav-link" id="profile-tab" data-bs-toggle="tab"
                                data-bs-target="#profile-tab-pane" type="button" role="tab"
                                aria-controls="profile-tab-pane" aria-selected="false"
                                (click)="onTabClick('text')">Text</button>
                        </li> -->
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="image-tab" data-bs-toggle="tab"
                                data-bs-target="#image-tab-pane" type="button" role="tab"
                                aria-controls="image-tab-pane" aria-selected="false" (click)="onTabClick('image')">
                                Upload</button>
                        </li>
                    </ul>

                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel"
                            aria-labelledby="home-tab" tabindex="0">
                            <canvas #canvas width="820" height="200" (click)="signatureField('signatureValid')"
                                class="sigPad"></canvas>
                            <div class="row">
                                <div class="col-lg-7">
                                    {{est_date}}
                                </div>
                                <div class="col-lg-1">
                                    <div class="clearMainBtn">
                                        <button type="button" class="btn btn-primary"
                                            (click)="clear()">Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel"
                            aria-labelledby="profile-tab" tabindex="0">
                            <div class="row">
                                <div class="col-md-6 ">
                                    By : <input placeholder="signature" class="form-control"
                                        formControlName="enterText" (focusout)="onfocusoutSignature()"
                                        id="signature" class="input" type="text" style="font-size: 15px;
                                    font-style: italic; " />
                                    <div>
                                        <div style="font-size: 70%;margin-left: 30px;">Signature of
                                            Primary
                                            Applicant <span class="fieldMan">*</span></div>
                                    </div>
                                </div>

                                <div class="col-md-6 ">
                                    Date : <input class="input" class="form-control" style="width: 28%"
                                        placeholder="date" value="{{date | date:'MM/dd/yyyy'}}" />
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade uploadimage" id="image-tab-pane" role="tabpanel"
                            aria-labelledby="image-tab" tabindex="0">
                            <input type="file" multiple="multiple" style="width: 40%" formControlName="uploadImage"
                                accept=".doc,.pdf,.csv,.docx,application/msword,image/png, image/jpeg"
                                name="uploadFile" (change)="upload($event)" class="form-control" id="uploadFIle">
                            <div class="mt-4">
                                {{est_date}}
                            </div>
                        </div>

                    </div>
                    <div *ngIf="message">
                        <small class="errormsg">
                            {{message}}
                        </small>
                    </div>
                    <div *ngIf="uploadedFile " class="mt-2">
                        <img *ngIf="imageUrl" [src]="imageUrl" class="displayImage">
                    </div>
                </div>
            </div>






        </div>
        <div class=" px-4 mb-1 form-card-footer " style="text-align:end">

            <button type="button" class="btn add-btn btn-primary mb-4" (click)="onAddGroup(Form.value)">
                <i class="bi bi-people"></i> Add Group
            </button>
        </div>
    </div>
</form>

</div>

import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ViewportScroller} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {PDFDocument} from 'pdf-lib';
import {SharingService} from '../../../services/sharing.service';

@Component({
    selector: 'app-d2cagreement',
    templateUrl: './d2cagreement.component.html',
    styleUrls: ['./d2cagreement.component.scss']
})
export class D2cagreementComponent implements OnInit {
    companyId: any;
    settings: any;
    agreementData: any[] = [];
    code: any;
    memberId: any = 0;
    pdfSrc: any | ArrayBuffer | null = null;
    role: string;
    sample: any;
    pdfViewer: any;
    pdfBytes: ArrayBuffer | null = null;
    dateFormat: any;
    dateTimeFormat: any;
    decryptedMemberId: any;
    decryptData: any;
    isRiderAvailable = false;
    member_document_id: string;
    mainMemberId: any;
    family_id: string;
    category: any;
    agentInfo: any = null;
    agent_text: any;
    isTermsAccepted = false;
    showTermsError = false;
    loader = false;
  agent_id_from_url_param: any;
  template: any;
  steps: { step: string; active: boolean; }[];

    constructor(private service: SharingService,
                private toastr: ToastrService,
                private route: ActivatedRoute,
                private router: Router,
                private viewportScroller: ViewportScroller
    ) {
        this.mainMemberId = this.route.snapshot.paramMap.get('id');
        this.family_id = this.route.snapshot.paramMap.get('family_id');
        this.route.queryParams.subscribe(params => {
          if (Object.keys(params).length !== 0 && params.hasOwnProperty('agent_id')) {
            this.agent_id_from_url_param = params['agent_id'];
        }
        })
    }

    ngOnInit(): void {
        this.pdfViewer = document.querySelector('#pdfViewer');
        this.service.clientData.subscribe((data) => {
            if (data !== '') {
                this.settings = data;
                this.companyId = data.company_id;
                this.agent_text = data.agent_text;
                this.listEsignMemberDocumentA2C();
                this.template = data?.template;
                this.steps =  [
                  ...(this.template !== 'template_3' ? [{ step: 'Enrollment/Product Pricing', active: false }] : []),
                  { step: 'Payment Details', active: false },
                  { step: 'Terms & Condition', active: true },
                  { step: 'Summary', active: false },
                  { step: 'Confirmation', active: false }
                ];
            }
        });

        // Get Agent info from localStorage
        if (localStorage.getItem('agentInfo')) {
            this.agentInfo = JSON.parse(localStorage.getItem('agentInfo'));
        }
    }

    getDecryptMemberIdA2C(): void {
        const url = 'DecryptMemberIdA2C/' + this.code;
        this.service.getData(url).subscribe((Object: any) => {

            this.decryptedMemberId = Object.data[0].member_id;
            this.decryptData = Object.data;
            localStorage.setItem('decrypted_member_id', Object.data[0].member_id);

            // this.listEsignMemberDocumentA2C(Object.data[0].member_id)
        }, err => {
            console.log(err);
            this.toastr.error(err.error.message);
        });
    }

    listEsignMemberDocumentA2C() {
        let body = {member_id: this.mainMemberId};
        const url = 'ListEsignMemberDocumentA2C';
        this.service.postData(url, body).subscribe((Object: any) => {
            this.agreementData = Object.data;
            this.isRiderAvailable = Object.is_rider_available;
            localStorage.setItem('isRiderAvailable', this.isRiderAvailable ? 'yes' : 'no');
            this.agreementData.forEach(element => {
                element.index = this.agreementData.indexOf(element) + 1;
            });

            this.member_document_id = this.agreementData[0]?.member_document_id;
            //  localStorage.setItem("A2c_familyId",this.decryptData[0].family_id)


            this.getUserInfo()

        }, err => {
            this.toastr.error(err.error.message);
        })
    }


    ngAfterViewInit() {

        // Wait for the viewer to be fully initialized
        setTimeout(() => {
            this.registerPdfSaveCallback();
        }, 200);
        // setTimeout(() => );
    }

    registerPdfSaveCallback(): void {
        const pdfViewerApplication = (window as any).PDFViewerApplication;

        if (pdfViewerApplication) {
            // Ensure that the viewer is loaded and the APIs are accessible
            if (pdfViewerApplication.pdfDocument) {
                // Do nothing if pdfDocument is already available (typically the case when the PDF is already rendered)
            } else {
                // This listens to an event which indicates that the PDF has been loaded into the viewer
                document.addEventListener('pdfloaded', () => this.registerPdfSaveCallback());
            }
        }
    }

    getUserInfo(): void {
        const url = 'DownloadEsignMemberDocument/' + this.member_document_id;
        this.service.downloadData(url).subscribe((obj: any) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(new Blob([obj], {type: obj.type})); // Change to readAsArrayBuffer
            reader.onloadend = () => {
                // Use URL.createObjectURL to create a Blob URL for ngx-extended-pdf-viewer
                this.pdfSrc = URL.createObjectURL(new Blob([reader.result as ArrayBuffer]));
                this.pdfBytes = reader.result as ArrayBuffer; // Save the ArrayBuffer for use with pdf-lib
                this.getFormFieldNames().then(r => {
                });
                this.scrollToBottom();
            };
        }, err => {
        });
    }

    scrollToBottom(): void {
        setTimeout(() => {
            this.viewportScroller.scrollToPosition([0, document.body.scrollHeight]);
        }, 500);
    }

    async getFormFieldNames() {
        if (!this.pdfBytes) {
            console.error('PDF not loaded');
            return undefined;
        }


        const pdfDoc = await PDFDocument.load(this.pdfBytes);
        const form = pdfDoc.getForm();
        const formFields = form.getFields();
        const formFieldNames = formFields.map(field => field.getName());

        console.log(formFieldNames);
        return formFieldNames;
    }

    selectTerms() {
        this.isTermsAccepted = (!this.isTermsAccepted);
        this.showTermsError = (!this.isTermsAccepted);
    }

    async export() {

        if (!this.isTermsAccepted) {
            this.showTermsError = true;
            console.log('validation works');
            return;
        }

        const pdfViewerApplication = (window as any).PDFViewerApplication;

        if (pdfViewerApplication && pdfViewerApplication.pdfDocument) {
            try {
                const pdfDocument = pdfViewerApplication.pdfDocument;
                // This saves the PDF as an ArrayBuffer
                const pdfData = await pdfDocument.saveDocument(pdfDocument.annotationStorage);

                // Convert the ArrayBuffer to a Blob
                const blob = new Blob([pdfData], {type: 'application/pdf'});
                // to download
                // FileSaver.saveAs(blob, "filled.pdf");
                const formData: FormData = new FormData();
                formData.append('member_id', this.mainMemberId);
                formData.append('category', this.category);
                formData.append('member_doccument', blob, '${this.sample.name}.pdf');
                formData.append('state_form_id', this.agreementData[0].state_form_id);
                this.loader = true;
                const url = 'CreateEsignMemberDocumentA2C';
                this.service.postData(url, formData).subscribe((Object: any) => {
                    this.loader = false;
                    if(!!this.agent_id_from_url_param){

                      this.router.navigate(['/package/summary', this.mainMemberId, this.family_id] , {queryParams:{agent_id:this.agent_id_from_url_param}});
                    }else{
                      if(this.template == 'template_3'){
                        let origin = window.location.pathname.split('/')[1]
                        this.router.navigate(['/'+origin+'/summary', this.mainMemberId, this.family_id]);
                      }else{
                        this.router.navigate(['/package/summary', this.mainMemberId, this.family_id]);
                      }

                    }
                }, err => {
                    this.loader = false;
                    this.toastr.error(err.error.message);
                });

                URL.revokeObjectURL(URL.createObjectURL(blob));
            } catch (error) {
                console.error('Error exporting PDF:', error);
            }
        }
    }

    onPrevious(): void {
        //  let acc_member_id = localStorage.getItem("acc_member_id")
        localStorage.setItem('from_Beneficiary', 'Beneficiary');
        if(!!this.agent_id_from_url_param){

          this.router.navigate(['/package/payment', this.family_id, this.mainMemberId], {queryParams:{agent_id:this.agent_id_from_url_param}});
        }else{
          if(this.template == 'template_3'){
            let origin = window.location.pathname.split('/')[1]
            this.router.navigate(['/'+origin+'/payment', this.family_id, this.mainMemberId]);
          }else{
            this.router.navigate(['/package/payment', this.family_id, this.mainMemberId]);
          }

        }
    }
}
